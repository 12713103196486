<template>
  <section class="flex">
    <div
      class="lg:w-3/5 w-full border border-slighter rounded-t-2xl shadow-md lg:pb-10 pb-16 min-h-screen h-full"
    >
      <div class="px-6 py-5 flex items-center justify-between">
        <h1 class="text-xl font-medium text-skillblack">Notifications</h1>
      </div>

      <div v-if="skeletonLoader" class="mx-8">
        <div
          v-for="notificationLoader in 3"
          :key="notificationLoader.index"
          class="lg:p-5 p-2 border-b border-slightest w-full pr-8 animate-pulse skeleton-box bg-gray-100 h-16 rounded-lg mb-3"
        ></div>
      </div>
      <!-- Empty notification -->
      <div v-if="!skeletonLoader && notifications.length === 0">
        <div class="my-5 p-2 text-center">
          <i class="fad fa-exclamation-circle text-yellow-400 text-4xl mb-2"></i>
          <p>No notification found</p>
        </div>
      </div>

      <!--  -->
      <div v-if="!skeletonLoader">
        <div v-for="(notification, index) in notifications" :key="notification.index">
          <div
            @click="notificationStatus(notification.url, notification._id, index)"
            class="lg:p-5 p-2 flex items-center border-b border-slightest focus:outline-none hover:bg-slightyellow w-full pr-8"
            :class="notification.status ? '' : 'bg-yellow-50'"
          >
            <img
              src="../assets/SkillNGBanner.png"
              class="w-10 h-10 lg:mx-4 mx-2 rounded-lg object-cover bg-gray-200"
            />
            <div class="mx-4">
              <p class="text-md text-left text-gray-800">
                {{ notification.message }}
              </p>
              <p class="text-sm font-light text-gray-800 text-left mt-2">
                {{ notification.dateCreated | moment("from", "now") }}
              </p>
            </div>
            <i class="fas fa-reply-all ml-auto text-skilldeepyelow"></i>
          </div>
        </div>
      </div>
    </div>
    <!-- side bar-->
    <ClassSuggestions class="hidden lg:block" />
  </section>
</template>

<script>
import ClassSuggestions from "../components/ClassSuggestions";
import axios from ".././plugin/axios";
export default {
  components: { ClassSuggestions },
  data() {
    return {
      notifications: [],
      skeletonLoader: true,
    };
  },
  methods: {
    async notificationStatus(route, id, index) {
      if (route.startsWith("http")) {
        let theUrl = new URL(route);
        route = theUrl.pathname;
        //console.log(theUrl.pathname);
      }
      this.$router.push(route);
      if (this.notifications[index].status == false) {
        let url = `notifications/markread/`;
        let token = this.$store.state.authUser.token;
        let data = { id: id };
        await axios
          .post(url, data, {
            headers: {
              "x-access-token": token,
            },
          })
          .then((response) => {
            console.log(response);
            let count = this.$store.state.unreadNotification;
            count -= 1;
            this.$store.commit("setUnreadNotification", count);
          })
          .catch(() => {});
      }
    },
  },
  async mounted() {
    let url = `notifications/usernotifications/${this.$store.state.authUser.id}`;
    let token = this.$store.state.authUser.token;

    await axios
      .get(url, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        // console.log(response);
        let data = response.data.data;
        this.notifications = data;
        this.skeletonLoader = false;
      })
      .catch(() => {});
  },
};
</script>

<style></style>
