<template>
  <section
    class="bg-skilldeepyelow w-full h-full flex justify-center items-center overflow-y-auto"
  >
    <div class="xl:w-4/12 lg:w-6/12 w-full md:px-16 lg:px-0 px-6 lg:py-16 py-6 relative">
      <div class="mt-5">
        <button>
          <router-link to="/">
            <img src="../../../src/assets/logo.png" alt="Logo" class="h-10 w-auto" />
          </router-link>
        </button>
      </div>

      <div>
        <p class="font-normal text-3xl my-7">Create new Password</p>
        <p>Enter the new password and then repear it.</p>
      </div>
      <!--  -->
      <vs-alert closable v-model="success" color="success" class="mt-4">
        <div class="text-black font-medium text-sm">
          Password reset successfully!
          <div @click="signin" class="text-black hover:text-mustardyellow cursor-pointer">
            <b>Click here to Sign in</b>
          </div>
        </div>
      </vs-alert>
      <vs-alert closable v-model="error" color="danger" class="mt-4">
        <p class="font-semibold">{{ error }}</p>
      </vs-alert>
      <!--  -->
      <div class="mt-8 center content-inputs">
        <vs-input
          @keydown.enter="focusPassword"
          dark
          block
          border
          ref="password"
          type="password"
          icon-after
          v-model="form.password"
          label-placeholder="Password"
          :progress="getProgress"
          :visiblePassword="hasVisiblePassword"
          @click-icon="hasVisiblePassword = !hasVisiblePassword"
        >
          <template #icon>
            <i v-if="!hasVisiblePassword" class="fas fa-eye-slash"></i>
            <i v-else class="fas fa-eye"></i>
          </template>
          <template v-if="form.password && getProgress >= 40" #message-success>
            Strong Password
          </template>
          <template v-if="form.password && getProgress < 40" #message-danger>
            Weak Password
          </template>
        </vs-input>
      </div>
      <!--  -->
      <div class="mt-10 center content-inputs">
        <vs-input
          @keydown.enter="submit"
          dark
          block
          border
          ref="passwordConfirm"
          type="password"
          icon-after
          v-model="form.passwordConfirm"
          label-placeholder="Password Confirm"
          :visiblePassword="hasVisiblePasswordConfirm"
          @click-icon="hasVisiblePasswordConfirm = !hasVisiblePasswordConfirm"
        >
          <template #icon>
            <i v-if="!hasVisiblePassword" class="fas fa-eye-slash"></i>
            <i v-else class="fas fa-eye"></i>
          </template>
          <template
            v-if="form.passwordConfirm && form.password === form.passwordConfirm"
            #message-success
          >
            Match Password
          </template>
          <template
            v-if="form.passwordConfirm && form.password !== form.passwordConfirm"
            #message-danger
          >
            Password not Match
          </template>
        </vs-input>
      </div>
      <!--  -->
      <div class="flex justify-center mt-8">
        <vs-button
          flat
          active
          @click="submit"
          :loading="loading"
          color="#FFF212"
          class="w-full shadow-md"
          type="button"
          :disabled="
            form.passwordConfirm &&
            form.password === form.passwordConfirm &&
            getProgress >= 40
              ? false
              : true
          "
        >
          <p class="text-skillblack py-0.5 text-base">Reset Password</p>
        </vs-button>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "../../plugin/axios";
export default {
  data() {
    return {
      error: "",
      forgotPasswordTab: false,
      form: {
        password: "",
        passwordConfirm: "",
      },
      token: "",
      success: false,
      hasVisiblePassword: false,
      hasVisiblePasswordConfirm: false,

      loading: false,
    };
  },
  methods: {
    async submit() {
      this.loading = true;
      this.error = "";
      let url = `/user/reset/password/${this.token}`;
      await axios
        .post(url, { password: this.form.password })
        .then((response) => {
          if (response.status === 200) {
            this.success = true;
            console.log(response);
            this.loading = false;
          }
        })
        .catch((error) => {
          this.error = error.response.data.message;
          this.loading = false;
          console.log(error);
        });
    },

    signin() {
      this.$router.push({ name: "Signin" });
    },

    focusPassword() {
      let input = this.$refs.passwordConfirm.$el.querySelector("input");
      input.focus();
    },
  },
  computed: {
    validEmail() {
      /* eslint-disable */
      return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,9})+$/.test(this.form.email);
      /* eslint-enable */
    },
    getProgress() {
      let progress = 0;

      // at least one number

      if (/\d/.test(this.form.password)) {
        progress += 20;
      }

      // at least one capital letter

      if (/(.*[A-Z].*)/.test(this.form.password)) {
        progress += 20;
      }

      // at menons a lowercase

      if (/(.*[a-z].*)/.test(this.form.password)) {
        progress += 20;
      }

      // more than 5 digits

      if (this.form.password.length >= 6) {
        progress += 20;
      }

      // at least one special character

      if (/[^A-Za-z0-9]/.test(this.form.password)) {
        progress += 20;
      }

      return progress;
    },
  },
  mounted() {
    let token = this.$router.currentRoute.fullPath;
    this.token = token.slice(16);
    //console.log(token);
    //console.log(this.token);
  },
};
</script>

<style></style>
