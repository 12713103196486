<template>
  <div class="flex w-full relative">
    <div class="lg:w-5/12 md:w-2/5 w-full">
      <div
        class="w-full border h-almost overflow-y-auto border-slighter rounded-t-2xl shadow-md lg:pb-10 pb-16"
      >
        <div class="px-6 py-5 flex items-center justify-between">
          <h1 class="text-xl font-medium text-skillblack">Settings</h1>
        </div>

        <div class="lg:px-0 px-3">
          <router-link v-for="tab in tabs" :key="tab.title" :to="tab.route">
            <button
              class="hover:bg-slightyellow focus:outline-none p-2 border lg:border-b lg:border-slightest border-gray-100 w-full pr-8 lg:shadow-none shadow-md lg:mb-0 mb-3 lg:rounded-none rounded-md"
            >
              <div class="flex items-center justify-between ml-4 my-2">
                <p class="text-base font-normal text-skillblack">{{ tab.title }}</p>
                <i :class="tab.icon"></i>
              </div>
            </button>
          </router-link>

          <router-link :to="{ name: 'PrivacyPolicy' }">
            <button
              class="hover:bg-slightyellow focus:outline-none p-2 border lg:border-b lg:border-slightest border-gray-100 w-full pr-8 lg:shadow-none shadow-md lg:mb-0 mb-3 lg:rounded-none rounded-md"
            >
              <div class="flex items-center justify-between ml-4 my-2">
                <p class="text-base font-normal text-skillblack">Privacy and Security</p>
                <i class="far fa-angle-right"></i>
              </div>
            </button>
          </router-link>
          <a href="https://wa.me/2347061282516" target="_blank">
            <button
              class="hover:bg-slightyellow focus:outline-none p-2 border lg:border-b lg:border-slightest border-gray-100 w-full pr-8 lg:shadow-none shadow-md lg:mb-0 mb-3 lg:rounded-none rounded-md"
            >
              <div class="flex items-center justify-between ml-4 my-2">
                <p class="text-base font-normal text-skillblack">Help</p>
                <i class="far fa-angle-right"></i>
              </div>
            </button>
          </a>
          <router-link :to="{ name: 'About' }">
            <button
              class="hover:bg-slightyellow focus:outline-none p-2 border lg:border-b lg:border-slightest border-gray-100 w-full pr-8 lg:shadow-none shadow-md lg:mb-0 mb-3 lg:rounded-none rounded-md"
            >
              <div class="flex items-center justify-between ml-4 my-2">
                <p class="text-base font-normal text-skillblack">About SkillNG</p>
                <i class="far fa-angle-right"></i>
              </div>
            </button>
          </router-link>
        </div>
      </div>
    </div>

    <div class="md:block hidden lg:w-7/12 md:w-3/5 h-almost overflow-y-auto">
      <router-view></router-view>
    </div>

    <router-view
      class="block md:hidden h-screen w-full top-0 left-0 bg-white z-50 fixed overflow-y-auto"
    >
    </router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabs: [
        {
          icon: "far fa-angle-right",
          title: "Profile",
          route: "/settings/editprofile",
        },
        {
          icon: "far fa-angle-right",
          title: "My Balance",
          route: "/settings/my-balance",
        },
        {
          icon: "far fa-angle-right",
          title: "Referral",
          route: "/settings/referral",
        },
        {
          icon: "far fa-angle-right",
          title: "Account",
          route: "/settings/account",
        },
      ],
    };
  },
};
</script>

<style></style>
