<template>
  <section class="bg-gray-100 w-full px-6 py-2 h-full overflow-y-auto">
    <div class="px-2 py-5 mt-8 lg:px-8 mx-0">
      <div class="">
        <h1 class="text-xl font-medium text-skillblack lg:px-6">Schools</h1>
      </div>

      <!--  -->
      <div class="w-full lg:px-4 mt-8">
        <div class="shadow-lg rounded bg-white border pb-4">
          <div class="py-3 flex justify-between mx-4">
            <h3 class="font-semibold text-lg text-gray-800">List of Schools</h3>
            <button
              @click="openAddSchoolDialog"
              class="bg-skilldeepyelow py-1 px-3 rounded-md text-sm font-semibold shadow-md"
            >
              Add new School
            </button>
          </div>
          <div class="block w-full overflow-x-auto">
            <table ref="schoolData" class="w-full">
              <thead>
                <tr class="bg-gray-100 border-gray-200 text-gray-600">
                  <th
                    class="py-3 px-4 border text-xs uppercase border-l-0 border-r-0 font-semibold text-left"
                  >
                    Name
                  </th>
                  <th
                    class="px-4 border text-xs uppercase border-l-0 border-r-0 font-semibold text-left"
                  >
                    Group Classes
                  </th>
                  <th
                    class="px-4 border text-xs uppercase border-l-0 border-r-0 font-semibold text-left"
                  >
                    Private CLasses
                  </th>

                  <th class="border border-l-0 border-r-0 text-left"></th>
                  <th class="border border-l-0 border-r-0 text-left"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(school, index) in schools[0]"
                  :key="school.id"
                  class="border-t"
                >
                  <td class="py-2 pl-4">
                    {{ school.name }}
                  </td>
                  <td v-if="!loading2" class="py-2 pl-4">
                    {{ school.groupClasses.length }}
                  </td>
                  <td v-if="!loading2" class="py-2 pl-4">
                    {{ school.privateClasses.length }}
                  </td>
                  <td
                    v-if="!loading2"
                    class="py-2 pr-4 text-right cursor-pointer"
                    @click="edit(index)"
                  >
                    <i class="far fa-edit text-green-600"></i>
                  </td>
                  <td
                    v-if="!loading2"
                    class="py-2 pr-4 text-left cursor-pointer"
                    @click="destroy(index)"
                  >
                    <i class="fas fa-trash-alt text-red-600"></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- Add School  Dialog -->
      <vs-dialog scroll width="650px" v-model="addSchoolDialogOpen">
        <template #header>
          <h4 class="not-margin font-semibold">Add New School</h4>
        </template>
        <div class="my-3">
          <vs-input
            block
            label-placeholder="School Name"
            v-model="newSchool.name"
            class="mb-6"
          />
          <vs-input
            block
            label-placeholder="About School Title "
            v-model="newSchool.aboutTitle"
            class="mb-4"
          />
          <textarea
            class="w-full border rounded-md mb-2 p-3"
            rows="3"
            placeholder="About School Description "
            v-model="newSchool.aboutDescription"
          ></textarea>
        </div>
        <div class="border-b-2 border-gray-100 my-3"></div>

        <div>
          <picture-input
            ref="pictureInput"
            @change="onChange"
            width="600"
            height="600"
            margin="16"
            accept="image/jpeg,image/png"
            size="10"
            buttonClass="bg-skillyelow text-black font-semibold rounded-xl p-3 px-5"
          >
          </picture-input>
        </div>
        <template #footer>
          <div class="footer-dialog">
            <vs-button
              @click="addSchool"
              active
              :disabled="
                newSchool.name !== '' && newSchool.schoolImage !== '' ? false : true
              "
              :loading="loading"
              block
              class="mb-2 mt-1 disabled"
              style="color: black"
            >
              <p class="font-semibold">Submit</p>
            </vs-button>
          </div>
        </template>
      </vs-dialog>
      <!-- Edit School  Dialog -->
      <vs-dialog scroll width="650px" v-model="editSchoolDialogOpen">
        <template #header>
          <h4 class="not-margin font-semibold">Edit School</h4>
        </template>
        <div class="my-3">
          <vs-input
            block
            label-placeholder="School Name"
            v-model="newSchool.name"
            class="mb-6"
          />
          <vs-input
            block
            label-placeholder="About School Title "
            v-model="newSchool.aboutTitle"
            class="mb-4"
          />
          <textarea
            class="w-full border rounded-md mb-2 p-3"
            rows="3"
            placeholder="About School Description "
            v-model="newSchool.aboutDescription"
          ></textarea>
        </div>
        <div class="border-b-2 border-gray-100 my-3"></div>

        <div>
          <picture-input
            ref="pictureInput"
            @change="onChange"
            width="600"
            height="600"
            margin="16"
            :prefill="newSchool.schoolImage"
            size="10"
            buttonClass="bg-skillyelow text-black font-semibold rounded-xl p-3 px-5"
          >
          </picture-input>
        </div>
        <template #footer>
          <div class="footer-dialog">
            <vs-button
              @click="editSchool"
              active
              :loading="loading"
              block
              class="mb-2 mt-1 disabled"
              style="color: black"
            >
              <p class="font-semibold">Update</p>
            </vs-button>
          </div>
        </template>
      </vs-dialog>

      <!-- Delete School Dialog -->
      <vs-dialog width="550px" v-model="destroySchoolDialogOpen">
        <template #header>
          <h4 class="pt-3">
            Are you sure you want to Delete <b>{{ newSchool.name }}</b>
          </h4>
        </template>

        <div class="con-content">
          <p>Deleting this school will delete all associated classed.</p>
        </div>

        <template #footer>
          <div class="flex">
            <vs-button
              :loading="loading"
              @click="destroySchool"
              color="youtube"
              style="color: white"
            >
              <p class="font-semibold">Delete</p>
            </vs-button>
            <vs-button @click="destroySchoolDialogOpen = false" dark transparent>
              Cancel
            </vs-button>
          </div>
        </template>
      </vs-dialog>
      <!--  -->
    </div>
  </section>
</template>

<script>
import PictureInput from "vue-picture-input";
import axios1 from "axios";
import axios from ".././plugin/axios";

export default {
  components: { PictureInput },
  data() {
    return {
      addSchoolDialogOpen: false,
      editSchoolDialogOpen: false,
      destroySchoolDialogOpen: false,
      message: "",
      loading: false,
      loading2: false,
      loadingData: null,
      newSchool: {
        name: "",
        aboutTitle: "",
        aboutDescription: "",
        schoolImage: "",
        schoolid: "",
      },
      schools: [],
    };
  },
  methods: {
    onChange(image) {
      if (image) {
        this.newSchool.schoolImage = image;
      } else {
        console.log("Error Uploding image");
      }
    },

    openAddSchoolDialog() {
      this.addSchoolDialogOpen = true;
      this.newSchool.name = "";
      this.newSchool.schoolImage = "";
    },
    addSchool() {
      this.loading = true;
      if (this.newSchool.schoolImage.includes("data:image")) {
        const url = "https://api.cloudinary.com/v1_1/skillng/image/upload";

        let formData = new FormData();
        formData.append("api_key", "249958796989371");
        formData.append("file", this.newSchool.schoolImage);
        formData.append("upload_preset", "skillng");

        axios1
          .post(url, formData)
          .then((response) => {
            this.newSchool.schoolImage = response.data.secure_url;
            // console.log(this.newSchool.schoolImage);
            this.saveData();
          })
          .catch(() => {
            this.message = "Error Uploding image";
            this.errorAlart();
            this.loading = false;
            this.addSchoolDialogOpen = false;
          });
      } else this.saveData();
    },
    async saveData() {
      let token = this.$store.state.authUser.token;
      let url = "school/create/";
      await axios
        .post(url, this.newSchool, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          // console.log(response);
          if (response.status === 200) {
            this.message = "School created sucessfully";
            this.sucessAlart();
            this.loading = false;
            this.addSchoolDialogOpen = false;
          }
        })
        .catch((error) => {
          this.message = error;
          this.errorAlart();
          this.loading = false;
          this.addSchoolDialogOpen = false;
        });
    },
    edit(index) {
      let id = index;
      let sch = this.schools[0];
      this.newSchool = {
        name: sch[id].name,
        aboutTitle: sch[id].aboutTitle,
        aboutDescription: sch[id].aboutDescription,
        schoolImage: sch[id].schoolImage,
        schoolid: sch[id]._id,
      };
      this.editSchoolDialogOpen = true;
    },
    destroy(index) {
      let id = index;
      let sch = this.schools[0];
      this.newSchool = {
        name: sch[id].name,
        schoolImage: sch[id].schoolImage,
        schoolid: sch[id]._id,
      };
      this.destroySchoolDialogOpen = true;
    },
    editSchool() {
      this.loading = true;
      if (this.newSchool.schoolImage.includes("data:image")) {
        const url = "https://api.cloudinary.com/v1_1/skillng/image/upload";

        let formData = new FormData();
        formData.append("api_key", "249958796989371");
        formData.append("file", this.newSchool.schoolImage);
        formData.append("upload_preset", "skillng");

        axios1
          .post(url, formData)
          .then((response) => {
            this.newSchool.schoolImage = response.data.secure_url;
            // console.log(this.newSchool.schoolImage);
            this.editSchoolUpdate();
          })
          .catch(() => {
            this.message = "Error Uploding image";
            this.errorAlart();
            this.loading = false;
            this.addSchoolDialogOpen = false;
          });
      } else this.editSchoolUpdate();
    },
    async editSchoolUpdate() {
      this.loading = true;
      let token = this.$store.state.authUser.token;

      let url = "school/update/";
      await axios
        .post(url, this.newSchool, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          // console.log(response);
          if (response.status === 200) {
            this.message = "School updated sucessfully";
            this.sucessAlart();
            this.loading = false;
            this.editSchoolDialogOpen = false;
          }
        })
        .catch((error) => {
          this.message = error;
          this.errorAlart();
          this.loading = false;
          this.editSchoolDialogOpen = false;
        });
    },
    async destroySchool() {
      this.loading = true;
      let token = this.$store.state.authUser.token;

      let url = "school/delete/";
      await axios
        .post(
          url,
          { schoolid: this.newSchool.schoolid },
          {
            headers: {
              "x-access-token": token,
            },
          }
        )
        .then((response) => {
          // console.log(response);
          if (response.status === 200) {
            this.message = "School deleted sucessfully";
            this.sucessAlart();
            this.loading = false;
            this.destroySchoolDialogOpen = false;
          }
        })
        .catch((error) => {
          this.message = error;
          this.errorAlart();
          this.loading = false;
          this.destroySchoolDialogOpen = false;
        });
    },
    sucessAlart() {
      this.$swal.fire({
        position: "top-end",
        icon: "success",
        title: this.message,
        showConfirmButton: false,
        timer: 3000,
        toast: true,
      });
      setInterval(() => {
        this.$router.go("");
      }, 1500);
    },
    errorAlart() {
      this.$swal.fire({
        position: "top-end",
        icon: "error",
        title: this.message,
        showConfirmButton: false,
        timer: 6000,
        toast: true,
      });
    },
  },
  async mounted() {
    this.loading2 = true;
    this.schools = [[{ name: "Loading..." }]];
    if (this.$store.state.schools.length === 0) {
      // console.log("empty");
      let url = "school/all";
      let token = this.$store.state.authUser.token;

      await axios
        .get(url, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          let data = response.data.data.schools;
          this.$store.commit("setSchools", data);
        })
        .catch(() => {});
    }
    this.schools = [this.$store.state.schools];
    this.loading2 = false;
  },
};
</script>

<style></style>
