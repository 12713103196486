<template>
  <section class="bg-gray-100 w-full px-6 py-2 h-full overflow-y-auto">
    <div class="px-2 py-5 mt-8 lg:px-8 mx-0">
      <div class="">
        <h1 class="text-xl font-medium text-skillblack lg:px-6">Group Classes</h1>
      </div>
      <!--  -->
      <div class="w-full lg:px-4 mt-4">
        <div class="flex mx-4 space-x-5">
          <vs-button @click="onGoingClassList" class="">
            <h3 class="font-semibold text-base text-gray-800">On-going Classes</h3>
          </vs-button>
          <vs-button @click="finishClassList" class="">
            <h3 class="font-semibold text-base text-gray-800">Finished Classes</h3>
          </vs-button>
          <vs-button @click="allClassList" class="">
            <h3 class="font-semibold text-base text-gray-800">All Classes</h3>
          </vs-button>
        </div>
      </div>
      <!--   -->

      <!--  -->
      <div class="w-full lg:px-4 mt-8">
        <div class="shadow-lg rounded bg-white border pb-4">
          <div class="py-3 flex justify-between mx-4">
            <h3 class="font-semibold text-lg text-gray-800">
              List of
              {{ tab == 1 ? "on-going" : tab == 2 ? "finished" : tab == 3 ? "all" : "" }}
              Group Classes ({{ groupClass.length }})
            </h3>
            <button
              @click="openAddClassDialog = true"
              class="bg-skilldeepyelow py-1 px-3 rounded-md text-sm font-semibold shadow-md"
            >
              Add new Class
            </button>
          </div>
          <div class="block w-full overflow-x-auto">
            <table class="w-full">
              <thead>
                <tr class="bg-gray-100 border-gray-200 text-gray-600">
                  <th
                    class="py-3 px-4 border text-xs uppercase border-l-0 border-r-0 font-semibold text-left"
                  >
                    Name
                  </th>
                  <th
                    class="px-4 border text-xs uppercase border-l-0 border-r-0 font-semibold text-left"
                  >
                    School
                  </th>
                  <th
                    class="px-4 border text-xs uppercase border-l-0 border-r-0 font-semibold text-left"
                  >
                    Due Date
                  </th>
                  <th
                    class="px-4 border text-xs uppercase border-l-0 border-r-0 font-semibold text-left"
                  >
                    Price
                  </th>

                  <th
                    class="px-4 border text-xs uppercase border-l-0 border-r-0 font-semibold text-center"
                  >
                    Progress
                  </th>
                  <th
                    class="px-4 border text-xs uppercase border-l-0 border-r-0 font-semibold text-center"
                  >
                    Action
                  </th>
                  <th class="border border-l-0 border-r-0 text-left"></th>
                  <th class="border border-l-0 border-r-0 text-left"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(clas, index) in groupClass"
                  :key="clas.index"
                  class="border-t"
                >
                  <td class="py-2 pl-4 cursor-pointer" @click="studentList(index)">
                    <p>{{ clas.name }} ({{ clas.students.length }})</p>
                  </td>
                  <td class="py-2 pl-4">
                    <p v-if="clas.school">
                      {{ clas.school[0].name }}
                    </p>
                  </td>
                  <td class="py-2 pl-4 text-sm">
                    <p v-if="clas.dueDate">
                      {{ new Date(clas.dueDate).toDateString() }}
                    </p>
                  </td>
                  <td class="py-2 pl-4">₦{{ clas.price.toLocaleString() }}</td>
                  <!--  -->
                  <td v-if="clas.school" class="">
                    <div
                      class="h-4 w-full relative rounded-full bg-gray-200 overflow-hidden"
                    >
                      <div
                        class="h-full absolute bg-green-500 rounded-full"
                        :style="{ width: percetageGroup(index) + '%' }"
                      ></div>
                      <div class="flex justify-center">
                        <p class="absolute z-20 text-xs">
                          {{ parseFloat(percetageGroup(index)).toFixed(0) }}%
                        </p>
                      </div>
                    </div>
                  </td>
                  <td v-if="clas.school" class="">
                    <div
                      v-if="groupClass[index].startClass.status === false"
                      class="flex justify-center items-center"
                    >
                      <button
                        @click="startClassDialog(index)"
                        flat
                        active
                        class="mt-1 p-1 bg-skilldeepyelow rounded-md text-sm font-medium"
                      >
                        <p class="text-black text-xs px-3">Start</p>
                      </button>
                    </div>
                    <div
                      v-else-if="
                        groupClass[index].startClass.status === true &&
                        percetageGroup(index) < 100
                      "
                      class="flex justify-center items-center"
                    >
                      <button
                        @click="updateModule(index)"
                        flat
                        :loading="loading"
                        active
                        class="mt-1 p-1 bg-skilldeepyelow rounded-md text-sm font-medium"
                      >
                        <p class="text-black text-xs px-1">Update</p>
                      </button>
                    </div>
                    <div
                      v-else-if="groupClass[index].completedClass.status === true"
                      class="flex justify-center items-center"
                    >
                      <p class="text-black text-sm px-1 text-center">Completed</p>
                    </div>
                    <div
                      v-else-if="
                        groupClass[index].startClass.status === true &&
                        percetageGroup(index) == 100
                      "
                      class="flex justify-center items-center"
                    >
                      <vs-button
                        @click="completeClassDialog(index)"
                        flat
                        :loading="loading"
                        active
                        class="mt-1 p-1 bg-skilldeepyelow rounded-md text-sm font-medium"
                      >
                        <p class="text-black text-xs px-1">Complete</p>
                      </vs-button>
                    </div>
                  </td>
                  <!--  -->
                  <td v-if="clas.school" class="py-2 pr-4 text-right cursor-pointer">
                    <i
                      @click="editClassMethod(index)"
                      class="far fa-edit text-green-600"
                    ></i>
                  </td>
                  <td v-if="clas.school" class="py-2 pr-4 text-left">
                    <i
                      @click="destroy(index)"
                      class="fas fa-trash-alt cursor-pointer text-red-600"
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- Add Class  Dialog -->
      <vs-dialog scroll prevent-close width="850px" v-model="openAddClassDialog">
        <template #header>
          <h4 class="not-margin font-semibold">Add New Group Class</h4>
        </template>
        <div class="my-3">
          <vs-input block label-placeholder="Class Name***" v-model="newClass.name" />
        </div>
        <div>
          <p class="text-sm text-red-600 pb-2">Class Image***</p>
          <picture-input
            ref="pictureInput"
            @change="onChange"
            width="600"
            height="300"
            margin="16"
            accept="image/jpeg,image/png"
            size="10"
            buttonClass="bg-skilldeepyelow text-black font-semibold rounded-xl p-3 px-5"
          >
          </picture-input>
        </div>
        <p class="text-sm text-red-600 py-2">Class Description</p>
        <textarea
          v-model="newClass.description"
          class="w-full border rounded-md mb-2 p-3"
          rows="7"
          placeholder="Start typing "
        ></textarea>
        <p class="text-sm text-red-600 py-2">Select a School***</p>
        <div
          v-for="school in this.$store.state.schools"
          :key="school.index"
          class="flex pl-2"
        >
          <vs-radio v-model="newClass.school" :val="school.id">
            {{ school.name }}
          </vs-radio>
        </div>
        <div class="pt-8">
          <p class="text-sm text-red-600">Price***</p>
          <vs-input v-model="newClass.price" block type="number" />
        </div>

        <div class="pt-5">
          <p class="text-sm text-red-600">Allow class audit</p>
          <vs-switch class="w-6" v-model="newClass.classAudit" />
        </div>

        <div class="pt-12">
          <vs-input
            block
            label="Registration Closing Date***"
            type="date"
            v-model="newClass.dueDate"
          />
        </div>
        <div class="pt-8">
          <vs-input
            block
            label="Class Start Date***"
            type="date"
            v-model="newClass.classStartDate"
          />
        </div>

        <p class="text-sm text-red-600 py-2">Curriculum***</p>
        <div
          v-for="(input, index) in newClass.curriculum"
          :key="input.index"
          class="flex items-center my-2"
        >
          <vs-input block placeholder="Module" v-model="newClass.curriculum[index]" />
          <i
            class="fal fa-plus-circle text-2xl text-green-600 px-2"
            @click="addModule"
          ></i>
          <i
            class="fal fa-times-circle text-2xl text-red-600 px-2"
            v-if="newClass.curriculum.length !== 1"
            @click="removeModule(index)"
          ></i>
        </div>

        <!--  -->
        <p class="text-sm text-red-600 py-2">What you'll learn</p>
        <div
          v-for="(input, index) in newClass.learningList"
          :key="input.index"
          class="flex items-center my-2"
        >
          <vs-input
            block
            placeholder="e.g. Figma, Photoshop"
            v-model="newClass.learningList[index]"
          />
          <i
            class="fal fa-plus-circle text-2xl text-green-600 px-2"
            @click="addLearn"
          ></i>
          <i
            class="fal fa-times-circle text-2xl text-red-600 px-2"
            v-if="newClass.learningList.length !== 1"
            @click="removeLearn(index)"
          ></i>
        </div>

        <!--  -->
        <p class="text-sm text-red-600 py-2">This class includes</p>
        <div
          v-for="(input, index) in newClass.inclusion"
          :key="input.index"
          class="flex items-center my-2"
        >
          <vs-input
            block
            placeholder="e.g. 5 PDF articles"
            v-model="newClass.inclusion[index]"
          />
          <i
            class="fal fa-plus-circle text-2xl text-green-600 px-2"
            @click="addInclude"
          ></i>
          <i
            class="fal fa-times-circle text-2xl text-red-600 px-2"
            v-if="newClass.inclusion.length !== 1"
            @click="removeInclude(index)"
          ></i>
        </div>
        <!--  -->
        <div class="pt-8">
          <vs-input
            block
            label="Duration in Weeks"
            placeholder="x week"
            type="text"
            v-model="newClass.duration"
          />
        </div>
        <!--  -->
        <p class="text-sm text-red-600 py-2">Additional Details for Duration</p>
        <textarea
          v-model="newClass.durationDetails"
          class="w-full border rounded-md mb-2 p-3"
          rows="3"
          placeholder="Start typing "
        ></textarea>
        <!--  -->
        <p class="text-sm text-red-600 py-2">Requirements</p>
        <div
          v-for="(input, index) in newClass.requirements"
          :key="input.index"
          class="flex items-center my-2"
        >
          <vs-input
            block
            placeholder="e.g. Laptop"
            v-model="newClass.requirements[index]"
          />
          <i
            class="fal fa-plus-circle text-2xl text-green-600 px-2"
            @click="addRequirement"
          ></i>
          <i
            class="fal fa-times-circle text-2xl text-red-600 px-2"
            v-if="newClass.requirements.length !== 1"
            @click="removeRequirement(index)"
          ></i>
        </div>
        <!--  -->
        <p class="text-sm text-red-600 py-2">Who is this course for?</p>
        <textarea
          v-model="newClass.audienceDescription"
          class="w-full border rounded-md mb-2 p-3"
          rows="3"
          placeholder="Start typing "
        ></textarea>
        <!--  -->
        <p class="text-sm text-red-600 py-2">Venue</p>
        <div class="flex space-x-4 mb-2">
          <vs-radio v-model="newClass.venue" val="Onsite"> Onsite </vs-radio>
          <vs-radio v-model="newClass.venue" val="Online"> Online </vs-radio>
          <vs-radio v-model="newClass.venue" val="Onsite & Online">
            Onsite & Online
          </vs-radio>
        </div>
        <!--  -->
        <p class="text-sm text-red-600 py-2">Additional Class Venue</p>
        <textarea
          v-model="newClass.venuenDetails"
          class="w-full border rounded-md mb-2 p-3"
          rows="3"
          placeholder="Start typing "
        ></textarea>
        <!--  -->
        <div class="mb-14 my-2">
          <p class="text-sm text-red-600 py-2">Tutors***</p>
          <input-tag
            v-model="newClass.tutors"
            placeholder="Type tutor name and end with COMMA"
            :allow-duplicates="true"
          ></input-tag>
        </div>

        <!--  -->
        <template #footer>
          <div class="footer-dialog">
            <vs-button
              @click="addClass"
              block
              active
              :loading="loading"
              :disabled="
                newClass.name !== '' &&
                newClass.school !== '' &&
                newClass.classImage !== '' &&
                newClass.price !== '' &&
                newClass.dueDate !== '' &&
                newClass.curriculum[0] !== '' &&
                newClass.tutors.length > 0
                  ? false
                  : true
              "
              class="mb-2 mt-1 disabled"
              style="color: black"
            >
              <p class="font-semibold">Submit</p>
            </vs-button>
          </div>
        </template>
      </vs-dialog>

      <!-- Edit Class  Dialog -->
      <div v-if="editClass.curriculum !== undefined">
        <vs-dialog scroll prevent-close width="850px" v-model="openEditClassDialog">
          <template #header>
            <h4 class="not-margin font-semibold">Edit Group Class</h4>
          </template>
          <div class="my-3">
            <vs-input block label-placeholder="Class Name" v-model="editClass.name" />
          </div>
          <div>
            <p class="text-sm text-red-600 pb-2">Class Image</p>
            <picture-input
              ref="pictureInput"
              @change="onChangeEdit"
              width="600"
              height="300"
              margin="16"
              :prefill="editClass.classImage"
              size="10"
              buttonClass="bg-skilldeepyelow text-black font-semibold rounded-xl p-3 px-5"
            >
            </picture-input>
          </div>
          <p class="text-sm text-red-600 py-2">Class Description</p>
          <textarea
            v-model="editClass.description"
            class="w-full border rounded-md mb-2 p-3"
            rows="7"
            placeholder="Start typing "
          ></textarea>
          <div class="flex pl-2">
            <p class="text-sm text-red-600 py-2">School</p>
            <vs-button
              @click="updateSchool"
              size="mini"
              style="color: black"
              class="font-semibold"
            >
              Update School
            </vs-button>
          </div>
          <div class="flex pl-2">
            <vs-radio checked>
              {{ editClass.school[0].name }}
            </vs-radio>
          </div>
          <div class="pt-8">
            <p class="text-sm text-red-600">Price</p>
            <vs-input block type="number" v-model="editClass.price" />
          </div>

          <div class="pt-5">
            <p class="text-sm text-red-600">Allow class audit</p>
            <vs-switch class="w-6" v-model="editClass.classAudit" />
          </div>

          <div class="pt-12">
            <vs-input
              block
              label="Registration Closing Date"
              type="date"
              v-model="editClass.dueDate"
            />
          </div>
          <div class="pt-8">
            <vs-input
              block
              label="Class Start Date***"
              type="date"
              v-model="editClass.classStartDate"
            />
          </div>

          <p class="text-sm text-red-600 py-2">Curriculum</p>
          <div
            v-for="(input, index) in editClass.curriculum[0].curriculum"
            :key="input.index"
            class="flex items-center my-2"
          >
            <vs-input
              block
              placeholder="Module"
              v-model="editClass.curriculum[0].curriculum[index].name"
            />
            <i
              class="fal fa-plus-circle text-2xl text-green-600 px-2"
              @click="addModuleEdit"
            ></i>
            <i
              class="fal fa-times-circle text-2xl text-red-600 px-2"
              v-if="editClass.curriculum[0].curriculum !== 1"
              @click="removeModuleEdit(index)"
            ></i>
          </div>

          <!--  -->
          <p class="text-sm text-red-600 py-2">What you'll learn</p>
          <div
            v-for="(input, index) in editClass.learningList"
            :key="input.index"
            class="flex items-center my-2"
          >
            <vs-input
              block
              placeholder="e.g. Figma, Photoshop"
              v-model="editClass.learningList[index]"
            />
            <i
              class="fal fa-plus-circle text-2xl text-green-600 px-2"
              @click="addLearnEdit"
            ></i>
            <i
              class="fal fa-times-circle text-2xl text-red-600 px-2"
              v-if="editClass.learningList.length !== 1"
              @click="removeLearnEdit(index)"
            ></i>
          </div>

          <!--  -->
          <p class="text-sm text-red-600 py-2">This class includes</p>
          <div
            v-for="(input, index) in editClass.inclusion"
            :key="input.index"
            class="flex items-center my-2"
          >
            <vs-input
              block
              placeholder="e.g. 5 PDF articles"
              v-model="editClass.inclusion[index]"
            />
            <i
              class="fal fa-plus-circle text-2xl text-green-600 px-2"
              @click="addIncludeEdit"
            ></i>
            <i
              class="fal fa-times-circle text-2xl text-red-600 px-2"
              v-if="editClass.inclusion.length !== 1"
              @click="removeIncludeEdit(index)"
            ></i>
          </div>
          <!--  -->
          <div class="pt-8">
            <vs-input
              block
              label="Duration in Weeks"
              placeholder="x week"
              type="text"
              v-model="editClass.duration"
            />
          </div>
          <!--  -->
          <p class="text-sm text-red-600 py-2">Additional Details for Duration</p>
          <textarea
            v-model="editClass.durationDetails"
            class="w-full border rounded-md mb-2 p-3"
            rows="3"
            placeholder="Start typing "
          ></textarea>
          <!--  -->
          <p class="text-sm text-red-600 py-2">Requirements</p>
          <div
            v-for="(input, index) in editClass.requirements"
            :key="input.index"
            class="flex items-center my-2"
          >
            <vs-input
              block
              placeholder="e.g. Laptop"
              v-model="editClass.requirements[index]"
            />
            <i
              class="fal fa-plus-circle text-2xl text-green-600 px-2"
              @click="addRequirementEdit"
            ></i>
            <i
              class="fal fa-times-circle text-2xl text-red-600 px-2"
              v-if="editClass.requirements.length !== 1"
              @click="removeRequirementEdit(index)"
            ></i>
          </div>
          <!--  -->
          <p class="text-sm text-red-600 py-2">Who is this course for?</p>
          <textarea
            v-model="editClass.audienceDescription"
            class="w-full border rounded-md mb-2 p-3"
            rows="3"
            placeholder="Start typing "
          ></textarea>
          <!--  -->
          <p class="text-sm text-red-600 py-2">Venue</p>
          <div class="flex space-x-4 mb-2">
            <vs-radio v-model="editClass.venue" val="Onsite"> Onsite </vs-radio>
            <vs-radio v-model="editClass.venue" val="Online"> Online </vs-radio>
            <vs-radio v-model="editClass.venue" val="Onsite & Online">
              Onsite & Online
            </vs-radio>
          </div>
          <!--  -->
          <p class="text-sm text-red-600 py-2">Additional Class Venue</p>
          <textarea
            v-model="editClass.venuenDetails"
            class="w-full border rounded-md mb-2 p-3"
            rows="3"
            placeholder="Start typing "
          ></textarea>
          <!--  -->
          <div class="mb-5 my-2">
            <p class="text-sm text-red-600 py-2">Tutors</p>
            <input-tag
              v-model="editClass.tutors"
              placeholder="Type tutor name and end with COMMA"
              :allow-duplicates="true"
            ></input-tag>
          </div>

          <div class="mb-14 mt-1">
            <vs-button
              @click="addStudentDialogMethod"
              class="disabled"
              style="color: black"
            >
              <p class="font-semibold">Add Student</p>
            </vs-button>
          </div>

          <!--  -->
          <template #footer>
            <div class="footer-dialog">
              <vs-button
                @click="updateClass"
                block
                active
                :loading="loading"
                :disabled="
                  editClass.name !== '' &&
                  editClass.school !== '' &&
                  editClass.classImage !== '' &&
                  editClass.price !== '' &&
                  editClass.dueDate !== '' &&
                  editClass.curriculum[0].curriculum[0] !== '' &&
                  editClass.tutors.length > 0
                    ? false
                    : true
                "
                class="mb-2 mt-1 disabled"
                style="color: black"
              >
                <p class="font-semibold">Update Class</p>
              </vs-button>
            </div>
          </template>
        </vs-dialog>
      </div>

      <!-- Update School Dialog -->
      <vs-dialog overflow-hidden v-model="updateSchoolDialog">
        <template #header>
          <h3>Update School</h3>
        </template>
        <div class="" v-for="(school, index) in updateSchoolList" :key="school.index">
          <vs-radio
            v-model="editClass.school[0]"
            checked
            :val="{
              name: updateSchoolList[index].name,
              _id: updateSchoolList[index]._id,
              id: updateSchoolList[index].id,
            }"
          >
            {{ school.name }}
            <template #icon v-if="school.name == editClass.school[0].name">
              <i class="fas fa-circle"></i>
            </template>
          </vs-radio>
        </div>
        <template #footer>
          <div class="flex justify-center">
            <vs-button style="color: black" @click="updateSchoolDialog = false">
              Ok
            </vs-button>
          </div>
        </template>
      </vs-dialog>

      <!-- Leave Class  Dialog -->
      <vs-dialog width="550px" v-model="leaveClassDialog">
        <template #header>
          <h4 class="pt-3">
            Are you sure you want to remove <b>{{ RemovefromClassUser.UserName }}</b> from
            <b>{{ studentListClassName }}</b>
          </h4>
        </template>

        <div class="con-content">
          <p>This action is irreversible.</p>
        </div>

        <template #footer>
          <div class="flex">
            <vs-button
              :loading="leaveClassLoading"
              @click="leaveClass"
              color="youtube"
              style="color: white"
            >
              <p class="font-semibold">Delete</p>
            </vs-button>
            <vs-button @click="leaveClassDialog = false" dark transparent>
              Cancel
            </vs-button>
          </div>
        </template>
      </vs-dialog>

      <!-- Delete Group Class Dialog -->
      <vs-dialog width="550px" v-model="destroyClassDialogOpen">
        <template #header>
          <h4 class="pt-3">
            Are you sure you want to Delete <b>{{ deleteClass.name }}</b>
          </h4>
        </template>

        <div class="con-content">
          <p>This action is irreversible.</p>
        </div>

        <template #footer>
          <div class="flex">
            <vs-button
              :loading="loading"
              @click="destroyClass"
              color="youtube"
              style="color: white"
            >
              <p class="font-semibold">Delete</p>
            </vs-button>
            <vs-button @click="destroyClassDialogOpen = false" dark transparent>
              Cancel
            </vs-button>
          </div>
        </template>
      </vs-dialog>

      <!-- Start Group Class Dialog -->
      <vs-dialog width="550px" v-model="startClassDialogOpen">
        <template #header>
          <h4 class="pt-3">
            Are you sure you want to Start <b>{{ startClassdata.name }}</b>
          </h4>
        </template>

        <div class="con-content">
          <p>This action is irreversible.</p>
        </div>

        <template #footer>
          <div class="flex">
            <vs-button :loading="loading" @click="startClass" style="color: black">
              <p class="font-semibold">Start</p>
            </vs-button>
            <vs-button @click="startClassDialogOpen = false" dark transparent>
              Cancel
            </vs-button>
          </div>
        </template>
      </vs-dialog>

      <!-- Update Module Dialog -->
      <div v-if="editClass.curriculum !== undefined">
        <vs-dialog scroll width="650px" v-model="openUpdateModuleDialog">
          <div class="p-3">
            <p class="font-semibold pb-3">List of Modules</p>
            <div
              v-for="(modu, index) in editClass.curriculum[0].curriculum"
              :key="modu.index"
              class="flex items-start mb-2"
            >
              <vs-checkbox v-model="modu.completion"> </vs-checkbox>
              <p class="pl-2">Module {{ index + 1 }}: {{ modu.name }}</p>
            </div>
          </div>
          <div class="flex justify-center">
            <vs-button
              @click="updateModuleDB"
              border
              active
              :loading="loading"
              class="p-2 bg-skilldeepyelow py-1 rounded-md text-sm font-medium"
            >
              <p class="text-black">Update Module</p>
            </vs-button>
          </div>
        </vs-dialog>
      </div>

      <!-- Complete Class Dialog -->
      <vs-dialog scroll width="650px" v-model="openCompleteClassDialog">
        <div class="p-3">
          <p class="font-semibold pb-3">Class Completion Details</p>
        </div>
        <div class="pt-4 font-semibold">
          <p class="py-2">Certificate Template</p>
          <vs-select block placeholder="Select" v-model="certificateData.template">
            <vs-option label="SkillNG" value="SkillNG"> SkillNG </vs-option>
            <vs-option label="ProjectSkill100" value="ProjectSkill100">
              ProjectSkill100
            </vs-option>
          </vs-select>
        </div>
        <div class="pt-4 font-semibold">
          <p class="py-2">Certificate Type</p>
          <vs-select block placeholder="Select" v-model="certificateData.type">
            <vs-option label="Premium" value="Premium"> Premium </vs-option>
            <vs-option label="Freemium" value="Freemium"> Freemium </vs-option>
          </vs-select>
          <p v-if="certificateData.type === 'Freemium'" class="py-2">Amount</p>
          <vs-input
            v-if="certificateData.type === 'Freemium'"
            v-model="certificateData.amount"
            type="Number"
            class="block"
            placeholder="Amount"
          />
        </div>
        <div class="flex justify-center">
          <vs-button
            @click="completeClass(certificateData.completeClassIndex)"
            border
            active
            :loading="loading"
            class="p-2 bg-skilldeepyelow py-1 rounded-md text-sm font-medium"
          >
            <p class="text-black">Update Module</p>
          </vs-button>
        </div>
      </vs-dialog>

      <!-- Student Per Class Module -->
      <vs-dialog scroll width="1800px" v-model="openStudentListDialog">
        <div class="p-3">
          <p class="font-semibold pb-3">
            {{ studentClassList.length }} Students in {{ studentListClassName }}
          </p>
        </div>
        <!-- Table -->
        <div class="block w-full overflow-x-auto px-4">
          <vs-table>
            <template #header>
              <vs-input v-model="tableSort.search" border placeholder="Search" />
            </template>
            <template #thead>
              <vs-tr>
                <vs-th
                  sort
                  @click="
                    studentClassList = $vs.sortData($event, studentClassList, 'fullName')
                  "
                >
                  Name
                </vs-th>
                <vs-th
                  sort
                  @click="
                    studentClassList = $vs.sortData($event, studentClassList, 'email')
                  "
                >
                  Email
                </vs-th>
                <vs-th
                  sort
                  @click="
                    studentClassList = $vs.sortData($event, studentClassList, 'mobileNo')
                  "
                >
                  Mobile No
                </vs-th>
                <vs-th
                  sort
                  @click="
                    studentClassList = $vs.sortData($event, studentClassList, 'classPaid')
                  "
                >
                  Payment
                </vs-th>
                <vs-th> </vs-th>
              </vs-tr>
            </template>
            <template #tbody>
              <vs-tr
                :key="index"
                v-for="(tr, index) in $vs.getPage(
                  $vs.getSearch(studentClassList, tableSort.search),
                  tableSort.page,
                  tableSort.max
                )"
                :data="tr"
              >
                <vs-td>
                  {{ tr.fullName }}
                </vs-td>
                <vs-td>
                  {{ tr.email }}
                </vs-td>
                <vs-td>
                  {{ tr.mobileNo }}
                </vs-td>
                <vs-td v-if="tr.groupClasses">
                  {{ tr.groupClasses[0].classPaid ? "Paid" : "Not Paid" }}
                </vs-td>
                <vs-td v-if="tr.groupClasses">
                  <button
                    v-if="!groupClass[studentListClassIndex].completedClass.status"
                    @click="
                      openLeaveClassDialog(
                        tr.groupClasses[0].classid,
                        tr._id,
                        tr.fullName
                      )
                    "
                    flat
                    active
                    class="mt-1 p-1 bg-skilldeepyelow rounded-md text-sm font-medium"
                  >
                    <p class="text-black text-xs px-3">Remove</p>
                  </button>
                </vs-td>
              </vs-tr>
            </template>
            <template #footer>
              <vs-pagination
                v-model="tableSort.page"
                :length="
                  $vs.getLength(
                    $vs.getSearch(studentClassList, tableSort.search),
                    tableSort.max
                  )
                "
              />
            </template>
          </vs-table>
        </div>
      </vs-dialog>

      <!-- add student Dialog -->
      <vs-dialog prevent-close width="550px" v-model="addStudentDialog">
        <template #header>
          <h4 class="pt-3">Add Students by email</h4>
        </template>

        <div class="con-content">
          <div
            v-for="(input, index) in addStudentEmail"
            :key="input.index"
            class="flex items-center my-3"
          >
            <vs-input
              block
              placeholder="Email"
              type="email"
              v-model="addStudentEmail[index]"
            >
              <template
                v-if="!validEmail(index) && addStudentEmail[index] !== ''"
                #message-warn
              >
                <span class="text-red-600"> Email Invalid </span>
              </template>
              <template
                v-if="
                  addStudentEmailResponse[index].email !== '' &&
                  addStudentEmailResponse[index].status
                "
                #message-success
              >
                Valid User - {{ addStudentEmailResponse[index].fullName }}
              </template>
              <template
                v-if="
                  addStudentEmailResponse[index].email !== '' &&
                  !addStudentEmailResponse[index].status
                "
                #message-danger
              >
                Invalid User
              </template>
            </vs-input>
            <i
              class="fal fa-plus-circle text-2xl text-green-600 px-2"
              @click="addStudentEmailBulk"
            ></i>
            <i
              class="fal fa-times-circle text-2xl text-red-600 px-2"
              v-if="addStudentEmail.length !== 1"
              @click="removeStudentEmailBulk(index)"
            ></i>
          </div>
        </div>

        <template #footer>
          <div class="flex">
            <vs-button
              v-if="!addStudentValide"
              :loading="addStudentValideLoading"
              :disabled="
                addStudentEmail.some((email, index) => !validEmail(index) || email === '')
              "
              @click="addStudentValideMethod"
              style="color: black"
            >
              <p class="font-semibold">Validate</p>
            </vs-button>
            <vs-button
              v-else
              :loading="addStudentLoading"
              @click="addStudentMethod"
              style="color: black"
            >
              <p class="font-semibold">Submit</p>
            </vs-button>
            <vs-button @click="closeAddStudentDialog" dark transparent>
              Cancel
            </vs-button>
          </div>
        </template>
      </vs-dialog>

      <!--  -->
    </div>
  </section>
</template>

<script>
import axios1 from "axios";
import axios from ".././plugin/axios";
import PictureInput from "vue-picture-input";
import { fireDB, fireBase } from "../ultilities/firebase";
export default {
  components: { PictureInput },
  data() {
    return {
      picked: "",
      tab: 1,
      loading: false,
      message: "",
      destroyClassDialogOpen: false,
      startClassDialogOpen: false,
      openAddClassDialog: false,
      openEditClassDialog: false,
      openUpdateModuleDialog: false,
      openStudentListDialog: false,
      openCompleteClassDialog: false,
      updateSchoolDialog: false,
      leaveClassDialog: false,
      leaveClassLoading: false,
      interval: null,
      groupClass: [],
      finishedClass: [],
      allClass: [],
      updateSchoolList: [],
      newClass: {
        name: "",
        classImage: "",
        description: "",
        school: "",
        price: "",
        classAudit: false,
        dueDate: "",
        classStartDate: "",
        duration: "",
        durationDetails: "",
        audienceDescription: "",
        venue: "",
        venuenDetails: "",
        curriculum: [""],
        learningList: [""],
        inclusion: [""],
        requirements: [""],
        tutors: [],
      },
      deleteClass: { name: "", classid: "" },
      startClassdata: { name: "", classid: "" },
      editClass: {
        classStartDate: "",
        durationDetails: "",
        venuenDetails: "",
        classAudit: false,
      },
      editSchoolID: "",
      certificateData: { template: "", type: "", amount: "", completeClassIndex: "" },
      studentClassList: [],
      studentListClassName: "",
      studentListClassIndex: "",
      RemovefromClassUser: { UserName: "" },
      tableSort: { search: "", page: 1, max: 20 },
      addStudentDialog: false,
      addStudentLoading: false,
      addStudentValide: false,
      addStudentValideLoading: false,
      addStudentEmail: [""],
      addStudentEmailResponse: [{ email: "", fullName: "", status: false }],
    };
  },
  methods: {
    addModule() {
      this.newClass.curriculum.push("");
    },
    removeModule(index) {
      this.newClass.curriculum = this.newClass.curriculum.filter(
        (input, i) => i !== index
      );
    },
    //
    addLearn() {
      this.newClass.learningList.push("");
    },
    removeLearn(index) {
      this.newClass.learningList = this.newClass.learningList.filter(
        (input, i) => i !== index
      );
    },
    //
    addInclude() {
      this.newClass.inclusion.push("");
    },
    removeInclude(index) {
      this.newClass.inclusion = this.newClass.inclusion.filter((input, i) => i !== index);
    },
    //
    addRequirement() {
      this.newClass.requirements.push("");
    },
    removeRequirement(index) {
      this.newClass.requirements = this.newClass.requirements.filter(
        (input, i) => i !== index
      );
    },
    //
    //
    addModuleEdit() {
      this.editClass.curriculum[0].curriculum.push({ completion: false, name: "" });
    },
    removeModuleEdit(index) {
      this.editClass.curriculum[0].curriculum = this.editClass.curriculum[0].curriculum.filter(
        (input, i) => i !== index
      );
    },
    //
    addLearnEdit() {
      this.editClass.learningList.push("");
    },
    removeLearnEdit(index) {
      this.editClass.learningList = this.editClass.learningList.filter(
        (input, i) => i !== index
      );
    },
    //
    addIncludeEdit() {
      this.editClass.inclusion.push("");
    },
    removeIncludeEdit(index) {
      this.editClass.inclusion = this.editClass.inclusion.filter(
        (input, i) => i !== index
      );
    },
    //
    addRequirementEdit() {
      this.editClass.requirements.push("");
    },
    removeRequirementEdit(index) {
      this.editClass.requirements = this.editClass.requirements.filter(
        (input, i) => i !== index
      );
    },
    //
    //

    async startClass() {
      this.loading = true;
      let token = this.$store.state.authUser.token;
      let classid = this.startClassdata.classid;

      await axios
        .post(
          "gclass/start",
          { classid: classid },
          {
            headers: {
              "x-access-token": token,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.startClassDialogOpen = false;
            this.message = "Class Started Sucesfully";
            this.sucessAlart();
            this.loadClassList();
            this.loading = false;
          }
        })
        .catch((error) => {
          this.message = error;
          this.errorAlart();
          this.loading = false;
          this.openUpdateModuleDialog = false;
        });
    },
    completeClassDialog(index) {
      this.openCompleteClassDialog = true;
      this.certificateData.completeClassIndex = index;
    },
    async completeClass(index) {
      this.loading = true;
      let token = this.$store.state.authUser.token;
      let classid = this.groupClass[index]._id;

      await axios
        .post(
          "gclass/complete",
          { classid: classid },
          {
            headers: {
              "x-access-token": token,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.message = "Class Completed Sucesfully";
            this.sucessAlart();
            this.loadClassList();
            this.classCertificate(index);
            this.loading = false;
          }
        })
        .catch((error) => {
          this.message = error;
          this.errorAlart();
          this.loading = false;
          this.openUpdateModuleDialog = false;
        });
    },

    updateModule(index) {
      this.editClass = this.groupClass[index];
      this.editSchoolID = this.groupClass[index].school[0]._id;
      this.openUpdateModuleDialog = true;
    },

    async updateModuleDB() {
      this.loading = true;
      this.editClass.school = this.editSchoolID;
      this.editClass.classid = this.editClass._id;

      let token = this.$store.state.authUser.token;
      let url = "gclass/update/";
      await axios
        .post(url, this.editClass, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          // console.log(response);
          if (response.status === 200) {
            this.message = "Module updated sucessfully";
            this.sucessAlart();
            this.loadClassList();
            this.loading = false;
            this.openUpdateModuleDialog = false;
          }
        })
        .catch((error) => {
          this.message = error;
          this.errorAlart();
          this.loading = false;
          this.openUpdateModuleDialog = false;
        });
    },

    updateSchool() {
      console.log("hi");
      this.updateSchoolDialog = true;
      this.updateSchoolList = this.$store.state.schools;
    },

    editClassMethod(index) {
      this.editClass = this.groupClass[index];
      this.editSchoolID = this.groupClass[index].school[0]._id;
      this.openEditClassDialog = true;
    },

    async updateClass() {
      this.groupClass = [{ name: "Loading data", students: [], price: 0 }];

      this.loading = true;
      this.editSchoolID = this.editClass.school[0]._id;
      this.editClass.school = this.editSchoolID;
      this.editClass.classid = this.editClass._id;
      if (this.editClass.classImage.includes("data:image")) {
        const url = "https://api.cloudinary.com/v1_1/skillng/image/upload";

        let formData = new FormData();
        formData.append("api_key", "249958796989371");
        formData.append("file", this.editClass.classImage);
        formData.append("upload_preset", "skillng");

        await axios1
          .post(url, formData)
          .then((response) => {
            this.editClass.classImage = response.data.secure_url;
            // console.log(this.newClass.classImage);
            this.saveEditData();
          })
          .catch(() => {
            this.message = "Error Uploding image";
            this.errorAlart();
            this.loading = false;
            this.openEditClassDialog = false;
          });
      } else this.saveEditData();
    },
    async saveEditData() {
      let token = this.$store.state.authUser.token;
      let url = "gclass/update/";
      await axios
        .post(url, this.editClass, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          // console.log(response);
          if (response.status === 200) {
            this.message = "Group Class updated sucessfully";
            this.sucessAlart();
            this.loadClassList();
            this.loading = false;
            this.openEditClassDialog = false;
          }
        })
        .catch((error) => {
          this.message = error;
          this.errorAlart();
          this.loading = false;
          this.openEditClassDialog = false;
        });
    },

    async addClass() {
      this.loading = true;
      if (this.newClass.classImage.includes("data:image")) {
        const url = "https://api.cloudinary.com/v1_1/skillng/image/upload";

        let formData = new FormData();
        formData.append("api_key", "249958796989371");
        formData.append("file", this.newClass.classImage);
        formData.append("upload_preset", "skillng");

        await axios1
          .post(url, formData)
          .then((response) => {
            this.newClass.classImage = response.data.secure_url;
            // console.log(this.newClass.classImage);
            this.saveData();
          })
          .catch(() => {
            this.message = "Error Uploding image";
            this.errorAlart();
            this.loading = false;
            this.openAddClassDialog = false;
          });
      } else this.saveData();
    },
    async saveData() {
      let token = this.$store.state.authUser.token;
      let url = "gclass/create/";
      await axios
        .post(url, this.newClass, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          // console.log(response);
          if (response.status === 200) {
            this.message = "Group Class created sucessfully";
            this.sucessAlart();
            this.loadClassList();
            this.loading = false;
            this.openAddClassDialog = false;

            this.newClass = {
              name: "",
              classImage: "",
              description: "",
              school: "",
              price: "",
              classAudit: false,
              dueDate: "",
              classStartDate: "",
              duration: "",
              durationDetails: "",
              audienceDescription: "",
              venue: "",
              venuenDetails: "",
              curriculum: [""],
              learningList: [""],
              inclusion: [""],
              requirements: [""],
              tutors: [],
            };
          }
        })
        .catch((error) => {
          this.message = error;
          this.errorAlart();
          this.loading = false;
          this.openAddClassDialog = false;
        });
    },

    onChange(image) {
      if (image) {
        this.newClass.classImage = image;
      } else {
        console.log("Error Uploding image");
      }
    },
    onChangeEdit(image) {
      if (image) {
        this.editClass.classImage = image;
      } else {
        console.log("Error Uploding image");
      }
    },
    async destroyClass() {
      this.loading = true;
      let token = this.$store.state.authUser.token;

      let url = "gclass/delete/";
      await axios
        .post(
          url,
          { classid: this.deleteClass.classid },
          {
            headers: {
              "x-access-token": token,
            },
          }
        )
        .then((response) => {
          // console.log(response);
          if (response.status === 200) {
            this.message = "Group Class deleted sucessfully";
            this.sucessAlart();
            this.loadClassList();

            this.loading = false;
            this.destroyClassDialogOpen = false;
          }
        })
        .catch((error) => {
          this.message = error;
          this.errorAlart();
          this.loading = false;
          this.destroyClassDialogOpen = false;
        });
    },
    destroy(index) {
      // let clas = this.$store.state.groupClass;
      this.deleteClass = {
        name: this.groupClass[index].name,
        classid: this.groupClass[index]._id,
      };
      this.destroyClassDialogOpen = true;
    },
    startClassDialog(index) {
      this.startClassdata = {
        name: this.groupClass[index].name,
        classid: this.groupClass[index]._id,
      };
      this.startClassDialogOpen = true;
    },
    sucessAlart() {
      this.$swal.fire({
        position: "top-end",
        icon: "success",
        title: this.message,
        showConfirmButton: false,
        timer: 3000,
        toast: true,
      });
    },
    errorAlart() {
      this.$swal.fire({
        position: "top-end",
        icon: "error",
        title: this.message,
        showConfirmButton: false,
        timer: 6000,
        toast: true,
      });
    },
    async loadClassList() {
      this.tab = 1;
      let url = "gclass/all?completedClass=false";
      let token = this.$store.state.authUser.token;

      await axios
        .get(url, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          let data = response.data.data.classes;
          this.$store.commit("setGroupClass", data);
        })
        .catch(() => {});
      this.groupClass = this.$store.state.groupClass;
    },
    async onGoingClassList() {
      this.tab = 1;
      this.groupClass = await this.$store.state.groupClass;
    },
    async finishClassList() {
      this.tab = 2;
      if (this.finishedClass.length > 0) {
        this.groupClass = this.finishedClass;
        return;
      }
      this.groupClass = [{ name: "Loading data", students: [], price: 0 }];
      let url = "gclass/all?completedClass=true";
      let token = this.$store.state.authUser.token;

      await axios
        .get(url, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          let data = response.data.data.classes;
          this.finishedClass = data;
        })
        .catch(() => {});
      this.groupClass = this.finishedClass;
    },
    async allClassList() {
      this.tab = 3;
      if (this.allClass.length > 0) {
        this.groupClass = this.allClass;
        return;
      }
      this.groupClass = [{ name: "Loading data", students: [], price: 0 }];
      let url = "gclass/all";
      let token = this.$store.state.authUser.token;

      await axios
        .get(url, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          let data = response.data.data.classes;
          this.allClass = data;
        })
        .catch(() => {});
      this.groupClass = this.allClass;
    },
    percetageGroup(index) {
      let count = 0;
      let lenght = this.groupClass[index].curriculum[0].curriculum.length;
      let curriculum = this.groupClass[index].curriculum[0].curriculum;

      for (let i = 0; i < lenght; i++) {
        if (curriculum[i].completion == true) {
          count++;
        }
      }
      return (count / lenght) * 100;
    },

    async studentList(index) {
      this.studentClassList = [{ fullName: "Loading..." }];

      this.studentListClassName = this.groupClass[index].name;
      this.studentListClassIndex = index;
      this.openStudentListDialog = true;
      let token = this.$store.state.authUser.token;
      let url = `gclass/students/${this.groupClass[index]._id}`;
      await axios
        .get(url, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          // console.log(response.data.data.students);
          this.studentClassList = response.data.data.students;
        })
        .catch(() => {});
    },

    async classCertificate(index) {
      let token = this.$store.state.authUser.token;
      let url = "certificate/create";
      let formData = {
        classType: "Group Class",
        classid: `${this.$store.state.groupClass[index]._id}`,
        template: this.certificateData.template,
        type: this.certificateData.type,
        amount: this.certificateData.amount,
        message:
          "Your class has been concluded. Kindly log on to your account to download your certificate. Thank you for learning with us.",
        url: "/classes",
        imgUrl:
          "https://res.cloudinary.com/skillng/image/upload/v1616794336/skillng-app/nzodwkple7pqvinwjnf9.jpg",
      };
      await axios
        .post(url, formData, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          console.log(response);
          this.openCompleteClassDialog = false;
        })
        .catch(() => {});
    },

    openLeaveClassDialog(classID, userID, UserName) {
      this.RemovefromClassUser = { classID: classID, userID: userID, UserName: UserName };
      this.leaveClassDialog = true;
    },

    async leaveClass() {
      const classID = this.RemovefromClassUser.classID;
      const userID = this.RemovefromClassUser.userID;

      this.leaveClassChat(classID, userID);

      this.leaveClassLoading = true;
      // Get Chat User List for deletion
      this.toBeDeleted = [];
      let deleted = [];
      let chatUserListRef = fireDB.ref("chatUserList/" + classID);
      chatUserListRef.once("value", (snapshot) => {
        snapshot.forEach(function (childSnapshot) {
          var childData = childSnapshot.val();
          childData.key = childSnapshot.key;
          if (childData.userId == userID) {
            deleted.push(childData);
          }
        });
      });

      this.interval = setInterval(() => {
        if (deleted != []) {
          this.toBeDeleted = deleted;
          // this.leaveClassChat(classID, userID);
          clearInterval(this.interval);
        }
      }, 1000);
    },

    async leaveClassChat(classID, userID) {
      let token = this.$store.state.authUser.token;
      let url = "user/leaveGClass";
      let formData = {
        userid: userID,
        classid: classID,
      };
      await axios
        .post(url, formData, {
          headers: {
            "x-access-token": token,
          },
        })
        .then(() => {
          //relaod page
          this.loadClassList();
          this.openStudentListDialog = false;
          this.leaveClassDialog = false;
          this.leaveClassLoading = false;
          this.notification(userID);

          // suucess message
          this.$swal.fire({
            position: "center",
            icon: "success",
            title: "You have successfully remove a student from the class.",
            showConfirmButton: false,
            timer: 3000,
            toast: true,
          });

          //announce removal from chat message//
          let newChat = fireDB.ref("chats/" + classID).push();
          newChat.set({
            userId: "",
            message: this.toBeDeleted[0].userName + " remove from chat",
            createdAt: fireBase.database.ServerValue.TIMESTAMP,
          });
          // delete user id from users in firebase
          let deleteUser = fireDB.ref(
            "users/" + userID + "/" + this.toBeDeleted[0].chatKey
          );
          deleteUser.remove();
          // delete chatID from ChatUserList in firebase
          let deleteUserList = fireDB.ref(
            "chatUserList/" + this.toBeDeleted[0].chatId + "/" + this.toBeDeleted[0].key
          );
          deleteUserList.remove();
          // //
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async notification(userID) {
      const url = "notifications/create";
      const token = this.$store.state.authUser.token;
      const notificationData = {
        userid: userID,
        message: `Dear ${this.RemovefromClassUser.UserName}, 
        You have been removed from ${this.studentListClassName} because your audit period has expired.  
        If you wish to continue with the class, kindly go back to https://app.skillng.com to pay and rejoin the class. 
        If you are not planning to continue with the class, kindly let us know why in order to continue to ensure the quality of our service for you and other learners.`,
        url: "https://wa.me/2347061282516",
        imgUrl:
          "https://res.cloudinary.com/skillng/image/upload/v1626132590/skillng-app/t2fpd4ddbr98vz1rwqc2.png",
      };
      await axios
        .post(url, notificationData, {
          headers: {
            "x-access-token": token,
          },
        })
        .then(() => {
          // console.log(response);
        })
        .catch(() => {});
    },
    addStudentDialogMethod() {
      this.addStudentDialog = true;
      this.openEditClassDialog = false;
    },
    async addStudentValideMethod() {
      this.addStudentValide = false;
      this.addStudentValideLoading = true;
      let url = "user/validate/";
      const token = this.$store.state.authUser.token;

      await axios
        .post(
          url,
          { email: this.addStudentEmail },
          {
            headers: {
              "x-access-token": token,
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.addStudentValideLoading = false;
          if (response.data.data.some((item) => item.status === false)) {
            this.addStudentValide = false;
          } else this.addStudentValide = true;
          this.addStudentEmailResponse = response.data.data;
        })
        .catch((error) => {
          this.addStudentValideLoading = false;
          this.$swal.fire({
            position: "top-right",
            icon: "error",
            title: `${error?.response?.data?.message}`,
            showConfirmButton: false,
            timer: 3000,
            toast: true,
          });
        });
    },
    async addStudentMethod() {
      this.addStudentLoading = true;

      let token = this.$store.state.authUser.token;
      let url = "user/addStudentToGClass";
      const extractedIds = this.addStudentEmailResponse.map((item) => item.id);

      let formData = {
        userids: extractedIds,
        classid: this.editClass._id,
      };
      await axios
        .post(url, formData, {
          headers: {
            "x-access-token": token,
          },
        })
        .then(() => {
          this.addStudentLoading = false;
          this.addStudentDialog = false;
          this.$swal.fire({
            position: "top-right",
            icon: "success",
            title: "Student added successfully to the class",
            showConfirmButton: false,
            timer: 3000,
            toast: true,
          });

          this.closeAddStudentDialog();
          this.loadClassList();
        })
        .catch((error) => {
          this.addStudentLoading = false;
          this.$swal.fire({
            position: "top-right",
            icon: "error",
            title: `${error?.response?.data?.message}`,
            showConfirmButton: false,
            timer: 3000,
            toast: true,
          });
        });
    },
    closeAddStudentDialog() {
      this.addStudentDialog = false;
      this.addStudentEmail = [""];
      this.addStudentEmailResponse = [{ email: "", fullName: "", status: false }];
      this.addStudentValide = false;
    },
    addStudentEmailBulk() {
      this.addStudentEmail.push("");
      this.addStudentEmailResponse.push({ email: "", fullName: "", status: false });
      this.addStudentValide = false;
    },
    removeStudentEmailBulk(index) {
      this.addStudentEmail = this.addStudentEmail.filter((input, i) => i !== index);
    },
    validEmail(index) {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const isValid = emailRegex.test(this.addStudentEmail[index]);
      return isValid;
    },
  },
  async mounted() {
    this.groupClass = [{ name: "Loading data", students: [], price: 0 }];
    if (this.$store.state.groupClass.length === 0) {
      let url = "gclass/all?completedClass=false";
      let token = this.$store.state.authUser.token;

      await axios
        .get(url, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          let data = response.data.data.classes;
          this.$store.commit("setGroupClass", data);
        })
        .catch(() => {});
    }
    this.groupClass = this.$store.state.groupClass;
    //
    if (this.$store.state.schools.length === 0) {
      let url = "school/all";
      let token = this.$store.state.authUser.token;

      await axios
        .get(url, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          let data = response.data.data.schools;
          this.$store.commit("setSchools", data);
        })
        .catch(() => {});
    }
  },
  computed: {},
};
</script>

<style></style>
