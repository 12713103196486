<template>
  <div class="flex h-screen w-full">
    <div
      v-if="isLoggedIn"
      class="hidden lg:block lg:pl-6 lg:ml-8 md:ml-2 lg:w-1/5 overflow-y-auto"
    >
      <SideBar />
    </div>
    <div
      v-if="isAdminLoggedIn"
      class="hidden lg:block lg:pl-6 lg:ml-8 md:ml-2 lg:w-1/5 border-r border-slighter overflow-y-auto"
    >
      <AdminSideBar />
    </div>
    <div v-if="isAdminLoggedIn" class="lg:hidden block">
      <AdminMobileBar />
    </div>
    <div
      class="overflow-y-auto"
      :class="
        isLoggedIn || isAdminLoggedIn ? 'lg:w-4/5 w-full ' : 'w-full h-screen min-h-full'
      "
    >
      <!-- offline notifications -->
      <div
        v-if="handleNetworkChangeOffline === true"
        class="sticky top-0 z-50 bg-red-600 text-white text-center flex items-center justify-center p-3"
      >
        <i class="fas fa-wifi-slash px-3 text-xl"></i>
        <p class="font-semibold">
          No Internect Connection! We are as confused as you are. Check your connection
          again.
        </p>
      </div>
      <div
        v-if="handleNetworkChangeOffline === false"
        class="sticky top-0 z-50 bg-green-600 text-white text-center flex items-center justify-center p-3"
      >
        <i class="fas fa-wifi px-3 text-xl"></i>
        <p class="font-semibold">Your internet connection was restored</p>
      </div>

      <!--  -->
      <TopNav v-if="isLoggedIn"></TopNav>
      <router-view></router-view>
    </div>
    <div v-if="isLoggedIn" class="lg:hidden block">
      <ButtomNav />
    </div>
  </div>
</template>

<script>
import SideBar from "./components/navs/SideBar";
import AdminSideBar from "./viewsAdmin/navs/SideBar";
import AdminMobileBar from "./viewsAdmin/navs/MobileBar";
import ButtomNav from "./components/navs/ButtomNav";
import TopNav from "./components/navs/TopNav";
import axios from "./plugin/axios";

export default {
  metaInfo: {
    title: "SkillNG",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  components: { SideBar, AdminSideBar, AdminMobileBar, ButtomNav, TopNav },
  data() {
    return {
      handleNetworkChangeOffline: null,
      interval: null,
      chatUsers: [],
    };
  },

  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
    isAdminLoggedIn() {
      return this.$store.state.isAdminLoggedIn;
    },
  },
  methods: {
    async getAdminSchools() {
      if (this.$store.state.schools.length === 0) {
        let url = "school/all";
        let token = this.$store.state.authUser.token;

        await axios
          .get(url, {
            headers: {
              "x-access-token": token,
            },
          })
          .then((response) => {
            let data = response.data.data.schools;
            this.$store.commit("setSchools", data);
          })
          .catch(() => {
            localStorage.removeItem("skill_user");
            localStorage.removeItem("skill_jwt");
            this.$store.commit("setAuthUser", {});
            this.$store.commit("setIsAdminLoggedIn", false);
            this.$router.push({ name: "Signin" });
          });
      }
    },

    async getProfile() {
      let token = this.$store.state.authUser.token;
      if (Object.keys(this.$store.state.authProfile).length === 0) {
        await axios
          .get(`user/profile/${this.$store.state.authUser._id}`, {
            headers: {
              "x-access-token": token,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              this.$store.commit("setAuthProfile", response.data.data);
            }
          })
          .catch(() => {
            localStorage.removeItem("skill_user");
            localStorage.removeItem("skill_jwt");
            this.$store.commit("setAuthUser", {});
            this.$store.commit("setAuthProfile", {});
            this.$store.commit("setIsLoggedIn", false);
            this.$router.push({ name: "Signin" });
          });
      }
    },

    handleNetworkChange() {
      if (window.navigator.onLine) {
        this.handleNetworkChangeOffline = false;
      } else {
        this.handleNetworkChangeOffline = true;
      }
    },
  },

  async beforeMount() {
    if (
      Object.keys(this.$store.state.authUser).length === 0 &&
      localStorage.getItem("skill_jwt") !== null
    ) {
      let user = JSON.parse(localStorage.getItem("skill_user"));
      this.$store.commit("setAuthUser", user);
      this.$store.commit("setJoinedGroupClass", user.groupClasses);
      if (user.is_admin == true) {
        this.$store.commit("setIsAdminLoggedIn", true);
        this.getAdminSchools();
        if (this.$router.currentRoute.fullPath === "/admin-singin") {
          this.$router.push({ name: "AdminDashboard" });
        }
      } else {
        this.$store.commit("setIsLoggedIn", true);
        this.getProfile();
        if (this.$router.currentRoute.fullPath === "/singin") {
          this.$router.push({ name: "Dashboard" });
        }
        if (
          this.$router.currentRoute.fullPath === "/" &&
          this.$store.state.authUser.tutorStatus
        ) {
          this.$router.push({ name: "Tutor" });
        }
      }
    }
  },
  async mounted() {
    window.addEventListener("online", this.handleNetworkChange);
    window.addEventListener("offline", this.handleNetworkChange);

    this.interval = setInterval(() => {
      if (this.handleNetworkChangeOffline === false) {
        this.handleNetworkChangeOffline = null;
      }
    }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style></style>
