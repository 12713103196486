<template>
  <div id="container">
    <!-- <section class="sm:mx-20 mx-4 mt-10"> -->
    <!-- Header -->
    <section
      class="bg-skillyelow bg-header-image bg-no-repeat bg-cover bg-center bg-fixed"
    >
      <Nav class="fixed z-50" />
      <div class="p-4">
        <div class="sm:mt-24 mt-16">
          <p
            class="text-center uppercase font-semibold mb-5"
            style="font-size: 18px; line-height: 28px; letter-spacing: 1.25px"
          >
            wherever, whenever, however
          </p>
          <p
            class="text-center font-bold mb-5 sm:text-7xl text-5xl sm:leading-normal leading-tight"
          >
            Kickstart Your Journey <br />
            In Digital Skills.
          </p>
        </div>
        <div class="flex justify-center space-x-7">
          <vs-button @click="$router.push({ name: 'Signup' })" color="#000" class="">
            <p class="text-sm uppercase font-semibold px-5 py-1 text-skillyelow">
              Get started 🚀
            </p>
          </vs-button>
          <vs-button
            @click="$router.push({ name: 'Signin' })"
            color="#000"
            border
            class=""
          >
            <p class="text-sm uppercase font-semibold px-5 py-1 text-skillblack">Login</p>
          </vs-button>
        </div>
        <div class="flex justify-center my-5">
          <img src="../../assets/hero-illustration.png" class="object-contain h-full" />
        </div>
        <!--  -->
        <div>
          <div class="rounded-md text-white mt-12 pb-24 flex justify-center">
            <div class="sm:flex block bg-black py-6 px-7 rounded-lg items-center">
              <div class="font-medium text-sm mr-10 text-center">
                Partnerships, Support, & Media
              </div>
              <div class="flex space-x-5 space-y-5 flex-wrap justify-center">
                <img src="../../assets/partners/Frame 37.svg" />
                <img src="../../assets/partners/Frame 41.svg" />
                <img src="../../assets/partners/Frame 42.svg" />
                <img src="../../assets/partners/Frame 43.svg" />
                <img src="../../assets/partners/Frame 44.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Upcoming classes -->

    <section class="mx-10 mt-10">
      <!-- skeleton loader -->
      <div
        v-if="loading"
        class="w-full my-3 rounded-lg shadow-md animate-pulse skeleton-box bg-gray-100 h-96"
      ></div>
      <splide v-else :options="options">
        <splide-slide
          v-for="classList in schoolDataFilter"
          :key="classList.index"
          class="sm:pl-16 pl-1"
        >
          <div>
            <p
              class="font-semibold text-gray-600 sm:text-center lg:text-left"
              style="font-size: 24px; line-height: 40px; letter-spacing: 0.25px"
            >
              Upcoming classes
            </p>
            <div class="mt-2 lg:flex block space-x-10">
              <div class="lg:w-1/2 w-full">
                <p
                  v-if="schoolDataFilter"
                  class="font-semibold sm:text-5xl text-3xl sm:text-center lg:text-left"
                >
                  {{ classList.name }}
                </p>
                <div class="flex space-x-6 mt-6 sm:justify-center lg:justify-start">
                  <p
                    class="bg-skillyelow rounded-md font-medium p-3 text-center text-base"
                  >
                    {{ classList.duration }}
                  </p>
                  <p
                    class="bg-skillyelow rounded-md font-medium p-3 text-center text-base"
                  >
                    {{
                      classList.curriculum[0]
                        ? classList.curriculum[0].curriculum.length
                        : 1
                    }}
                    modules
                  </p>

                  <p
                    class="bg-skillyelow rounded-md font-medium p-3 text-center text-base"
                  >
                    {{ classList.venue }}
                  </p>
                </div>
                <div class="flex space-x-6 mt-6 sm:justify-center lg:justify-start">
                  <div>
                    <p class="pl-1 pb-1">Registration ends:</p>
                    <p
                      class="bg-skillyelow rounded-md font-medium p-3 text-center text-base"
                    >
                      {{ new Date(classList.dueDate).toDateString() }}
                    </p>
                  </div>
                  <div>
                    <p class="pl-1 pb-1">Class starts:</p>
                    <p
                      class="bg-skillyelow rounded-md font-medium p-3 text-center text-base"
                    >
                      {{ new Date(classList.classStartDate).toDateString() }}
                    </p>
                  </div>
                </div>
                <div
                  class="flex space-x-4 mt-3 items-center sm:justify-center lg:justify-start"
                >
                  <div>
                    <p class="font-semibold text-lg p-3">
                      ₦ {{ classList.price.toLocaleString() }}
                    </p>
                  </div>
                  <div>
                    <vs-button
                      color="#000"
                      class=""
                      @click="
                        $router.push({
                          name: 'SharedClass',
                          params: { gclassID: classList._id },
                        })
                      "
                    >
                      <p
                        class="text-sm uppercase font-semibold px-5 py-1 text-skillyelow"
                      >
                        Explore 🚀
                      </p>
                    </vs-button>
                  </div>
                </div>
              </div>
              <div class="lg:w-1/2 w-full relative flex items-center justify-center">
                <img
                  :src="classList.classImage"
                  alt=""
                  class="rounded-full absolute z-10"
                />
                <img src="../../assets/home/class-image-bg.svg" alt="" class="z-0" />
              </div>
            </div>
          </div>
        </splide-slide>
      </splide>
    </section>
    <!-- end of upcoming classes sction -->

    <!-- features section -->
    <section>
      <div class="mx-10 py-10 mt-11">
        <h1 class="text-4xl font-bold text-center">Our Features</h1>
        <!-- <p class="text-gray-400 my-4">
          Lorem ipsum dolor sit amet consectetur adipisicing elit.
        </p> -->

        <div
          class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-6 gap-3 justify-items-center mt-16"
        >
          <div class="">
            <img src="../../../src/assets/feature-1.png" alt="Illustration" class="" />
            <div
              class="bg-skillyelow w-1/4 text-center relative bottom-10 right-4 rounded-bl-xl py-4 font-bold"
            >
              Tutor led
            </div>
          </div>
          <div class="">
            <img src="../../../src/assets/feature-2.png" alt="Illustration" class="" />
            <div
              class="bg-skillyelow w-1/4 text-center relative bottom-10 right-4 rounded-bl-xl py-4 font-bold"
            >
              Practical
            </div>
          </div>
          <div class="">
            <img src="../../../src/assets/feature-3.png" alt="Illustration" class="" />
            <div
              class="bg-skillyelow w-2/4 text-center relative bottom-10 right-4 rounded-bl-xl py-4 font-bold"
            >
              Virtual / Physical
            </div>
          </div>
          <div class="">
            <img src="../../../src/assets/feature-4.png" alt="Illustration" class="" />
            <div
              class="bg-skillyelow w-2/4 text-center relative bottom-10 right-4 rounded-bl-xl py-4 font-bold"
            >
              Expert Tutors
            </div>
          </div>
          <div class="">
            <img src="../../../src/assets/feature-5.png" alt="Illustration" class="" />
            <div
              class="bg-skillyelow w-1/4 text-center relative bottom-10 right-4 rounded-bl-xl py-4 font-bold"
            >
              Projects
            </div>
          </div>
          <div class="">
            <img src="../../../src/assets/feature-6.png" alt="Illustration" class="" />
            <div
              class="bg-skillyelow w-3/4 text-center relative bottom-10 right-4 rounded-bl-xl py-4 font-bold"
            >
              Virtual / Placement Opportunities
            </div>
          </div>
        </div>
        <div class="flex justify-center">
          <vs-button @click="$router.push({ name: 'Signup' })" color="#FFF212" class="">
            <p class="text-sm text-skillblack font-bold px-10 py-1">GET STARTED 🚀</p>
          </vs-button>
        </div>
      </div>
    </section>

    <!-- end of features section -->

    <!-- tutor section -->
    <section class="w-full sm:px-16 px-6 sm:py-24 py-10 bg-skillblack">
      <div class="lg:flex block items-center">
        <div class="w-full flex justify-center relative">
          <img src="../../assets/home/tutor-image.svg" class="z-10 relative" />
          <img
            src="../../assets/home/tutor-image-ico.svg"
            class="absolute top-5 right-4 md:top-10 md:right-4 lg:top-7 lg:left-4 z-0"
          />
        </div>
        <div class="text-white md:pl-6 lg:pl-8">
          <p class="text-skillyelow font-bold text-4xl lg:mt-0 mt-16">
            Would you like to be tutored privately?
          </p>
          <p class="my-8 text-base break-words">
            If our regular group cohort schedule cannot work for you or if we don't have
            the class you are interested in as part of our group classes, you can easily
            request for a private class that you can take anytime. You can also customize
            the course contents.
          </p>
          <div class="flex items-center justify-center">
            <vs-avatar-group>
              <vs-avatar circle size="50">
                <img src="../../assets/home/privateC-icon1.svg" class="img-av" />
              </vs-avatar>
              <vs-avatar circle size="50">
                <img src="../../assets/home/privateC-icon3.svg" class="img-av" />
              </vs-avatar>
              <vs-avatar circle size="50">
                <img src="../../assets/home/privateC-icon5.png" class="img-av" />
              </vs-avatar>
            </vs-avatar-group>
            <p class="font-semibold text-sm pl-3">Private classes ongoing</p>
          </div>
          <div class="mt-8 flex justify-center">
            <vs-button @click="$router.push({ name: 'Signup' })" color="#FFF212" class="">
              <p class="text-sm text-skillblack font-bold px-10 py-1">GET STARTED 🚀</p>
            </vs-button>
          </div>
        </div>
      </div>
    </section>
    <!-- end of tutor section -->
    <!-- hire for company -->
    <section class="w-full">
      <div
        class="sm:flex block sm:space-x-14 space-x-0 sm:mx-10 p-10 sm:justify-between sm:items-center"
      >
        <div class="lg:w-1/2 w-full">
          <div class="font-bold text-4xl sm:pt-0 pt-12 sm:text-left text-center">
            We simplify hiring <br />
            for companies.
          </div>
          <div class="py-6 text-base break-words sm:text-left text-center">
            We have over 10,000 young and digitally skilled talents in our network. You
            can focus on running your business while we focus on hiring the best talents
            for you.
          </div>
          <div class="flex justify-center lg:mb-0 mb-5">
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSdZJWE9VEHodnN3aDHJuZLVEOumiQur_AG2D-toKPOUlSmgDw/viewform"
              target="_blank"
            >
              <vs-button color="#FFF212" class="">
                <p class="text-sm text-skillblack uppercase font-bold px-10 py-1">
                  I want to hire
                </p>
              </vs-button>
            </a>
          </div>
        </div>
        <div class="lg:w-1/2 w-full">
          <div class="w-full flex space-x-5">
            <img class="flex-1" src="../../assets/home/hiring1.svg" />
            <img
              class="mt-24 flex-1 xl:block hidden"
              src="../../assets/home/hiring2.svg"
            />
          </div>
        </div>
      </div>
      <div class="hidden w-full p-16">
        <h1 class="font-bold text-4xl text-center">
          Companies that trust us with <br />
          hiring their talents
        </h1>

        <div class="flex flex-wrap px-24 py-12 space-x-5 justify-between">
          <div><img src="../../assets/company-1.svg" alt="Company 1" /></div>
          <div><img src="../../assets/company-2.svg" alt="Company 2" /></div>
          <div><img src="../../assets/company-3.svg" alt="Company 3" /></div>
          <div><img src="../../assets/company-4.svg" alt="Company 4" /></div>
          <!-- <div><img src="../../assets/company-5.svg" alt="Company 5" /></div> -->
          <div><img src="../../assets/company-6.svg" alt="Company 6" /></div>
        </div>

        <div class="sm:flex block justify-center items-center space-x-5">
          <div class="font-semibold s:text-left text-center">
            Are you looking to hire talents?
          </div>
          <div class="flex justify-center">
            <vs-button @click="scrollToBottom" color="#000" class="">
              <p class="text-sm uppercase font-semibold px-5 py-1 text-skillyelow">
                CONTACT US
              </p>
            </vs-button>
          </div>
        </div>
      </div>
    </section>
    <!-- alumni -->
    <section class="sm:mx-12 mx-4">
      <div class="flex justify-between items-start px-7 py-20">
        <div class="flex flex-col max-w-2xl">
          <div class="z-0">
            <div class="z-0 relative top-12 right-4">
              <svg
                width="101"
                height="100"
                viewBox="0 0 101 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  width="100"
                  height="100"
                  rx="20"
                  fill="#FFF212"
                  fill-opacity="0.25"
                />
              </svg>
            </div>
          </div>
          <div class="lg:mr-14 mr-0 z-10">
            <div class="z-10">
              <p class="font-bold text-4xl">5000+</p>
              <p class="font-bold py-3 text-xl">Alumni</p>
              <p v-if="alumni === 'alumni'" class="text-base break-words">
                We have graduated over 5,000 alumni in different digital skills across
                coding, design, data analysis and digital marketing.
              </p>
              <p v-else-if="alumni === 'digital'" class="text-base break-words">
                We have graduated over 5,000 alumni in different digital skills across
                coding, design, data analysis and digital marketing.
              </p>
              <p v-else-if="alumni === 'tutors'" class="text-base break-words">
                Our tutors are currently working in startups, companies or freelancing in
                the various skills they are teaching. Each tutor has a minimum of two
                years professional experience.
              </p>
              <p v-else-if="alumni === 'placements'" class="text-base break-words">
                We partner with startups, small businesses and other professional talents
                to place our outstanding students for work, internship and mentorship
                opportunities.
              </p>
            </div>
          </div>

          <!-- <p class="font-bold py-6">Get Started</p> -->

          <!-- <div class="bg-gray-200 mt-6 rounded-2xl font-bold flex justify-between p-8"> -->
          <div
            class="bg-gray-200 mt-6 rounded-2xl font-bold grid sm:grid-cols-4 grid-cols-2 gap-3 justify-between p-8"
          >
            <div
              @mouseover="alumni = 'alumni'"
              class="flex items-center transition duration-150 hover:bg-skillyelow bg-gray-100 rounded-2xl px-6 py-6 cursor-pointer flex-col"
            >
              <div><img src="../../assets/alumniLogo.svg" alt="Alumni" /></div>
              <div class="hover:font-bolder text-sm hover:text-lg">Alumni</div>
            </div>
            <div
              @mouseover="alumni = 'digital'"
              class="flex flex-col items-center hover:bg-skillyelow bg-gray-100 rounded-2xl px-6 py-6 cursor-pointer"
            >
              <div><img src="../../assets/digitalSkills.svg" alt="Digital Skills" /></div>
              <div class="text-sm">Digital Skills</div>
            </div>
            <div
              @mouseover="alumni = 'tutors'"
              class="flex flex-col items-center hover:bg-skillyelow bg-gray-100 rounded-2xl px-6 py-6 cursor-pointer"
            >
              <div><img src="../../assets/tutors.svg" alt="Tutors" /></div>
              <div class="text-sm">Tutors</div>
            </div>
            <div
              @mouseover="alumni = 'placements'"
              class="flex flex-col items-center hover:bg-skillyelow bg-gray-100 rounded-2xl px-6 py-6 cursor-pointer"
            >
              <div><img src="../../assets/placement.svg" alt="Tutors" /></div>
              <div class="text-sm">Placements</div>
            </div>
          </div>
        </div>
        <div class="lg:block hidden relative">
          <div>
            <img
              v-if="alumni === 'alumni'"
              class="absolute bottom-14 right-14 rounded-3xl"
              :class="alumni === 'alumni' ? 'z-40' : 'z-30'"
              src="../../assets/home/Alumni.jpg"
              alt=""
            />
            <img
              v-else
              class="absolute bottom-14 right-14"
              :class="alumni === 'alumni' ? 'z-40' : 'z-30'"
              src="../../assets/home/alumni-bg1.svg"
              alt=""
            />
          </div>
          <div>
            <img
              v-if="alumni === 'digital'"
              class="absolute bottom-10 right-10 rounded-3xl"
              :class="alumni === 'digital' ? 'z-40' : 'z-20'"
              src="../../assets/home/DigitalSkills.jpg"
              alt=""
            />
            <img
              v-else
              class="absolute bottom-10 right-10"
              :class="alumni === 'digital' ? 'z-40' : 'z-20'"
              src="../../assets/home/alumni-bg2.svg"
              alt=""
            />
          </div>
          <div>
            <img
              v-if="alumni === 'tutors'"
              class="absolute bottom-5 right-5 rounded-3xl"
              :class="alumni === 'tutors' ? 'z-40' : 'z-10'"
              src="../../assets/home/Tutor.jpg"
              alt=""
            />
            <img
              v-else
              class="absolute bottom-5 right-5"
              :class="alumni === 'tutors' ? 'z-40' : 'z-10'"
              src="../../assets/home/alumni-bg3.svg"
              alt=""
            />
          </div>
          <div>
            <img
              v-if="alumni === 'placements'"
              class="absolute bottom-1 right-1 rounded-3xl"
              :class="alumni === 'placements' ? 'z-40' : 'z-0'"
              src="../../assets/home/Placement.jpg"
              alt=""
            />
            <img
              v-else
              class="absolute bottom-0 right-0"
              :class="alumni === 'placements' ? 'z-40' : 'z-0'"
              src="../../assets/home/alumni-bg4.svg"
              alt=""
            />
          </div>
          <img class="z-0" src="../../assets/home/alumni-bg4.svg" alt="" />
        </div>
      </div>

      <!-- end of additional info -->

      <!-- testimonial Section -->
    </section>
    <!-- testtimonial -->
    <section class="">
      <div class="lg:px-16 px-8 lg:py-20 py-10 w-full bg-skillblack">
        <h1 class="text-skillyelow font-bold text-4xl text-center">Testimonials</h1>
        <p class="text-white text-sm py-3 pb-5 text-center font-bold">
          Our alumni work for some of the best companies within and outside Nigeria.
        </p>

        <div class="flex justify-between mx-auto lg:px-16 px-0 text-sm items-center">
          <div class="text-white lg:w-1/2 w-full">
            <div>
              <p class="">{{ testimonies[testimonySliderIndex].text }}</p>
              <div class="flex items-center py-8 space-x-4">
                <div class="w-20 h-20">
                  <img
                    :src="testimonies[testimonySliderIndex].image"
                    class="rounded-full"
                  />
                </div>
                <div class="flex text-sm flex-col">
                  <p>{{ testimonies[testimonySliderIndex].name }}</p>
                  <div class="uppercase text-skillyelow text-sm">
                    {{ testimonies[testimonySliderIndex].title }}
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-center space-x-2 cursor-pointer">
              <div
                v-for="(testimony, index) in testimonies"
                :key="index"
                class="w-1.5 h-1.5 rounded-full"
                :class="testimonySliderIndex === index ? 'bg-gray-300 ' : 'bg-gray-600'"
                @click="testimonySliderIndex = index"
              ></div>
            </div>
          </div>
          <div class="lg:block hidden text-white">
            <img
              src="../../assets/testimonial-illustration.svg"
              alt="testtimonial Illustration"
              class="h-96"
            />
          </div>
        </div>
      </div>
    </section>
    <!-- Ola section -->
    <section class="mx-14 my-24 hidden">
      <div
        class="bg-skillyelow rounded-3xl px-16 py-24 flex space-x-12 justify-between items-center"
      >
        <div class="flex flex-col w-1/2">
          <div class="font-bold text-4xl py-6">Ola from SkillNG</div>
          <div class="">
            Your week is not complete without reading the newsletter from Ola. Ola is
            SkillNG's Customer Happiness Minister and Ola ensures you stay updated about
            SkillNG activities, promos, campaigns and announcements. But much more than
            that, Ola regularly shares job vacancies, job search hacks, useful tips to
            make you learn better and generally anything to help your personal and
            professional growth journey. Sign up to start reading from Ola.
          </div>
        </div>
        <div class="w-1/2 pl-20">
          <form action="#" class="space-y-3">
            <label class="relative block">
              <input
                class="placeholder:italic placeholder:text-black block bg-skillyelow w-4/5 border-2 border-black rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none sm:text-sm"
                placeholder="Email address..."
                type="text"
              />
            </label>
            <label class="relative block">
              <input
                class="placeholder:italic placeholder:text-black block bg-skillyelow w-4/5 border-2 border-black rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none mb-4 sm:text-sm"
                placeholder="Full name"
                type="text"
              />
            </label>
            <div
              class="w-4/5 bg-black py-3 cursor-pointer rounded-xl text-center uppercase text-xs text-skillyelow"
            >
              read from ola
            </div>
          </form>
        </div>
      </div>
    </section>

    <!-- divider -->
    <div class="w-full bg-skillyelow py-4"></div>

    <!-- footer -->
    <section>
      <div class="bg-skillblack text-white bottom-0 p-9">
        <div class="sm:flex block justify-between sm:space-x-6 space-x-0">
          <div class="flex flex-wrap sm:w-1/3 w-full">
            <img src="../../assets/footer-logo.svg" alt="footer logo" class="h-48" />
            <p class="text-sm">
              SkillNG is a skill development and talent management company that helps
              young people acquire global in-demand digital skills by leveraging on
              peer-to-peer learning networks and critical learning resources while
              connecting them to placement opportunities.
            </p>
          </div>

          <div class="capitalize sm:w-1/3 w-full sm:mt-0 mt-8">
            <p class="my-2 text-xl font-bold mb-2 text-skillyelow">Company</p>
            <p
              @click="$router.push({ name: 'About' })"
              class="my-2 text-sm cursor-pointer"
            >
              About Us
            </p>
            <!-- <p class="my-2 text-sm">Become a tutor</p> -->
            <p
              @click="$router.push({ name: 'PrivacyPolicy' })"
              class="my-2 text-sm cursor-pointer"
            >
              Privacy Policy
            </p>
            <!-- <p class="my-2 text-sm">Terms & Condition</p> -->
          </div>
          <!-- <div class="flex ">
            <p class="my-2 text-xl font-bold mb-2 text-skillyelow">School</p>
            <a class="my-2 text-sm" href="#">Design School</a>
            <a class="my-2 text-sm" href="#">School of Data</a>
            <a class="my-2 text-sm" href="#">Programming School</a>
            <a class="my-2 text-sm" href="#">School of Management</a>
            <a class="my-2 text-sm" href="#">Digital Marketing Academy</a>
            <a class="my-2 text-sm" href="#">Employment</a>
          </div> -->
          <div class="sm:w-1/3 w-full sm:mt-0 mt-8">
            <p class="my-2 text-xl mb-2 font-bold text-skillyelow">Recommended for you</p>
            <p
              v-for="classList in topRatedClass"
              :key="classList.index"
              class="my-2 text-sm cursor-pointer"
              @click="
                $router.push({
                  name: 'SharedClass',
                  params: { gclassID: classList._id },
                })
              "
            >
              {{ classList.name }}
            </p>
          </div>
        </div>

        <div class="rounded-md text-black mt-6 pb-10 lg:flex block justify-center">
          <div class="lg:flex block bg-skillyelow py-6 px-7 rounded-lg items-center">
            <div
              class="font-medium text-sm sm:mr-10 mr-0 lg:text-left text-center lg:pb-0 pb-2"
            >
              Follow us on socials:
            </div>
            <div class="flex space-x-5 justify-center items-center">
              <div>
                <a href="https://web.facebook.com/SkillTutors/" target="_blank">
                  <svg
                    width="48"
                    height="49"
                    viewBox="0 0 48 49"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24 4.5C12.954 4.5 4 13.454 4 24.5C4 35.546 12.954 44.5 24 44.5C35.046 44.5 44 35.546 44 24.5C44 13.454 35.046 4.5 24 4.5Z"
                      fill="black"
                    />
                    <path
                      d="M21.0091 21.0459V24.5439L21.0081 29.8019V44.2769C21.9841 44.4239 22.9831 44.4999 24.0001 44.4999C24.9181 44.4999 25.8221 44.4379 26.7071 44.3179V21.6689C26.7071 19.4849 27.4211 17.5479 29.4641 17.5479H32.7471V12.9599C32.1701 12.8819 30.9501 12.7119 28.6451 12.7119C23.8311 12.7119 21.0091 15.2539 21.0091 21.0459Z"
                      fill="white"
                    />
                    <path d="M32 30.5H16V24.5H33L32 30.5Z" fill="white" />
                    <path
                      d="M26.7071 30.5V24.5H21.0091V24.544L21.0081 29.802V30.5H26.7071Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
              <div>
                <a href="https://www.linkedin.com/company/skillng/" target="_blank">
                  <svg
                    width="48"
                    height="49"
                    viewBox="0 0 48 49"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24 44.5C35.0457 44.5 44 35.5457 44 24.5C44 13.4543 35.0457 4.5 24 4.5C12.9543 4.5 4 13.4543 4 24.5C4 35.5457 12.9543 44.5 24 44.5Z"
                      fill="black"
                    />
                    <path d="M17 18.5H12V35.5H17V18.5Z" fill="white" />
                    <path
                      d="M14.485 16.5H14.457C12.965 16.5 12 15.388 12 13.999C12 12.58 12.995 11.5 14.514 11.5C16.035 11.5 16.972 12.58 17 13.999C17 15.387 16.035 16.5 14.485 16.5Z"
                      fill="white"
                    />
                    <path
                      d="M36 35.5L35.999 25.774C35.999 20.75 33.316 18.5 29.738 18.5C28.086 18.5 26.386 19.094 25 20.438V18.5H20V35.5H25V24.687L25.002 24.688C25.396 23.71 26.307 22.703 27.808 22.703C29.775 22.703 31 24.203 31 26.401V35.5H36Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
              <div>
                <a href="https://twitter.com/skill_ng/" target="_blank">
                  <svg
                    width="48"
                    height="49"
                    viewBox="0 0 48 49"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24 4.5C12.954 4.5 4 13.454 4 24.5C4 35.546 12.954 44.5 24 44.5C35.046 44.5 44 35.546 44 24.5C44 13.454 35.046 4.5 24 4.5Z"
                      fill="black"
                    />
                    <path
                      d="M37.844 16.066C36.776 16.539 35.424 16.984 34.212 17.131C35.445 16.4 37.4 14.878 37.844 13.5C36.693 14.177 34.611 14.899 33.252 15.161C32.168 14.011 30.621 13.5 28.907 13.5C25.614 13.5 23.318 16.29 23.318 19.553V21.974C18.476 21.974 13.755 18.286 10.817 14.711C10.3 15.584 10.01 16.605 10.01 17.685C10.01 19.887 12.033 22.122 13.634 23.184C12.657 23.154 10.807 22.408 10.002 21.973C10.002 21.992 10.002 22.017 10.002 22.042C10.002 24.907 12.013 26.853 14.738 27.395C14.24 27.532 13.634 28.026 11.3 28.026C12.058 30.368 15.867 31.607 18.476 31.658C16.435 33.24 12.796 34.079 10.002 34.079C9.51902 34.079 9.25802 34.106 8.79102 34.051C11.428 35.722 15.11 36.5 18.476 36.5C29.44 36.5 35.423 28.126 35.423 20.315C35.423 20.058 35.415 19.199 35.401 18.947C36.573 18.122 37.047 17.257 37.844 16.066Z"
                      fill="white"
                    />
                    <path
                      d="M23.318 21.974C23.318 21.974 23.157 28.269 18.476 31.658C18.476 31.658 28.005 29.971 28.005 21.498C28.005 21.498 25.752 22.007 23.318 21.974Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
              <div>
                <a href="https://www.instagram.com/skillng/" target="_blank">
                  <svg
                    width="48"
                    height="49"
                    viewBox="0 0 48 49"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M34.017 42.4902L14.017 42.5092C9.61699 42.5132 6.01399 38.9172 6.00899 34.5172L5.98999 14.5172C5.98599 10.1172 9.58199 6.51424 13.982 6.50924L33.982 6.49024C38.382 6.48624 41.985 10.0822 41.99 14.4822L42.009 34.4822C42.014 38.8832 38.417 42.4862 34.017 42.4902Z"
                      fill="black"
                    />
                    <path
                      d="M24 31.5C20.141 31.5 17 28.36 17 24.5C17 20.64 20.141 17.5 24 17.5C27.859 17.5 31 20.64 31 24.5C31 28.36 27.859 31.5 24 31.5ZM24 19.5C21.243 19.5 19 21.743 19 24.5C19 27.257 21.243 29.5 24 29.5C26.757 29.5 29 27.257 29 24.5C29 21.743 26.757 19.5 24 19.5Z"
                      fill="white"
                    />
                    <path
                      d="M31.5 18.5C32.3284 18.5 33 17.8284 33 17C33 16.1716 32.3284 15.5 31.5 15.5C30.6716 15.5 30 16.1716 30 17C30 17.8284 30.6716 18.5 31.5 18.5Z"
                      fill="white"
                    />
                    <path
                      d="M30 37.5H18C14.141 37.5 11 34.36 11 30.5V18.5C11 14.64 14.141 11.5 18 11.5H30C33.859 11.5 37 14.64 37 18.5V30.5C37 34.36 33.859 37.5 30 37.5ZM18 13.5C15.243 13.5 13 15.743 13 18.5V30.5C13 33.257 15.243 35.5 18 35.5H30C32.757 35.5 35 33.257 35 30.5V18.5C35 15.743 32.757 13.5 30 13.5H18Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div class="px-10 font-medium text lg:text-left text-center lg:py-0 py-5">
              Contacts:
            </div>
            <div class="lg:block flex justify-center">
              <div class="flex flex-col font-bold">
                <a href="mailto:hello@skillng.com">
                  <div class="flex space-x-2">
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_565_2779)">
                        <path
                          d="M24 18.8127C24 19.5199 23.719 20.1982 23.219 20.6983C22.7189 21.1984 22.0406 21.4793 21.3333 21.4793H2.66667C1.95942 21.4793 1.28115 21.1984 0.781049 20.6983C0.280952 20.1982 0 19.5199 0 18.8127V6.81266C0 6.10542 0.280952 5.42714 0.781049 4.92704C1.28115 4.42695 1.95942 4.146 2.66667 4.146H21.3333C22.0406 4.146 22.7189 4.42695 23.219 4.92704C23.719 5.42714 24 6.10542 24 6.81266V18.8127Z"
                          fill="#CCD6DD"
                        />
                        <path
                          d="M7.96677 12.57L0.424766 20.112C0.406766 20.1306 0.400099 20.154 0.384766 20.1726C0.611432 20.5526 0.927432 20.868 1.30743 21.0953C1.32677 21.08 1.34943 21.0733 1.36743 21.0553L8.9101 13.5126C9.0351 13.3876 9.1053 13.2179 9.10523 13.0411C9.10517 12.8642 9.03486 12.6947 8.90977 12.5696C8.78467 12.4446 8.61504 12.3744 8.4382 12.3745C8.26135 12.3746 8.09177 12.4449 7.96677 12.57ZM23.6154 20.1726C23.6014 20.154 23.5934 20.1306 23.5754 20.1126L16.0341 12.57C15.9722 12.508 15.8987 12.4589 15.8178 12.4254C15.7369 12.3918 15.6502 12.3745 15.5627 12.3745C15.4751 12.3745 15.3884 12.3917 15.3075 12.4252C15.2266 12.4587 15.153 12.5077 15.0911 12.5696C15.0292 12.6315 14.98 12.705 14.9465 12.7859C14.9129 12.8668 14.8957 12.9535 14.8956 13.0411C14.8956 13.1286 14.9128 13.2154 14.9463 13.2963C14.9798 13.3772 15.0289 13.4507 15.0908 13.5126L22.6328 21.0553C22.6501 21.0726 22.6741 21.08 22.6928 21.0953C23.0724 20.8694 23.3895 20.5522 23.6154 20.1726Z"
                          fill="#7B0000"
                        />
                        <path
                          d="M21.3333 4.146H2.66667C1.95942 4.146 1.28115 4.42695 0.781049 4.92704C0.280952 5.42714 0 6.10542 0 6.81266L0 7.49933L9.68533 17.1633C10.2951 17.7696 11.1201 18.1099 11.98 18.1099C12.8399 18.1099 13.6649 17.7696 14.2747 17.1633L24 7.48533V6.81266C24 6.10542 23.719 5.42714 23.219 4.92704C22.7189 4.42695 22.0406 4.146 21.3333 4.146Z"
                          fill="#7B0000"
                        />
                        <path
                          d="M21.3334 4.146H2.66669C2.16484 4.14711 1.67354 4.2901 1.24948 4.55846C0.825413 4.82682 0.485866 5.20961 0.27002 5.66266L10.114 15.5073C10.3616 15.755 10.6556 15.9514 10.9792 16.0854C11.3027 16.2194 11.6495 16.2884 11.9997 16.2884C12.3499 16.2884 12.6967 16.2194 13.0202 16.0854C13.3438 15.9514 13.6377 15.755 13.8854 15.5073L23.73 5.66266C23.5142 5.20956 23.1747 4.82672 22.7506 4.55836C22.3265 4.28999 21.8352 4.14703 21.3334 4.146Z"
                          fill="#E1E8ED"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_565_2779">
                          <rect
                            width="24"
                            height="24"
                            fill="white"
                            transform="translate(0 0.8125)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    <p>hello@skillng.com</p>
                  </div>
                </a>
                <a href="https://wa.me/2347061282516" target="_blank">
                  <div class="flex space-x-2">
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_565_2775)">
                        <path
                          d="M0.51209 12.7616C0.511527 14.7938 1.04253 16.7781 2.05221 18.5271L0.415527 24.503L6.53103 22.8995C8.22249 23.8203 10.1177 24.3028 12.0435 24.3029H12.0486C18.4062 24.3029 23.5815 19.1295 23.5842 12.7707C23.5855 9.68942 22.3866 6.79198 20.2084 4.61211C18.0306 2.43242 15.1342 1.23139 12.0481 1.22998C5.68972 1.22998 0.514809 6.40311 0.512184 12.7616"
                          fill="url(#paint0_linear_565_2775)"
                        />
                        <path
                          d="M0.100313 12.7577C0.0996563 14.8631 0.649687 16.9184 1.69537 18.73L0 24.92L6.33478 23.2591C8.08022 24.2107 10.0454 24.7125 12.0451 24.7132H12.0502C18.636 24.7132 23.9972 19.3537 24 12.7673C24.0011 9.57531 22.7591 6.57372 20.5031 4.31575C18.2468 2.05806 15.2468 0.813813 12.0502 0.8125C5.46337 0.8125 0.103031 6.17125 0.100406 12.7577H0.100313ZM3.87281 18.418L3.63628 18.0425C2.64197 16.4615 2.11716 14.6345 2.11791 12.7585C2.12006 7.28397 6.57544 2.83 12.054 2.83C14.7071 2.83113 17.2005 3.86538 19.0759 5.74188C20.9512 7.61856 21.9831 10.1132 21.9824 12.7666C21.98 18.2411 17.5245 22.6956 12.0502 22.6956H12.0463C10.2638 22.6947 8.51569 22.216 6.99113 21.3114L6.62831 21.0963L2.86912 22.0818L3.87281 18.4179V18.418Z"
                          fill="url(#paint1_linear_565_2775)"
                        />
                        <path
                          d="M9.06358 7.76406C8.8399 7.26691 8.60449 7.25688 8.39177 7.24816C8.21758 7.24066 8.01846 7.24122 7.81952 7.24122C7.6204 7.24122 7.29687 7.31613 7.0234 7.61472C6.74965 7.9136 5.97827 8.63585 5.97827 10.1048C5.97827 11.5739 7.04824 12.9935 7.1974 13.1929C7.34674 13.392 9.26299 16.503 12.2979 17.6998C14.8201 18.6944 15.3334 18.4966 15.8808 18.4467C16.4283 18.397 17.6474 17.7246 17.8961 17.0274C18.1451 16.3303 18.1451 15.7327 18.0704 15.6078C17.9958 15.4834 17.7967 15.4087 17.4981 15.2595C17.1994 15.1101 15.7315 14.3878 15.4578 14.2881C15.1841 14.1886 14.985 14.1389 14.7859 14.4378C14.5868 14.7363 14.015 15.4087 13.8407 15.6078C13.6666 15.8074 13.4923 15.8323 13.1938 15.6829C12.8951 15.5331 11.9335 15.2182 10.7926 14.2011C9.90499 13.4097 9.30574 12.4323 9.13155 12.1334C8.95737 11.8349 9.1129 11.6731 9.26262 11.5243C9.39677 11.3905 9.5613 11.1756 9.71074 11.0013C9.85962 10.827 9.9093 10.7026 10.0089 10.5034C10.1085 10.3041 10.0586 10.1298 9.98412 9.98041C9.9093 9.83106 9.32908 8.3545 9.06358 7.76416"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <linearGradient
                          id="paint0_linear_565_2775"
                          x1="11.9999"
                          y1="24.503"
                          x2="11.9999"
                          y2="1.22998"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#1FAF38" />
                          <stop offset="1" stop-color="#60D669" />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear_565_2775"
                          x1="12"
                          y1="24.92"
                          x2="12"
                          y2="0.8125"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#F9F9F9" />
                          <stop offset="1" stop-color="white" />
                        </linearGradient>
                        <clipPath id="clip0_565_2775">
                          <rect
                            width="24"
                            height="24.1875"
                            fill="white"
                            transform="translate(0 0.8125)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    <p>+234 706 128 2516</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        <p class="p-2 text-center">
          Copyright {{ getcurrentYear }} SkillNG. All rights reserved.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import Nav from "../../components/navs/HomeTopNav";
import axios from "../../plugin/axios";

export default {
  components: {
    Nav,
  },
  data() {
    return {
      schoolData: [],
      schoolDataFilter: [],
      //
      alumni: "alumni",
      loading: true,
      options: {
        rewind: true,
        pagination: false,
        autoplay: true,
        pauseOnHover: true,
        perPage: 1,
        perMove: 1,
        gap: "1rem",
      },
      testimonySliderIndex: 0,
      testimonies: [
        {
          text: `SkillNG provided me with a structured and focused learning environment where i was able to learn from experienced instructors who have a deep understanding of the subject matter and can provide guidance and feedback throughout the course. 
          I took the data analysis course and it was a hands-on experience using Microsoft Excel and Power BI. This practical experience  helped me to better understand data analysis and apply the knowledge across the different career paths I have taken.
          After the course, I was able to secure a role as a customer success specialist at Paystack. My understanding of data analysis enabled me to analyze customer data, and gain insights into customer behavior.`,
          name: "Sadiya Ovaino Idris",
          image: require(`../../assets/home/Sadiya.jpeg`),
          title: "Customer Success Specialist, GovAssist",
        },
        {
          text: `Learning Software Quality Assurance with SkillNG was a game-changer for me. The course content was comprehensive and well-structured, and the instructors were knowledgeable and supportive throughout the program. Thanks to the hands-on projects and assignments, I gained practical skills that I could immediately apply in my work as a software analyst at Flutterwave. I highly recommend SkillNG to anyone looking to upskill in the tech industry.`,
          name: "Comfort Oladeji",
          image: require(`../../assets/home/Comfort.jpeg`),
          title: "Quality Assurance Analyst Flutterwave",
        },
        {
          text: `I recently took the Software Testing course with SkillNG, and it was an excellent experience. The course content was thorough and well-structured, and the instructors were knowledgeable and supportive throughout the program. I especially appreciated the practical approach to learning, which allowed me to apply the concepts I learned in real-world scenarios.

          Thanks to the skills and knowledge I gained from the course, I was able to secure a job as a Software Tester at Sterling Bank. I would highly recommend SkillNG to anyone looking to enhance their skills in software testing or any other IT field.`,
          name: "Olusola Ajibade",
          image: require(`../../assets/home/Olusola2.jpg`),
          title: "Software Tester, Sterling Bank",
        },
      ],
    };
  },
  computed: {
    getcurrentYear() {
      const d = new Date();
      return d.getFullYear();
    },
    topRatedClass() {
      let shuffled = this.schoolDataFilter
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);
      return shuffled.slice(0, 5);
    },
  },
  methods: {
    filter() {
      let data = this.schoolData;
      let allClass = [];
      for (let i = 0; i < data.length; i++) {
        for (let k = 0; k < data[i].groupClasses.length; k++) {
          allClass.push(data[i].groupClasses[k]);
        }
      }
      this.schoolDataFilter = allClass;
    },
    scrollToBottom() {
      let element = document.getElementById("container");
      element.scrollIntoView(false);
    },
    testimonySlider() {
      setInterval(() => {
        if (this.testimonySliderIndex === 2) {
          this.testimonySliderIndex = 0;
        } else this.testimonySliderIndex += 1;
      }, 10000);
    },
  },

  async mounted() {
    this.testimonySlider();
    this.loading = true;
    if (this.$store.state.schoolsHome.length === 0) {
      let url = "school/all/active2";
      await axios
        .get(url)
        .then((response) => {
          let data = response.data.data.schools;
          this.$store.commit("setSchoolsHome", data);
          this.schoolData = data;
          this.filter();
          this.loading = false;
        })
        .catch(() => {});
    } else {
      this.schoolData = this.$store.state.schools;
      this.filter();
      this.loading = false;
    }
  },
};
</script>

<style scoped>
.bg-header-image {
  background-image: url(../../assets/worldmap.svg);
}
</style>
