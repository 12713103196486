<template>
  <section
    class="bg-skilldeepyelow w-full h-full flex justify-center items-center overflow-y-auto"
  >
    <div class="xl:w-4/12 lg:w-6/12 w-full md:px-16 lg:px-0 px-6 lg:py-16 py-6 relative">
      <div class="mt-5">
        <button>
          <router-link to="/">
            <img src="../../../src/assets/logo.png" alt="Logo" class="h-10 w-auto" />
          </router-link>
        </button>
      </div>
      <!-- sign in form -->
      <div v-if="!forgotPasswordTab">
        <div>
          <p class="font-normal text-3xl my-2">Welcome Back,</p>
          <p>Sign in to continue</p>
        </div>
        <!--  -->
        <vs-alert closable v-model="error" color="danger" class="mt-4">
          <p class="font-semibold">{{ error }}</p>
        </vs-alert>
        <!--  -->
        <div class="mt-10 center content-inputs">
          <vs-input
            @keydown.enter="focusPassword"
            block
            border
            dark
            ref="email"
            type="email"
            v-model="form.email"
            label-placeholder="Email"
          >
            <template v-if="validEmail" #message-success> Email Valid </template>
            <template v-if="!validEmail && form.email !== ''" #message-danger>
              Email Invalid
            </template>
          </vs-input>
        </div>

        <!--  -->
        <div class="mt-10 center content-inputs">
          <vs-input
            @keydown.enter="submit"
            dark
            block
            border
            type="password"
            icon-after
            ref="password"
            v-model="form.password"
            label-placeholder="Password"
            :visiblePassword="hasVisiblePassword"
            @click-icon="hasVisiblePassword = !hasVisiblePassword"
          >
            <template #icon>
              <i v-if="!hasVisiblePassword" class="fas fa-eye-slash"></i>
              <i v-else class="fas fa-eye"></i>
            </template>
          </vs-input>
        </div>
        <!--  -->
        <div>
          <button
            @click="forgotPasswordTab = true"
            class="right-2 absolute mt-2 text-sm font-semibold text-gray-600 hover:text-white"
          >
            Forgot Password?
          </button>
        </div>
        <!--  -->
        <div class="flex justify-center mt-14">
          <vs-button
            flat
            active
            :loading="loading"
            color="#FFF212"
            @click="submit"
            class="w-full shadow-md"
            type="button"
            :disabled="validEmail === true && form.password !== '' ? false : true"
          >
            <p class="text-skillblack py-0.5 text-base">Sign In</p>
          </vs-button>
        </div>
        <!--  -->
        <div class="text-center mb-5">
          <div class="text-black font-medium text-sm mb-2 mt-6">
            New user?
            <span
              @click="signup"
              class="text-black hover:text-mustardyellow cursor-pointer"
              ><b> Sign up</b></span
            >
          </div>
        </div>
      </div>
      <!-- Forgot Password Tab -->
      <div v-if="forgotPasswordTab">
        <div>
          <p class="font-normal text-3xl my-7">Forgot Password?</p>
          <p>
            Enter the email address associated with your account below and we'll send you
            a link to reset your password.
          </p>
        </div>
        <!--  -->
        <vs-alert closable v-model="error" color="danger" class="mt-4">
          <p class="font-semibold">{{ error }}</p>
        </vs-alert>
        <vs-alert closable v-model="success" color="success" class="mt-4">
          <p class="font-semibold text-green-800">{{ success }}</p>
        </vs-alert>
        <!--  -->
        <div class="mt-10 center content-inputs">
          <vs-input
            @keydown.enter="forgetPassword"
            block
            border
            dark
            ref="email"
            type="email"
            v-model="form.email"
            label-placeholder="Email"
          >
            <template v-if="validEmail" #message-success> Email Valid </template>
            <template v-if="!validEmail && form.email !== ''" #message-danger>
              Email Invalid
            </template>
          </vs-input>
        </div>

        <!--  -->
        <div class="flex justify-center mt-14">
          <vs-button
            flat
            active
            :loading="loading"
            color="#FFF212"
            @click="forgetPassword"
            class="w-full shadow-md"
            type="button"
            :disabled="validEmail === true ? false : true"
          >
            <p class="text-skillblack py-0.5 text-base">Send Reset Link</p>
          </vs-button>
        </div>
        <div class="text-center mb-5">
          <div class="text-black font-medium text-sm mb-2 mt-6">
            Remember?
            <span
              @click="forgotPasswordTab = false"
              class="text-black hover:text-mustardyellow cursor-pointer"
              ><b> Go Back</b></span
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "../../plugin/axios";
export default {
  data() {
    return {
      error: "",
      success: "",
      forgotPasswordTab: false,
      form: {
        email: "",
        password: "",
      },
      hasVisiblePassword: false,
      loading: false,
    };
  },
  methods: {
    async forgetPassword() {
      this.loading = true;
      this.error = "";
      await axios
        .post("/user/forgot", { email: this.form.email })
        .then((response) => {
          if (response.status === 200) {
            this.success = "We have e-mailed your password reset link! ";
            console.log(response);
            this.loading = false;
          }
        })
        .catch((error) => {
          this.error = error.response.data.message;
          this.loading = false;
          console.log(error);
        });
    },
    async submit() {
      if (this.validEmail === true && this.form.password !== "") {
        this.loading = true;
        this.error = "";
        await axios
          .post("user/login/", this.form)
          .then((response) => {
            if (response.status === 200 && response.data.data.isValidated === false) {
              this.error =
                "Account not Activated, check your Email for the activate link. If you did not get the mail, click forget password";
              this.loading = false;
              return;
            }
            if (response.status === 200 && response.data.data.isValidated === true) {
              let user = response.data.data;
              localStorage.setItem("skill_user", JSON.stringify(user));
              localStorage.setItem("skill_jwt", user.token);
              this.$store.commit("setAuthUser", user);
              this.$store.commit("setJoinedGroupClass", user.groupClasses);
              this.$store.commit("setIsLoggedIn", true);
              if (user.tutorStatus == true) {
                this.$router.push({ name: "Tutor" });
              } else this.$router.push({ name: "Dashboard" });

              this.getProfile();
            }
          })
          .catch((error) => {
            if (error.response) {
              this.error = error.response.data.message;
              this.loading = false;
            } else {
              this.error = "Something went wrong, please try again";
              this.loading = false;
            }
          });
      }
    },
    signup() {
      this.$router.push({ name: "Signup" });
    },

    async getProfile() {
      let token = this.$store.state.authUser.token;
      if (Object.keys(this.$store.state.authProfile).length === 0) {
        await axios
          .get(`user/profile/${this.$store.state.authUser._id}`, {
            headers: {
              "x-access-token": token,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              this.$store.commit("setAuthProfile", response.data.data);
            }
          })
          .catch(() => {
            localStorage.removeItem("skill_user");
            localStorage.removeItem("skill_jwt");
            this.$store.commit("setAuthUser", {});
            this.$store.commit("setAuthProfile", {});
            this.$store.commit("setIsLoggedIn", false);
            this.$router.push({ name: "Signin" });
          });
      }
    },
    focusPassword() {
      let input = this.$refs.password.$el.querySelector("input");
      input.focus();
    },
  },
  computed: {
    validEmail() {
      /* eslint-disable */
      return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,9})+$/.test(this.form.email);
      /* eslint-enable */
    },
  },
  mounted() {
    let input = this.$refs.email.$el.querySelector("input");
    input.focus();
  },
};
</script>

<style></style>
