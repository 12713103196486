<template>
  <div class="overflow-y-auto">
    <div
      class="px-6 pt-4 pb-3 border-b border-slighter flex items-center justify-between bg-white"
    >
      <div class="flex">
        <button @click="$router.go(-1)" class="">
          <i
            class="fal fa-long-arrow-left text-skilldeepyelow text-center text-xl pr-5"
          ></i>
        </button>
        <h1 class="text-base font-medium text-skillblack">Transactions</h1>
      </div>
    </div>
    <!--  -->
    <div class="mt-12 mx-8">
      <vs-table striped>
        <template #thead>
          <vs-tr>
            <vs-th class="w-2/12"> Date </vs-th>
            <vs-th class="w-3/12"> Transaction Ref </vs-th>
            <vs-th class="w-2/12"> Amount (₦) </vs-th>
            <vs-th class="w-5/12"> Purpose </vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr :key="i" v-for="(tr, i) in transactions" :data="tr">
            <vs-td class="w-2/12">
              {{ tr.dateofTransaction.slice(0, 10) }}
            </vs-td>
            <vs-td class="w-3/12">
              {{ tr.refNo }}
            </vs-td>
            <vs-td class="w-2/12">
              {{ tr.amount.toLocaleString() }}
            </vs-td>
            <vs-td class="w-5/12">
              {{ tr.purpose }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>

    <!--  -->
  </div>
</template>

<script>
import axios from "../../plugin/axios";
export default {
  data() {
    return {
      transactions: [],
    };
  },
  methods: {
    async transaction() {
      let token = this.$store.state.authUser.token;
      let url = `txn/user/${this.$store.state.authUser._id}`;
      await axios
        .get(url, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          let data = response.data.data;
          this.transactions = data;
          // console.log(response);
        })
        .catch(() => {});
    },
  },
  async mounted() {
    await this.transaction();
  },
};
</script>

<style></style>
