<template>
  <section class="w-full xl:px-5 bg-skillyelow shadow-lg mb-6 z-50">
    <div class="flex justify-between px-6 pt-3 pb-2 sticky top-0 bg-skillyelow z-40">
      <div @click="scrollToTop">
        <router-link to="/">
          <button>
            <img
              src="../../../src/assets/logo.png"
              alt="Logo"
              class="h-6 lg:h-10 w-auto"
            />
          </button>
        </router-link>
      </div>
      <div class="sm:flex hidden justify-between space-x-3">
        <vs-button @click="$router.push({ name: 'Signup' })" color="#000" class="">
          <p class="text-sm uppercase font-semibold px-1 py-1 text-skillyelow">
            Get started 🚀
          </p>
        </vs-button>

        <vs-button @click="$router.push({ name: 'Signin' })" color="#000" border class="">
          <p class="text-sm uppercase font-semibold px-5 py-1 text-skillblack">Login</p>
        </vs-button>
      </div>
    </div>

    <!--  -->
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {
    scrollToTop() {
      let element = document.getElementById("container");
      element.scrollIntoView();
    },
  },
  beforeMount() {},
};
</script>

<style></style>
