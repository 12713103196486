<template>
  <section class="bg-gray-100 w-full px-6 py-2 h-full overflow-y-auto">
    <div class="px-2 py-5 mt-8 lg:px-8 mx-0">
      <div class="">
        <h1 class="text-xl font-medium text-skillblack lg:px-6">Private Classes</h1>
      </div>

      <!--  -->
      <div class="w-full lg:px-4 mt-8">
        <div class="shadow-lg rounded bg-white border pb-4">
          <div class="py-3 flex justify-between mx-4">
            <h3 class="font-semibold text-lg text-gray-800">List of Private Classes</h3>
            <button
              @click="openAddClassDialog = true"
              class="bg-skilldeepyelow py-1 px-3 rounded-md text-sm font-semibold shadow-md"
            >
              Add new Class
            </button>
          </div>
          <div class="block w-full overflow-x-auto">
            <table class="w-full">
              <thead>
                <tr class="bg-gray-100 border-gray-200 text-gray-600">
                  <th
                    class="py-3 px-4 border text-xs uppercase border-l-0 border-r-0 font-semibold text-left"
                  >
                    Name
                  </th>
                  <th
                    class="px-4 border text-xs uppercase border-l-0 border-r-0 font-semibold text-left"
                  >
                    School
                  </th>

                  <th class="border border-l-0 border-r-0 text-left"></th>
                  <th class="border border-l-0 border-r-0 text-left"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(clas, index) in privateClass" :key="clas.id" class="border-t">
                  <td class="py-2 pl-4">
                    <p>
                      {{ clas.name }}
                    </p>
                  </td>
                  <td class="py-2 pl-4">
                    <p v-if="clas.school">
                      {{ clas.school.name }}
                    </p>
                  </td>

                  <td v-if="clas.school" class="py-2 pr-4 text-right cursor-pointer">
                    <i
                      @click="editClassMethod(index)"
                      class="far fa-edit text-green-600"
                    ></i>
                  </td>
                  <td v-if="clas.school" class="py-2 pr-4 text-left">
                    <i
                      @click="destroy(index)"
                      class="fas fa-trash-alt cursor-pointer text-red-600"
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- Add Class  Dialog -->
      <vs-dialog scroll prevent-close width="850px" v-model="openAddClassDialog">
        <template #header>
          <h4 class="not-margin font-semibold">Add New Private Class</h4>
        </template>
        <div class="my-3">
          <vs-input block label-placeholder="Class Name***" v-model="newClass.name" />
        </div>
        <div>
          <p class="text-sm text-red-600 pb-2">Class Image***</p>
          <picture-input
            ref="pictureInput"
            @change="onChange"
            width="600"
            height="300"
            margin="16"
            accept="image/jpeg,image/png"
            size="10"
            buttonClass="bg-skilldeepyelow text-black font-semibold rounded-xl p-3 px-5"
          >
          </picture-input>
        </div>
        <p class="text-sm text-red-600 py-2">Class Description</p>
        <textarea
          v-model="newClass.description"
          class="w-full border rounded-md mb-2 p-3"
          rows="7"
          placeholder="Start typing "
        ></textarea>
        <p class="text-sm text-red-600 py-2">Select a School***</p>
        <div
          v-for="school in this.$store.state.schools"
          :key="school.index"
          class="flex pl-2"
        >
          <vs-radio v-model="newClass.school" :val="school.id">
            {{ school.name }}
          </vs-radio>
        </div>
        <div class="pt-8">
          <vs-input
            block
            label-placeholder="Price***"
            type="number"
            v-model="newClass.price"
          />
        </div>

        <p class="text-sm text-red-600 py-2">Curriculum***</p>
        <div
          v-for="(input, index) in newClass.curriculum"
          :key="input.index"
          class="flex items-center my-2"
        >
          <vs-input block placeholder="Module" v-model="newClass.curriculum[index]" />
          <i
            class="fal fa-plus-circle text-2xl text-green-600 px-2"
            @click="addModule"
          ></i>
          <i
            class="fal fa-times-circle text-2xl text-red-600 px-2"
            v-if="newClass.curriculum.length !== 1"
            @click="removeModule(index)"
          ></i>
        </div>

        <!--  -->
        <p class="text-sm text-red-600 py-2">What you'll learn</p>
        <div
          v-for="(input, index) in newClass.learningList"
          :key="input.index"
          class="flex items-center my-2"
        >
          <vs-input
            block
            placeholder="e.g. Figma, Photoshop"
            v-model="newClass.learningList[index]"
          />
          <i
            class="fal fa-plus-circle text-2xl text-green-600 px-2"
            @click="addLearn"
          ></i>
          <i
            class="fal fa-times-circle text-2xl text-red-600 px-2"
            v-if="newClass.learningList.length !== 1"
            @click="removeLearn(index)"
          ></i>
        </div>

        <!--  -->
        <p class="text-sm text-red-600 py-2">This class includes</p>
        <div
          v-for="(input, index) in newClass.inclusion"
          :key="input.index"
          class="flex items-center my-2"
        >
          <vs-input
            block
            placeholder="e.g. 5 PDF articles"
            v-model="newClass.inclusion[index]"
          />
          <i
            class="fal fa-plus-circle text-2xl text-green-600 px-2"
            @click="addInclude"
          ></i>
          <i
            class="fal fa-times-circle text-2xl text-red-600 px-2"
            v-if="newClass.inclusion.length !== 1"
            @click="removeInclude(index)"
          ></i>
        </div>
        <!--  -->
        <p class="text-sm text-red-600 py-2">Duration</p>
        <textarea
          v-model="newClass.duration"
          class="w-full border rounded-md mb-2 p-3"
          rows="3"
          placeholder="Start typing "
        ></textarea>
        <!--  -->
        <p class="text-sm text-red-600 py-2">Requirements</p>
        <div
          v-for="(input, index) in newClass.requirements"
          :key="input.index"
          class="flex items-center my-2"
        >
          <vs-input
            block
            placeholder="e.g. Laptop"
            v-model="newClass.requirements[index]"
          />
          <i
            class="fal fa-plus-circle text-2xl text-green-600 px-2"
            @click="addRequirement"
          ></i>
          <i
            class="fal fa-times-circle text-2xl text-red-600 px-2"
            v-if="newClass.requirements.length !== 1"
            @click="removeRequirement(index)"
          ></i>
        </div>
        <!--  -->
        <p class="text-sm text-red-600 py-2">Who is this course for?</p>
        <textarea
          v-model="newClass.audienceDescription"
          class="w-full border rounded-md mb-2 p-3"
          rows="3"
          placeholder="Start typing "
        ></textarea>

        <!--  -->
        <template #footer>
          <div class="footer-dialog">
            <vs-button
              @click="addClass"
              block
              active
              :loading="loading"
              :disabled="
                newClass.name !== '' &&
                newClass.school !== '' &&
                newClass.classImage !== '' &&
                newClass.price !== '' &&
                newClass.curriculum[0] !== ''
                  ? false
                  : true
              "
              class="mb-2 mt-1 disabled"
              style="color: black"
            >
              <p class="font-semibold">Submit</p>
            </vs-button>
          </div>
        </template>
      </vs-dialog>

      <!-- Edit Class Dialog -->
      <div v-if="editClass.curriculum">
        <vs-dialog scroll prevent-close width="850px" v-model="openEditClassDialog">
          <template #header>
            <h4 class="not-margin font-semibold">Edit Private Class</h4>
          </template>
          <div class="my-3">
            <vs-input block label-placeholder="Class Name***" v-model="editClass.name" />
          </div>
          <div>
            <p class="text-sm text-red-600 pb-2">Class Image***</p>
            <picture-input
              ref="pictureInput"
              @change="onChangeEdit"
              width="600"
              height="300"
              margin="16"
              :prefill="editClass.classImage"
              size="10"
              buttonClass="bg-skilldeepyelow text-black font-semibold rounded-xl p-3 px-5"
            >
            </picture-input>
          </div>
          <p class="text-sm text-red-600 py-2">Class Description</p>
          <textarea
            v-model="editClass.description"
            class="w-full border rounded-md mb-2 p-3"
            rows="7"
            placeholder="Start typing "
          ></textarea>
          <p class="text-sm text-red-600 py-2">School</p>
          <div class="flex pl-2">
            <vs-radio checked>
              {{ editClass.school.name }}
            </vs-radio>
          </div>
          <div class="pt-8">
            <vs-input
              block
              label-placeholder="Price***"
              type="number"
              v-model="editClass.price"
            />
          </div>

          <p class="text-sm text-red-600 py-2">Curriculum***</p>
          <div
            v-for="(input, index) in editClass.curriculum"
            :key="input.index"
            class="flex items-center my-2"
          >
            <vs-input block placeholder="Module" v-model="editClass.curriculum[index]" />
            <i
              class="fal fa-plus-circle text-2xl text-green-600 px-2"
              @click="addModuleEdit"
            ></i>
            <i
              class="fal fa-times-circle text-2xl text-red-600 px-2"
              v-if="editClass.curriculum.length !== 1"
              @click="removeModuleEdit(index)"
            ></i>
          </div>

          <!--  -->
          <p class="text-sm text-red-600 py-2">What you'll learn</p>
          <div
            v-for="(input, index) in editClass.learningList"
            :key="input.index"
            class="flex items-center my-2"
          >
            <vs-input
              block
              placeholder="e.g. Figma, Photoshop"
              v-model="editClass.learningList[index]"
            />
            <i
              class="fal fa-plus-circle text-2xl text-green-600 px-2"
              @click="addLearnEdit"
            ></i>
            <i
              class="fal fa-times-circle text-2xl text-red-600 px-2"
              v-if="editClass.learningList.length !== 1"
              @click="removeLearnEdit(index)"
            ></i>
          </div>

          <!--  -->
          <p class="text-sm text-red-600 py-2">This class includes</p>
          <div
            v-for="(input, index) in editClass.inclusion"
            :key="input.index"
            class="flex items-center my-2"
          >
            <vs-input
              block
              placeholder="e.g. 5 PDF articles"
              v-model="editClass.inclusion[index]"
            />
            <i
              class="fal fa-plus-circle text-2xl text-green-600 px-2"
              @click="addIncludeEdit"
            ></i>
            <i
              class="fal fa-times-circle text-2xl text-red-600 px-2"
              v-if="editClass.inclusion.length !== 1"
              @click="removeIncludeEdit(index)"
            ></i>
          </div>
          <!--  -->
          <p class="text-sm text-red-600 py-2">Duration</p>
          <textarea
            v-model="editClass.duration"
            class="w-full border rounded-md mb-2 p-3"
            rows="3"
            placeholder="Start typing "
          ></textarea>
          <!--  -->
          <p class="text-sm text-red-600 py-2">Requirements</p>
          <div
            v-for="(input, index) in editClass.requirements"
            :key="input.index"
            class="flex items-center my-2"
          >
            <vs-input
              block
              placeholder="e.g. Laptop"
              v-model="editClass.requirements[index]"
            />
            <i
              class="fal fa-plus-circle text-2xl text-green-600 px-2"
              @click="addRequirementEdit"
            ></i>
            <i
              class="fal fa-times-circle text-2xl text-red-600 px-2"
              v-if="editClass.requirements.length !== 1"
              @click="removeRequirementEdit(index)"
            ></i>
          </div>
          <!--  -->
          <p class="text-sm text-red-600 py-2">Who is this course for?</p>
          <textarea
            v-model="editClass.audienceDescription"
            class="w-full border rounded-md mb-2 p-3"
            rows="3"
            placeholder="Start typing "
          ></textarea>

          <!--  -->
          <template #footer>
            <div class="footer-dialog">
              <vs-button
                @click="updateClass"
                block
                active
                :loading="loading"
                :disabled="
                  editClass.name !== '' &&
                  editClass.school !== '' &&
                  editClass.classImage !== '' &&
                  editClass.price !== '' &&
                  editClass.curriculum[0] !== ''
                    ? false
                    : true
                "
                class="mb-2 mt-1 disabled"
                style="color: black"
              >
                <p class="font-semibold">Update Class</p>
              </vs-button>
            </div>
          </template>
        </vs-dialog>
      </div>

      <!-- Delete Private Class Dialog -->
      <vs-dialog width="550px" v-model="destroyClassDialogOpen">
        <template #header>
          <h4 class="pt-3">
            Are you sure you want to Delete <b>{{ deleteClass.name }}</b>
          </h4>
        </template>

        <div class="con-content">
          <p>This action is irreversible.</p>
        </div>

        <template #footer>
          <div class="flex">
            <vs-button
              :loading="loading"
              @click="destroyClass"
              color="youtube"
              style="color: white"
            >
              <p class="font-semibold">Delete</p>
            </vs-button>
            <vs-button @click="destroyClassDialogOpen = false" dark transparent>
              Cancel
            </vs-button>
          </div>
        </template>
      </vs-dialog>
      <!--  -->
    </div>
  </section>
</template>

<script>
import PictureInput from "vue-picture-input";
import axios1 from "axios";
import axios from ".././plugin/axios";
export default {
  components: { PictureInput },

  data() {
    return {
      loading: false,
      privateClass: [],
      newClass: {
        name: "",
        classImage: "",
        description: "",
        school: "",
        price: "",
        duration: "",
        audienceDescription: "",
        curriculum: [""],
        learningList: [""],
        inclusion: [""],
        requirements: [""],
      },
      deleteClass: { name: "", classid: "" },
      editClass: {},
      editSchoolID: "",

      openAddClassDialog: false,
      destroyClassDialogOpen: false,
      openEditClassDialog: false,
      message: "",
    };
  },
  methods: {
    addModule() {
      this.newClass.curriculum.push("");
    },
    removeModule(index) {
      this.newClass.curriculum = this.newClass.curriculum.filter(
        (input, i) => i !== index
      );
    },
    //
    addLearn() {
      this.newClass.learningList.push("");
    },
    removeLearn(index) {
      this.newClass.learningList = this.newClass.learningList.filter(
        (input, i) => i !== index
      );
    },
    //
    addInclude() {
      this.newClass.inclusion.push("");
    },
    removeInclude(index) {
      this.newClass.inclusion = this.newClass.inclusion.filter((input, i) => i !== index);
    },
    //
    addRequirement() {
      this.newClass.requirements.push("");
    },
    removeRequirement(index) {
      this.newClass.requirements = this.newClass.requirements.filter(
        (input, i) => i !== index
      );
    },

    //  //
    addModuleEdit() {
      this.editClass.curriculum.push("");
    },
    removeModuleEdit(index) {
      this.editClass.curriculum = this.editClass.curriculum.filter(
        (input, i) => i !== index
      );
    },
    //
    addLearnEdit() {
      this.editClass.learningList.push("");
    },
    removeLearnEdit(index) {
      this.editClass.learningList = this.editClass.learningList.filter(
        (input, i) => i !== index
      );
    },
    //
    addIncludeEdit() {
      this.editClass.inclusion.push("");
    },
    removeIncludeEdit(index) {
      this.editClass.inclusion = this.editClass.inclusion.filter(
        (input, i) => i !== index
      );
    },
    //
    addRequirementEdit() {
      this.editClass.requirements.push("");
    },
    removeRequirementEdit(index) {
      this.editClass.requirements = this.editClass.requirements.filter(
        (input, i) => i !== index
      );
    },
    //
    onChange(image) {
      if (image) {
        this.newClass.classImage = image;
      } else {
        console.log("Error Uploding image");
      }
    },
    onChangeEdit(image) {
      if (image) {
        this.editClass.classImage = image;
      } else {
        console.log("Error Uploding image");
      }
    },
    // //

    editClassMethod(index) {
      this.editClass = this.privateClass[index];
      this.editSchoolID = this.privateClass[index].school._id;
      this.openEditClassDialog = true;
    },

    async updateClass() {
      this.loading = true;
      this.editClass.school = this.editSchoolID;
      this.editClass.classid = this.editClass._id;
      if (this.editClass.classImage.includes("data:image")) {
        const url = "https://api.cloudinary.com/v1_1/skillng/image/upload";

        let formData = new FormData();
        formData.append("api_key", "249958796989371");
        formData.append("file", this.editClass.classImage);
        formData.append("upload_preset", "skillng");

        await axios1
          .post(url, formData)
          .then((response) => {
            this.editClass.classImage = response.data.secure_url;
            // console.log(this.newClass.classImage);
            this.saveEditData();
          })
          .catch(() => {
            this.message = "Error Uploding image";
            this.errorAlart();
            this.loading = false;
            this.openEditClassDialog = false;
          });
      } else this.saveEditData();
    },
    async saveEditData() {
      let token = this.$store.state.authUser.token;
      let url = "pclass/update/";
      await axios
        .post(url, this.editClass, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          // console.log(response);
          if (response.status === 200) {
            this.message = "Private Class updated sucessfully";
            this.sucessAlart();
            this.loadClassList();
            this.loading = false;
            this.openEditClassDialog = false;
          }
        })
        .catch((error) => {
          this.message = error;
          this.errorAlart();
          this.loading = false;
          this.openEditClassDialog = false;
        });
    },

    //

    addClass() {
      this.loading = true;
      if (this.newClass.classImage.includes("data:image")) {
        const url = "https://api.cloudinary.com/v1_1/skillng/image/upload";

        let formData = new FormData();
        formData.append("api_key", "249958796989371");
        formData.append("file", this.newClass.classImage);
        formData.append("upload_preset", "skillng");

        axios1
          .post(url, formData)
          .then((response) => {
            this.newClass.classImage = response.data.secure_url;
            // console.log(this.newClass.classImage);
            this.saveData();
          })
          .catch(() => {
            this.message = "Error Uploding image";
            this.errorAlart();
            this.loading = false;
            this.openAddClassDialog = false;
          });
      } else this.saveData();
    },
    async saveData() {
      let token = this.$store.state.authUser.token;
      let url = "pclass/create/";
      await axios
        .post(url, this.newClass, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          // console.log(response);
          if (response.status === 200) {
            this.message = "Private Class created sucessfully";
            this.sucessAlart();
            this.loadClassList();
            this.loading = false;
            this.openAddClassDialog = false;
          }
        })
        .catch((error) => {
          this.message = error;
          this.errorAlart();
          this.loading = false;
          this.openAddClassDialog = false;
        });
    },
    sucessAlart() {
      this.$swal.fire({
        position: "top-end",
        icon: "success",
        title: this.message,
        showConfirmButton: false,
        timer: 3000,
        toast: true,
      });
    },
    errorAlart() {
      this.$swal.fire({
        position: "top-end",
        icon: "error",
        title: this.message,
        showConfirmButton: false,
        timer: 6000,
        toast: true,
      });
    },
    async loadClassList() {
      this.privateClass = [{ name: "Loading data" }];
      let url = "pclass/all";
      let token = this.$store.state.authUser.token;

      await axios
        .get(url, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          let data = response.data.data.classes;
          this.$store.commit("setPrivateClass", data);
        })
        .catch(() => {});
      this.privateClass = this.$store.state.privateClass;
    },
    async destroyClass() {
      this.loading = true;
      let token = this.$store.state.authUser.token;

      let url = "pclass/delete/";
      await axios
        .post(
          url,
          { classid: this.deleteClass.classid },
          {
            headers: {
              "x-access-token": token,
            },
          }
        )
        .then((response) => {
          // console.log(response);
          if (response.status === 200) {
            this.message = "Private Class  deleted sucessfully";
            this.sucessAlart();
            this.loadClassList();
            this.loading = false;
            this.destroyClassDialogOpen = false;
          }
        })
        .catch((error) => {
          this.message = error;
          this.errorAlart();
          this.loading = false;
          this.destroyClassDialogOpen = false;
        });
    },
    destroy(index) {
      let id = index;
      let clas = this.$store.state.privateClass;
      this.deleteClass = {
        name: clas[id].name,
        classid: clas[id]._id,
      };
      this.destroyClassDialogOpen = true;
    },
  },
  async mounted() {
    this.privateClass = [{ name: "Loading data" }];
    if (this.$store.state.privateClass.length === 0) {
      let url = "pclass/all";
      let token = this.$store.state.authUser.token;
      await axios
        .get(url, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          let data = response.data.data.classes;
          this.$store.commit("setPrivateClass", data);
        })
        .catch(() => {});
    }
    this.privateClass = this.$store.state.privateClass;
  },
};
</script>

<style></style>
