<template>
  <div class="overflow-y-auto">
    <div
      class="px-6 pt-4 pb-3 border-b border-slighter flex items-center justify-between bg-white"
    >
      <div class="flex">
        <button @click="$router.go(-1)" class="">
          <i
            class="fal fa-long-arrow-left text-skilldeepyelow text-center text-xl pr-5"
          ></i>
        </button>
        <h1 class="text-base font-medium text-skillblack">Update Password</h1>
      </div>
    </div>
    <!--  -->
    <div class="mx-8">
      <vs-alert closable v-model="error" color="danger" class="mt-4">
        <p class="font-semibold">{{ error }}</p>
      </vs-alert>
      <vs-alert closable v-model="success" color="success" class="mt-4">
        <p class="font-semibold text-green-800">{{ success }}</p>
      </vs-alert>
    </div>
    <!--  -->

    <div class="">
      <div class="space-y-8 mt-12 mx-10">
        <vs-input
          block
          icon-after
          type="password"
          label="Current Password"
          v-model="form.currentPassword"
          :visiblePassword="hasVisibleCurrentPassword"
          @click-icon="hasVisibleCurrentPassword = !hasVisibleCurrentPassword"
        >
          <template #icon>
            <i v-if="!hasVisiblePassword" class="fas fa-eye-slash"></i>
            <i v-else class="fas fa-eye"></i>
          </template>
        </vs-input>
        <vs-input
          block
          icon-after
          type="password"
          label="New Password"
          v-model="form.password"
          :progress="getProgress"
          :visiblePassword="hasVisiblePassword"
          @click-icon="hasVisiblePassword = !hasVisiblePassword"
        >
          <template #icon>
            <i v-if="!hasVisiblePassword" class="fas fa-eye-slash"></i>
            <i v-else class="fas fa-eye"></i>
          </template>
          <template v-if="form.password && getProgress >= 40" #message-success>
            Strong Password
          </template>
          <template v-if="form.password && getProgress < 40" #message-danger>
            Weak Password
          </template>
        </vs-input>
        <vs-input
          block
          @keydown.enter="submit"
          icon-after
          type="password"
          label="Confirm Password"
          v-model="passwordConfirm"
          :visiblePassword="hasVisiblePasswordConfirm"
          @click-icon="hasVisiblePasswordConfirm = !hasVisiblePasswordConfirm"
        >
          <template #icon>
            <i v-if="!hasVisiblePassword" class="fas fa-eye-slash"></i>
            <i v-else class="fas fa-eye"></i>
          </template>
          <template
            v-if="passwordConfirm && form.password === passwordConfirm"
            #message-success
          >
            Match Password
          </template>
          <template
            v-if="passwordConfirm && form.password !== passwordConfirm"
            #message-danger
          >
            Password not Match
          </template>
        </vs-input>
      </div>
    </div>
    <!--  -->
    <p class="mt-8 text-gray-400 text-sm text-center">
      Changing your password will log you out and you will need to log in again.
    </p>
    <!--  -->
    <div class="mx-8 py-4">
      <vs-button
        flat
        active
        @click="submit"
        :loading="loading"
        :disabled="
          passwordConfirm && form.password === passwordConfirm && getProgress >= 40
            ? false
            : true
        "
        class="w-full shadow-md"
        type="button"
      >
        <p class="text-skillblack py-0.5 text-base">Save</p>
      </vs-button>
    </div>
    <!--  -->
  </div>
</template>

<script>
import axios from "../../plugin/axios";

export default {
  data() {
    return {
      success: "",
      error: "",
      loading: false,
      passwordConfirm: "",
      form: { userid: "", password: "", currentPassword: "" },
      hasVisiblePassword: false,
      hasVisiblePasswordConfirm: false,
      hasVisibleCurrentPassword: false,
    };
  },
  methods: {
    async submit() {
      this.success = "";
      this.error = "";
      this.loading = true;
      this.form.userid = `${this.$store.state.authUser._id}`;
      let token = this.$store.state.authUser.token;
      await axios
        .post("user/update/password", this.form, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            this.success =
              "Password Updated sucesfully, you will be redirected to login page after 3 seconds.";
            this.loading = false;
            setTimeout(() => {
              localStorage.removeItem("skill_user");
              localStorage.removeItem("skill_jwt");
              this.$store.commit("setAuthUser", {});
              this.$store.commit("setAuthProfile", {});
              this.$store.commit("setIsLoggedIn", false);
              this.$router.push({ name: "Signin" });
            }, 3000);
          }
          console.log(response);
        })
        .catch((error) => {
          this.error = error.response.data.message;
          this.loading = false;
          console.log(error);
        });
    },
  },
  computed: {
    getProgress() {
      let progress = 0;

      // at least one number

      if (/\d/.test(this.form.password)) {
        progress += 20;
      }

      // at least one capital letter

      if (/(.*[A-Z].*)/.test(this.form.password)) {
        progress += 20;
      }

      // at menons a lowercase

      if (/(.*[a-z].*)/.test(this.form.password)) {
        progress += 20;
      }

      // more than 5 digits

      if (this.form.password.length >= 6) {
        progress += 20;
      }

      // at least one special character

      if (/[^A-Za-z0-9]/.test(this.form.password)) {
        progress += 20;
      }

      return progress;
    },
  },
};
</script>

<style></style>
