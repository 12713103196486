<template>
  <div
    class="z-10 w-full h-almost overflow-y-auto border border-slighter rounded-t-2xl shadow-md"
  >
    <div class="z-20 w-full min-h-screen h-full lg:h-screen lg:pb-10 pb-16">
      <div class="mb-5 px-6 py-5 flex items-center">
        <button @click="$router.go(-1)" class="">
          <i
            class="fal fa-long-arrow-left text-skilldeepyelow text-center text-xl pr-5"
          ></i>
        </button>
        <div
          class="animate-pulse skeleton-box h-6 w-56 rounded-md bg-gray-100"
          v-if="skeletonLoader"
        ></div>
        <h1 class="text-xl font-medium text-skillblack" v-if="!skeletonLoader">
          {{ school.name }}
        </h1>
      </div>
      <!--  -->
      <div class="mx-auto w-4/5 mb-12">
        <div class="w-full px-6 py-4 text-white bg-deepblue rounded-2xl shadow-lg">
          <p class="font-semibold pb-2">{{ school.aboutTitle }}</p>
          <p class="text-sm whitespace-pre-line">
            {{ school.aboutDescription }}
          </p>
        </div>
      </div>
      <!--  -->

      <!-- Empty class -->
      <div v-if="!skeletonLoader" class="px-10 md:px-48 lg:px-72">
        <div v-if="school.groupClasses.length == 0" class="my-5 p-2 text-center">
          <i class="fad fa-exclamation-circle text-yellow-400 text-4xl mb-2"></i>
          <p>
            This school is not currently taking any registration, kindly check back later
            or visit other schools to register for a tutor-led course.
          </p>
        </div>
      </div>

      <!-- class skeleton  loader -->
      <div class="lg:px-8 px-5 mb-10" v-if="skeletonLoader">
        <div>
          <div class="py-8">
            <div class="h-36 bg-gray-100 rounded-lg animate-pulse skeleton-box"></div>
          </div>
        </div>
      </div>
      <!--  -->

      <div :class="school.groupClasses.length == 0 ? '' : 'pb-24'"></div>
      <div v-if="school.groupClasses.length != 0" class="lg:px-8 px-5 pb-16">
        <p class="font-semibold text-sm text-gray-600" v-if="!skeletonLoader">
          Upcoming classes in <b>{{ school.name }} </b>
        </p>
        <!-- skeleton  loader -->
        <div class="lg:px-8 px-5 mb-10" v-if="skeletonLoader">
          <div>
            <div class="py-8">
              <div class="h-80 bg-gray-100 rounded-lg animate-pulse skeleton-box"></div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="flex flex-wrap">
          <div
            class="relative md:w-1/2 w-full py-3 md:px-3"
            v-for="gclass in school.groupClasses"
            :key="gclass.index"
          >
            <div
              @click="
                $router.push({ name: 'GroupClass', params: { gclassID: gclass.id } })
              "
              class="relative block rounded-lg overflow-hidden shadow-md"
            >
              <div
                class="w-full h-full bg-black hover:opacity-10 opacity-0 absolute z-10"
              ></div>
              <div class="h-36">
                <img
                  v-if="gclass.classImage"
                  class="absolute inset-0 h-full w-full object-cover z-0"
                  :src="
                    'https://res.cloudinary.com/skillng/image/fetch/' + gclass.classImage
                  "
                  alt="Image"
                />
              </div>

              <div class="h-24 bg-vtrans opacity-90"></div>

              <div class="absolute left-3 bottom-1 text-white w-full z-10">
                <p class="text-xl font-semibold w-2/3">{{ gclass.name }}</p>
                <p v-if="gclass.price" class="font-semibold mb-2">
                  ₦{{ gclass.price.toLocaleString() }}
                </p>
                <p v-else class="font-semibold mb-2">Free class</p>
              </div>
              <div class="absolute right-3 bottom-1 grid justify-items-center z-10">
                <div
                  class="text-center rounded-lg border bg-skillyelow text-skillblack w-20 p-1"
                >
                  <p class="font-bold">{{ new Date(gclass.dueDate).toDateString() }}</p>
                </div>
                <p class="text-white font-semibold text-sm mb-2">Deadline</p>
              </div>
            </div>
            <!-- change hidden t flex  -->
            <div class="absolute top-4 md:right-4 right-2 hidden justify-between z-10">
              <vs-button @click="bookmark" icon color="#fdffc2">
                <i class="far fa-bookmark vs-icon-black"></i>
              </vs-button>
              <vs-button icon color="#000">
                <i class="fas fa-share-alt"></i>
              </vs-button>
            </div>
            <!--  -->
          </div>
        </div>
      </div>

      <div v-if="reviews.length != 0" class="w-full mx-auto pt-16">
        <div class="flex flex-wrap">
          <div
            class="lg:w-2/5 w-full bg-skilldeepyelow py-16 lg:py-32 px-8 lg:px-16 lg:text-center text-left rounded-t-lg lg:rounded-none"
          >
            <h1 class="font-extrabold text-4xl text-black pb-2">
              Join Our Happy Learners
            </h1>
            <p class="text-lg text-black">
              Join over 3,000 other young people who are happy to kickstart their digital
              careers from SkillNG. We're building a network of valuable workforce for the
              future, won't you join us?
            </p>
          </div>
          <div
            class="lg:w-3/5 w-full bg-gray-200 md:px-4 md:pb-4 px-2 pb-2 lg:pl-0 lg:pt-10 lg:pr-10 lg:pb-10"
          >
            <div v-for="(review, index) in reviews" :key="review.index">
              <div v-if="current === index" class="">
                <div class="relative bg-white">
                  <img
                    src="../assets/quote-img.png"
                    alt=""
                    class="absolute -mt-4 pl-6 sm:pl-12"
                  />
                  <div class="pt-20 px-6 sm:px-12">
                    <p class="text-gray-600 text-base font-medium mb-1">
                      {{ review.class.name }}
                    </p>

                    <p class="text-gray-600 text-base pb-3">{{ review.comment }}</p>

                    <div class="flex items-center justify-between">
                      <div class="pb-12">
                        <p class="text-gray-600 font-semibold">
                          {{ review.user.fullName }}
                        </p>
                        <div class="flex items-center mb-2">
                          <star-rating
                            :star-size="15"
                            :read-only="true"
                            :show-rating="false"
                            v-model="review.star"
                          ></star-rating>
                          <p class="text-gray-800 text-sm ml-4">
                            {{ review.reviewDate | moment("ddd, MMM D YYYY, h:mm A") }}
                          </p>
                        </div>
                      </div>

                      <div class="cursor-pointer flex pb-12">
                        <svg
                          class="icon icon-tabler icon-tabler-chevron-left"
                          width="44"
                          height="44"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#CBD5E0"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          @click="movePrev()"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" />
                          <polyline points="15 6 9 12 15 18" />
                        </svg>
                        <svg
                          class="icon icon-tabler icon-tabler-chevron-right"
                          width="44"
                          height="44"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#CBD5E0"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          @click="moveForward()"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" />
                          <polyline points="9 6 15 12 9 18" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
    </div>
  </div>
</template>

<script>
import axios from "../plugin/axios";
import StarRating from "vue-star-rating";

export default {
  components: { StarRating },

  data() {
    return {
      skeletonLoader: true,
      school: { groupClasses: [] },
      current: 0,
      reviews: [],
    };
  },
  methods: {
    async loadSchoolReviews() {
      let url = `reviews/school/${this.$route.params.schoolID}`;
      let token = this.$store.state.authUser.token;

      await axios
        .get(url, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          let data = response.data.data;
          this.reviews = data;
          // console.log(response);
        })
        .catch(() => {});
    },

    bookmark() {
      this.$swal.fire({
        position: "bottom",
        icon: "success",
        title: "Class bookmarked sucessfully",
        showConfirmButton: false,
        timer: 3000,
        toast: true,
      });
    },

    moveForward() {
      this.current = this.current === this.reviews.length - 1 ? 0 : this.current + 1;
    },

    movePrev() {
      this.current = this.current === 0 ? this.reviews.length - 1 : this.current - 1;
    },
  },

  async beforeMount() {
    if (this.$route.params.schoolID == undefined) {
      this.$router.push({ name: "Schools" });
      return;
    }

    this.loadSchoolReviews();

    let url = `school/active/${this.$route.params.schoolID}`;
    let token = this.$store.state.authUser.token;

    await axios
      .get(url, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        //console.log(response);
        let data = response.data.data;
        this.$store.commit("setSchoolUnit", data);
        this.school = this.$store.state.schoolUnit;
        this.skeletonLoader = false;
      })
      .catch(() => {});
  },
};
</script>

<style></style>
