<template>
  <div class="bg-skilldeepyelow w-full h-full flex justify-center items-center">
    <p class="-mt-56 font-extralight text-3xl text-center p-5 leading-normal">
      Your email has been verified.<br />
      You can now sign-in with your new account.<br />
      This page will redirect in {{ timerCount }} seconds.<br />
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timerCount: 10,
    };
  },

  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
        setTimeout(() => {
          this.$router.push({ name: "Signin" });
        }, 11000);
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
