<template>
  <div>
    <Nav v-if="showHeader" />
    <section class="bg-white h-full w-full flex justify-center items-center relative">
      <div
        class="lg:pb-10 pb-16 lg:w-3/5 w-full border-t border-slighter rounded-t-2xl relative min-h-screen h-full"
      >
        <div
          class="px-6 pt-0 pb-5 flex items-center border-l border-r border-slighter rounded-t-2xl"
        ></div>

        <!--  -->

        <div class="w-full">
          <div class="relative block overflow-visible">
            <div v-if="loading" class="h-96 z-10">
              <img
                class="inset-0 h-full w-full object-contain rounded-b-2rem bg-gray-200 border border-t-0 border-slighter pointer-events-none"
                src="../assets/SKILLNGYELLOW-LOGO-MARK@300x.png"
              />
            </div>
            <div v-else class="h-96 z-10">
              <!--  -->
              <img
                v-if="classData.classImage"
                class="inset-0 h-full w-full object-cover rounded-b-2rem bg-gray-200 border border-t-0 border-slighter pointer-events-none"
                :src="
                  'https://res.cloudinary.com/skillng/image/fetch/' + classData.classImage
                "
              />
            </div>
            <div v-if="!$store.state.isLoggedIn" class="absolute top-0 left-3 z-20">
              <button @click="$router.go(-1)" class="">
                <i
                  class="fal fa-long-arrow-left text-skilldeepyelow text-center text-xl pr-5"
                ></i>
              </button>
            </div>
            <!--  -->
            <div class="w-full lg:px-12 md:px-10 px-6 absolute -mt-24">
              <div
                class="mb-5 p-5 w-full rounded-xl border border-gray-300 bg-white shadow-md"
              >
                <p class="text-xl mb-2 font-semibold">{{ classData.name }}</p>
                <div class="flex items-start justify-between">
                  <p class="font-normal mb-4">{{ classData.description }}</p>
                  <div
                    class="text-center rounded-lg bg-gray-200 shadow-md text-skillblack ml-2 p-2 break-words"
                  >
                    <p v-if="classData.dueDate" class="font-bold">
                      {{ new Date(classData.dueDate).toDateString() }}
                    </p>
                  </div>
                </div>

                <div class="flex justify-center">
                  <div>
                    <div class="flex flex-wrap justify-center items-center">
                      <p
                        v-if="classData.price > 0"
                        class="text-sm font-semibold px-3 py-1 text-skillblack"
                      >
                        ₦{{ classData.price.toLocaleString() }}
                      </p>

                      <vs-button @click="register" color="#FFCC29" class="mx-6">
                        <p class="text-sm font-semibold px-3 py-1 text-skillblack">
                          <span v-if="classData.price == 0"> Register for Free </span>
                          <span v-if="classData.price > 0"> Register Now </span>
                        </p>
                      </vs-button>
                    </div>
                    <!--  -->
                  </div>
                  <!--  -->
                </div>
                <div class="flex flex-wrap justify-center items-center mt-3">
                  <p class="text-3xl space-x-4">
                    <ShareNetwork
                      network="Facebook"
                      :url="`https://app.skillng.com/shared/` + classData._id"
                      :title="classData.name"
                      hashtags="SkillNG"
                      :quote="classData.name"
                    >
                      <i class="fab fa-facebook cursor-pointer"></i>
                    </ShareNetwork>
                    <ShareNetwork
                      network="WhatsApp"
                      :url="`https://app.skillng.com/shared/` + classData._id"
                      :title="
                        'Hi! I am inviting you to register for the course below at SkillNG. \n \n *' +
                        classData.name +
                        '*'
                      "
                      :description="classData.description"
                    >
                      <i class="fab fa-whatsapp cursor-pointer"></i>
                    </ShareNetwork>
                    <ShareNetwork
                      network="Twitter"
                      :url="`https://app.skillng.com/shared/` + classData._id"
                      :title="classData.name"
                      twitter-user="skill_ng"
                      hashtags="SkillNG"
                    >
                      <i class="fab fa-twitter cursor-pointer"></i>
                    </ShareNetwork>
                    <ShareNetwork
                      network="LinkedIn"
                      :url="`https://app.skillng.com/shared/` + classData._id"
                      :title="classData.name"
                      hashtags="SkillNG"
                      :quote="classData.name"
                    >
                      <i class="fab fa-linkedin cursor-pointer"></i>
                    </ShareNetwork>
                    <ShareNetwork
                      network="Messenger"
                      :url="`https://app.skillng.com/shared/` + classData._id"
                      :title="classData.name"
                      :description="classData.description"
                    >
                      <i class="fab fa-facebook-messenger cursor-pointer"></i>
                    </ShareNetwork>
                    <ShareNetwork
                      network="Email"
                      :url="
                        'Hi! I am inviting you to register for the course below at SkillNG. \n \n' +
                        'https://app.skillng.com/shared/' +
                        classData._id
                      "
                      :title="classData.name"
                      :description="' \n' + classData.description"
                    >
                      <i class="fas fa-envelope cursor-pointer"></i>
                    </ShareNetwork>
                    <ShareNetwork
                      network="SMS"
                      :url="`https://app.skillng.com/shared/` + classData._id"
                      :title="
                        'Register for the course below at SkillNG \n' + classData.name
                      "
                    >
                      <i class="fas fa-sms cursor-pointer"></i>
                    </ShareNetwork>
                  </p>
                </div>
                <!--  -->
              </div>
              <!--  -->
              <div
                v-if="classData.curriculum !== undefined"
                class="mb-5 p-5 w-full rounded-xl border border-gray-300 bg-white shadow-md"
              >
                <p class="text-xl mb-3 font-semibold">Curriculum</p>

                <div
                  v-for="(currModule, index) in classData.curriculum[0].curriculum"
                  :key="currModule.index"
                  class="flex"
                >
                  <p class="flex-none font-semibold pr-3">Module {{ index + 1 }}:</p>
                  <p class="flex-auto">{{ currModule.name }}</p>
                </div>
              </div>
              <!--  -->
              <div
                v-if="classData.learningList[0] !== '' && classData.inclusion[0] !== ''"
                class="mb-5 p-5 w-full rounded-xl border border-gray-300 bg-white shadow-md"
              >
                <div v-if="classData.learningList[0] !== ''">
                  <p class="text-xl mb-3 font-semibold">What you'll learn</p>
                  <div
                    v-for="learnlist in classData.learningList"
                    :key="learnlist.index"
                    class="flex"
                  >
                    <i class="fal fa-check pr-4"></i>
                    <p>{{ learnlist }}</p>
                  </div>
                </div>
                <div v-if="classData.inclusion[0] !== ''">
                  <p class="text-lg my-1 mt-3 ml-3 font-medium">This class includes:</p>
                  <div class="ml-5">
                    <div
                      v-for="inclusion in classData.inclusion"
                      :key="inclusion.index"
                      class="flex text-sm"
                    >
                      <i class="fal fa-plus text-gray-600 pr-4"></i>
                      <p>{{ inclusion }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <!--  -->
              <div
                v-if="classData.duration"
                class="mb-5 p-5 w-full rounded-xl border border-gray-300 bg-white shadow-md"
              >
                <p class="text-xl mb-3 font-semibold">Duration</p>

                <p>
                  {{ classData.duration }}
                </p>
              </div>
              <!--  -->
              <div
                v-if="classData.requirements[0] !== ''"
                class="mb-5 p-5 w-full rounded-xl border border-gray-300 bg-white shadow-md"
              >
                <p class="text-xl mb-3 font-semibold">Requirements</p>

                <div
                  v-for="requirement in classData.requirements"
                  :key="requirement.index"
                  class="ml-6"
                >
                  <ul class="list-disc">
                    <li>{{ requirement }}</li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div
                v-if="classData.audienceDescription"
                class="mb-5 p-5 w-full rounded-xl border border-gray-300 bg-white shadow-md"
              >
                <p class="text-xl mb-3 font-semibold">Who is this course for?</p>

                <p>
                  {{ classData.audienceDescription }}
                </p>
              </div>
              <!--  -->
              <div
                v-if="classData.venue"
                class="mb-5 p-5 w-full rounded-xl border border-gray-300 bg-white shadow-md"
              >
                <p class="text-xl mb-3 font-semibold">Class Venue</p>

                <p>
                  {{ classData.venue }}
                </p>
              </div>
              <!--  -->
              <div
                class="mb-5 p-5 w-full rounded-xl border border-gray-300 bg-white shadow-md"
              >
                <p class="text-xl mb-2 font-semibold">Tutors</p>

                <div class="center con-avatars">
                  <vs-avatar-group>
                    <vs-avatar
                      size="70"
                      v-for="tutor in classData.tutors"
                      :key="tutor.index"
                    >
                      <vs-tooltip>
                        <img src="../assets/ProfileAvater.jpg" class="img-av" />
                        <template #tooltip> {{ tutor }} </template>
                      </vs-tooltip>
                    </vs-avatar>
                  </vs-avatar-group>
                </div>
              </div>
              <!--  -->
            </div>
          </div>
        </div>
        <!--  -->
      </div>
    </section>
  </div>
</template>

<script>
import axios from "../plugin/axios";
import Nav from "../components/navs/HomeTopNav";

// import { Facebook, Twitter, Linkedin, WhatsApp, Email } from "vue-socialmedia-share";

export default {
  components: {
    Nav,
  },
  metaInfo() {
    return {
      titleTemplate: `${this.classData.name}`,
      meta: [
        { property: "og:title", content: `${this.classData.name}` },
        { property: "og:description", content: `${this.classData.description}` },
        { property: "og:image", content: `${this.classData.classImage}` },
        { property: "og:url", content: `${this.classData.classImage}` },
        { property: "twitter:site", content: `@skill_ng` },
        { property: "twitter:image", content: `${this.classData.classImage}` },
        { property: "og:image:width", content: "1000" },
        { property: "og:site_name", content: "SKillNG" },
      ],
    };
  },
  data() {
    return {
      loading: true,
      classData: {
        price: 0,
        learningList: [],
        inclusion: [],
        requirements: [],
        startClass: { status: false },
        audienceDescription: "",
        duration: "",
        venue: "",
        name: "",
        description: "",
        classImage: "",
      },
    };
  },

  methods: {
    register() {
      let classID = "";
      if (this.$route.params.gclassID) {
        classID = this.$route.params.gclassID;
      } else {
        let route = this.$router.currentRoute.fullPath;
        classID = route.split("/").pop();
      }
      this.$router.push({ name: "GroupClass", params: { gclassID: classID } });
    },
  },
  computed: {
    showHeader() {
      return !this.$store.state.isLoggedIn;
    },
  },

  async beforeCreate() {
    let classID = "";
    if (this.$route.params.gclassID) {
      classID = this.$route.params.gclassID;
    } else {
      let route = this.$router.currentRoute.fullPath;
      classID = route.split("/").pop();
    }

    this.loading = true;
    const loading = this.$vs.loading();

    let url = `gclass/shared/${classID}`;

    await axios
      .get(url)
      .then((response) => {
        let data = response.data.data;
        this.$store.commit("setClassUnit", data);
        this.classData = this.$store.state.classUnit;
        this.loading = false;
        loading.close();
      })
      .catch(() => {});
  },
};
</script>

<style></style>
