<template>
  <div class="overflow-y-auto mb-24">
    <div class="px-6 pt-4 pb-3 border-b border-slighter mb-5 flex items-center bg-white">
      <button @click="$router.go(-1)" class="block md:hidden">
        <i
          class="fal fa-long-arrow-left text-skilldeepyelow text-center text-xl pr-5"
        ></i>
      </button>
      <h1 class="text-base font-medium text-skillblack">Profile</h1>
    </div>

    <!--  -->
    <div class="p-2 xl:pl-8 pl-5 pr-2 w-full">
      <div class="flex items-center mb-8">
        <div class="relative rounded-lg overflow-hidden border border-gray-500 shadow-md">
          <div class="w-full h-full absolute z-10"></div>
          <div class="flex items-center justify-center">
            <img
              v-if="profileImage"
              :src="profileImage"
              class="w-40 h-40 object-cover z-0 bg-gray-200"
            />
            <img
              v-else-if="!profileImage && authProfile.profilePicUrl"
              :src="
                'https://res.cloudinary.com/skillng/image/fetch/' +
                authProfile.profilePicUrl
              "
              class="w-40 h-40 object-cover z-0 bg-gray-200"
            />

            <img
              v-else
              src="../../assets/ProfileAvater.jpg"
              class="w-40 h-40 object-cover z-0 bg-gray-200"
            />

            <div
              @click="profileImageModal = true"
              class="cursor-pointer absolute text-4xl text-white z-20 hover:opacity-70"
            >
              <i class="fas fa-camera"></i>
            </div>
          </div>
        </div>
        <div class="mx-4">
          <p class="text-md flex-wrap text-left text-gray-800">
            Tap the camera icon to change your profile picture
          </p>
        </div>
      </div>
      <!--  -->
      <div class="pb-14">
        <form @submit.prevent="save" class="mb-5">
          <label class="text-sm text-gray-800 pl-1">First Name</label>
          <input
            required
            class="mb-5 shadow-md w-full p-3 rounded-lg border border-gray-100 text-base font-bold focus:outline-none"
            type="text"
            v-model="authProfile.firstName"
          />
          <label class="text-sm text-gray-800 pl-1">Last Name</label>
          <input
            required
            class="mb-5 shadow-md w-full p-3 rounded-lg border border-gray-100 text-base font-bold focus:outline-none"
            type="text"
            v-model="authProfile.lastName"
          />
          <label class="text-sm text-gray-800 pl-1">Email</label>
          <input
            required
            disabled
            class="mb-5 shadow-md w-full p-3 rounded-lg border border-gray-100 text-base font-bold focus:outline-none"
            type="text"
            v-model="authProfile.email"
          />
          <label class="text-sm text-gray-800 pl-1"
            >Phone Number (include country code without "+" )</label
          >
          <input
            required
            class="mb-5 shadow-md w-full p-3 rounded-lg border border-gray-100 text-base font-bold focus:outline-none"
            type="text"
            v-model="authProfile.mobileNo"
          />
          <p v-if="!validNumber" class="text-red-500 text-sm mb-2 -mt-4 pl-2">
            Invalid Phone Number
          </p>
          <label class="text-sm text-gray-800 pl-1">Location</label>
          <input
            class="mb-5 shadow-md w-full p-3 rounded-lg border border-gray-100 text-base font-bold focus:outline-none"
            type="text"
            v-model.lazy="authProfile.location"
          />

          <label class="text-sm text-gray-800 pl-1">Gender</label>
          <!--  -->
          <div class="relative">
            <select
              v-model="authProfile.gender"
              class="mb-5 shadow-md w-full p-3 rounded-lg border border-gray-100 text-base font-bold focus:outline-none"
            >
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Prefer not to say">Prefer not to say</option>
            </select>
          </div>
          <!--  -->

          <label class="text-sm text-gray-800 pl-1">Occupation</label>
          <input
            class="mb-5 shadow-md w-full p-3 rounded-lg border border-gray-100 text-base font-bold focus:outline-none"
            type="text"
            v-model.lazy="authProfile.occupation"
          />

          <label class="text-sm text-gray-800 pl-1">Job Status</label>
          <input
            class="mb-5 shadow-md w-full p-3 rounded-lg border border-gray-100 text-base font-bold focus:outline-none"
            type="text"
            v-model.lazy="authProfile.jobStatus"
          />
          <div class="flex justify-center">
            <vs-button
              flat
              :disabled="!authProfile.firstName"
              active
              :loading="loading"
              @click="save"
              class="w-full shadow-md"
              type="button"
            >
              <p class="text-skillblack py-0.5 text-base">Save</p>
            </vs-button>
          </div>
        </form>
      </div>
    </div>
    <vs-dialog v-model="profileImageModal">
      <template>
        <div>
          <picture-input
            ref="pictureInput"
            @change="onChange"
            width="600"
            height="600"
            margin="16"
            accept="image/jpeg,image/png"
            size="10"
            buttonClass="bg-skillyelow text-black font-semibold rounded-xl p-3 px-5"
          >
          </picture-input>
        </div>
      </template>
      <template #footer>
        <div class="footer-dialog">
          <vs-button
            @click="profileImageModal = false"
            block
            class="mb-2 mt-1 disabled"
            style="color: black"
          >
            <p class="font-semibold">Done</p>
          </vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import PictureInput from "vue-picture-input";
import axios1 from "axios";
import axios from "../../plugin/axios";
import { mapState } from "vuex";
export default {
  components: { PictureInput },
  data() {
    return {
      error: "",
      interval: null,
      loading: false,
      profileImageModal: false,
      profileImage: "",
      // authProfile: { mobileNo: 234 },
    };
  },
  methods: {
    save() {
      this.loading = true;
      if (this.profileImage.includes("data:image")) {
        const url = "https://api.cloudinary.com/v1_1/skillng/image/upload";

        let formData = new FormData();
        formData.append("api_key", "249958796989371");
        formData.append("file", this.profileImage);
        formData.append("public_id", this.$store.state.authProfile._id);
        formData.append("upload_preset", "skillng");

        axios1
          .post(url, formData)
          .then((response) => {
            this.profileImage = response.data.secure_url;
            this.authProfile.profilePicUrl = response.data.secure_url;
            // console.log(this.profileImage);
            this.updateProfile();
          })
          .catch(() => {
            this.error =
              "There was an error updating your prifile image please try again later";
            this.errorAlart();
            this.loading = false;
          });
      } else this.updateProfile();
    },
    async updateProfile() {
      if (!this.validNumber || !this.authProfile.mobileNo.startsWith("234")) {
        this.error = "Phone Number must be 13 digits and start with 234";
        this.errorAlart();
        this.loading = false;

        return;
      }

      let token = this.$store.state.authUser.token;
      this.authProfile.userid = this.$store.state.authProfile._id;
      this.authProfile.fullName =
        this.authProfile.firstName + " " + this.authProfile.lastName;
      let url = "user/update/";
      await axios
        .post(url, this.authProfile, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          // console.log(response);
          this.$store.commit("setAuthProfile", response.data.data);

          this.sucessAlart();
          this.loading = false;
        })
        .catch((error) => {
          this.error = error.response.data.message;
          this.errorAlart();
        });
      this.loading = false;
    },
    sucessAlart() {
      this.$swal.fire({
        position: "top-end",
        icon: "success",
        title: "Profile saved sucessfully",
        showConfirmButton: false,
        timer: 3000,
        toast: true,
      });
    },
    errorAlart() {
      this.$swal.fire({
        position: "top-end",
        icon: "error",
        title: this.error,
        showConfirmButton: false,
        timer: 6000,
        toast: true,
      });
    },
    onChange(image) {
      if (image) {
        this.profileImage = image;
      } else {
        console.log("Error Uploding image");
      }
    },

    async beforeMountMethod() {
      if (this.authProfile.firstName == "") {
        let newName = this.authProfile.fullName.split(/[ ,]+/);
        this.authProfile.this.authProfile.fullName = newName[0];

        if (newName.length == 1) {
          this.authProfile.lastName = newName[1];
        } else if (newName.length > 1) {
          for (let i = 1; i < newName.length; i++) {
            this.authProfile.lastName = this.authProfile.lastName + " " + newName[i];
          }
        }
      }
    },
  },

  computed: {
    ...mapState(["authProfile"]),
    validNumber() {
      return this.authProfile.mobileNo.length > 4;
    },
  },

  beforeMount() {
    this.interval = setInterval(() => {
      if (this.$store.state.authProfile.email != undefined) {
        this.beforeMountMethod();
        clearInterval(this.interval);
      }
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style></style>
