<template>
  <section class="flex">
    <div class="lg:w-5/12 md:w-2/5 w-full h-full z-10 overflow-y-auto">
      <div
        class="w-full border border-slighter rounded-t-2xl shadow-md h-almost overflow-y-auto lg:pb-10 pb-16"
      >
        <div
          class="px-6 py-5 flex items-center justify-between bg-white sticky top-0 z-10"
        >
          <h1 class="text-xl font-medium text-skillblack">Messages</h1>
        </div>

        <!-- skeleton  loader -->
        <div v-if="skeletonLoader">
          <div
            v-for="skeleton in 3"
            :key="skeleton.index"
            class="p-5 flex border-b border-slighteste hover:bg-slightyellow w-full pr-8"
          >
            <div
              class="w-10 h-10 mr-4 rounded-lg bg-gray-100 animate-pulse skeleton-box"
            ></div>
            <div>
              <div
                class="animate-pulse skeleton-box h-6 w-80 rounded-md bg-gray-100 mb-2"
              ></div>
              <div
                class="animate-pulse skeleton-box h-2 w-80 rounded-md bg-gray-100"
              ></div>
            </div>
          </div>
        </div>
        <!-- Empty chat -->
        <div v-if="emptyChat">
          <div class="my-5 p-2 text-center">
            <i class="fad fa-exclamation-circle text-yellow-400 text-4xl mb-2"></i>
            <p>No chat found</p>
          </div>
        </div>

        <!--  -->

        <div v-if="!skeletonLoader" class="flex flex-col-reverse">
          <div
            v-for="(user, index) in users"
            :key="user.index"
            @click="loadChat(user.chatId, index, user.key)"
            class="pl-4 pr-2 py-4 flex items-center justify-between border-b border-slightest focus:outline-none hover:bg-slightyellow w-full"
          >
            <button class="flex items-center focus:outline-none">
              <div class="relative">
                <img
                  v-if="user.chatImage"
                  :src="
                    'https://res.cloudinary.com/skillng/image/fetch/' + user.chatImage
                  "
                  class="w-10 h-10 mr-4 rounded-lg object-cover bg-gray-200"
                />
                <div
                  v-if="user.unreadMessage"
                  class="absolute top-1/2 -mt-2 right-2 bg-red-500 rounded-full h-3.5 w-3.5 border-2 border-white"
                ></div>
              </div>

              <div class="mx-1">
                <p class="text-md text-left font-medium text-gray-800">
                  <span v-if="user.chatName.endsWith(AuthName)">
                    {{ user.chatName.slice(0, -AuthNameLength) }}
                  </span>
                  <span v-else>
                    {{ user.chatName }}
                  </span>
                </p>
                <p class="text-md text-left text-gray-800 text-sm">
                  <span v-if="!user.lastMessage">Image</span>
                  <span v-if="user.lastMessage">
                    {{
                      user.lastMessage.length > 40
                        ? user.lastMessage.substring(0, 40) + " ..."
                        : user.lastMessage
                    }}
                  </span>
                </p>
              </div>
            </button>

            <p class="text-xs font-normal text-right text-skillblack mx-1">
              {{ user.lastMessageTimeStamp | moment("from", "now") }}
            </p>
          </div>
        </div>

        <!--  -->
      </div>
    </div>

    <!-- side bar (right)-->
    <section class="md:block hidden lg:w-7/12 md:w-3/5 relative">
      <div v-chat-scroll ref="chatScrool" class="h-almost w-full overflow-y-auto">
        <div
          class="px-6 py-5 border-b border-clighter bg-white sticky top-0 z-30 cursor-pointer"
          @click="openChatDetails = !openChatDetails"
        >
          <div v-if="chatId !== ''" class="flex items-center justify-between">
            <div class="flex justify-start items-center">
              <img
                v-if="users[chatIndex].chatImage"
                :src="
                  'https://res.cloudinary.com/skillng/image/fetch/' +
                  users[chatIndex].chatImage
                "
                alt="Avarter"
                class="w-10 h-10 mr-4 rounded-lg object-cover bg-gray-200"
              />
              <div class="font-semibold">
                <p class="text-md text-left text-gray-800">
                  <span v-if="users[chatIndex].chatName.endsWith(AuthName)">
                    {{ users[chatIndex].chatName.slice(0, -AuthNameLength) }}
                  </span>
                  <span v-else> {{ users[chatIndex].chatName }} </span>
                </p>
              </div>
            </div>

            <i v-if="!openChatDetails" class="far fa-ellipsis-v px-4"></i>
            <i v-if="openChatDetails" class="fal fa-times px-4"></i>
          </div>
        </div>
        <!-- openChatDetails Desktop-->
        <div
          v-if="openChatDetails"
          class="bg-white z-10 inset-0 absolute w-full h-almost overflow-y-auto"
        >
          <div class="h-2/2 w-full top-0 left-0 bg-white z-50 absolute mt-20 p-6 pb-24">
            <div class="flex justify-around my-4">
              <img
                v-if="users[chatIndex].chatImage"
                :src="
                  'https://res.cloudinary.com/skillng/image/fetch/' +
                  users[chatIndex].chatImage
                "
                alt="Image"
                class="w-60 h-60 rounded-lg object-cover bg-gray-200"
              />
            </div>
            <div class="my-4">
              <p class="text-sm font-semibold text-yellow-600 py-2 pt-4">Description</p>
              <p class="text-sm">
                {{ users[chatIndex].chatDescription }}
              </p>
            </div>
            <div class="my-4">
              <p class="text-sm font-semibold text-yellow-600 py-2 pt-4">
                {{ chatUserList.length }} Participants
              </p>
              <div
                v-for="list in chatUserList"
                :key="list.index"
                class="flex justify-start items-center py-2 border-b"
              >
                <img
                  v-if="list.userProfilePic"
                  :src="
                    'https://res.cloudinary.com/skillng/image/fetch/' +
                    list.userProfilePic
                  "
                  class="w-10 h-10 mr-4 rounded-lg object-cover bg-gray-200"
                />
                <img
                  v-else
                  src="../assets/ProfileAvater.jpg"
                  class="w-10 h-10 mr-4 rounded-lg object-cover bg-gray-200"
                />
                <div class="">
                  <p class="text-gray-800 font-semibold">{{ list.userName }}</p>
                  <!-- <span class="text-gray-800">Tel: +{{ list.userMobileNo }} </span> -->
                  <span class="text-gray-800">Email: {{ list.userEmail }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Spin Loader -->
        <div
          v-if="spinLoader"
          class="flex justify-center items-center mt-24 text-yellow-600 text-4xl"
        >
          <i class="animate-spin fad fa-circle-notch"></i>
        </div>
        <!--  -->
        <div class="mb-0 lg:mb-24 md:mb-36" v-if="!spinLoader">
          <div
            v-for="chat in chats"
            :key="chat.index"
            class="p-2 px-6 grid"
            :class="
              chat.userId === $store.state.authUser._id
                ? 'justify-items-end'
                : 'justify-items-start'
            "
          >
            <div
              class="px-auto max-w-md p-2 py-3 rounded-lg"
              :class="
                chat.userId === $store.state.authUser._id
                  ? ' bg-skilldeepyelow'
                  : 'bg-gray-900'
              "
            >
              <p
                v-if="chat.userId !== $store.state.authUser._id"
                class="px-2 font-semibold text-sm"
                :class="
                  chat.userId === $store.state.authUser._id
                    ? ' text-blabg-black'
                    : 'text-skillwhite'
                "
              >
                {{ chat.userName }}
              </p>
              <p
                class="px-2 text-base flex-wrap font-normal"
                :class="
                  chat.userId === $store.state.authUser._id
                    ? ' text-blabg-black'
                    : 'text-skillwhite'
                "
              >
                <img
                  v-if="chat.imageUrl"
                  class="inset-0 h-full w-full object-cover pb-1"
                  :src="'https://res.cloudinary.com/skillng/image/fetch/' + chat.imageUrl"
                  alt="image"
                />
                <span
                  class="chatlink"
                  target="_blank"
                  v-html="markedText(chat.message)"
                ></span>
              </p>
              <p
                class="text-xs text-right font-normal px-2"
                :class="
                  chat.userId === $store.state.authUser._id
                    ? ' text-blabg-black'
                    : 'text-skillyelow'
                "
              >
                {{
                  chat.createdAt
                    | moment("calendar", null, {
                      sameElse: "ddd, MMM D YYYY, h:mm A",
                    })
                }}
              </p>
            </div>
          </div>
        </div>
        <!-- chatImageModal -->
        <div v-if="chatImageModal" class="bg-white z-10 inset-0 absolute w-full h-almost">
          <div
            class="h-2/2 w-full top-0 left-0 bg-white z-20 absolute mt-20 overflow-y-auto"
          >
            <div class="bg-yellow-500 flex justify-between">
              <p class="tex-sm text-white font-semibold pl-10 p-1">Preview</p>
              <button
                class="flex justify-center items-center rounded-full pr-10 p-2"
                @click="closeChatImageModal"
              >
                <i class="text-white fal fa-times"></i>
              </button>
            </div>

            <picture-input
              ref="pictureInput"
              @change="onChange"
              width="600"
              height="600"
              margin="16"
              size="20"
              accept="image/jpeg,image/png"
              :hideChangeButton="true"
            >
            </picture-input>
          </div>
        </div>

        <!--  -->
        <button
          v-if="srollVisible"
          class="flex justify-center items-center rounded-full bg-mustardyellow bg-opacity-25 hover:bg-opacity-100 h-12 w-12 shadow-md absolute right-8 bottom-0 lg:bottom-28 md:bottom-40 z-10"
          @click="ChatScroolDown"
        >
          <i class="text-gray-600 fas fa-chevron-down"></i>
        </button>

        <div
          class="border-t-2 border-slighter px-4 p-4 bg-white w-full absolute lg:bottom-0 md:bottom-14 z-30"
        >
          <div class="flex space-x-3">
            <button
              :disabled="chatId === ''"
              @click="chatImageModal = true"
              type="button"
              class="rounded-full h-10 w-10 text-gray-500 hover:bg-mustardyellow"
            >
              <i class="far fa-paperclip text-xl"></i>
            </button>
            <input
              :disabled="chatId === ''"
              v-model="newChat"
              @keydown.enter="addChats"
              ref="newChatRef"
              type="text"
              placeholder="Type a message"
              class="w-full px-4 bg-mustardyellow rounded-full py-3"
            />
            <button
              :disabled="chatId === '' || (newChat === '' && chatImage === '')"
              @click="addChats"
              type="button"
              class="inline-flex justify-center items-center rounded-full h-12 w-14 text-white bg-deepblue hover:text-skillyelow"
            >
              <svg
                viewBox="0 0 20 20"
                fill="currentColor"
                class="h-6 w-6 transform rotate-90"
              >
                <path
                  d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </section>

    <!-- Mobile dailog -->
    <section
      v-if="MobileDialog"
      class="md:hidden block h-full w-full top-0 left-0 bg-white z-50 fixed"
    >
      <div ref="chatScrool" v-chat-scroll class="h-almost overflow-y-auto">
        <div
          class="px-6 py-5 border-b border-clighter bg-white sticky top-0 z-30 cursor-pointer"
          @click="openChatDetails = !openChatDetails"
        >
          <div v-if="chatId !== ''" class="flex items-center justify-between">
            <div class="flex justify-start items-center">
              <button @click="MobileDialog = false" class="z-50">
                <i
                  class="fal fa-long-arrow-left text-skilldeepyelow text-center text-xl pr-5"
                ></i>
              </button>
              <img
                v-if="users[chatIndex].chatImage"
                :src="
                  'https://res.cloudinary.com/skillng/image/fetch/' +
                  users[chatIndex].chatImage
                "
                alt="Avarter"
                class="w-10 h-10 mr-4 rounded-lg object-cover bg-gray-200"
              />
              <div class="font-semibold">
                <p class="text-md text-left text-gray-800">
                  <span v-if="users[chatIndex].chatName.endsWith(AuthName)">
                    {{ users[chatIndex].chatName.slice(0, -AuthNameLength) }}
                  </span>
                  <span v-else>
                    {{ users[chatIndex].chatName }}
                  </span>
                </p>
              </div>
            </div>
            <i v-if="!openChatDetails" class="far fa-ellipsis-v px-4"></i>
            <i v-if="openChatDetails" class="fal fa-times px-4"></i>
          </div>
        </div>

        <!-- openChatDetails Desktop-->
        <div
          v-if="openChatDetails"
          class="bg-white z-10 inset-0 absolute w-full h-almost overflow-y-auto"
        >
          <div class="h-2/2 w-full top-0 left-0 bg-white z-50 absolute mt-20 p-6 pb-24">
            <div class="flex justify-around my-4">
              <img
                v-if="users[chatIndex].chatImage"
                :src="
                  'https://res.cloudinary.com/skillng/image/fetch/' +
                  users[chatIndex].chatImage
                "
                alt="Image"
                class="w-60 h-60 rounded-lg object-cover bg-gray-200"
              />
            </div>
            <div class="my-4">
              <p class="text-sm font-semibold text-yellow-600 py-2 pt-4">Description</p>
              <p class="text-sm">
                {{ users[chatIndex].chatDescription }}
              </p>
            </div>
            <div class="my-4">
              <p class="text-sm font-semibold text-yellow-600 py-2 pt-4">
                {{ chatUserList.length }} Participants
              </p>
              <div
                v-for="list in chatUserList"
                :key="list.index"
                class="flex justify-start items-center py-2 border-b"
              >
                <img
                  v-if="list.userProfilePic"
                  :src="
                    'https://res.cloudinary.com/skillng/image/fetch/' +
                    list.userProfilePic
                  "
                  class="w-10 h-10 mr-4 rounded-lg object-cover bg-gray-200"
                />
                <img
                  v-else
                  src="../assets/ProfileAvater.jpg"
                  class="w-10 h-10 mr-4 rounded-lg object-cover bg-gray-200"
                />
                <div class="">
                  <p class="text-gray-800 font-semibold">{{ list.userName }}</p>
                  <!-- <span class="text-gray-800">Tel: +{{ list.userMobileNo }} </span> -->
                  <span class="text-gray-800">Email: {{ list.userEmail }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Spin Loader -->
        <div
          v-if="spinLoader"
          class="flex justify-center items-center mt-24 text-yellow-600 text-4xl"
        >
          <i class="animate-spin fad fa-circle-notch"></i>
        </div>
        <!--  -->
        <div class="mb-20" v-if="!spinLoader">
          <div
            v-for="chat in chats"
            :key="chat.index"
            class="p-2 px-6 grid"
            :class="
              chat.userId === $store.state.authUser._id
                ? 'justify-items-end'
                : 'justify-items-start'
            "
          >
            <div
              class="px-auto max-w-md p-2 py-3 rounded-lg"
              :class="
                chat.userId === $store.state.authUser._id
                  ? ' bg-skilldeepyelow'
                  : 'bg-gray-900'
              "
            >
              <p
                v-if="chat.userId !== $store.state.authUser._id"
                class="px-2 font-semibold text-sm"
                :class="
                  chat.userId === $store.state.authUser._id
                    ? ' text-blabg-black'
                    : 'text-skillwhite'
                "
              >
                {{ chat.userName }}
              </p>
              <p
                class="px-2 text-base flex-wrap font-normal"
                :class="
                  chat.userId === $store.state.authUser._id
                    ? ' text-blabg-black'
                    : 'text-skillwhite'
                "
              >
                <img
                  v-if="chat.imageUrl"
                  class="inset-0 h-full w-full object-cover pb-1"
                  :src="'https://res.cloudinary.com/skillng/image/fetch/' + chat.imageUrl"
                  alt="image"
                />

                {{ chat.message }}
              </p>
              <p
                class="text-xs text-right font-normal px-2"
                :class="
                  chat.userId === $store.state.authUser._id
                    ? ' text-blabg-black'
                    : 'text-skillyelow'
                "
              >
                {{
                  chat.createdAt
                    | moment("calendar", null, {
                      sameElse: "ddd, MMM D YYYY, h:mm A",
                    })
                }}
              </p>
            </div>
          </div>
        </div>
        <!-- chatImageModal -->
        <div v-if="chatImageModal" class="bg-white z-20 inset-0 absolute w-full h-almost">
          <div
            class="h-almost w-full top-0 left-0 bg-white z-10 fixed mt-24 pb-24 overflow-y-auto"
          >
            <div class="bg-yellow-500 flex justify-between">
              <p class="tex-sm text-white font-semibold pl-10 p-1">Preview</p>
              <button
                class="flex justify-center items-center rounded-full pr-10 p-2"
                @click="closeChatImageModal"
              >
                <i class="text-white fal fa-times"></i>
              </button>
            </div>

            <picture-input
              ref="pictureInput"
              @change="onChange"
              width="600"
              height="800"
              margin="16"
              size="20"
              accept="image/jpeg,image/png"
              :hideChangeButton="true"
            >
            </picture-input>
          </div>
        </div>
      </div>
      <!--  -->
      <button
        v-if="srollVisible"
        class="flex justify-center items-center rounded-full bg-mustardyellow bg-opacity-25 hover:bg-opacity-100 h-12 w-12 shadow-md fixed right-8 bottom-24 z-10"
        @click="ChatScroolDown"
      >
        <i class="text-gray-600 fas fa-chevron-down"></i>
      </button>
      <!--  -->
      <div
        class="border-t-2 border-slighter px-4 p-4 bg-white w-full z-50 absolute bottom-0"
      >
        <div class="flex space-x-3">
          <button
            :disabled="chatId === ''"
            @click="chatImageModal = true"
            type="button"
            class="rounded-full h-10 w-12 text-gray-500"
          >
            <i class="far fa-paperclip text-xl"></i>
          </button>
          <input
            :disabled="chatId === ''"
            :value="newChat"
            @input="newChat = $event.target.value"
            @keydown.enter="addChats"
            ref="newChatRef"
            type="text"
            placeholder="Type a message"
            class="w-full px-4 bg-mustardyellow rounded-full py-3"
          />
          <button
            :disabled="chatId === '' || (newChat === '' && chatImage === '')"
            @click="addChats"
            type="button"
            class="inline-flex justify-center items-center rounded-full h-12 w-16 text-white bg-deepblue hover:text-skillyelow"
          >
            <svg
              viewBox="0 0 20 20"
              fill="currentColor"
              class="h-6 w-6 transform rotate-90"
            >
              <path
                d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"
              ></path>
            </svg>
          </button>
        </div>
      </div>
      <!--  -->
    </section>
    <!--  -->
  </section>
</template>

<script>
import { fireDB, fireBase } from "../ultilities/firebase";
import axios from "axios";
import PictureInput from "vue-picture-input";
import marked from "marked";

export default {
  components: { PictureInput },
  data() {
    return {
      skeletonLoader: true,
      spinLoader: false,
      chatImageModal: false,
      srollVisible: false,
      MobileDialog: false,
      emptyChat: false,
      openChatDetails: false,
      interval: null,
      newChat: "",
      chatId: "",
      chatKey: "",
      chatIndex: "",
      chatImage: "",
      chats: [],
      users: [],
      chatUserList: [],
    };
  },

  methods: {
    markedText(msg) {
      return marked(msg);
    },
    closeChatImageModal() {
      this.chatImageModal = false;
      this.chatImage = "";
    },
    ChatScroolDown() {
      this.$nextTick(() => {
        let scrool = this.$refs.chatScrool;
        scrool.scrollTop = scrool.scrollHeight;
      });
    },
    async addChats() {
      if (this.chatId !== "" || (this.newChat !== "" && this.chatImage !== "")) {
        this.chatImageModal = false;
        if (this.chatImage.includes("data:image")) {
          const url = "https://api.cloudinary.com/v1_1/skillng/image/upload";

          let formData = new FormData();
          formData.append("api_key", "249958796989371");
          formData.append("file", this.chatImage);
          formData.append("upload_preset", "skillng");

          await axios
            .post(url, formData)
            .then((response) => {
              this.chatImage = response.data.secure_url;
              this.addChatstoDB();

              return;
            })
            .catch(() => {});
        } else this.addChatstoDB();
      }
    },
    async addChatstoDB() {
      if (this.newChat !== "" || this.chatImage !== "") {
        let userId = `${this.$store.state.authUser.id}`;
        let chatId = this.chatId;
        let userName = `${this.$store.state.authUser.fullName}`;

        let newChat = fireDB.ref("chats/" + chatId).push();
        newChat.set({
          userId: userId,
          userName: userName,
          message: this.newChat,
          imageUrl: this.chatImage,
          createdAt: fireBase.database.ServerValue.TIMESTAMP,
        });

        //last chat message for all users
        let userList = this.chatUserList;
        for (let i = 0; i < userList.length; i++) {
          let lastChat = fireDB.ref(
            "users/" + userList[i].userId + "/" + userList[i].chatKey
          );
          lastChat.update({
            lastMessage: this.newChat,
            lastMessageTimeStamp: fireBase.database.ServerValue.TIMESTAMP,
            unreadMessage: true,
          });
        }
        // set last chat message unread to false
        let unreadFalse = fireDB.ref("users/" + userId + "/" + this.chatKey);
        unreadFalse.update({
          unreadMessage: false,
        });
        //

        this.newChat = "";
        this.chatImage = "";
        this.$refs.newChatRef.focus();
      }
    },
    async loadChat(chatId, index, key) {
      this.openChatDetails = false;
      if (window.innerWidth <= 768) {
        this.MobileDialog = true;
      } else {
        setTimeout(() => {
          this.$refs.newChatRef.focus();
        }, 200);
      }

      if (this.chatId !== chatId) {
        this.chats = [];

        this.$nextTick(() => {
          let scrool = this.$refs.chatScrool;
          this.interval = setInterval(() => {
            if (scrool.scrollHeight - scrool.scrollTop == scrool.clientHeight) {
              this.srollVisible = false;
            } else this.srollVisible = true;
          }, 1500);
        });

        this.spinLoader = true;

        this.chatIndex = index;
        this.chatId = chatId;
        this.chatKey = key;
        let chatref = fireDB.ref("chats/" + chatId);
        chatref.on("child_added", (snapshot) => {
          this.chats.push({ key: snapshot.key, ...snapshot.val() });
          this.spinLoader = false;
        });

        //
        this.chatUserList = [];
        let chatUserListRef = fireDB.ref("chatUserList/" + chatId);
        chatUserListRef.on("child_added", (snapshot) => {
          this.chatUserList.push({ key: snapshot.key, ...snapshot.val() });
        });

        // set last chat message unread to false
        let userId = `${this.$store.state.authUser.id}`;
        let unreadFalse = fireDB.ref("users/" + userId + "/" + this.chatKey);
        unreadFalse.update({
          unreadMessage: false,
        });
        //
      }
    },
    onChange(image) {
      if (image) {
        this.chatImage = image;
      } else {
        console.log("Error Uploding image");
      }
    },
    async loadChatUser() {
      let userId = `${this.$store.state.authUser.id}`;
      let userRef = await fireDB
        .ref("users/" + userId)
        .orderByChild("lastMessageTimeStamp");
      userRef.on("value", (snapshot) => {
        let chatUsers = [];
        snapshot.forEach(function (childSnapshot) {
          var childData = childSnapshot.val();
          childData.key = childSnapshot.key;
          chatUsers.push(childData);
        });
        this.users = chatUsers;
        this.skeletonLoader = false;
        if (this.users.length === 0) {
          this.emptyChat = true;
        }
      });
    },
  },

  computed: {
    authID() {
      return this.$store.state.authUser.id;
    },
    AuthName() {
      return this.$store.state.authUser.fullName;
    },
    AuthNameLength() {
      return this.$store.state.authUser.fullName.length + 6;
    },
  },

  async mounted() {
    await this.loadChatUser();
    // this.groupClassCount();
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style>
/* unvisited link */
.chatlink a:link {
  color: red;
}

/* visited link */
.chatlink a:visited {
  color: lightgreen;
}

/* mouse over link */
.chatlink a:hover {
  color: hotpink;
}

/* selected link */
.chatlink a:active {
  color: blue;
}
</style>
