<template>
  <section
    class="bg-skilldeepyelow w-full h-full flex justify-center items-center overflow-y-auto"
  >
    <div class="xl:w-4/12 lg:w-6/12 w-full md:px-16 lg:px-0 px-6 lg:py-16 py-6 relative">
      <div class="mt-5">
        <button>
          <router-link to="/">
            <img src="../../../src/assets/logo.png" alt="Logo" class="h-10 w-auto" />
          </router-link>
        </button>
      </div>
      <!--  -->
      <vs-alert closable v-model="error" color="danger" class="mt-4">
        <p class="font-semibold text-center">{{ error }}</p>
        <div v-if="invalidReferral">
          <p class="font-semibold text-center">Would you like to continue?</p>
          <div class="flex justify-center mt-2">
            <vs-button
              @click="invalidReferralTrue"
              flat
              active
              color="#FFF212"
              class="w-full shadow-md"
              type="button"
            >
              <p class="text-skillblack text-sm">Yes</p>
            </vs-button>

            <vs-button
              @click="invalidReferralFalse"
              flat
              active
              color="#FFF212"
              class="w-full shadow-md"
              type="button"
            >
              <p class="text-skillblack text-sm">No</p>
            </vs-button>
          </div>
        </div>
      </vs-alert>
      <!--  -->
      <div class="flex">
        <div class="mt-5 -mb-2.5 border-b-2 border-green-400 rounded-full w-1/2"></div>
        <div
          class="mt-5 -mb-2.5 border-b-2 rounded-full w-1/2"
          :class="formTab === 2 ? 'border-green-400 ' : 'border-white '"
        ></div>
      </div>
      <div class="flex justify-between">
        <div class="h-4 w-4 bg-green-400 rounded-full"></div>
        <div
          class="h-4 w-4 rounded-full"
          :class="formTab === 2 ? 'bg-green-400 ' : 'bg-white '"
        ></div>
      </div>
      <div class="flex justify-around">
        <div
          class="text-xs font-medium text-center cursor-pointer w-1/2 flex items-center justify-center"
          @click="formTab = 1"
        >
          <i class="far fa-chevron-left"></i>
          <p class="pl-4">Personal Info</p>
        </div>
        <div
          class="text-xs font-medium text-center cursor-pointer w-1/2 flex items-center justify-center"
          @click="formTab = 2"
        >
          <p class="pr-4">Login Info</p>
          <i class="far fa-chevron-right"></i>
        </div>
      </div>

      <!-- FormTab 1 -->
      <div v-if="formTab === 1">
        <div class="mt-8 center content-inputs">
          <vs-input
            @keydown.enter="focusLastName"
            block
            border
            dark
            ref="firstName"
            type="text"
            v-model="form.firstName"
            label-placeholder="First Name"
          >
            <template
              v-if="form.firstName.length < 3 && form.firstName !== ''"
              #message-danger
            >
              First Name must be more than 3 letters
            </template>
          </vs-input>
        </div>

        <!--  -->
        <div class="mt-8 center content-inputs">
          <vs-input
            @keydown.enter="focusNumber"
            block
            border
            dark
            ref="lastName"
            type="text"
            v-model="form.lastName"
            label-placeholder="Last Name"
          >
            <template
              v-if="form.lastName.length < 3 && form.lastName !== ''"
              #message-danger
            >
              Last Name must be more than 3 letters
            </template>
          </vs-input>
        </div>
        <!--  -->

        <div class="mt-8 mb-12 center content-inputs flex space-x-1 z-50">
          <vue-country-code
            @onSelect="onSelectCountry"
            :preferredCountries="['ng']"
            enableSearchField
          >
          </vue-country-code>
          <vs-input
            @keydown.enter="focusReferral"
            block
            border
            dark
            ref="number"
            type="number"
            v-model="form.mobileNo"
            label-placeholder="Phone Number"
          >
            <template #icon>
              <p>+{{ countryCode }}</p>
            </template>
          </vs-input>
        </div>
        <!--  -->
        <p
          v-if="!referral"
          @click="referral = !referral"
          class="font-semibold text-center cursor-pointer"
        >
          Got an invite code?
        </p>
        <!--  -->
        <div v-if="referral" class="mt-8 bg-yellow-400 p-5 rounded-md">
          <p>Enter Referral Phone Number with Country Code (Optional)</p>
          <div class="center content-inputs">
            <vs-input
              block
              border
              dark
              ref="referral"
              type="number"
              v-model="form.referralCode"
              placeholder="Referral Code (with country code)"
            >
            </vs-input>
          </div>
        </div>

        <!--  -->
        <div class="flex justify-center mt-8">
          <vs-button
            flat
            active
            color="#FFF212"
            @click="next"
            class="w-full shadow-md"
            type="button"
          >
            <p class="text-skillblack py-0.5 text-base">Next</p>
          </vs-button>
        </div>
      </div>
      <!-- formTab 2 -->
      <div v-if="formTab === 2">
        <div class="mt-8 center content-inputs">
          <vs-input
            @keydown.enter="focusPassword"
            block
            border
            dark
            ref="email"
            type="email"
            v-model="form.email"
            label-placeholder="Email"
          >
            <template v-if="validEmail" #message-success> Email Valid </template>
            <template v-if="!validEmail && form.email !== ''" #message-danger>
              Email Invalid
            </template>
          </vs-input>
        </div>

        <!--  -->
        <div class="mt-8 center content-inputs">
          <vs-input
            @keydown.enter="submit"
            dark
            block
            border
            ref="password"
            type="password"
            icon-after
            v-model="form.password"
            label-placeholder="Password"
            :progress="getProgress"
            :visiblePassword="hasVisiblePassword"
            @click-icon="hasVisiblePassword = !hasVisiblePassword"
          >
            <template #icon>
              <i v-if="!hasVisiblePassword" class="fas fa-eye-slash"></i>
              <i v-else class="fas fa-eye"></i>
            </template>
            <template v-if="form.password && getProgress >= 40" #message-success>
              Strong Password
            </template>
            <template v-if="form.password && getProgress < 40" #message-danger>
              Weak Password
            </template>
          </vs-input>
        </div>

        <!--  -->

        <div class="flex justify-center mt-8">
          <vs-button
            flat
            active
            :loading="loading"
            color="#FFF212"
            @click="submit"
            class="w-full shadow-md"
            type="button"
            :disabled="
              validEmail == true && getProgress >= 40 && validateForm == true
                ? false
                : true
            "
          >
            <p class="text-skillblack py-0.5 text-base">Sign Up</p>
          </vs-button>
        </div>
      </div>

      <!--  -->
      <div class="text-center mb-5">
        <div class="text-black font-medium text-sm mb-2 mt-6">
          Already have an account?
          <span
            @click="$router.push({ name: 'Signin' })"
            class="text-black hover:text-mustardyellow cursor-pointer"
            ><b>Sign in</b></span
          >
        </div>
      </div>

      <!-- sucess dailog -->
      <vs-dialog width="550px" v-model="sucessDialog">
        <template #header>
          <h4 class="not-margin">
            <b>Almost there...</b>
          </h4>
        </template>

        <div class="text-center">
          <p>
            Please check your email <b class="font-semibold">({{ form.email }})</b>
            <br />
            to confirm your account,<br />
            <br />
            If <b class="font-semibold">{{ form.email }}</b> is not your email address,<br />
            please
            <b @click="sucessDialog = false" class="cursor-pointer text-yellow-800">
              go back</b
            >
            and enter the correct one.<br />
            <br />
            If you haven't received our email in 15 minutes, please check your spam folder
            or promotions tab.<br />
          </p>
        </div>
      </vs-dialog>
      <!--  -->
    </div>
  </section>
</template>

<script>
import axios from "../../plugin/axios";
export default {
  data() {
    return {
      formTab: 1,
      form: {
        fullName: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        mobileNo: "",
        referralCode: "",
      },
      confirmPassword: "",
      countryCode: "",
      referral: false,
      hasVisiblePassword: false,
      loading: false,
      error: "",
      sucessDialog: false,
      invalidReferral: false,
    };
  },
  methods: {
    async submit() {
      if (
        this.validEmail === true &&
        this.getProgress >= 40 &&
        this.validateForm == true
      ) {
        this.loading = true;
        let numner = this.countryCode + this.form.mobileNo;

        this.form.mobileNo = numner;
        this.form.fullName = this.form.firstName + " " + this.form.lastName;
        await axios
          .post("/user/register", this.form)
          .then((response) => {
            if (response.status === 200) {
              this.sucessDialog = true;
              this.loading = false;
              if (this.countryCode == "234") {
                this.sendSMS();
              }
              // console.log(response.data);
            }
          })
          .catch((error) => {
            let length = this.countryCode.length;
            if (error.response.data.message == "Invalid referral mobile number") {
              this.invalidReferral = true;
              this.loading = false;
            }
            if (error.response.data.message === undefined) {
              this.error = "Something went wrong, please try again";
              this.form.mobileNo = this.form.mobileNo.substring(length);
              this.loading = false;
              this.formTab = 1;
            } else {
              this.error = error.response.data.message;
              this.form.mobileNo = this.form.mobileNo.substring(length);
              this.loading = false;
              this.formTab = 1;
            }
          });
      }
    },
    async sendSMS() {
      let message = `Hi! ${this.form.firstName}, thank you for registering an account with SkillNG. And thank you for trusting us in your skill development journey. Ola from SkillNG`;
      let mobile = this.form.mobileNo;
      let data = {
        api_key: "TLZfOcg0BTvN6nGSqiISMjEwnHU2GsUDJSgjwCMKJ7WJ2DyFs71WSTgH6LxCtm",
        to: mobile,
        from: "N-Alert",
        sms: message,
        type: "plain",
        channel: "dnd",
      };
      let url = "https://api.ng.termii.com/api/sms/send";
      await axios
        .post(url, data)
        .then(() => {})
        .catch(() => {});
    },
    invalidReferralTrue() {
      this.form.referralCode = "";
      this.referral = false;
      this.invalidReferral = false;
      this.error = "";
      this.formTab = 2;
      this.submit();
    },
    invalidReferralFalse() {
      this.invalidReferral = false;
      this.error = "";
      this.formTab = 1;
    },
    signin() {
      this.$router.push({ name: "Signin" });
    },
    focusLastName() {
      let input = this.$refs.lastName.$el.querySelector("input");
      input.focus();
    },
    focusNumber() {
      let input = this.$refs.number.$el.querySelector("input");
      input.focus();
    },
    focusReferral() {
      if (this.referral) {
        let input = this.$refs.referral.$el.querySelector("input");
        input.focus();
      }
    },
    focusPassword() {
      let input = this.$refs.password.$el.querySelector("input");
      input.focus();
    },
    next() {
      setTimeout(() => {
        let input = this.$refs.email.$el.querySelector("input");
        input.focus();
      }, 200);
      this.formTab = 2;
    },
    onSelectCountry({ name, iso2, dialCode }) {
      console.log(name, iso2, dialCode);
      this.countryCode = dialCode;
    },
  },
  computed: {
    validEmail() {
      /* eslint-disable */
      return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,9})+$/.test(this.form.email);
      /* eslint-enable */
    },
    validateForm() {
      return (
        this.form.mobileNo !== "" &&
        this.form.firstName !== "" &&
        this.form.lastName !== ""
      );
    },

    getProgress() {
      let progress = 0;
      // at least one number
      if (/\d/.test(this.form.password)) {
        progress += 20;
      }
      // at least one capital letter
      if (/(.*[A-Z].*)/.test(this.form.password)) {
        progress += 20;
      }
      // at menons a lowercase
      if (/(.*[a-z].*)/.test(this.form.password)) {
        progress += 20;
      }
      // more than 5 digits
      if (this.form.password.length >= 6) {
        progress += 20;
      }
      // at least one special character
      if (/[^A-Za-z0-9]/.test(this.form.password)) {
        progress += 20;
      }
      return progress;
    },
  },
  mounted() {
    if (this.$route.query.referral) {
      this.referral = true;
      this.form.referralCode = this.$route.query.referral;
    }
    ///
    let input = this.$refs.firstName.$el.querySelector("input");
    input.focus();
  },
};
</script>

<style>
.vue-country-select .dropdown-list {
  z-index: 50 !important;
}
.vue-country-select {
  border: none !important;
}
</style>
