<template>
  <section class="bg-gray-100 w-full px-6 py-2 h-full overflow-y-auto">
    <section
      v-if="dropdown"
      @click="dropdown = false"
      class="z-40 h-screen w-screen fixed top-0 overflow-hidden"
    ></section>

    <div class="px-2 py-5 mt-8 lg:px-8 mx-0">
      <div class="flex justify-between lg:pr-5 pr-1">
        <h1 class="text-xl font-medium text-skillblack lg:px-6">Users</h1>
        <button
          class="cursor-pointer bg-skilldeepyelow py-1 px-3 rounded-md text-sm font-semibold shadow-md"
        >
          <download-csv :data="users"> Download CSV </download-csv>
        </button>
      </div>

      <!--  -->
      <div class="w-full lg:px-4 mt-8">
        <div class="shadow-lg rounded bg-white border pb-4">
          <div class="py-3 flex justify-between mx-4">
            <p class="font-semibold text-lg text-gray-800">
              List of Users ({{ users.length }})
            </p>
            <p @click="dropdown = !dropdown" class="flex items-center cursor-pointer p-2">
              <i class="fas fa-filter px-2"></i>
              <span class="font-semibold lg:pr-4 pr-1"> Filtered By: {{ filter }} </span>
            </p>
          </div>
          <!-- filter dropdonw  -->
          <div
            v-if="dropdown"
            class="z-50 absolute top-40 mt-3 right-28 w-48 rounded-lg shadow-md border border-clightest bg-white mb-20 overflow-hidden"
          >
            <button
              @click="filterAll"
              class="py-2 flex items-center font-medium px-5 hover:bg-yellow-100 w-full"
            >
              <p>All</p>
            </button>
            <button
              @click="filterTutor"
              class="py-2 flex items-center font-medium px-5 hover:bg-yellow-100 w-full"
            >
              <p>Status: Tutor</p>
            </button>
            <button
              @click="filterLeaner"
              class="py-2 flex items-center font-medium px-5 hover:bg-yellow-100 w-full"
            >
              <p>Status: Learner</p>
            </button>
            <button
              @click="filterActive"
              class="py-2 flex items-center font-medium px-5 hover:bg-yellow-100 w-full"
            >
              <p>Account: Active</p>
            </button>
            <button
              @click="filterInactive"
              class="py-2 flex items-center font-medium px-5 hover:bg-yellow-100 w-full"
            >
              <p>Account: Inactive</p>
            </button>
          </div>
          <!--  -->

          <div class="block w-full overflow-x-auto px-4">
            <!--  -->
            <vs-table>
              <template #header>
                <vs-input v-model="search" border placeholder="Search" />
              </template>
              <template #thead>
                <vs-tr>
                  <vs-th sort @click="users = $vs.sortData($event, users, 'fullName')">
                    Name
                  </vs-th>
                  <vs-th sort @click="users = $vs.sortData($event, users, 'email')">
                    Email
                  </vs-th>
                  <vs-th sort @click="users = $vs.sortData($event, users, 'mobileNo')">
                    Phone Number
                  </vs-th>
                  <vs-th sort @click="users = $vs.sortData($event, users, 'tutorStatus')">
                    Role
                  </vs-th>
                  <vs-th sort @click="users = $vs.sortData($event, users, 'isValidated')">
                    Status
                  </vs-th>
                  <vs-th sort @click="users = $vs.sortData($event, users, 'createdDate')">
                    Register
                  </vs-th>
                </vs-tr>
              </template>
              <template #tbody>
                <vs-tr
                  @click="(openUserDialog = true), getUser(tr._id)"
                  class="cursor-pointer"
                  :key="index"
                  v-for="(tr, index) in $vs.getPage(
                    $vs.getSearch(users, search),
                    page,
                    max
                  )"
                  :data="tr"
                >
                  <vs-td>
                    {{ tr.fullName }}
                  </vs-td>
                  <vs-td>
                    {{ tr.email }}
                  </vs-td>
                  <vs-td>
                    {{ tr.mobileNo }}
                  </vs-td>
                  <vs-td>
                    <p v-if="tr.tutorStatus == false">Learner</p>
                    <p v-else>Tutor</p>
                  </vs-td>
                  <vs-td>
                    <p v-if="tr.isValidated == false">Not Active</p>
                    <p v-else>Active</p>
                  </vs-td>
                  <vs-td>
                    <p>{{ new Date(tr.createdDate).toLocaleDateString() }}</p>
                  </vs-td>
                </vs-tr>
              </template>
              <template #footer>
                <vs-pagination
                  v-model="page"
                  :length="$vs.getLength($vs.getSearch(users, search), max)"
                />
              </template>
            </vs-table>
            <!--  -->
          </div>
        </div>
      </div>
      <!-- Dialog -->
      <vs-dialog scroll width="850px" v-model="openUserDialog">
        <template #header>
          <h4 class="not-margin font-semibold">User Details</h4>
        </template>
        <div class="h-96 z-10 my-8">
          <img
            v-if="user.profilePicUrl"
            :src="'https://res.cloudinary.com/skillng/image/fetch/' + user.profilePicUrl"
            class="inset-0 h-full w-full object-contain bg-gray-200"
          />
          <img
            v-else
            src="../assets/ProfileAvater.jpg"
            class="inset-0 h-full w-full object-contain bg-gray-200"
          />
        </div>
        <div>
          <p v-if="user.tutorStatus" class="py-5">Status: <b>Tutor </b></p>
          <p v-else class="py-5">Status: <b> Learner </b></p>
          <hr />
        </div>
        <div>
          <p>
            Full Name: <b> {{ user.fullName }} </b>
          </p>
          <p>
            Email: <b> {{ user.email }} </b>
          </p>
          <p>
            Phone Number : <b> {{ user.mobileNo }} </b>
          </p>
          <p>
            Location : <b> {{ user.location }} </b>
          </p>
          <p>
            Occupation : <b> {{ user.occupation }} </b>
          </p>
          <p>
            Job Status : <b> {{ user.jobStatus }} </b>
          </p>
          <hr />
        </div>
        <div v-if="user.tutorStatus">
          <div>
            <p class="my-4">
              Tutor Wallet Balance: ₦{{ user.tutorWallet.toLocaleString() }}
            </p>
          </div>
          <p class="font-semibold">Tutor Skills</p>
          <div class="ml-5">
            <ul class="list-disc">
              <li v-for="skill in user.tutorSkills" :key="skill.index">
                {{ skill }}
              </li>
            </ul>
          </div>
          <p class="font-semibold">Tutor Portfolio</p>
          <div class="ml-5">
            <ul class="list-disc">
              <li v-for="portfolio in user.tutorPortfolio" :key="portfolio.index">
                <a :href="portfolio" target="_blank" class="text-blue-700">{{
                  portfolio
                }}</a>
              </li>
            </ul>
          </div>
        </div>
        <template #footer>
          <div v-if="!user.isValidated" class="footer-dialog">
            <vs-button
              block
              class="mb-2 mt-1 disabled"
              style="color: black"
              @click="activateUser"
            >
              Activate User
            </vs-button>
          </div>
        </template>
      </vs-dialog>
    </div>
  </section>
</template>

<script>
import axios from ".././plugin/axios";
export default {
  components: {},
  data() {
    return {
      openUserDialog: false,
      loading: false,
      dropdown: false,
      message: "",
      search: "",
      page: 1,
      max: 20,
      users: [],
      user: {},
      filter: "All",
    };
  },
  methods: {
    async activateUser() {
      let url = `/user/profile/confirm/${this.user._id}`;
      let token = this.$store.state.authUser.token;

      await axios
        .get(url, {
          headers: {
            "x-access-token": token,
          },
        })
        .then(() => {
          this.loadData();
          this.openUserDialog = false;
          this.$swal.fire({
            position: "center",
            icon: "success",
            title: this.message,
            showConfirmButton: false,
            timer: 3000,
            toast: true,
          });
        })
        .catch((error) => {
          this.$swal.fire({
            position: "center",
            icon: "error",
            title: error,
            showConfirmButton: false,
            timer: 3000,
            toast: true,
          });
        });
    },
    async getUser(id) {
      let url = `/user/profile/${id}`;
      let token = this.$store.state.authUser.token;

      await axios
        .get(url, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          console.log(response);
          let data = response.data.data;
          this.user = data;
        })
        .catch(() => {});
    },

    filterAll() {
      this.filter = "All";
      this.dropdown = false;
      this.users = this.$store.state.usersList;
    },

    filterTutor() {
      this.filter = "Tutor";
      this.dropdown = false;
      let filter = this.$store.state.usersList.filter(function (type) {
        return type.tutorStatus == true;
      });
      this.users = filter;
    },

    filterLeaner() {
      this.filter = "Learner";
      this.dropdown = false;
      let filter = this.$store.state.usersList.filter(function (type) {
        return type.tutorStatus == false;
      });
      this.users = filter;
    },

    filterActive() {
      this.filter = "Active";
      this.dropdown = false;
      let filter = this.$store.state.usersList.filter(function (type) {
        return type.isValidated == true;
      });
      this.users = filter;
    },

    filterInactive() {
      this.filter = "Inactive";
      this.dropdown = false;
      let filter = this.$store.state.usersList.filter(function (type) {
        return type.isValidated == false;
      });
      this.users = filter;
    },
    async loadData() {
      let url = "user/all";
      let token = this.$store.state.authUser.token;

      await axios
        .get(url, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          let data = response.data.data.users;
          this.$store.commit("setUsersList", data);
          this.users = this.$store.state.usersList;
          // console.log(data.users);
        })
        .catch(() => {});
    },
  },
  async mounted() {
    this.users = [{ fullName: "Loading..." }];
    if (this.$store.state.usersList.length === 0) {
      await this.loadData();
    }
    this.users = this.$store.state.usersList;
  },
};
</script>

<style></style>
