<template>
  <div class="px-6 py-2 h-almost overflow-y-auto pb-24">
    <div
      class="lg:block hidden -ml-6 min-h-screen h-screen border-l border-slighter fixed justify-items-start"
    ></div>
    <div class="flex justify-center mx-auto w-full">
      <div class="xl:w-4/12 lg:w-6/12 w-full md:px-16 lg:px-0 px-6">
        <div>
          <p class="text-2xl font-medium text-center pt-10 pb-4">Verify Certificate</p>
          <div class="">
            <vs-input
              class="block"
              v-model="certificateID"
              placeholder="Certificate ID"
            />
          </div>
          <div class="flex justify-center mt-5">
            <vs-button
              :loading="loading"
              :disabled="certificateID !== '' ? false : true"
              @click="verifyCertificate"
              class="mb-2 mt-1 shadow-lg"
              style="color: black"
            >
              <p class="text-sm font-medium px-10">Verify</p>
            </vs-button>
          </div>
        </div>

        <div v-if="error">
          <p class="text-red-600 text-sm font-semibold p-5 text-center">{{ error }}</p>
        </div>

        <div v-if="dataRetunred" class="test-result2 mt-8">
          <div class="test-result-table">
            <div class="test-result-table-item">
              <div><b>DETAILS</b></div>
            </div>
            <div class="test-result-table-item">
              <div>NAME</div>
              <div>{{ details.userFullname }}</div>
            </div>
            <div class="test-result-table-item">
              <div>COURSE</div>
              <div>{{ details.classid.name }}</div>
            </div>
            <div class="test-result-table-item">
              <div>COMPLETION DATE</div>
              <div>
                {{ new Date(details.created).toDateString().replace(/^\S+\s/, "") }}
              </div>
            </div>
            <div class="test-result-table-item">
              <div>CERTIFICATE ID</div>
              <div>{{ details._id }}</div>
            </div>
            <div class="test-result-table-item">
              <div>CURRICULUM</div>
              <div v-if="details.classType === 'Group Class'">
                <div
                  v-for="(currModule, index) in details.classid.curriculum[0].curriculum"
                  :key="currModule.index"
                  class="flex"
                >
                  <p class="flex-none font-semibold pr-3">Module {{ index + 1 }}:</p>
                  <p class="flex-auto">{{ currModule.name }}</p>
                </div>
              </div>
              <div v-else>
                <div
                  v-for="(currModule, index) in details.classid.curriculum"
                  :key="currModule.index"
                  class="flex"
                >
                  <p class="flex-none font-semibold pr-3">Module {{ index + 1 }}:</p>
                  <p class="flex-auto">{{ currModule }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../plugin/axios";
export default {
  data() {
    return {
      details: { userFullname: "" },
      certificateID: "",
      loading: false,
      dataRetunred: false,
      error: "",
    };
  },
  methods: {
    async verifyCertificate() {
      this.loading = true;
      this.details = { userFullname: "" };
      this.error = "";
      this.dataRetunred = false;

      let url = `certificate/details/${this.certificateID}`;
      await axios
        .get(url)
        .then((res) => {
          console.log(res);

          if (res.data.data !== null) {
            this.details = res.data.data;
            this.details = res.data.data;
            this.dataRetunred = true;
            this.loading = false;
            this.certificateID = "";
          } else {
            this.error = "Invalid Certificate ID";
          }

          if (res.data.data.message) {
            this.error = res.data.data.message;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.test-result-table {
  /* width: 100%; */
}
.test-result2 {
  justify-content: center;
  display: flex;
  display: -webkit-flex;
  width: 100%;
}
.test-result-table-item {
  width: 100%;
  border-top: 1px solid #dbdbdb;
  display: flex;
  display: -webkit-flex;
  text-align: left;
  font-weight: 500;
}
.test-result-table .test-result-table-item:nth-last-of-type(1) {
  border-bottom: 1px solid #dbdbdb;
}
.test-result-table-item > div {
  padding: 10px;
}
.test-result-table-item > div:first-child {
  width: 180px;
  min-width: 180px;
}
/* .test-result-table-item > div:nth-child(2) {
  flex-grow: 1;
} */
</style>
