<template>
  <section
    class="bg-skilldeepyelow w-full h-full flex justify-center items-center overflow-y-auto"
  >
    <div class="xl:w-4/12 lg:w-6/12 w-full md:px-16 lg:px-0 px-6 lg:py-16 py-6 relative">
      <div class="mt-5">
        <button>
          <img src="../../../src/assets/logo.png" alt="Logo" class="h-10 w-auto" />
        </button>
      </div>
      <div>
        <p class="font-normal text-3xl my-2">Welcome Admin,</p>
        <p>Sign in to continue</p>
      </div>
      <!--  -->
      <vs-alert closable v-model="error" color="danger" class="mt-4">
        <p class="font-semibold">{{ error }}</p>
      </vs-alert>

      <!--  -->
      <div class="mt-10 center content-inputs">
        <vs-input
          block
          border
          dark
          type="email"
          v-model="form.email"
          label-placeholder="Email"
        />
      </div>

      <!--  -->
      <div class="mt-10 center content-inputs">
        <vs-input
          @keydown.enter="submit"
          dark
          block
          border
          type="password"
          icon-after
          v-model="form.password"
          label-placeholder="Password"
          :visiblePassword="hasVisiblePassword"
          @click-icon="hasVisiblePassword = !hasVisiblePassword"
        >
          <template #icon>
            <i v-if="!hasVisiblePassword" class="fas fa-eye-slash"></i>
            <i v-else class="fas fa-eye"></i>
          </template>
        </vs-input>
      </div>
      <!--  -->

      <!--  -->
      <div class="flex justify-center mt-14">
        <vs-button
          flat
          :active="!loading"
          :loading="loading"
          color="#FFF212"
          @click="submit"
          class="w-full shadow-md"
          type="button"
        >
          <p class="text-skillblack py-0.5 text-base">Sign In</p>
        </vs-button>
      </div>
      <!--  -->
    </div>
  </section>
</template>

<script>
import axios from "../../plugin/axios";
export default {
  data() {
    return {
      error: "",
      form: {
        email: "",
        password: "",
      },
      hasVisiblePassword: false,
      loading: false,
    };
  },
  methods: {
    async submit() {
      this.loading = true;
      this.error = "";
      await axios
        .post("/user/login", this.form)
        .then((response) => {
          if (response.status === 200 && response.data.data.is_admin === false) {
            this.error = "This is not admin account.";
            this.loading = false;
            return;
          }
          if (response.status === 200 && response.data.data.isValidated === true) {
            let user = response.data.data;
            localStorage.setItem("skill_user", JSON.stringify(user));
            localStorage.setItem("skill_jwt", user.token);
            this.$store.commit("setAuthUser", user);
            this.$store.commit("setIsAdminLoggedIn", true);
            this.$router.push({ name: "AdminDashboard" });
            // console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data.message === undefined) {
            this.error = "Something went wrong, please try again";
            this.loading = false;
          } else {
            this.error = error.response.data.message;
            this.loading = false;
          }
        });
    },
  },
};
</script>

<style></style>
