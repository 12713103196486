<template>
  <div class="overflow-y-auto">
    <div
      class="px-6 pt-4 pb-3 border-b border-slighter flex items-center justify-between bg-white"
    >
      <div class="flex">
        <button @click="$router.go(-1)" class="">
          <i
            class="fal fa-long-arrow-left text-skilldeepyelow text-center text-xl pr-5"
          ></i>
        </button>
        <h1 class="text-base font-medium text-skillblack">Become Tutor</h1>
      </div>
    </div>
    <div class="mt-8 mx-8">
      <!-- Stage 1 BVN -->
      <div v-if="stage === 1">
        <p class="text-gray-400 text-sm">
          Enter your BNV to validate yourself. Your BVN will not be stored with us.
        </p>
        <vs-input
          block
          type="number"
          label="BVN "
          class="mt-8"
          v-model="tutorDetails.bvn"
        />
        <p class="pl-4 pt-2" v-if="tutorDetails.fullName">
          Your BVN Name is: <b>{{ tutorDetails.fullName }}</b>
        </p>
        <p class="pl-4 pt-2 text-red-600">{{ bvnError }}</p>
        <div class="flex justify-end mx-8">
          <button
            :disabled="loading"
            v-if="!continue1"
            @click="BvnValid"
            class="mt-5 bg-skilldeepyelow p-2 px-4 rounded-lg"
            :class="loading ? 'disabled:opacity-50' : ''"
          >
            <span>Validate </span>
            <i v-if="loading" class="animate-spin fad fa-circle-notch"></i>
          </button>
          <button
            v-if="continue1"
            :disabled="!tutorDetails.fullName"
            @click="stage = 3"
            class="mt-5 bg-skilldeepyelow p-2 px-4 rounded-lg"
          >
            <span>Continue </span>
          </button>
        </div>
      </div>

      <!-- Stage 2 Phone Number -->
      <div v-if="stage === 2" class="w-full px-3">
        <p class="text-gray-400 text-sm">
          Enter your Phone Number for validation. An OTP will be send to you.
        </p>
        <vs-input
          block
          type="number"
          label="Phone Number (without code and prefix 0)"
          class="mt-8"
          v-model="tutorDetails.mobileNo"
        >
          <template #icon>
            <p>234</p>
          </template>
          <template v-if="!validNumber && tutorDetails.mobileNo !== ''" #message-danger>
            Phone Number must be 10 digits
          </template>
        </vs-input>

        <div v-if="otp" class="flex justify-center mt-8">
          <v-otp-input
            inputClasses="otp-input"
            :numInputs="4"
            separator=""
            :shouldAutoFocus="true"
            @on-complete="handleOnComplete"
          />
        </div>
        <p
          class="text-sm text-center pt-2"
          :class="optMessage.startsWith(`Invalid`) ? 'text-red-600' : 'text-green-600'"
        >
          {{ optMessage }}
        </p>
        <div class="flex justify-end mx-8">
          <div v-if="!otp">
            <button
              :disabled="loading"
              v-if="!continue2"
              @click="sendSMS"
              class="mt-5 bg-skilldeepyelow p-2 px-4 rounded-lg"
              :class="loading ? 'disabled:opacity-50' : ''"
            >
              <span v-if="!otp">Validate </span>
              <!-- <span v-if="otp" @click="handleOnComplete">Verify OTP </span> -->
              <i v-if="loading" class="animate-spin fad fa-circle-notch"></i>
            </button>
          </div>
          <button
            v-if="continue2"
            @click="stage = 3"
            class="mt-5 bg-skilldeepyelow p-2 px-4 rounded-lg"
          >
            <span>Continue </span>
          </button>
        </div>
      </div>

      <!-- Stage 3 Add Skill -->
      <div v-if="stage === 3">
        <p class="text-sm">Add some Skills to your tutor profile</p>
        <!--  -->
        <p class="text-sm text-green-700 pt-4">
          Enter your address (this will be used to send request within same region to
          you.)
        </p>
        <!--  -->
        <div class="py-2 w-full">
          <gmaps-map @mounted="ready" />
          <input
            ref="autocomplete"
            id="autocomplete"
            placeholder="Search Address"
            class="w-full pl-4 p-2 bg-gray-100 rounded-lg"
          />
        </div>
        <!--  -->
        <div v-if="mapError">
          <p class="text-red-600 text-sm pl-4">{{ mapError }}</p>
        </div>
        <div
          v-if="tutorDetails.address[0].fullAddress && !tutorDetails.address[0].localGovt"
        >
          <p class="text-red-600 pb-4 px-4 text-sm font-medium">
            Your address must include a street address
          </p>
        </div>
        <!--  -->
        <p class="text-sm text-green-700 pt-4">Skills</p>

        <div
          v-for="(input, index) in tutorDetails.skills"
          :key="input.index"
          class="flex items-center my-2"
        >
          <vs-input block placeholder="Skill" v-model="tutorDetails.skills[index]" />
          <i
            class="fal fa-plus-circle text-2xl text-green-600 px-2"
            @click="addSkill"
          ></i>
          <i
            class="fal fa-times-circle text-2xl text-red-600 px-2"
            v-if="tutorDetails.skills.length !== 1"
            @click="removeSkill(index)"
          ></i>
        </div>
        <!--  -->
        <p class="text-sm text-green-700 pt-4">
          Link to Portfolio (Linkedin, Github, Blog etc.)
        </p>

        <div
          v-for="(input, index) in tutorDetails.portfolio"
          :key="input.index"
          class="flex items-center my-2"
        >
          <vs-input block placeholder="URL" v-model="tutorDetails.portfolio[index]" />
          <i class="fal fa-plus-circle text-2xl text-green-600 px-2" @click="addUrl"></i>
          <i
            class="fal fa-times-circle text-2xl text-red-600 px-2"
            v-if="tutorDetails.portfolio.length !== 1"
            @click="removeUrl(index)"
          ></i>
        </div>
        <!--  -->

        <div class="flex justify-end mx-8">
          <button
            :disabled="loading || !tutorDetails.address[0].localGovt"
            @click="validateSkill"
            class="mt-5 bg-skilldeepyelow p-2 px-4 rounded-lg"
            :class="loading ? 'disabled:opacity-50' : ''"
          >
            <span>Submit </span>
            <i v-if="loading" class="animate-spin fad fa-circle-notch"></i>
          </button>
        </div>
      </div>
      <!--  -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import axios1 from "../../plugin/axios";

import { gmapsMap } from "x5-gmaps";

export default {
  components: { gmapsMap },

  data() {
    return {
      loading: false,
      stage: 2,
      continue1: false,
      continue2: false,
      otp: false,
      optMessage: "",
      otpCode: "",
      bvnError: "",
      mapError: "",
      tutorDetails: {
        fullName: "",
        bvn: "",
        mobileNo: "",
        address: [{ fullAddress: "", localGovt: "", city: "", state: "", country: "" }],
        skills: [""],
        portfolio: [""],
      },
      notificationData: {},
    };
  },
  computed: {
    validNumber() {
      return this.tutorDetails.mobileNo.length === 10;
    },
  },
  methods: {
    ready() {
      this.$GMaps().then((maps) => {
        this.autocomplete = new maps.places.Autocomplete(this.$refs.autocomplete);
        this.autocomplete.setComponentRestrictions({ country: "ng" });
        this.autocomplete.setFields([
          "geometry",
          "formatted_address",
          "address_components",
        ]);
        this.autocomplete.addListener("place_changed", this.onPlaceChanged);
      });
    },
    onPlaceChanged() {
      this.getPlace = this.autocomplete.getPlace();
      if (!this.getPlace.geometry) {
        this.mapError = "No Result Found";
        return;
      }
      if (this.getPlace.address_components !== undefined) {
        // console.log("placechanged", this.getPlace);
        this.mapError = "";
        this.getAddressData();
      }
    },
    getAddressData() {
      this.tutorDetails.address[0].fullAddress = this.getPlace.formatted_address;

      let administrative_area_level_2 = this.getPlace.address_components.filter(function (
        address_component
      ) {
        return address_component.types.includes("administrative_area_level_2");
      });
      this.tutorDetails.address[0].localGovt = administrative_area_level_2.length
        ? administrative_area_level_2[0].long_name
        : "";
      //
      let locality = this.getPlace.address_components.filter(function (
        address_component
      ) {
        return address_component.types.includes("locality");
      });
      this.tutorDetails.address[0].city = locality.length ? locality[0].long_name : "";
      //
      let administrative_area_level_1 = this.getPlace.address_components.filter(function (
        address_component
      ) {
        return address_component.types.includes("administrative_area_level_1");
      });
      this.tutorDetails.address[0].state = administrative_area_level_1.length
        ? administrative_area_level_1[0].long_name
        : "";
      //
      let country = this.getPlace.address_components.filter(function (address_component) {
        return address_component.types.includes("country");
      });
      this.tutorDetails.address[0].country = country.length ? country[0].long_name : "";
    },

    async validateSkill() {
      //
      this.notificationData = {
        userid: this.$store.state.authUser.id,
        message:
          "Congratulation on becoming a SKillNG Tutor. Your tutor dashboard has been created.",
        url: "https://app.skillng.com/tutor",
        imgUrl:
          "https://res.cloudinary.com/skillng/image/upload/v1626132590/skillng-app/t2fpd4ddbr98vz1rwqc2.png",
      };
      //
      this.loading = true;
      let token = this.$store.state.authUser.token;
      let id = this.$store.state.authUser._id;

      let url = "user/tutor/create";
      await axios1
        .post(
          url,
          {
            // fullName: this.tutorDetails.fullName,
            bvn: this.tutorDetails.bvn,
            mobileNo: "234" + this.tutorDetails.mobileNo,
            address: this.tutorDetails.address,
            skills: this.tutorDetails.skills,
            portfolio: this.tutorDetails.portfolio,
            userid: id,
          },

          {
            headers: {
              "x-access-token": token,
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.sucessAlart();
          this.loading = false;
          this.notification();
        })
        .catch((error) => {
          console.log(error);
          // this.error = error;
          // this.errorAlart();
        });
      this.loading = false;
    },

    sucessAlart() {
      setTimeout(() => {
        this.$swal.fire({
          position: "top-end",
          icon: "success",
          title: "Congratulations on becoming a SkillNG Tutor",
          showConfirmButton: false,
          timer: 3000,
          toast: true,
        });
      }, 1500);
      setTimeout(() => {
        this.$router.push("/tutor");
        this.$store.commit("setIsTutor", true);
      }, 3000);
    },

    async BvnValid() {
      this.loading = true;
      let url = "https://api.paystack.co/bank/resolve_bvn/" + this.tutorDetails.bvn;
      let key = "Bearer sk_test_e3091c9cf1f5eed89ff72608ddbcf0bd32756e60";

      await axios
        .get(url, {
          headers: {
            Authorization: key,
          },
        })
        .then((response) => {
          this.tutorDetails.fullName =
            response.data.data.first_name + " " + response.data.data.last_name;
          this.continue1 = true;
          this.loading = false;
        })
        .catch((error) => {
          this.bvnError = error.response.data.message;
          this.loading = false;
          this.continue1 = true;
        });
    },
    handleOnComplete(value) {
      // console.log("OTP completed: ", value);
      if (value == this.otpCode) {
        setTimeout(() => {
          this.optMessage = "OPT verified sucessfull";
          this.continue2 = true;
        }, 500);
      } else this.optMessage = "Invalid OTP, please enter correct OTP";
    },

    async sendSMS() {
      this.loading = true;
      let otp = Math.floor(Math.random() * 9000);
      this.otpCode = otp;
      let message = `<#> ${otp} is your SkillNG tutor activation code`;
      let mobile = "234" + this.tutorDetails.mobileNo;
      let data = {
        api_key: "TLZfOcg0BTvN6nGSqiISMjEwnHU2GsUDJSgjwCMKJ7WJ2DyFs71WSTgH6LxCtm",
        to: mobile,
        from: "N-Alert",
        sms: message,
        type: "plain",
        channel: "dnd",
      };

      let url = "https://api.ng.termii.com/api/sms/send";
      await axios
        .post(url, data)
        .then(() => {
          this.loading = false;
          this.otp = true;
        })
        .catch(() => {
          this.$swal.fire({
            position: "top-end",
            icon: "error",
            title: "Error sending OTP, ensure your phone number is correct and try again",
            showConfirmButton: false,
            timer: 3000,
            toast: true,
          });
          this.loading = false;
        });
    },

    addSkill() {
      this.tutorDetails.skills.push("");
    },
    removeSkill(index) {
      this.tutorDetails.skills = this.tutorDetails.skills.filter(
        (input, i) => i !== index
      );
    },
    addUrl() {
      this.tutorDetails.portfolio.push("");
    },
    removeUrl(index) {
      this.tutorDetails.portfolio = this.tutorDetails.portfolio.filter(
        (input, i) => i !== index
      );
    },
    async notification() {
      let url = "notifications/create";
      let token = this.$store.state.authUser.token;
      await axios1
        .post(url, this.notificationData, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          console.log(response);
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
