<template>
  <div class="w-full h-screen">
    <div class="fixed inset-x-0 bottom-0 z-10 bg-white border-t border-slighter">
      <section
        v-if="dropdown"
        @click="dropdown = false"
        class="z-50 h-screen w-screen bg-gray-500 fixed top-0 opacity-50 overflow-hidden"
      ></section>

      <div class="flex justify-between">
        <!--  -->
        <router-link
          v-for="tab in tabs"
          :key="tab.title"
          :to="{ name: tab.route }"
          class="w-full"
        >
          <button
            class="w-full focus:outline-none justify-center inline-block text-center pt-2 pb-1"
          >
            <i
              :class="[
                $route.path === tab.link ? 'font-semibold text-yellow-400' : 'font-thin',
                tab.icon,
              ]"
              class="font-thin text-2xl"
            ></i>

            <span class="block text-xs">{{ tab.title }}</span>
          </button>
        </router-link>

        <button
          @click="dropdown = !dropdown"
          class="w-full focus:outline-none justify-center inline-block text-center pt-2 pb-1"
        >
          <i class="fas fa-bars font-thin text-2xl"></i>
          <span class="block text-xs">Menu</span>
        </button>
      </div>
    </div>
    <!-- Dropdown -->
    <div v-if="dropdown">
      <div
        class="z-20 w-full max-h-3/4 bg-white fixed bottom-0 right-0 rounded-t-xl border border-clightest overflow-auto pb-8"
      >
        <div class="flex justify-center">
          <router-link to="/request">
            <button
              @click="dropdown = false"
              class="focus:outline-none p-3 py-2 px-5 bg-skillyelow rounded-full text-skillblack font-medium shadow-md mb-3 mt-8"
            >
              Request for a tutor
            </button>
          </router-link>
        </div>

        <router-link v-for="tab in dropdownTabs" :key="tab.title" :to="tab.route">
          <button
            @click="dropdown = false"
            class="w-full text-left hover:bg-slightyellow border-t border-slighter p-3 text-sm focus:outline-none relative"
          >
            <div class="flex items-center">
              <i :class="tab.icon"></i>
              <p class="ml-2">{{ tab.title }}</p>
            </div>
            <div
              v-if="tab.title == 'Notifications'"
              class="absolute right-4 top-1/2 -mt-3"
            >
              <p
                v-if="notificationCount"
                class="text-xs text-white px-2 py-0.5 bg-yellow-500 border-2 border-white rounded-full"
              >
                {{ notificationCount }}
              </p>
            </div>
            <div v-if="tab.title == 'Messages'" class="absolute right-4">
              <div
                v-if="unreadMessage"
                class="absolute animate-ping top-1/2 -mt-4 right-2 bg-red-500 rounded-full h-3.5 w-3.5"
              ></div>
              <div
                v-if="unreadMessage"
                class="absolute top-1/2 -mt-4 right-2 bg-red-500 rounded-full h-3.5 w-3.5 border-2 border-white"
              ></div>
            </div>
          </button>
        </router-link>
        <button
          @click="logout"
          class="w-full text-left hover:bg-slightyellow border-b border-slighter p-3 text-sm focus:outline-none"
        >
          <div class="flex items-center">
            <i class="fad fa-sign-out"></i>
            <p class="ml-2">Sign Out</p>
          </div>
        </button>
      </div>
    </div>

    <!-- Dropdwown End -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabs: [
        {
          icon: "far fa-home-alt",
          title: "Dashboard",
          route: "Dashboard",
          link: "/",
        },
        {
          icon: "far fa-layer-plus ",
          title: "Request",
          route: "Request",
          link: "/request",
        },
        {
          icon: "far fa-graduation-cap",
          title: "Schools",
          route: "Schools",
          link: "/schools",
        },
      ],

      dropdownTabs: [
        {
          icon: "far fa-user",
          title: "Profile",
          route: "/profile",
        },
        {
          icon: "far fa-bell",
          title: "Notifications",
          route: "/notifications",
        },
        {
          icon: "far fa-envelope ",
          title: "Messages",
          route: "/messages",
        },
        {
          icon: "far fa-chalkboard-teacher",
          title: "My Classes",
          route: "/classes",
        },
        {
          icon: "far fa-cog",
          title: "Settings",
          route: "/settings",
        },
      ],
      dropdown: false,
    };
  },
  computed: {
    notificationCount() {
      let count = this.$store.state.unreadNotification;
      if (count > 9) {
        count = "9+";
      }
      return count;
    },
    unreadMessage() {
      let unread = this.$store.state.unreadMessage;
      return unread;
    },
  },
  methods: {
    logout() {
      this.dropdown = false;
      localStorage.removeItem("skill_user");
      localStorage.removeItem("skill_jwt");
      this.$store.commit("setAuthUser", {});
      this.$store.commit("setAuthProfile", {});
      this.$store.commit("setIsLoggedIn", false);
      this.$router.push({ name: "Home" });
    },
  },
  beforeMount() {
    if (this.$store.state.authUser.tutorStatus) {
      this.tabs[0].route = "Tutor";
      this.tabs[0].link = "/tutor";
    }
  },
};
</script>

<style></style>
