<template>
  <section class="bg-gray-100 w-full px-6 py-2 h-full overflow-y-auto">
    <div class="px-2 py-5 mt-8 lg:px-8 mx-0">
      <div class="flex justify-between lg:pr-5 pr-1">
        <h1 class="text-xl font-medium text-skillblack lg:px-6">Referral</h1>
        <button
          class="cursor-pointer bg-skilldeepyelow py-1 px-3 rounded-md text-sm font-semibold shadow-md"
        >
          <download-csv :data="referralUser"> Download CSV </download-csv>
        </button>
      </div>

      <!--  -->
      <div class="w-full lg:px-4 mt-8">
        <div class="shadow-lg rounded bg-white border pb-4">
          <div class="py-3 flex justify-between mx-4">
            <p class="font-semibold text-lg text-gray-800">
              List of Referral ({{ referralUser.length }})
            </p>
          </div>

          <div class="block w-full overflow-x-auto px-4">
            <!--  -->
            <vs-table>
              <template #header>
                <vs-input v-model="search" border placeholder="Search" />
              </template>
              <template #thead>
                <vs-tr>
                  <vs-th
                    sort
                    @click="referralUser = $vs.sortData($event, referralUser, 'fullName')"
                  >
                    Name
                  </vs-th>
                  <vs-th
                    sort
                    @click="referralUser = $vs.sortData($event, referralUser, 'mobileNo')"
                  >
                    Phone Number
                  </vs-th>
                  <vs-th
                    sort
                    @click="
                      referralUser = $vs.sortData($event, referralUser, 'totalReferals')
                    "
                  >
                    Total Referal
                  </vs-th>
                  <vs-th
                    sort
                    @click="
                      referralUser = $vs.sortData($event, referralUser, 'activeReferals')
                    "
                  >
                    Active Referal
                  </vs-th>
                  <vs-th
                    sort
                    @click="
                      referralUser = $vs.sortData(
                        $event,
                        referralUser,
                        'referalWithClass'
                      )
                    "
                  >
                    Referal with class
                  </vs-th>
                </vs-tr>
              </template>
              <template #tbody>
                <vs-tr
                  :key="index"
                  v-for="(tr, index) in $vs.getPage(
                    $vs.getSearch(referralUser, search),
                    page,
                    max
                  )"
                  :data="tr"
                >
                  <vs-td>
                    {{ tr.fullName }}
                  </vs-td>
                  <vs-td>
                    {{ tr.mobileNo }}
                  </vs-td>
                  <vs-td>
                    {{ tr.totalReferals }}
                  </vs-td>
                  <vs-td>
                    {{ tr.activeReferals }}
                  </vs-td>
                  <vs-td>
                    {{ tr.referalWithClass }}
                  </vs-td>
                </vs-tr>
              </template>
              <template #footer>
                <vs-pagination
                  v-model="page"
                  :length="$vs.getLength($vs.getSearch(referralUser, search), max)"
                />
              </template>
            </vs-table>
            <!--  -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from ".././plugin/axios";
export default {
  components: {},
  data() {
    return {
      loading: false,
      dropdown: false,
      ready: false,
      message: "",
      search: "",
      page: 1,
      max: 20,
      user: [],
      referralUser: [],
      count1: 0,
      count2: 0,
    };
  },

  methods: {
    activeReferal() {
      for (let i = 0; i < this.user.length; i++) {
        if (this.user[i].referralList.length > 0) {
          this.count1 = 0;
          this.count2 = 0;
          for (let k = 0; k < this.user[i].referralList.length; k++) {
            if (this.user[i].referralList[k].groupClasses.length > 0) {
              this.count2 += 1;
            }
            if (this.user[i].referralList[k].isValidated == true) {
              this.count1 += 1;
            }
          }
          this.referralUser.push({
            fullName: this.user[i].fullName,
            mobileNo: this.user[i].mobileNo,
            totalReferals: this.user[i].referralList.length,
            activeReferals: this.count1,
            referalWithClass: this.count2,
          });
          //console.log(this.count1);
        }
      }
      this.referralUser.shift();
    },
  },
  async mounted() {
    this.referralUser = [{ fullName: "Loading..." }];

    if (this.$store.state.usersList.length === 0) {
      let url = "user/all";
      let token = this.$store.state.authUser.token;

      await axios
        .get(url, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          let data = response.data.data.users;
          this.$store.commit("setUsersList", data);
          this.user = this.$store.state.usersList;
          this.ready = true;
          this.activeReferal();
          // console.log(data.users);
        })
        .catch(() => {});
    } else {
      this.user = this.$store.state.usersList;
      this.ready = true;
      this.activeReferal();
    }
  },
};
</script>

<style></style>
