<template>
  <div class="bg-white w-full px-6 py-2 h-full overflow-y-auto pb-24">
    <div
      class="lg:block hidden -ml-6 min-h-screen h-screen border-l border-slighter fixed"
    ></div>

    <div class="flex items-center justify-center">
      <img
        src="../assets/404Illustration.png"
        alt="Illustration"
        class="object-scale-down h-96"
      />
    </div>
    <p class="text-3xl text-center md:pt-4">Be like say you don miss road o.</p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
