<template>
  <section class="bg-gray-100 w-full px-6 py-2 h-full overflow-y-auto">
    <div class="px-2 py-5 mt-8 lg:px-8 mx-0">
      <div class="">
        <h1 class="text-xl font-medium text-skillblack lg:px-6">Tutor Request</h1>
      </div>

      <!--  -->
      <div class="w-full lg:px-4 mt-8">
        <div class="shadow-lg rounded bg-white border pb-4">
          <div class="py-3 flex justify-between mx-4">
            <h3 class="font-semibold text-lg text-gray-800">Tutor Request List</h3>
          </div>
          <div class="block w-full overflow-x-auto">
            <table class="w-full">
              <thead>
                <tr class="bg-gray-100 border-gray-200 text-gray-600">
                  <th
                    class="py-3 px-4 border text-xs uppercase border-l-0 border-r-0 font-semibold text-left"
                  >
                    Course Name
                  </th>
                  <th
                    class="px-4 border text-xs uppercase border-l-0 border-r-0 font-semibold text-left"
                  >
                    Tutor Name
                  </th>
                  <th
                    class="px-4 border text-xs uppercase border-l-0 border-r-0 font-semibold text-left"
                  >
                    Status
                  </th>
                  <th
                    class="px-4 border text-xs uppercase border-l-0 border-r-0 font-semibold text-left"
                  >
                    Requested Date
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(list, index) in tutorRequestList"
                  :key="list.index"
                  class="border-t cursor-pointer hover:bg-slightyellow"
                  @click="OpenTutorDetailsDailog(index)"
                >
                  <td class="py-2 pl-4">
                    <p>
                      {{ list.course.name }}
                    </p>
                  </td>
                  <td class="py-2 pl-4">
                    <p v-if="list.tutor.fullName">
                      {{ list.tutor.fullName }}
                    </p>
                  </td>
                  <td class="py-2 pl-4">
                    <p v-if="list.applicationStatus">
                      {{ list.applicationStatus }}
                    </p>
                  </td>
                  <td v-if="list.createdDate" class="py-2 pl-4">
                    {{ new Date(list.createdDate).toDateString() }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- Update Tutor Details  -->
      <vs-dialog width="550px" v-model="tutorDetailsDailog">
        <div class="con-content mt-3">
          <p class="py-3 font-semibold">Tutor Course Detials</p>
          <div v-if="tutorListUnit !== null">
            <p>
              Course Name: <b> {{ tutorListUnit.course.name }} </b>
            </p>
            <p>
              Application Status: <b> {{ tutorListUnit.applicationStatus }} </b>
            </p>
            <p>
              Tutor Name: <b> {{ tutorListUnit.tutor.fullName }} </b>
            </p>
            <p>
              Tutor Number: <b> {{ tutorListUnit.tutor.mobileNo }} </b>
            </p>
            <p>
              Tutor Email: <b> {{ tutorListUnit.tutor.email }} </b>
            </p>
            <p class="font-semibold">Tutor Skills</p>
            <div class="ml-5">
              <ul class="list-disc">
                <li v-for="skill in tutorListUnit.tutor.tutorSkills" :key="skill.index">
                  {{ skill }}
                </li>
              </ul>
            </div>
            <p class="font-semibold">Tutor Portfolio</p>
            <div class="ml-5">
              <ul class="list-disc">
                <li
                  v-for="portfolio in tutorListUnit.tutor.tutorPortfolio"
                  :key="portfolio.index"
                >
                  <a :href="portfolio" target="_blank" class="text-blue-700">{{
                    portfolio
                  }}</a>
                </li>
              </ul>
            </div>
            <!--  -->
            <div class="pt-4 font-semibold">
              <hr />
              <p class="py-2">Update Appliation Status to</p>
              <vs-select block placeholder="Select" v-model="updateTutorApplication">
                <vs-option label="Application Submited" value="Application Submited">
                  Application Submited
                </vs-option>
                <vs-option label="Declined" value="Declined"> Declined </vs-option>
                <vs-option label="Processing" value="Processing"> Processing </vs-option>

                <vs-option label="Approved" value="Approved"> Approved </vs-option>
              </vs-select>
            </div>
            <!--  -->
          </div>
        </div>

        <template #footer>
          <div class="flex justify-center">
            <vs-button @click="UpdateAppStaus" :loading="loading" style="color: black">
              <p class="font-semibold">Update Status</p>
            </vs-button>
          </div>
        </template>
      </vs-dialog>
      <!--  -->
    </div>
  </section>
</template>

<script>
import axios from ".././plugin/axios";
export default {
  components: {},
  data() {
    return {
      tutorDetailsDailog: false,
      loading: false,
      message: "",
      updateTutorApplication: "",
      tutorRequestList: [],
      tutorListUnit: null,
      notificationData: {},
    };
  },
  methods: {
    async tutorReqList() {
      this.tutorRequestList = [{ course: { name: "Loading data" }, tutor: {} }];
      let url = "tutorscourse/all";
      let token = this.$store.state.authUser.token;

      await axios
        .get(url, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          // console.log(response);
          let data = response.data.data;
          this.tutorRequestList = data.courses;
        })
        .catch(() => {});
    },
    OpenTutorDetailsDailog(index) {
      this.tutorDetailsDailog = true;
      this.tutorListUnit = this.tutorRequestList[index];
    },
    async UpdateAppStaus() {
      //
      this.notificationData = {
        userid: this.tutorListUnit.tutor.id,
        message: `Your application for ${this.tutorListUnit.course.name} has been updated to Status - ${this.updateTutorApplication}`,
        url: "https://app.skillng.com/tutor",
        imgUrl:
          "https://res.cloudinary.com/skillng/image/upload/v1626132590/skillng-app/t2fpd4ddbr98vz1rwqc2.png",
      };
      //

      this.loading = true;
      let url = "tutorscourse/update/status";
      let token = this.$store.state.authUser.token;
      let requestData = {
        tutorcourseid: this.tutorListUnit.id,
        applicationStatus: this.updateTutorApplication,
      };

      await axios
        .post(url, requestData, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          console.log(response);
          this.loading = false;
          this.tutorDetailsDailog = false;
          this.tutorReqList();
          this.notification();
        })
        .catch((error) => {
          this.loading = false;
          this.tutorDetailsDailog = false;

          this.$swal.fire({
            position: "top",
            icon: "error",
            title: `${error.response.data.message}`,
            showConfirmButton: false,
            timer: 5000,
            toast: true,
          });
        });
    },

    async notification() {
      let url = "notifications/create";
      let token = this.$store.state.authUser.token;
      await axios
        .post(url, this.notificationData, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          console.log(response);
        })
        .catch(() => {});
    },

    sucessAlart() {
      this.$swal.fire({
        position: "top-end",
        icon: "success",
        title: this.message,
        showConfirmButton: false,
        timer: 3000,
        toast: true,
      });
    },
    errorAlart() {
      this.$swal.fire({
        position: "top-end",
        icon: "error",
        title: this.message,
        showConfirmButton: false,
        timer: 6000,
        toast: true,
      });
    },
  },
  async mounted() {
    this.tutorReqList();
  },
};
</script>

<style></style>
