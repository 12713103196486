<template>
  <section class="w-full xl:px-5 bg-white">
    <div class="px-6 pt-9 pb-3 sticky top-0 bg-white z-50">
      <button>
        <img src="../../../src/assets/logo.png" alt="Logo" class="h-6 lg:h-10 w-auto" />
      </button>
    </div>
    <!--  -->
    <div class="flex flex-col justify-between">
      <div class="mt-5">
        <router-link v-for="tab in tabs" :key="tab.title" :to="{ name: tab.route }">
          <button
            class="flex items-center mb-1 py-3 px-4 hover:bg-skilldeepyelow rounded-full mr-auto w-full"
            :class="
              id === tab.id
                ? 'text-skillwhite rounded-full bg-skilldeepyelow font-medium'
                : 'text-skillblack font-regular'
            "
            @click="id = tab.id"
          >
            <i
              :class="[id === tab.id ? 'font-semibold ' : '', tab.icon]"
              class="text-lg mr-4"
            ></i>
            <p class="hidden lg:block">{{ tab.title }}</p>
          </button>
        </router-link>
        <button
          @click="logout"
          class="flex items-center mb-1 py-3 px-4 hover:bg-skilldeepyelow rounded-full mr-auto w-full"
        >
          <i class="fad fa-sign-out text-lg mr-4"></i>
          <p class="hidden lg:block">Sign Out</p>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      tabs: [
        {
          icon: "far fa-users",
          title: "Users",
          id: "users",
          route: "AdminDashboard",
        },
        {
          icon: "far fa-graduation-cap",
          title: "School",
          id: "school",
          route: "AdminSchools",
        },
        {
          icon: "far fa-chalkboard-teacher",
          title: "Group Class",
          id: "Gclasses",
          route: "AdminGroupClass",
        },
        {
          icon: "far fa-chalkboard-teacher",
          title: "Private Class",
          id: "Pclasses",
          route: "AdminPrivateClass",
        },
        {
          icon: "fal fa-list-ul",
          title: "Tutor Request List",
          id: "tutorRequest",
          route: "AdminTutorRequestList",
        },
        {
          icon: "fal fa-list-ul",
          title: "Referral List",
          id: "referralList",
          route: "AdminReferralList",
        },
        {
          icon: "fal fa-gift",
          title: "Coupon",
          id: "coupon",
          route: "AdminCoupon",
        },
        {
          icon: "fal fa-comment-alt-lines",
          title: "SMS",
          id: "sms",
          route: "AdminSMS",
        },
      ],
      id: "dashboard",
    };
  },
  methods: {
    logout() {
      localStorage.removeItem("skill_user");
      localStorage.removeItem("skill_jwt");
      this.$store.commit("setAuthUser", {});
      this.$store.commit("setIsAdminLoggedIn", false);
      this.$router.push({ name: "AdminSignin" });
    },
  },
};
</script>

<style></style>
