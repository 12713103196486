import Vue from 'vue'
import App from './App.vue'
import "./assets/style.css"
import router from './router'
import store from './store'
import './registerServiceWorker'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueSplide from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import Vuesax4 from 'vuesax4'
import 'vuesax4/dist/vuesax.css' 
import x5GMaps from 'x5-gmaps'
import VueChatScroll from 'vue-chat-scroll';
import VueMoment from 'vue-moment'
import OtpInput from "@bachdgvn/vue-otp-input";
import InputTag from 'vue-input-tag'
import VueGtag from "vue-gtag"
import JsonCSV from 'vue-json-csv'
import VueCountryCode from "vue-country-code-select";
import VueMeta from 'vue-meta'
import VueAnalytics from 'vue-ua'
import VueSocialSharing from 'vue-social-sharing'





Vue.component('downloadCsv', JsonCSV);
Vue.component('input-tag', InputTag);
Vue.component("v-otp-input", OtpInput);

Vue.use(VueCountryCode);
Vue.use(VueMoment)
Vue.use(VueChatScroll);
Vue.use(x5GMaps, { key: 'AIzaSyCp6QRFXvfzKuAwFM9wACvQhi1Hh3xdmBE', libraries: ['places'] });
Vue.use( VueSplide );
Vue.use(VueSweetalert2);
Vue.use(Vuesax4, {
  colors: {
    primary:'#FFCC29',
    success:'rgb(23, 201, 100)',
    danger:'rgb(242, 19, 93)',
    warning:'rgb(255, 130, 0)',
    dark:'rgb(36, 33, 69)'
  }
})
Vue.use(VueGtag, {
  config: { id: "AW-855908999" }
  // config: { id: "G-CS8CQWNGEC" } // old ID on Ibrahim account
});

Vue.use(VueMeta);

Vue.use(VueAnalytics, {
  appName: 'SKillNG', 
  appVersion: '1.1.10', 
  trackingId: 'UA-159055643-2', 
  debug: false, 
  vueRouter: router, 
  trackPage: false, 
})

Vue.use(VueSocialSharing);

Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
