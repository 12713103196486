<template>
  <section class="flex">
    <section
      v-if="dropdown"
      @click="dropdown = false"
      class="z-40 h-screen w-screen fixed top-0 overflow-hidden"
    ></section>
    <div
      class="lg:w-3/5 w-full border border-slighter rounded-t-2xl shadow-md lg:pb-10 pb-16 relative min-h-screen h-full"
    >
      <div class="px-6 py-5 flex items-center justify-between">
        <h1 class="text-xl font-medium text-skillblack">Profile</h1>
        <div
          @click="dropdown = !dropdown"
          class="z-20 flex justify-center items-center rounded-full h-8 w-8 hover:bg-yellow-100"
        >
          <i class="far fa-ellipsis-v text-gray-500 text-center text-xl"></i>
        </div>
      </div>

      <!-- dropdown -->
      <div>
        <div @click="dropdown = true" class="fixed pin z-10"></div>

        <div
          v-if="dropdown"
          class="z-50 absolute top-14 right-3 w-48 rounded-lg shadow-md border border-clightest bg-white mb-20 overflow-hidden"
        >
          <router-link v-for="tab in tabs" :key="tab.title" :to="tab.route">
            <button
              @click="dropdown = false"
              class="w-full text-left hover:bg-slightyellow border-b border-slighter p-3 text-sm focus:outline-none"
            >
              <div class="flex items-center">
                <i :class="tab.icon"></i>
                <p class="ml-2">{{ tab.title }}</p>
              </div>
            </button>
          </router-link>
        </div>
      </div>

      <!-- end dropdown -->

      <div class="w-full mb-4">
        <div class="relative block overflow-hidden">
          <div class="w-full h-full"></div>
          <div class="h-96 z-10">
            <img
              v-if="this.$store.state.authProfile.profilePicUrl"
              class="inset-0 h-full w-full object-cover bg-gray-200"
              :src="
                'https://res.cloudinary.com/skillng/image/fetch/' +
                this.$store.state.authProfile.profilePicUrl
              "
            />
            <img
              v-else
              class="inset-0 h-full w-full object-cover bg-gray-200"
              src="../assets/ProfileAvater.jpg"
            />
          </div>
          <div class="absolute w-full bottom-0 z-0">
            <div class="h-24 bg-vtrans opacity-80"></div>
          </div>
          <div class="pl-3 absolute left-3 bottom-1 text-white">
            <p class="text-xl font-semibold">{{ profile.fullName }}</p>
            <p class="font-regular mb-2">{{ profile.occupation }}</p>
          </div>
        </div>
      </div>

      <!-- course skeleton  loader -->
      <div v-if="loading">
        <div
          v-for="skeleton in 2"
          :key="skeleton.index"
          class="mx-10 mb-2 h-8 bg-gray-100 rounded-xl animate-pulse skeleton-box"
        ></div>
      </div>
      <!--  -->

      <div v-else class="mb-8">
        <div>
          <div class="p-2 pl-3 border-b border-slightest w-full pr-8">
            <div class="mx-4 mt-2">
              <p class="text-base font-bold text-skillblack">{{ profile.email }}</p>
              <p class="text-sm text-gray-800">Email</p>
            </div>
          </div>
        </div>
        <div v-if="profile.mobileNo">
          <div class="p-2 pl-3 border-b border-slightest w-full pr-8">
            <div class="mx-4 mt-2">
              <p class="text-base font-bold text-skillblack">{{ profile.mobileNo }}</p>
              <p class="text-sm text-gray-800">Phone Number</p>
            </div>
          </div>
        </div>
        <div v-if="profile.location">
          <div class="p-2 pl-3 border-b border-slightest w-full pr-8">
            <div class="mx-4 mt-2">
              <p class="text-base font-bold text-skillblack">{{ profile.location }}</p>
              <p class="text-sm text-gray-800">Location</p>
            </div>
          </div>
        </div>
        <div v-if="profile.gender">
          <div class="p-2 pl-3 border-b border-slightest w-full pr-8">
            <div class="mx-4 mt-2">
              <p class="text-base font-bold text-skillblack">{{ profile.gender }}</p>
              <p class="text-sm text-gray-800">Gender</p>
            </div>
          </div>
        </div>
        <div v-if="profile.occupation">
          <div class="p-2 pl-3 border-b border-slightest w-full pr-8">
            <div class="mx-4 mt-2">
              <p class="text-base font-bold text-skillblack">{{ profile.occupation }}</p>
              <p class="text-sm text-gray-800">Occupation</p>
            </div>
          </div>
        </div>
        <div v-if="profile.jobStatus">
          <div class="p-2 pl-3 border-b border-slightest w-full pr-8">
            <div class="mx-4 mt-2">
              <p class="text-base font-bold text-skillblack">{{ profile.jobStatus }}</p>
              <p class="text-sm text-gray-800">Job Status</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- side bar-->
    <ClassSuggestions class="hidden lg:block" />
  </section>
</template>

<script>
import ClassSuggestions from "../components/ClassSuggestions";
export default {
  components: { ClassSuggestions },
  data() {
    return {
      profile: {},
      dropdown: false,
      referral: false,
      interval: null,
      loading: false,
      tabs: [
        {
          icon: "far fa-user",
          title: "Edit Profile",
          route: "/settings/editprofile",
        },
        {
          icon: "fas fa-chalkboard-teacher",
          title: "My Courses",
          route: "/classes",
        },
      ],
    };
  },

  async beforeMount() {
    this.loading = true;
    this.interval = setInterval(() => {
      if (this.$store.state.authProfile.email != undefined) {
        this.profile = this.$store.state.authProfile;
        this.loading = false;
        clearInterval(this.interval);
      }
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style></style>
