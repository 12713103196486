<template>
  <div class="overflow-y-auto mb-24">
    <div class="px-6 pt-4 pb-3 border-b border-slighter flex items-center bg-white">
      <button @click="$router.go(-1)" class="block md:hidden">
        <i
          class="fal fa-long-arrow-left text-skilldeepyelow text-center text-xl pr-5"
        ></i>
      </button>
      <h1 class="text-base font-medium text-skillblack">Account</h1>
    </div>

    <div>
      <div class="md:px-0 px-3 md:mt-0 mt-4">
        <router-link
          to="/settings/become-tutor"
          v-if="!this.$store.state.isTutor && !this.$store.state.authUser.tutorStatus"
        >
          <button
            class="hover:bg-slightyellow focus:outline-none py-4 border md:border-b md:border-slightest border-gray-100 w-full pr-8 md:shadow-none shadow-md md:mb-0 mb-3 md:rounded-none rounded-md"
          >
            <div class="flex items-center justify-between ml-4 my-2">
              <div>
                <p class="text-left text-base font-normal text-skillblack">
                  Become Tutor
                </p>
                <p class="text-left text-xs font-normal text-skillblack">
                  Become a tutor by verifying your skills and identify
                </p>
              </div>
              <i class="far fa-angle-right"></i>
            </div>
          </button>
        </router-link>
        <router-link to="/settings/update-password">
          <button
            class="hover:bg-slightyellow focus:outline-none py-4 border md:border-b md:border-slightest border-gray-100 w-full pr-8 md:shadow-none shadow-md md:mb-0 mb-3 md:rounded-none rounded-md"
          >
            <div class="flex items-center justify-between ml-4 my-2">
              <div>
                <p class="text-left text-base font-normal text-skillblack">
                  Update Password
                </p>
                <p class="text-left text-xs font-normal text-skillblack">
                  Update your password
                </p>
              </div>
              <i class="far fa-angle-right"></i>
            </div>
          </button>
        </router-link>
        <!-- <router-link to="/settings/transaction">
          <button
            class="hover:bg-slightyellow focus:outline-none py-4 border md:border-b md:border-slightest border-gray-100 w-full pr-8 md:shadow-none shadow-md md:mb-0 mb-3 md:rounded-none rounded-md"
          >
            <div class="flex items-center justify-between ml-4 my-2">
              <div>
                <p class="text-left text-base font-normal text-skillblack">
                  Transactions
                </p>
                <p class="text-left text-xs font-normal text-skillblack">
                  View your transactions
                </p>
              </div>
              <i class="far fa-angle-right"></i>
            </div>
          </button>
        </router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabs: [
        {
          icon: "far fa-angle-right",
          title: "Become Tutor",
          about: "Become a tutor by verifying your skills and identify",
          route: "/settings/become-tutor",
        },
        {
          icon: "far fa-angle-right",
          title: "Update Password ",
          about: "Update your password",
          route: "/settings/update-password",
        },
        // {
        //   icon: "far fa-angle-right",
        //   title: "Transactions",
        //   about: "View your transactions",
        //   route: "/settings/transaction",
        // },
      ],
    };
  },
};
</script>

<style></style>
