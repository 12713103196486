<template>
  <div>
    <Nav v-if="!isLoggedIn" />
    <section class="bg-white h-full w-full flex justify-center items-center relative">
      <div
        class="lg:pb-10 pb-16 lg:w-3/5 w-full border-t border-slighter rounded-t-2xl relative min-h-screen h-full"
      >
        <div class="absolute top-5 left-3 z-20">
          <button @click="$router.go(-1)" class="">
            <i
              class="fal fa-long-arrow-left text-skilldeepyelow text-center text-xl pr-5"
            ></i>
          </button>
        </div>
        <div
          class="px-6 pb-5 pt-12 border-l border-r border-slighter rounded-t-2xl min-h-screen"
        >
          <div class="p-4">
            <p class="text-3xl pb-5">About Us</p>
            <p class="text-base">
              SkillNG is a skill development and talent management company that helps
              young people acquire global in-demand digital skills by leveraging on
              peer-to-peer learning networks and critical learning resources while
              connecting them to placement opportunities.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Nav from "../../components/navs/HomeTopNav";

export default {
  components: {
    Nav,
  },
  data() {
    return {};
  },

  methods: {},
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
  },
};
</script>

<style></style>
