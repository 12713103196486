<template>
  <section class="flex">
    <div
      class="lg:w-3/5 w-full border border-slighter rounded-t-2xl shadow-md lg:pb-10 pb-16 min-h-screen h-full"
    >
      <div class="px-6 py-5 flex items-center justify-between mb-5">
        <h1 class="text-xl font-medium text-skillblack">My Classes</h1>
      </div>

      <!-- tab -->
      <div class="-mb-11 -pb-2 border-b-2 border-gray-100"></div>
      <div class="mx-5 mb-4">
        <div class="px-2 py-1.5 mb-5 flex flex-wrap sm:justify-start justify-center">
          <button
            @click="activetab = 1"
            :class="activetab === 1 ? 'border-b-2 border-skilldeepyelow' : ''"
            class="text-lg text-center p-1 px-5"
          >
            Current
          </button>
          <button
            @click="getUserCertificate()"
            :class="activetab === 2 ? 'border-b-2 border-skilldeepyelow' : ''"
            class="text-lg text-center p-1 px-5"
          >
            Finished
          </button>
          <button
            hidden
            @click="activetab = 3"
            :class="activetab === 3 ? 'border-b-2 border-skilldeepyelow' : ''"
            class="text-lg text-center p-1 px-5"
          >
            Wishlist
          </button>
        </div>
        <div class="p-2">
          <!-- tab 1 -->
          <div v-if="activetab === 1">
            <!-- Empty class -->
            <div v-if="emptyGroupClass">
              <div class="m-5 p-2 text-center">
                <i class="fad fa-exclamation-circle text-yellow-400 text-4xl mb-2"></i>
                <p>
                  You are yet to register for a class. Click on schools to register for a
                  class.
                </p>
              </div>
            </div>
            <!--  -->
            <div v-else>
              <p class="py-2 font-normal text-sm text-gray-600">
                List of ongoing classes taken by you
              </p>
              <!--  -->
              <button
                @click="GroupActiveClassTab = !GroupActiveClassTab"
                class="shadow-md rounded-md border border-gray-100 hover:bg-slightyellow p-2 mt-5 w-full"
                :class="GroupActiveClassTab ? 'bg-skillyelow' : 'bg-white'"
              >
                <div class="flex items-center justify-between ml-4 my-2">
                  <div class="flex space-x-2">
                    <p class="text-base font-normal text-skillblack">Group Classes</p>
                    <span v-if="classes.groupClasses">
                      ({{ classes.groupClasses.length }})
                    </span>
                  </div>
                  <div v-if="classes.groupClasses">
                    <i v-if="!GroupActiveClassTab" class="px-4 far fa-angle-down"></i>
                    <i v-if="GroupActiveClassTab" class="px-4 fal fa-times"></i>
                  </div>
                </div>
              </button>

              <!--  -->
              <div v-if="GroupActiveClassTab">
                <!-- skeleton Loader -->
                <div v-if="skeletonLoader">
                  <div
                    v-for="loader in 2"
                    :key="loader.index"
                    class="shadow-md rounded-md border border-gray-100 bg-gray-100 my-5 h-24 animate-pulse skeleton-box"
                  ></div>
                </div>
                <!--  -->
                <div v-if="classes.groupClasses">
                  <div v-if="!skeletonLoader">
                    <div
                      v-for="(clas, index) in classes.groupClasses"
                      :key="clas.index"
                      class="shadow-md rounded-md border border-gray-100 bg-white hover:bg-slightyellow p-5 my-5"
                    >
                      <p class="text-sm">Jump back in!</p>
                      <p class="text-lg font-medium">{{ clas.classid.name }}</p>

                      <p v-if="clas.classid.startClass.status" class="mb-2 text-gray-600">
                        {{ parseFloat(percetageGroup(index)).toFixed(0) }}% completed
                      </p>
                      <p
                        v-if="!clas.classid.startClass.status"
                        class="mb-2 text-gray-600"
                      >
                        Class will commence on {{ clas.classid.dueDate }}
                      </p>
                      <p
                        v-if="!clas.classPaid"
                        class="mb-2 font-medium text-red-600 text-center italic"
                      >
                        You're currently auditing this class. This means you'll have
                        access to attend 25% of the class for free. However, you'll need
                        to pay for the class to access the remaining class in the Cohort
                        and get certified.
                      </p>
                      <div
                        v-if="clas.classid.startClass.status"
                        class="h-3 mb-3 relative rounded-full bg-gray-100 overflow-hidden"
                      >
                        <div
                          class="h-full absolute bg-green-500 rounded-full"
                          :style="{ width: percetageGroup(index) + '%' }"
                        ></div>
                      </div>
                      <div class="flex flex-wrap justify-between">
                        <button
                          @click="$router.push({ name: 'Messages' })"
                          class="py-1 px-3 lg:px-4 bg-skilldeepyelow rounded-lg focus:outline-none"
                        >
                          <p class="text-sm font-semibold">Go to Class</p>
                        </button>
                        <button
                          v-if="clas.classid.startClass.status"
                          @click="curriculumGroup(index)"
                          class="py-1 px-3 lg:px-4 bg-skilldeepyelow rounded-lg focus:outline-none"
                        >
                          <p class="text-sm font-semibold">View Module</p>
                        </button>

                        <button
                          v-if="!clas.classPaid"
                          @click="
                            $router.push({
                              name: 'GroupClass',
                              params: { gclassID: clas.classid._id },
                            })
                          "
                          class="py-1 px-3 lg:px-4 bg-skilldeepyelow rounded-lg focus:outline-none"
                        >
                          <p class="text-sm font-semibold">Make Payment</p>
                        </button>

                        <button
                          v-if="!clas.classPaid"
                          @click="leaveClass(clas.classid._id)"
                          :disabled="leaveClassSpin"
                          class="py-1 px-3 lg:px-4 bg-red-600 rounded-lg focus:outline-none"
                        >
                          <p class="text-sm font-semibold text-white">
                            Leave Class
                            <i
                              v-if="leaveClassSpin"
                              class="fad fa-circle-notch text-base animate-spin px-2"
                            ></i>
                          </p>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <!--  -->
              </div>
              <!--  -->
              <button
                @click="PrivateActiveClassTab = !PrivateActiveClassTab"
                class="shadow-md rounded-md border border-gray-100 hover:bg-slightyellow p-2 mt-5 w-full"
                :class="PrivateActiveClassTab ? 'bg-skillyelow' : 'bg-white'"
              >
                <div class="flex items-center justify-between ml-4 my-2">
                  <div class="flex space-x-2">
                    <p class="text-base font-normal text-skillblack">Private Classes</p>

                    <span v-if="classes.requests"> ({{ classes.requests.length }}) </span>
                  </div>
                  <div v-if="!classes.requests">
                    <i v-if="!PrivateActiveClassTab" class="px-4 far fa-angle-down"></i>
                    <i v-if="PrivateActiveClassTab" class="px-4 fal fa-times"></i>
                  </div>
                </div>
              </button>
              <!--  -->
              <div v-if="PrivateActiveClassTab">
                <!-- skeleton Loader -->
                <div v-if="skeletonLoader">
                  <div
                    v-for="loader in 2"
                    :key="loader.index"
                    class="shadow-md rounded-md border border-gray-100 bg-gray-100 my-5 h-24 animate-pulse skeleton-box"
                  ></div>
                </div>
                <!--  -->
                <div
                  v-for="(clas, index) in classes.requests"
                  :key="clas.index"
                  class="shadow-md rounded-md border border-gray-100 bg-white hover:bg-slightyellow p-5 my-5"
                >
                  <p v-if="clas.paymentStatus.status" class="text-sm">Jump back in!</p>

                  <p class="text-lg font-medium">{{ clas.class.name }}</p>

                  <p v-if="!clas.paymentStatus.status" class="mb-2 text-sm">
                    Request Date: {{ clas.createdDate.slice(0, 10) }}
                  </p>

                  <div v-if="clas.paymentStatus.status">
                    <p class="mb-2 text-gray-600">
                      {{ parseFloat(percetagePrivate(index)).toFixed(0) }}% completed
                    </p>
                    <div
                      class="h-3 mb-3 relative rounded-full bg-gray-100 overflow-hidden"
                    >
                      <div
                        class="h-full absolute bg-green-500 rounded-full"
                        :style="{ width: percetagePrivate(index) + '%' }"
                      ></div>
                    </div>
                  </div>

                  <div
                    v-if="!clas.selected"
                    class="py-1 px-3 lg:px-4 rounded-lg focus:outline-none"
                    style="background-color: #feecdc"
                  >
                    <p class="text-sm font-semibold px-3 py-1">
                      Status: Searching For Tutor
                    </p>
                  </div>
                  <!--  -->
                  <div
                    v-if="clas.selected && !clas.paymentStatus.status"
                    class="flex items-center justify-between rounded-lg"
                    style="background-color: #def7ec"
                  >
                    <div class="py-1 px-3 lg:px-4">
                      <p class="text-sm font-semibold px-3 py-1">Status: Tutor found</p>
                    </div>
                    <button
                      @click="paymentIndex = index"
                      class="mx-1.5 py-1 px-3 lg:px-4 bg-skilldeepyelow rounded-lg"
                    >
                      <p
                        v-if="walletbalance > 0"
                        @click="walletDailog = true"
                        class="text-sm font-semibold px-3 py-1 text-skillblack"
                      >
                        Make Payment ₦{{ clas.class.price.toLocaleString() }}
                      </p>
                      <paystack
                        v-else
                        :amount="clas.class.price * 100"
                        :email="authEmail"
                        :paystackkey="'pk_live_a87e4916f0e775175aa3770a34a9bf101a8030ca'"
                        :reference="reference"
                        :callback="processPayment"
                        :close="paystackDialogClose"
                      >
                        <p class="text-sm font-semibold">
                          Make Payment ₦{{ clas.class.price.toLocaleString() }}
                        </p>
                      </paystack>
                    </button>
                  </div>
                  <!--  -->
                  <div v-if="clas.completionConfirmation.status">
                    <p class="text-sm font-semibold">Class Completed</p>
                  </div>
                  <div v-else>
                    <div v-if="clas.paymentStatus.status" class="flex justify-between">
                      <button
                        @click="$router.push({ name: 'Messages' })"
                        class="py-1 px-3 lg:px-4 bg-skilldeepyelow rounded-lg focus:outline-none"
                      >
                        <p class="text-sm font-semibold">Go to Class</p>
                      </button>
                      <button
                        @click="curriculumPrivate(index)"
                        class="py-1 px-3 lg:px-4 bg-skilldeepyelow rounded-lg focus:outline-none"
                      >
                        <p class="text-sm font-semibold">View Module</p>
                      </button>
                      <button
                        v-if="clas.completion.status"
                        @click="completeClass(index)"
                        class="py-1 px-3 lg:px-4 bg-skilldeepyelow rounded-lg focus:outline-none"
                      >
                        <p v-if="!completeClassSpin" class="text-sm font-semibold">
                          Confirm Class Completed
                          <i
                            v-if="completeClassSpin"
                            class="fad fa-circle-notch text-base animate-spin px-2"
                          ></i>
                        </p>
                      </button>
                    </div>
                  </div>
                </div>
                <!--  -->
              </div>
            </div>
          </div>
          <!-- View Private Curriculum Dialog -->
          <div v-if="curriculum.length !== 0">
            <vs-dialog scroll width="650px" v-model="viewCurriculumPrivate">
              <div class="p-3">
                <p class="font-semibold pb-3">List of Modules</p>
                <div
                  v-for="(modu, index) in curriculum"
                  :key="modu.index"
                  class="flex items-start mb-2"
                >
                  <vs-checkbox
                    type="hidden"
                    onclick="return false"
                    warm
                    v-model="modu.completion"
                  >
                  </vs-checkbox>
                  <p class="pl-2">Module {{ index + 1 }}: {{ modu.name }}</p>
                </div>
              </div>
            </vs-dialog>
          </div>

          <!--  -->
          <!-- View Group Curriculum Dialog -->
          <div v-if="curriculum.length !== 0">
            <vs-dialog scroll width="650px" v-model="viewCurriculumGroup">
              <div class="p-3">
                <p class="font-semibold pb-3">List of Modules</p>
                <div
                  v-for="(modu, index) in curriculum"
                  :key="modu.index"
                  class="flex items-start mb-2"
                >
                  <vs-checkbox
                    type="hidden"
                    onclick="return false"
                    warm
                    v-model="modu.completion"
                  >
                  </vs-checkbox>
                  <p class="pl-2">Module {{ index + 1 }}: {{ modu.name }}</p>
                </div>
              </div>
            </vs-dialog>
          </div>

          <!-- tab 2 -->

          <div v-if="activetab === 2">
            <p class="py-2 font-normal text-sm text-gray-600">
              All your earned certifications
            </p>

            <!-- skeleton Loader -->
            <div v-if="certificateSkeletonLoader">
              <div
                v-for="loader in 2"
                :key="loader.index"
                class="shadow-md rounded-md border border-gray-100 bg-gray-100 my-5 h-24 animate-pulse skeleton-box"
              ></div>
            </div>

            <!-- Empty Certificate -->
            <div v-if="!certificateSkeletonLoader">
              <div v-if="certificates.length == 0" class="m-5 p-2 text-center">
                <i class="fad fa-exclamation-circle text-yellow-400 text-4xl mb-2"></i>
                <p>
                  You are yet to complete a class. There's no certificate for you at the
                  moment. Complete a class and check back later.
                </p>
              </div>
            </div>
            <!--  -->

            <div>
              <div
                v-for="(certificate, index) in certificates"
                :key="certificate.index"
                class="bg-slightyellow shadow-md border border-gray-100 rounded-lg mb-5 p-3"
              >
                <!--  -->
                <div class="mb-3">
                  <div class="text-center">
                    <i class="far fa-diploma text-xl"></i>
                    <p class="py-2 font-bold text-base flex-wrap">
                      {{ certificate.classid.name }}
                    </p>
                  </div>
                  <div class="flex flex-wrap justify-center items-center">
                    <button
                      @click="downloadCert(index)"
                      class="py-1 mb-2 px-4 mx-2 lg:mx-5 bg-skillyelow rounded-md focus:outline-none"
                    >
                      <p class="text-sm font-semibold">
                        Download
                        <span v-if="certificateLoader">
                          <i class="animate-spin fad fa-circle-notch"></i>
                        </span>
                      </p>
                    </button>
                    <!-- <button
                      class="py-1 mb-2 px-4 mx-2 lg:mx-5 bg-skillyelow rounded-md focus:outline-none"
                    >
                      <p class="text-sm font-semibold">Share</p>
                    </button> -->
                    <button
                      @click="reviewClass(index)"
                      class="py-1 mb-2 px-4 mx-2 lg:mx-5 bg-skillyelow rounded-md focus:outline-none"
                    >
                      <p class="text-sm font-semibold">Review</p>
                    </button>

                    <button
                      v-if="certificate.type == 'Freemium'"
                      @click="paymentIndex = index"
                      class="py-1 mb-2 px-4 mx-2 lg:mx-5 bg-skillyelow rounded-md focus:outline-none"
                    >
                      <paystack
                        :amount="1000 * 100"
                        :email="authEmail"
                        :paystackkey="'pk_live_a87e4916f0e775175aa3770a34a9bf101a8030ca'"
                        :reference="reference"
                        :callback="premiumCertificate"
                        :close="paystackDialogClose"
                      >
                        <p class="text-sm font-semibold">Request Premium Certificate</p>
                      </paystack>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Review Dialog -->
          <vs-dialog width="650px" v-model="ReviewDialogOpen">
            <template #header>
              <h4 class="not-margin">Review by {{ $store.state.authUser.fullName }}</h4>
            </template>
            <p class="mb-1">Reviews are public and editable.</p>

            <div class="">
              <textarea
                v-model="addReview.comment"
                class="w-full border rounded-md mb-2 p-3"
                rows="7"
                placeholder="Tell others what you think about this class. Would you recommend it for them? "
              ></textarea>
              <div class="flex items-center">
                <star-rating :show-rating="false" v-model="addReview.star"></star-rating>
                <p class="ml-3 text-sm">Rating required</p>
              </div>
            </div>

            <template #footer>
              <div class="footer-dialog">
                <vs-button
                  :loading="loading"
                  :disabled="
                    addReview.comment !== '' && addReview.star !== 0 ? false : true
                  "
                  block
                  class="mb-2 mt-1 disabled"
                  style="color: black"
                  @click="addNewReview"
                >
                  Submit
                </vs-button>
              </div>
            </template>
          </vs-dialog>

          <!--wallet Debit Dailog  -->
          <vs-dialog prevent-close scroll width="350px" v-model="walletDailog">
            <div class="flex justify-center items-center p-3 overflow-hidden">
              <div>
                <div v-if="insuficientBalance" class="m-5 p-2 text-center">
                  <i class="fad fa-exclamation-circle text-yellow-400 text-4xl mb-2"></i>
                  <p>Insufficient Balance, kindly top-up your wallet.</p>
                  <router-link
                    to="/settings/my-balance"
                    class="flex justify-center items-center"
                  >
                    <vs-button border color="#FFCC29" class="">
                      <p class="text-sm font-semibold px-3 py-1 text-skillblack">
                        Balance Top-up
                      </p>
                    </vs-button>
                  </router-link>
                </div>

                <div v-else class="flex justify-center items-center">
                  <vs-button @click="payFromBalance" color="#FFCC29" class="">
                    <p class="text-sm font-semibold px-3 py-1 text-skillblack">
                      Pay from Balance (Your Balance ₦{{
                        walletbalance ? walletbalance.toLocaleString() : 0
                      }})
                    </p>
                  </vs-button>
                </div>

                <!--  -->
                <div class="flex justify-center items-center">
                  <vs-button color="#FFCC29" class="">
                    <paystack
                      v-if="classes.requests.length > 0"
                      :amount="classes.requests[paymentIndex].class.price * 100"
                      :email="authEmail"
                      :paystackkey="'pk_live_a87e4916f0e775175aa3770a34a9bf101a8030ca'"
                      :reference="reference"
                      :callback="processPayment"
                      :close="paystackDialogClose"
                    >
                      <p class="text-sm font-semibold px-3 py-1 text-skillblack">
                        Pay with PayStack (₦{{
                          classes.requests[paymentIndex].class.price.toLocaleString()
                        }})
                      </p>
                    </paystack>
                  </vs-button>
                </div>
              </div>
            </div>
          </vs-dialog>
          <!--  -->

          <!-- tab 3 (Wish classes) -->
          <!-- <div v-if="activetab === 3">
            <p class="py-2 font-normal text-sm text-gray-600">List of Wish classes</p>
            <router-link :to="{ name: 'GroupClass' }">
              <div
                class="relative block rounded-lg overflow-hidden shadow-lg border-2 border-gray-100"
              >
                <div
                  class="w-full h-full bg-black hover:opacity-10 opacity-0 absolute z-10"
                ></div>
                <div class="h-40">
                  <img
                    class="absolute inset-0 h-full w-full object-cover z-0"
                    src="../assets/ProfileAvater.jpg"
                    alt="Image"
                  />
                </div>

                <div class="h-32 bg-vtrans opacity-90"></div>

                <div class="absolute left-3 bottom-1 text-white z-10">
                  <p class="text-xl font-semibold">UI/UX Design Class</p>
                  <p class="font-semibold mb-2">N20,000</p>
                </div>
                <div class="absolute right-3 bottom-1 grid justify-items-end z-10">
                  <div
                    class="text-center rounded-lg border bg-skillyelow text-skillblack w-12 h-15 py-2"
                  >
                    <p class="font-bold">15</p>
                    <p class="font-medium">Mar</p>
                  </div>
                  <div>
                    <p class="text-white font-semibold text-sm mb-2">Deadline</p>
                  </div>
                </div>
              </div>
            </router-link>
          </div> -->
        </div>
      </div>
      <!-- tab end -->
    </div>
    <!-- side bar-->
    <ClassSuggestions class="hidden lg:block" />
  </section>
</template>

<script>
import ClassSuggestions from "../components/ClassSuggestions";
import axios from "../plugin/axios";
import paystack from "vue-paystack";
import { fireDB, fireBase } from "../ultilities/firebase";
import projectskill1000 from "../ultilities/certificateTemplate/projectSkill1000";
import skillngPremium from "../ultilities/certificateTemplate/skillngPremium";
import StarRating from "vue-star-rating";

export default {
  components: { ClassSuggestions, StarRating, paystack },
  data() {
    return {
      activetab: 1,
      paymentIndex: 0,
      GroupActiveClassTab: false,
      PrivateActiveClassTab: false,
      skeletonLoader: false,
      viewCurriculumPrivate: false,
      viewCurriculumGroup: false,
      completeClassSpin: false,
      leaveClassSpin: false,
      certificateSkeletonLoader: false,
      certificateLoader: false,
      ReviewDialogOpen: false,
      toBeDeleted: [],
      interval: null,
      curriculum: [],
      classes: { groupClasses: [], requests: [] },
      notificationData: {},
      notificationDataTutor: {},
      certificates: [],
      reviewIndex: "",
      addReview: {},
      loading: false,
      emptyGroupClass: false,
      walletDailog: false,
      insuficientBalance: false,
      payFromBalanceCheck: false,
    };
  },
  methods: {
    async loadClass() {
      this.skeletonLoader = true;
      let url = `user/classes/${this.$store.state.authUser.id}`;
      let token = this.$store.state.authUser.token;
      await axios
        .get(url, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          if (
            response.data.data.groupClasses.length == 0 &&
            response.data.data.requests.length == 0
          ) {
            this.emptyGroupClass = true;
          }
          const resData = response.data.data;
          // let groupclass = [];

          // for (let i = 0; i < resData.groupClasses.length; i++) {
          //   if (resData.groupClasses[i].classid !== null) {
          //     groupclass.push(resData.groupClasses[i]);
          //   }
          // }
          // resData.groupClasses = groupclass;
          this.classes = resData;
        })
        .catch(() => {});
      this.skeletonLoader = false;
    },
    async processPayment() {
      const loading = this.$vs.loading();
      //
      this.notificationData = {
        userid: this.$store.state.authUser.id,
        message: `Your payment for ${
          this.classes.requests[this.paymentIndex].class.name
        } is successful, a private chat has been created for you and your tutor.`,
        url: "https://app.skillng.com/classes",
        imgUrl:
          "https://res.cloudinary.com/skillng/image/upload/v1626132590/skillng-app/t2fpd4ddbr98vz1rwqc2.png",
      };
      //
      //
      this.notificationDataTutor = {
        userid: this.classes.requests[this.paymentIndex].tutor,
        message: `Your learner for ${
          this.classes.requests[this.paymentIndex].class.name
        } has paid for the class, a private chat has been created for you and the learner to start interacting.`,
        url: "https://app.skillng.com/tutor",
        imgUrl:
          "https://res.cloudinary.com/skillng/image/upload/v1626132590/skillng-app/t2fpd4ddbr98vz1rwqc2.png",
      };
      //
      let url = "/classReq/madepayment";
      let token = this.$store.state.authUser.token;
      let requestData = { classrequestid: this.classes.requests[this.paymentIndex].id };
      await axios
        .post(url, requestData, {
          headers: {
            "x-access-token": token,
          },
        })
        .then(() => {
          this.loadClass();
          loading.close();
          this.notification();
          this.transaction();
          setTimeout(() => {
            this.notificationTutor();
          }, 500);
        })
        .catch(() => {});
      //chat Tutor
      let userName = this.$store.state.authUser.fullName;
      let tutorId = this.classes.requests[this.paymentIndex].tutor;
      let chatId =
        `${this.classes.requests[this.paymentIndex].id}` +
        `${this.classes.requests[this.paymentIndex].tutor}` +
        `${this.$store.state.authUser.id}`;
      let chatName =
        this.classes.requests[this.paymentIndex].class.name + " with " + userName;
      let chatImage = this.classes.requests[this.paymentIndex].class.classImage;
      let chatDescription = this.classes.requests[this.paymentIndex].class.description;
      let newTutorChatUser = fireDB.ref("users/" + tutorId).push();
      newTutorChatUser.set({
        chatId: chatId,
        chatName: chatName,
        chatImage: chatImage,
        chatDescription: chatDescription,
        createdAt: fireBase.database.ServerValue.TIMESTAMP,
        lastMessage: "Tutor added to this chat",
        lastMessageTimeStamp: fireBase.database.ServerValue.TIMESTAMP,
        unreadMessage: true,
      });
      let newTutorChat = fireDB.ref("chats/" + chatId).push();
      newTutorChat.set({
        userId: "",
        message: "Tutor added to this chat",
        createdAt: fireBase.database.ServerValue.TIMESTAMP,
      });
      //chat Student
      let userId = this.$store.state.authUser.id;
      let newChatUser = fireDB.ref("users/" + userId).push();
      newChatUser.set({
        chatId: chatId,
        chatName: chatName,
        chatImage: chatImage,
        chatDescription: chatDescription,
        createdAt: fireBase.database.ServerValue.TIMESTAMP,
        lastMessage: userName + " added to this chat",
        lastMessageTimeStamp: fireBase.database.ServerValue.TIMESTAMP,
        unreadMessage: true,
      });
      let newChat = fireDB.ref("chats/" + chatId).push();
      newChat.set({
        userId: "",
        message: userName + " added to this chat",
        createdAt: fireBase.database.ServerValue.TIMESTAMP,
      });
      //chat userlist for student
      let userEmail = this.$store.state.authUser.email;
      let userMobileNo = this.$store.state.authUser.mobileNo;
      let userProfilePic = this.$store.state.authUser.profilePicUrl;
      let newChatUserList = fireDB.ref("chatUserList/" + chatId).push();
      let chatKey = newChatUser.getKey();
      newChatUserList.set({
        chatId: chatId,
        chatKey: chatKey,
        userId: userId,
        userName: userName,
        userEmail: userEmail,
        userMobileNo: userMobileNo,
        userProfilePic: userProfilePic,
      });
      // get tutor profile details
      //let token = this.$store.state.authUser.token;
      await axios
        .get(`user/profile/${tutorId}`, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          let data = response.data.data;
          //chat userlist for tutor
          let tutorUserEmail = data.email;
          let tutorUserName = data.fullName;
          let tutorUserMobileNo = data.mobileNo;
          let tutorUserProfilePic = data.profilePicUrl;
          let newTutorChatUserList = fireDB.ref("chatUserList/" + chatId).push();
          let tutorChatKey = newTutorChatUser.getKey();
          newTutorChatUserList.set({
            chatId: chatId,
            chatKey: tutorChatKey,
            userId: tutorId,
            userName: tutorUserName,
            userEmail: tutorUserEmail,
            userMobileNo: tutorUserMobileNo,
            userProfilePic: tutorUserProfilePic,
          });
        })
        .catch(() => {});
    },
    paystackDialogClose() {},
    async notification() {
      let url = "notifications/create";
      let token = this.$store.state.authUser.token;
      await axios
        .post(url, this.notificationData, {
          headers: {
            "x-access-token": token,
          },
        })
        .then(() => {
          // console.log(response);
        })
        .catch(() => {});
    },
    async notificationTutor() {
      let url = "notifications/create";
      let token = this.$store.state.authUser.token;
      await axios
        .post(url, this.notificationDataTutor, {
          headers: {
            "x-access-token": token,
          },
        })
        .then(() => {
          // console.log(response);
        })
        .catch(() => {});
    },
    async transaction() {
      let token = this.$store.state.authUser.token;
      let url = "txn/create";
      let txnData = {
        userid: this.$store.state.authUser._id,
        refNo: this.reference,
        amount: this.classes.requests[this.paymentIndex].class.price,
        purpose: `Payment for Private Class - ${
          this.classes.requests[this.paymentIndex].class.name
        }`,
      };
      await axios
        .post(url, txnData, {
          headers: {
            "x-access-token": token,
          },
        })
        .then(() => {
          //console.log(response);
        })
        .catch(() => {});
    },
    percetageGroup(index) {
      let count = 0;
      let length = this.classes.groupClasses[index].classid.curriculum[0].curriculum
        .length;
      let curriculum = this.classes.groupClasses[index].classid.curriculum[0].curriculum;
      for (let i = 0; i < length; i++) {
        if (curriculum[i].completion == true) {
          count++;
        }
      }
      return (count / length) * 100;
    },
    percetagePrivate(index) {
      let count = 0;
      let length = this.classes.requests[index].modules[0].curriculum.length;
      let curriculum = this.classes.requests[index].modules[0].curriculum;
      for (let i = 0; i < length; i++) {
        if (curriculum[i].completion == true) {
          count++;
        }
      }
      return (count / length) * 100;
    },
    curriculumGroup(index) {
      this.curriculum = this.classes.groupClasses[index].classid.curriculum[0].curriculum;
      this.viewCurriculumGroup = true;
    },
    curriculumPrivate(index) {
      this.curriculum = this.classes.requests[index].modules[0].curriculum;
      this.viewCurriculumPrivate = true;
    },
    async completeClass(index) {
      // this.paymentIndex = index;
      this.completeClassSpin = true;
      let token = this.$store.state.authUser.token;
      let classid = this.classes.requests[index].id;
      let tutorFee =
        this.classes.requests[index].class.price -
        (this.classes.requests[index].class.price * 30) / 100;
      //
      this.notificationDataTutor = {
        userid: this.classes.requests[index].tutor,
        message: `Your learner for ${this.classes.requests[index].class.name} has confirmed class completion, your tutor fee of (₦ ${tutorFee}) has been credited to your wallet.`,
        url: "https://app.skillng.com/tutor",
        imgUrl:
          "https://res.cloudinary.com/skillng/image/upload/v1626132590/skillng-app/t2fpd4ddbr98vz1rwqc2.png",
      };
      //
      const payload = {
        userid: this.$store.state.authProfile._id,
        classrequestid: classid,
        classid: this.classes.requests[index].class._id,
        certificateData: {
          fullName: this.$store.state.authProfile.fullName,
          classType: "Private Class",
          template: "SkillNG",
          type: "Premium",
        },
        notificationData: {
          email: this.$store.state.authProfile.email,
          message:
            "Your private class has been concluded. Kindly log on to your account to download your certificate. Thank you for learning with us.",
          url: "/classes",
          imgUrl:
            "https://res.cloudinary.com/skillng/image/upload/v1616794336/skillng-app/nzodwkple7pqvinwjnf9.jpg",
        },
      };
      await axios
        .post("classReq/confirmcompleted", payload, {
          headers: {
            "x-access-token": token,
          },
        })
        .then(() => {
          // console.log(response);
          this.completeClassSpin = false;
          this.loadClass();
          this.notificationTutor(index);
          this.tutorCreditWallet(index);
          this.tutorTransaction(index);
          // this.classCertificate(index);
          // setTimeout(() => {
          // this.notification();
          // }, 500);
        })
        .catch((error) => {
          this.completeClassSpin = false;
          this.$swal.fire({
            position: "center",
            icon: "error",
            title: `${error.response.data.message}`,
            showConfirmButton: false,
            timer: 5000,
            toast: true,
          });
        });
    },
    async tutorCreditWallet(index) {
      let token = this.$store.state.authUser.token;
      let url = "user/credit";
      let tutorFee =
        this.classes.requests[index].class.price -
        (this.classes.requests[index].class.price * 30) / 100;
      let walletData = {
        userid: this.classes.requests[index].tutor,
        amount: tutorFee,
        refNo: "",
        purpose: `Tutor Fee for  Class - ${this.classes.requests[index].class.name}`,
        type: "credit",
        notificationData: {
          message: `Your SkillNG balance has been credited with ₦${tutorFee}`,
          url: "https://app.skillng.com/settings/my-balance",
          imgUrl:
            "https://res.cloudinary.com/skillng/image/upload/v1626132590/skillng-app/t2fpd4ddbr98vz1rwqc2.png",
        },
      };
      await axios
        .post(url, walletData, {
          headers: {
            "x-access-token": token,
          },
        })
        .then(() => {
          // console.log(response);
        })
        .catch(() => {});
    },
    async tutorTransaction(index) {
      let token = this.$store.state.authUser.token;
      let url = "wallet/create";
      let tutorFee =
        this.classes.requests[index].class.price -
        (this.classes.requests[index].class.price * 30) / 100;
      let walletCreateData = {
        userid: this.classes.requests[index].tutor,
        refNo: "",
        purpose: `Payment for Private Class - ${this.classes.requests[index].class.name}`,
        amount: tutorFee,
      };
      await axios
        .post(url, walletCreateData, {
          headers: {
            "x-access-token": token,
          },
        })
        .then(() => {
          // console.log(response);
        })
        .catch(() => {});
    },
    // async classCertificate(index) {
    //   let token = this.$store.state.authUser.token;
    //   let url = "user/pclass/certificate";
    //   let formData = {
    //     classid: `${this.classes.requests[index].class._id}`,
    //     certificateUrl:
    //       "https://res.cloudinary.com/skillng/image/upload/v1620601211/skillng-app/oheyzvkdccerr98y9xbc.jpg",
    //   };
    //   await axios
    //     .post(url, formData, {
    //       headers: {
    //         "x-access-token": token,
    //       },
    //     })
    //     .then(() => {
    //       // console.log(response);
    //     })
    //     .catch(() => {});
    // },
    async leaveClassChat(classID) {
      let userID = this.$store.state.authUser._id;
      let token = this.$store.state.authUser.token;
      let url = "user/leaveGClass";
      let formData = {
        userid: userID,
        classid: classID,
      };
      await axios
        .post(url, formData, {
          headers: {
            "x-access-token": token,
          },
        })
        .then(() => {
          //announce removal from chat message//
          let newChat = fireDB.ref("chats/" + classID).push();
          newChat.set({
            userId: "",
            message: this.toBeDeleted[0].userName + " remove from chat",
            createdAt: fireBase.database.ServerValue.TIMESTAMP,
          });
          // delete user id from users in firebase
          let deleteUser = fireDB.ref(
            "users/" + userID + "/" + this.toBeDeleted[0].chatKey
          );
          deleteUser.remove();
          // delete chatID from ChatUserList in firebase
          let deleteUserList = fireDB.ref(
            "chatUserList/" + this.toBeDeleted[0].chatId + "/" + this.toBeDeleted[0].key
          );
          deleteUserList.remove();
          // //
          this.leaveClassSpin = false;
          // suucess message
          this.$swal.fire({
            position: "center",
            icon: "success",
            title: "You have successfully removed yourself from the class.",
            showConfirmButton: false,
            timer: 5000,
            toast: true,
          });
          //re-load class list
          this.loadClass();
        })
        .catch((error) => {
          this.$swal.fire({
            position: "center",
            icon: "error",
            title: `${error.response.data.message}`,
            showConfirmButton: false,
            timer: 5000,
            toast: true,
          });
        });
    },
    async leaveClass(classID) {
      this.leaveClassSpin = true;
      let userID = this.$store.state.authUser._id;
      // Get Chat User List for deletion
      this.toBeDeleted = [];
      let deleted = [];
      let chatUserListRef = fireDB.ref("chatUserList/" + classID);
      chatUserListRef.once("value", (snapshot) => {
        snapshot.forEach(function (childSnapshot) {
          var childData = childSnapshot.val();
          childData.key = childSnapshot.key;
          if (childData.userId == userID) {
            deleted.push(childData);
          }
        });
      });
      // await new Promise((resolve) => setTimeout(resolve, 5000));
      // if (!toBeDeleted.length || toBeDeleted == undefined) {
      //   this.leaveClassSpin = false;
      //   this.$swal.fire({
      //     position: "center",
      //     icon: "error",
      //     title: "Error removing the class, kindly try again later",
      //     showConfirmButton: false,
      //     timer: 5000,
      //     toast: true,
      //   });
      //   return;
      // }
      this.interval = setInterval(() => {
        if (deleted != []) {
          this.toBeDeleted = deleted;
          this.leaveClassChat(classID);
          clearInterval(this.interval);
        }
      }, 1000);
    },
    async getUserCertificate() {
      this.activetab = 2;
      this.certificates = [];

      if (this.activetab == 2) {
        // if (this.certificates.length == 0) {
        this.certificateSkeletonLoader = true;
        let url = `/certificate/user/${this.$store.state.authUser.id}`;
        let token = this.$store.state.authUser.token;
        await axios
          .get(url, {
            headers: {
              "x-access-token": token,
            },
          })
          .then((response) => {
            this.certificates = response.data.data;
            this.certificateSkeletonLoader = false;
            // console.log(response);
          })
          .catch(() => {});
        // }
      }
    },
    async downloadCert(index) {
      if (this.certificates[index].template == "ProjectSkill100") {
        let payload = this.certificates[index];

        setTimeout(() => {
          projectskill1000.certificate(payload);
        }, 200);

        this.certificateLoader = true;

        this.$swal.fire({
          position: "center",
          icon: "success",
          title: "Certificate file download Successful!",
          showConfirmButton: false,
          timer: 5000,
          toast: true,
        });
        this.certificateLoader = false;
      } else if (this.certificates[index].template == "SkillNG") {
        let payload = this.certificates[index];

        setTimeout(() => {
          skillngPremium.certificate(payload);
        }, 200);

        this.certificateLoader = true;

        this.$swal.fire({
          position: "center",
          icon: "success",
          title: "Certificate file download Successful!",
          showConfirmButton: false,
          timer: 5000,
          toast: true,
        });
        this.certificateLoader = false;
      }
    },
    reviewClass(id) {
      this.reviewIndex = id;
      this.ReviewDialogOpen = true;
    },
    async addNewReview() {
      this.loading = true;
      let url = "reviews/create";
      let token = this.$store.state.authUser.token;
      let formData = {
        user: this.$store.state.authUser._id,
        class: this.certificates[this.reviewIndex].classid._id,
        school: this.certificates[this.reviewIndex].classid.school[0],
        star: this.addReview.star,
        comment: this.addReview.comment,
      };

      await axios
        .post(url, formData, {
          headers: {
            "x-access-token": token,
          },
        })
        .then(() => {
          this.loading = false;
          this.ReviewDialogOpen = false;
          this.addReview.comment = "";
          this.addReview.star = 0;
          this.$swal.fire({
            position: "center",
            icon: "success",
            title: "Review Submission Successful!",
            showConfirmButton: false,
            timer: 3000,
            toast: true,
          });
        })
        .catch(() => {});
    },
    async premiumCertificate() {
      const loading = this.$vs.loading();
      this.notificationData = {
        userid: this.$store.state.authUser.id,
        message: `Your payment for Premium Certificate is successful, kindly proceed to download your certificate`,
        url: "https://app.skillng.com/classes",
        imgUrl:
          "https://res.cloudinary.com/skillng/image/upload/v1626132590/skillng-app/t2fpd4ddbr98vz1rwqc2.png",
      };

      let url2 = "certificate/update";
      let token = this.$store.state.authUser.token;
      let formData = {
        certificateid: this.certificates[this.paymentIndex]._id,
        template: "SkillNG",
        type: "Premium",
      };

      await axios
        .post(url2, formData, {
          headers: {
            "x-access-token": token,
          },
        })
        .then(() => {
          this.notification();
          this.getUserCertificate();
          loading.close();
          this.$swal.fire({
            position: "center",
            icon: "success",
            title: "Certificate Generated Successfully!",
            showConfirmButton: false,
            timer: 3000,
            toast: true,
          });
        })
        .catch(() => {});
    },
    async payFromBalance() {
      if (this.walletbalance < this.classes.requests[this.paymentIndex].class.price) {
        this.insuficientBalance = true;
      } else {
        this.payFromBalanceCheck = true;

        //debit user wallet
        const loading = this.$vs.loading();
        let token = this.$store.state.authUser.token;
        let url = "user/debit";
        let walletData = {
          userid: this.$store.state.authUser._id,
          amount: this.classes.requests[this.paymentIndex].class.price,
          refNo: this.reference,
          purpose: `Payment for ${this.classes.requests[this.paymentIndex].class.name}`,
          type: "debit",
          notificationData: {
            message: `Your SkillNG balance have been debited with ₦${
              this.classes.requests[this.paymentIndex].class.price
            }`,
            url: "https://app.skillng.com/settings/my-balance",
            imgUrl:
              "https://res.cloudinary.com/skillng/image/upload/v1626132590/skillng-app/t2fpd4ddbr98vz1rwqc2.png",
          },
        };
        await axios
          .post(url, walletData, {
            headers: {
              "x-access-token": token,
            },
          })
          .then(() => {
            loading.close();
            this.processPayment();
          })
          .catch((error) => {
            loading.close();
            this.$swal.fire({
              position: "center",
              icon: "error",
              title: `${error.response.data.message}`,
              showConfirmButton: false,
              timer: 5000,
              toast: true,
            });
          });
        //
      }
    },
  },

  async mounted() {
    await this.loadClass();
  },
  computed: {
    walletbalance() {
      return this.$store.state.authProfile.tutorWallet;
    },
    reference() {
      let text = "";
      let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    },
    authEmail() {
      return this.$store.state.authUser.email;
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style></style>
