<template>
  <section class="bg-gray-100 w-full px-6 py-2 h-full overflow-y-auto">
    <div class="px-2 py-5 mt-8 lg:px-8 mx-0">
      <div class="">
        <h1 class="text-xl font-medium text-skillblack lg:px-6 mb-8">Compose SMS</h1>
        <h1 class="text-xl font-medium text-skillblack lg:px-6 mb-8">
          Balance: ₦{{ balance }}
        </h1>
      </div>

      <div class="lg:flex block content-between lg:space-x-5">
        <div class="w-full lg:mb-0 mb-5">
          <p class="ml-3 mb-2">Recipients (Seperate numbers using a comma)</p>
          <textarea
            v-model="recipientNumber"
            class="w-full p-4 rounded-2xl"
            rows="10"
            placeholder="e.g 07061282516,08160580772"
          ></textarea>
          <div class="flex items-center space-x-10">
            <p class="ml-3 mb-2">Total Number Of Recipients: {{ recipientCount }}</p>
            <vs-button size="small" @click="numberWithCommas" class="">
              <p class="text-xm text-gray-800">Auto Format</p>
            </vs-button>
          </div>
        </div>
        <div class="w-full mb-10">
          <p class="ml-3 mb-2">Message</p>
          <textarea v-model="message" class="w-full p-4 rounded-2xl" rows="10"></textarea>
          <p class="ml-3 mb-2">{{ characters }} character | {{ pages }} page(s)</p>
        </div>
      </div>
      <div>
        <vs-button
          :loading="loading"
          block
          @click="sendSMS"
          class=""
          :disabled="message == '' || recipientNumber == ''"
        >
          <p class="text-base font-semibold text-gray-800">Send SMS</p>
        </vs-button>
      </div>
    </div>
  </section>
</template>

<script>
import axios from ".././plugin/axios";

export default {
  data() {
    return {
      loading: false,
      recipientNumber: "",
      recipientNumberArray: [],
      message: "",
      balance: 0,
    };
  },
  computed: {
    characters() {
      return this.message.length;
    },
    pages() {
      let page = Math.ceil(this.message.length / 160);
      return page;
    },
    recipientCount() {
      let count = this.recipientNumber.split(",").length;
      return count;
    },
  },
  methods: {
    async numberWithCommas() {
      const numbersArray = this.recipientNumber.split(/[,\n]+/).map((number) => {
        // Remove any non-digit characters
        const cleanedNumber = number.replace(/\D/g, "");

        if (cleanedNumber.startsWith("0")) {
          return "234" + cleanedNumber.slice(1);
        } else if (!cleanedNumber.startsWith("234")) {
          return "234" + cleanedNumber;
        }

        return cleanedNumber;
      });

      this.recipientNumber = numbersArray.join(",");
    },
    async numberWithCommasOld() {
      let number = this.recipientNumber.replace(/\n\n*/g, ",");
      this.recipientNumber = number;
    },
    async sendSMS() {
      this.loading = true;

      let number = this.recipientNumber.split(/[ ,]+/);

      let data = {
        api_key: "TLZfOcg0BTvN6nGSqiISMjEwnHU2GsUDJSgjwCMKJ7WJ2DyFs71WSTgH6LxCtm",
        to: number,
        // from: "N-Alert",
        from: "SkillNG",
        sms: this.message,
        type: "plain",
        channel: "generic",
        // channel: "dnd",
      };
      console.log(data);

      let url = "https://api.ng.termii.com/api/sms/send";
      await axios
        .post(url, data)
        .then((response) => {
          this.loading = false;
          this.clearData();
          console.log(response);
          this.$swal.fire({
            position: "top-end",
            icon: "success",
            title: `${response.data.message}`,
            showConfirmButton: false,
            timer: 3000,
            toast: true,
          });
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.$swal.fire({
            position: "top-end",
            icon: "error",
            title: `${error.response.data.message}`,
            showConfirmButton: false,
            timer: 3000,
            toast: true,
          });
        });
    },
    clearData() {
      this.message = "";
      this.recipientNumber = "";
    },
  },
  async mounted() {
    let api_key = "TLZfOcg0BTvN6nGSqiISMjEwnHU2GsUDJSgjwCMKJ7WJ2DyFs71WSTgH6LxCtm";
    let url = `https://api.ng.termii.com/api/get-balance?api_key=${api_key}`;
    await axios
      .get(url)
      .then((response) => {
        this.balance = response.data.balance;
      })
      .catch(() => {
        this.$swal.fire({
          position: "top-end",
          icon: "error",
          title: "Error retrieving balance",
          showConfirmButton: false,
          timer: 3000,
          toast: true,
        });
      });
  },
};
</script>

<style lang="css">
textarea:focus {
  resize: auto !important;
}
</style>
