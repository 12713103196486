<template>
  <section class="w-full xl:px-5 bg-white">
    <div class="px-6 pt-9 pb-3 sticky top-0 bg-white z-40">
      <router-link to="/dashboard">
        <button>
          <img src="../../../src/assets/logo.png" alt="Logo" class="h-6 lg:h-10 w-auto" />
        </button>
      </router-link>
    </div>
    <!--  -->
    <div class="flex flex-col justify-between">
      <div class="mt-5">
        <router-link v-for="tab in tabs" :key="tab.title" :to="{ name: tab.route }">
          <button
            class="flex items-center mb-1 py-3 px-4 hover:bg-skilldeepyelow rounded-full mr-auto w-full relative"
            :class="
              $route.path === tab.link
                ? 'text-skillwhite rounded-full bg-skilldeepyelow font-medium'
                : 'text-skillblack font-normal'
            "
          >
            <i
              :class="[$route.path === tab.link ? 'font-semibold ' : '', tab.icon]"
              class="text-lg mr-4"
            ></i>
            <p class="hidden lg:block">{{ tab.title }}</p>
            <div v-if="tab.title == 'Notifications'" class="absolute right-2">
              <p
                v-if="notificationCount"
                class="text-xs text-white px-2 py-0.5 bg-yellow-500 border-2 border-white rounded-full"
              >
                {{ notificationCount }}
              </p>
            </div>
            <div v-if="tab.title == 'Messages'" class="absolute right-2">
              <div
                v-if="unreadMessage"
                class="absolute animate-ping top-1/2 -mt-2 right-2 bg-red-500 rounded-full h-3.5 w-3.5"
              ></div>
              <div
                v-if="unreadMessage"
                class="absolute top-1/2 -mt-2 right-2 bg-red-500 rounded-full h-3.5 w-3.5 border-2 border-white"
              ></div>
            </div>
          </button>
        </router-link>
      </div>

      <div class="">
        <router-link to="/request" class="mt-8 mb-2 flex justify-center">
          <button
            class="hidden lg:block xl:w-5/6 w-full pr-2 py-8 bg-skillblack text-skillyelow rounded-3xl focus:outline-none shadow-md font-light text-lg"
          >
            Request for <br />
            a tutor
            <i class="fal fa-long-arrow-right"></i>
          </button>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { fireDB } from "../../ultilities/firebase";
import axios from "../../plugin/axios";

export default {
  data() {
    return {
      tabs: [
        {
          icon: "far fa-home-alt",
          title: "Dashboard",
          route: "Dashboard",
          link: "/",
        },
        {
          icon: "far fa-graduation-cap",
          title: "School",
          route: "Schools",
          link: "/schools",
        },
        {
          icon: "far fa-chalkboard-teacher",
          title: "My Classes",
          route: "Classes",
          link: "/classes",
        },
        {
          icon: "far fa-bell",
          title: "Notifications",
          route: "Notifications",
          link: "/notifications",
        },
        {
          icon: "far fa-envelope",
          title: "Messages",
          route: "Messages",
          link: "/messages",
        },
        {
          icon: "far fa-user",
          title: "Profile",
          route: "Profile",
          link: "/profile",
        },
        {
          icon: "far fa-cog",
          title: "Settings",
          route: "EditProfile",
          link: "/settings/editprofile",
        },
      ],
      chatUsersArray: [],
      // unreadMessage: false,
    };
  },
  computed: {
    notificationCount() {
      let count = this.$store.state.unreadNotification;
      if (count > 9) {
        count = "9+";
      }
      return count;
    },
    unreadMessage() {
      let unread = this.$store.state.unreadMessage;
      return unread;
    },
  },
  methods: {
    async loadNotificationCount() {
      let url = `notifications/usernotifications/${this.$store.state.authUser.id}`;
      let token = this.$store.state.authUser.token;
      await axios
        .get(url, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          // console.log(response);
          let data = response.data.data;
          var count = 0;
          for (let i = 0; i < data.length; i++) {
            if (data[i].status == false) {
              count += 1;
            }
          }
          this.$store.commit("setUnreadNotification", count);
        })
        .catch(() => {});
    },

    async loadChatUser() {
      let userId = `${this.$store.state.authUser.id}`;
      let userRef = await fireDB
        .ref("users/" + userId)
        .orderByChild("lastMessageTimeStamp");
      userRef.on("value", (snapshot) => {
        let chatUsers = [];
        snapshot.forEach(function (childSnapshot) {
          var childData = childSnapshot.val();
          childData.key = childSnapshot.key;
          chatUsers.push(childData);
        });
        this.chatUsersArray = chatUsers;
        if (chatUsers.length !== 0) {
          for (let i = 0; i < chatUsers.length; i++) {
            if (chatUsers[i].unreadMessage == true) {
              this.$store.commit("setUnreadMessage", true);
              break;
            } else this.$store.commit("setUnreadMessage", false);
          }
        }
      });
    },
  },
  beforeMount() {
    this.loadNotificationCount();
    this.loadChatUser();

    if (this.$store.state.authUser.tutorStatus) {
      this.tabs[0].route = "Tutor";
      this.tabs[0].link = "/tutor";
    }
  },
};
</script>

<style></style>
