<template>
  <section class="rounded-t-xl bg-gray-50 relative">
    <div
      class="w-full border border-slighter rounded-t-2xl shadow-md h-almost overflow-y-auto pb-16"
    >
      <div class="px-6 py-5 flex items-center justify-between">
        <h1 class="text-xl font-medium text-skillblack">Tutor Dashboard</h1>
      </div>
      <!-- Course -->
      <div class="lg:px-10 px-6">
        <div class="lg:flex block mb-5">
          <div class="lg:w-2/5 w-full mb-5">
            <div class="shadow-md bg-white p-4 rounded-3xl mb-5">
              <p class="font-semibold py-2">Wallet</p>
              <div class="flex items-baseline">
                <p class="font-normal text-3xl pb-1">
                  ₦
                  {{
                    tutorWallet.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  }}
                </p>
                <p class="text-gray-600 text-xs pl-5 mb-3">Available balance</p>
              </div>
              <vs-button border block @click="OpenWithdrawlDialog = !OpenWithdrawlDialog">
                <p class="text-black">Withdraw</p>
              </vs-button>
            </div>
            <!-- Withdrawl Dialog -->
            <vs-dialog scroll prevent-close width="650px" v-model="OpenWithdrawlDialog">
              <template #header>
                <h4 class="not-margin font-semibold">Account Details</h4>
              </template>
              <div class="my-4 mx-3">
                <vs-input
                  block
                  type="number"
                  label-placeholder="Amount"
                  v-model="withdrawalDetails.amount"
                >
                  <template
                    v-if="!amountValidate && withdrawalDetails.amount !== ''"
                    #message-danger
                  >
                    Amount must be greater than ₦1,000 and less than wallet balance.
                  </template>
                </vs-input>
              </div>
              <div class="my-4 pt-4 mx-3">
                <vs-input
                  block
                  type="number"
                  max="5"
                  label-placeholder="Account Number"
                  v-model="withdrawalDetails.accountNo"
                />
                <p class="pl-4 py-2" v-if="withdrawalDetails.accountName">
                  <b>{{ withdrawalDetails.accountName }}</b>
                </p>
              </div>
              <div class="py-4 mx-3 mb-2">
                <vs-select
                  filter
                  block
                  label-placeholder="Select Bank"
                  v-model="withdrawalDetails.bankValue"
                >
                  <vs-option
                    v-for="bank in bankList"
                    :key="bank.index"
                    :label="bank.name"
                    :value="[bank.code, bank.name]"
                  >
                    {{ bank.name }}
                  </vs-option>
                </vs-select>
                <div class="flex my-4">
                  <vs-button
                    @click="validateBank"
                    border
                    active
                    :disabled="
                      !amountValidate ||
                      withdrawalDetails.bankValue == '' ||
                      withdrawalDetails.accountNo == ''
                    "
                    :loading="validateBanktLoading"
                    class="font-semibold w-1/2"
                  >
                    <p class="text-black">Validate</p>
                  </vs-button>
                  <vs-button
                    @click="sendSMS"
                    border
                    active
                    :disabled="!validateBankSuccess"
                    :loading="requestWithdrawLoading"
                    class="font-semibold w-1/2"
                  >
                    <p class="text-black">Submit</p>
                  </vs-button>
                </div>
              </div>
              <div v-if="otp">
                <p class="text-gray-400 text-sm mb-4 text-center">
                  To confirm this transaction, enter the OTP sent to your registered
                  mobile number.
                </p>
                <div v-if="otp" class="flex justify-center my-4">
                  <v-otp-input
                    inputClasses="otp-input"
                    :numInputs="4"
                    separator=""
                    :shouldAutoFocus="true"
                    @on-complete="handleOnComplete"
                  />
                  <p class="text-sm text-center text-red-600">
                    {{ optMessage }}
                  </p>
                </div>
              </div>
            </vs-dialog>
            <!--  -->
            <div class="shadow-md bg-white p-4 rounded-3xl overflow-y-auto">
              <p class="font-semibold py-2">Course</p>
              <button
                @click="OpenCoursesDialog"
                class="mb-5 flex items-center border border-gray-200 rounded-xl p-4 hover:bg-slightyellow shadow-md w-full"
              >
                <div
                  class="bg-skilldeepyelow text-white rounded-full flex justify-center items-center w-10 h-10"
                >
                  <i class="far fa-plus"></i>
                </div>
                <p class="pl-3">Add new Course</p>
              </button>

              <!-- course skeleton  loader -->
              <div v-if="skeletonLoaderCourse">
                <div
                  v-for="skeleton in 3"
                  :key="skeleton.index"
                  class="mb-5 h-12 bg-gray-100 rounded-xl animate-pulse skeleton-box"
                ></div>
              </div>
              <!--  -->
              <div v-if="!skeletonLoaderCourse">
                <div
                  v-for="course in courses"
                  :key="course.index"
                  class="mb-5 p-4 bg-gray-100 rounded-xl hover:bg-slightyellow"
                >
                  <p class="font-semibold">{{ course.course.name }}</p>
                  <p class="text-green-800 font-semibold text-sm">
                    {{ course.applicationStatus }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- Course Dialog -->
          <vs-dialog scroll width="650px" v-model="coursesDialog">
            <vs-table>
              <template #thead>
                <vs-tr class="bg-gray-200 rounded-xl">
                  <vs-th> Courses </vs-th>
                </vs-tr>
              </template>
              <template #tbody>
                <vs-tr v-for="tr in CourseClass" :key="tr.index">
                  <vs-td class="font-semibold">
                    {{ tr.name }}
                  </vs-td>

                  <template #expand>
                    <div class="con-content">
                      <p v-for="(mod, index) in tr.curriculum" :key="mod.index">
                        Module {{ index + 1 }}: {{ mod }}
                      </p>

                      <div class="mt-3">
                        <input
                          type="checkbox"
                          v-model="moduleAgree"
                          class="h-4 w-4 text-yellow-500 p-2"
                        />

                        <span class="font-semibold pl-2">
                          I have read all the modules in this course, I know the modules
                          and I can teach them.
                        </span>
                        <div class="py-1 mb-3">
                          <button
                            @click="addCourse(tr.id, tr.name)"
                            class="w-full bg-skilldeepyelow rounded-lg"
                          >
                            <p class="font-semibold text-black py-2 text-xs">
                              Add Course
                            </p>
                          </button>
                        </div>
                      </div>
                    </div>
                  </template>
                </vs-tr>
              </template>
            </vs-table>
          </vs-dialog>

          <!-- Request -->

          <div class="h-almost lg:w-3/5 w-full">
            <div class="md:flex black mb-5">
              <div
                class="md:w-1/2 w-full shadow-md p-4 rounded-3xl lg:ml-8 ml-0 bg-white overflow-y-auto md:mb-0 mb-5"
              >
                <p class="font-semibold py-2">Request</p>

                <!--  -->
                <div v-if="!RequestSkeloton">
                  <div
                    v-for="request in requestCourse"
                    :key="request.index"
                    class="mb-3 p-2 bg-gray-100 rounded-xl hover:bg-slightyellow"
                  >
                    <p class="font-medium">{{ request.class.name }}</p>
                    <p v-if="request.preferedLocation == 'Physical'" class="text-sm">
                      Location: {{ request.address[0].fullAddress }}
                    </p>
                    <p v-if="request.preferedLocation !== 'Physical'" class="text-sm">
                      Location: {{ request.preferedLocation }}
                    </p>
                    <p class="text-sm">
                      Tutor Fee: ₦{{
                        (
                          request.class.price -
                          (request.class.price * 30) / 100
                        ).toLocaleString()
                      }}
                    </p>
                    <p class="mb-2 text-sm">
                      Request Date: {{ request.createdDate.slice(0, 10) }}
                    </p>

                    <div class="flex">
                      <vs-button
                        @click="
                          acceptRequest(request._id, request.class.name, request.userid)
                        "
                        border
                        active
                        :loading="acceptLoading"
                        class="font-semibold w-1/2"
                      >
                        <p class="text-black">Accept</p>
                      </vs-button>
                      <vs-button
                        @click="declineRequest(request._id)"
                        border
                        danger
                        :loading="declineLoading"
                        class="w-1/2"
                      >
                        Decline
                      </vs-button>
                    </div>
                  </div>
                </div>
                <!-- Request Skeloton  loader -->
                <div v-if="RequestSkeloton">
                  <div
                    v-for="skeloton in 2"
                    :key="skeloton.index"
                    class="mb-3 p-2 bg-gray-100 rounded-xl h-24 animate-pulse skeleton-box"
                  ></div>
                </div>
                <div v-if="requestCourse.length === 0">
                  <div class="mb-3 p-2 text-center">
                    <i class="fad fa-exclamation-circle text-yellow-400 text-4xl"></i>
                    <p>No request found</p>
                  </div>
                </div>
              </div>

              <!-- Classes -->
              <div
                class="md:w-1/2 w-full shadow-md p-4 rounded-3xl md:ml-8 ml-0 bg-white overflow-y-auto"
              >
                <p class="font-semibold py-2">Classes</p>
                <div class="flex p-1 bg-gray-100 rounded-full mb-2 justify-center">
                  <button
                    @click="classActive = 1"
                    class="w-1/2 text-sm"
                    :class="
                      classActive === 1
                        ? 'bg-skilldeepyelow text-skillblack py-1 rounded-full text-sm font-semibold'
                        : ''
                    "
                  >
                    Active
                  </button>
                  <button
                    @click="classActive = 2"
                    class="w-1/2 text-sm"
                    :class="
                      classActive === 2
                        ? 'bg-skilldeepyelow text-skillblack py-1 rounded-full text-sm font-semibold'
                        : ''
                    "
                  >
                    Completed
                  </button>
                </div>
                <!-- active 1 -->
                <div v-if="classActive === 1">
                  <div v-if="!ActiveClassSkeloton">
                    <div
                      v-for="(active, index) in activeClass"
                      :key="active.index"
                      class="mb-3 p-2 bg-gray-100 rounded-xl hover:bg-slightyellow"
                    >
                      <p class="font-medium pb-1">
                        {{ active.class.name }} with {{ active.userid.fullName }}
                      </p>
                      <p class="mb-1 text-gray-600">
                        {{ parseFloat(percetage(index)).toFixed(0) }}% completed
                      </p>
                      <div
                        class="h-3 mb-3 relative rounded-full bg-white overflow-hidden"
                      >
                        <div
                          class="h-full absolute bg-green-500 rounded-full"
                          :style="{ width: percetage(index) + '%' }"
                        ></div>
                      </div>

                      <div v-if="active.paymentStatus.status" class="flex">
                        <button
                          @click="$router.push({ name: 'Messages' })"
                          class="w-1/2 mx-2 bg-skilldeepyelow text-skillblack py-1 rounded-md text-xs font-semibold"
                        >
                          View Class
                        </button>
                        <!--  -->
                        <button
                          v-if="percetage(index) !== 100"
                          @click="updateModuleIndex(index)"
                          class="w-1/2 mx-2 bg-skilldeepyelow text-skillblack py-1 rounded-md text-xs font-semibold"
                        >
                          Update Module
                        </button>
                        <!--  -->
                        <button
                          v-if="
                            percetage(index) == 100 && active.completion.status == false
                          "
                          @click="completeClass(index)"
                          class="w-1/2 mx-2 bg-skilldeepyelow text-skillblack py-1 rounded-md text-xs font-semibold"
                        >
                          <p v-if="!completeClassSpin">Complete Class</p>
                          <i
                            v-if="completeClassSpin"
                            class="fad fa-circle-notch text-base animate-spin"
                          ></i>
                        </button>
                      </div>
                      <div v-if="active.completion.status">
                        <p class="text-sm text-black font-semibold text-center mt-2">
                          Waiting for student to confirm class completion
                        </p>
                      </div>
                      <!--  -->
                      <div v-if="!active.paymentStatus.status">
                        <p class="text-sm text-black font-semibold">
                          Waiting for student paymnt
                        </p>
                      </div>
                    </div>
                  </div>

                  <!-- Active Class Skeloton Loader -->
                  <div v-if="ActiveClassSkeloton">
                    <div
                      v-for="activeed in 2"
                      :key="activeed.index"
                      class="mb-3 p-2 bg-gray-100 rounded-xl h-14 animate-pulse skeleton-box"
                    ></div>
                  </div>
                  <div v-if="activeClass.length === 0">
                    <div class="mb-3 p-2 text-center">
                      <i class="fad fa-exclamation-circle text-yellow-400 text-4xl"></i>
                      <p>Empty active class</p>
                    </div>
                  </div>
                </div>
                <!-- active 2 -->
                <div v-if="classActive === 2">
                  <div
                    v-for="comp in completedClass"
                    :key="comp.index"
                    class="mb-3 p-2 bg-gray-100 rounded-xl hover:bg-slightyellow"
                  >
                    <p class="font-medium p1-2">{{ comp.title }}</p>
                    <p class="mb-1 text-gray-600">{{ comp.number }} class completed</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- Update Module Dialog -->
            <vs-dialog scroll width="650px" v-model="updateModuleDialog">
              <div class="p-3">
                <p class="font-semibold pb-3">List of Modules</p>
                <div
                  v-for="(modu, index) in updateModule.modules"
                  :key="modu.index"
                  class="flex items-start mb-2"
                >
                  <vs-checkbox v-model="modu.completion"> </vs-checkbox>
                  <p class="pl-2">Module {{ index + 1 }}: {{ modu.name }}</p>
                </div>
              </div>
              <div class="flex justify-center">
                <vs-button
                  @click="updateModuleDB"
                  border
                  active
                  :loading="moduletLoading"
                  class="p-2 bg-skilldeepyelow py-1 rounded-md text-sm font-medium"
                >
                  <p class="text-black">Update Module</p>
                </vs-button>
              </div>
            </vs-dialog>
            <!-- Transaction -->
            <div
              class="shadow-md bg-white p-4 pb-5 mb-24 rounded-t-3xl lg:ml-8 ml-0 pb-20"
            >
              <p class="font-semibold py-2">Transaction</p>

              <vs-table striped>
                <template #thead>
                  <vs-tr>
                    <vs-th class="w-2/12"> Date </vs-th>
                    <vs-th class="w-3/12"> Amount </vs-th>
                    <vs-th class="w-7/12"> Purpose </vs-th>
                  </vs-tr>
                </template>
                <template #tbody>
                  <vs-tr :key="i" v-for="(tr, i) in transactions" :data="tr">
                    <vs-td class="w-2/12">
                      {{ tr.dateofTransaction.slice(0, 10) }}
                    </vs-td>
                    <vs-td class="w-3/12">
                      {{ tr.amount.toLocaleString() }}
                    </vs-td>
                    <vs-td class="w-7/12">
                      {{ tr.purpose }}
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
            <!--  -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "../plugin/axios";

export default {
  data() {
    return {
      coursesDialog: false,
      skeletonLoaderCourse: false,
      RequestSkeloton: false,
      ActiveClassSkeloton: false,
      updateModuleDialog: false,
      moduleAgree: false,
      acceptLoading: false,
      declineLoading: false,
      moduletLoading: false,
      completeClassSpin: false,
      OpenWithdrawlDialog: false,
      validateBanktLoading: false,
      validateBankSuccess: false,
      requestWithdrawLoading: false,
      otp: false,
      otpCode: "",
      optMessage: "",
      classActive: 1,
      tutorWallet: 0,
      activeClass: [],
      completedClass: [
        // { title: "Excel & SQL for Data Analysis", number: "4" },
        // { title: "Digital Marketing", number: "10" },
      ],
      courses: [],
      CourseClass: [],
      notificationData: {},
      requestCourse: [
        {
          class: { price: 0 },
          createdDate: "2021-03-07T22:20:20.562Z",
        },
      ],
      withdrawalDetails: {
        userid: "",
        amount: "",
        bankValue: "",
        bankName: "",
        bankCode: "",
        accountNo: "",
        accountName: "",
      },
      transactions: [],
      updateModule: {},
      bankList: [],
    };
  },
  computed: {
    amountValidate() {
      return (
        this.withdrawalDetails.amount >= 999 &&
        this.withdrawalDetails.amount <= this.tutorWallet
      );
    },
  },
  methods: {
    async addCourse(id, name) {
      if (this.moduleAgree === false) {
        this.$swal.fire({
          position: "top",
          icon: "error",
          title: "You must agree to the modules",
          showConfirmButton: false,
          timer: 1500,
          toast: true,
        });
      } else {
        this.coursesDialog = false;
        this.$swal.fire({
          position: "center",
          icon: "info",
          title: "processing please wait",
          showConfirmButton: false,
          timer: 1000,
          toast: true,
        });

        //
        this.notificationData = {
          userid: this.$store.state.authUser.id,
          message: `Your application for ${name} has been received and will be processed.`,
          url: "https://app.skillng.com/tutor",
          imgUrl:
            "https://res.cloudinary.com/skillng/image/upload/v1626132590/skillng-app/t2fpd4ddbr98vz1rwqc2.png",
        };
        //
        let url = "tutorscourse/create";
        let token = this.$store.state.authUser.token;

        await axios
          .post(
            url,
            { tutor: this.$store.state.authUser.id, course: id },
            {
              headers: {
                "x-access-token": token,
              },
            }
          )
          .then((response) => {
            console.log(response);
            this.$swal.fire({
              position: "center",
              icon: "success",
              title: "Application submited sucessfull",
              showConfirmButton: false,
              timer: 1500,
              toast: true,
            });

            this.loadCourse();

            this.moduleAgree = false;
            this.notification();
          })
          .catch((error) => {
            this.$swal.fire({
              position: "top",
              icon: "error",
              title: `${error.response.data.message}`,
              showConfirmButton: false,
              timer: 5000,
              toast: true,
            });
          });
      }
    },
    async OpenCoursesDialog() {
      (this.CourseClass = [{ name: "Loading Data" }]), (this.coursesDialog = true);
      let url = `tutorscourse/courses/${this.$store.state.authUser.id}`;

      let token = this.$store.state.authUser.token;

      await axios
        .get(url, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          let data = response.data.data;
          this.CourseClass = data;
          // console.log(response.data.data);
        })
        .catch(() => {});
    },

    async acceptRequest(classID, name, userid) {
      //
      this.notificationData = {
        userid: userid,
        message: `A tutor has accepted your request for ${name}, kindly proceed to payment to commence class.`,
        url: "https://app.skillng.com/classes",
        imgUrl:
          "https://res.cloudinary.com/skillng/image/upload/v1626132590/skillng-app/t2fpd4ddbr98vz1rwqc2.png",
      };
      //

      this.acceptLoading = true;
      let url = "classReq/accept";
      let token = this.$store.state.authUser.token;
      await axios
        .post(
          url,
          {
            classrequestid: classID,
            tutorid: this.$store.state.authUser.id,
          },
          {
            headers: {
              "x-access-token": token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          this.acceptLoading = false;

          this.$swal.fire({
            position: "center",
            icon: "success",
            title: "Request Accepted Sucesfully",
            showConfirmButton: false,
            timer: 1000,
            toast: true,
          });
          this.notification();
          this.classRequest();
          this.activeRequestClass();
        })
        .catch((error) => {
          this.acceptLoading = false;

          this.$swal.fire({
            position: "top",
            icon: "error",
            title: `${error.response.data.message}`,
            showConfirmButton: false,
            timer: 5000,
            toast: true,
          });
        });
    },
    async declineRequest(classID) {
      this.declineLoading = true;

      let url = "classReq/decline";
      let token = this.$store.state.authUser.token;
      await axios
        .post(
          url,
          {
            classrequestid: classID,
            tutorid: this.$store.state.authUser.id,
          },
          {
            headers: {
              "x-access-token": token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          this.declineLoading = false;

          this.$swal.fire({
            position: "center",
            icon: "success",
            title: "Request Decline Sucesfully",
            showConfirmButton: false,
            timer: 1000,
            toast: true,
          });
          this.classRequest();
          this.activeRequestClass();
        })
        .catch((error) => {
          this.declineLoading = false;

          this.$swal.fire({
            position: "top",
            icon: "error",
            title: `${error.response.data.message}`,
            showConfirmButton: false,
            timer: 5000,
            toast: true,
          });
        });
    },

    async loadCourse() {
      this.skeletonLoaderCourse = true;
      let url = `tutorscourse/details/${this.$store.state.authUser.id}`;
      let token = this.$store.state.authUser.token;
      await axios
        .get(url, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          this.courses = response.data.data;
          // console.log(response.data.data);
        })
        .catch(() => {});
      this.skeletonLoaderCourse = false;
    },
    async classRequest() {
      this.RequestSkeloton = true;
      let url = `classReq/tutorList/${this.$store.state.authUser.id}`;
      let token = this.$store.state.authUser.token;
      await axios
        .get(url, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          let data = response.data.data;
          this.requestCourse = data;
          this.RequestSkeloton = false;
          // console.log(data);
        })
        .catch(() => {});
    },
    async activeRequestClass() {
      this.ActiveClassSkeloton = true;
      let url = `classReq/classes/${this.$store.state.authUser.id}`;
      let token = this.$store.state.authUser.token;
      await axios
        .get(url, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          let data = response.data.data;
          this.activeClass = data;
          this.ActiveClassSkeloton = false;
        })
        .catch(() => {});
    },
    updateModuleIndex(i) {
      this.updateModuleDialog = true;
      this.updateModule = {
        classrequestid: this.activeClass[i].id,
        modules: this.activeClass[i].modules[0].curriculum,
        tutor: this.activeClass[i].tutor,
        userid: this.activeClass[i].userid,
        className: this.activeClass[i].class.name,
      };
    },
    async updateModuleDB() {
      this.notificationData = {
        userid: this.updateModule.userid,
        message: `Your tutor for ${this.updateModule.className} has updated a module as completed. If this is not the case, kindly call your tutor’s attention to it.`,
        url: "https://app.skillng.com/classes",
        imgUrl:
          "https://res.cloudinary.com/skillng/image/upload/v1626132590/skillng-app/t2fpd4ddbr98vz1rwqc2.png",
      };
      this.moduletLoading = true;
      let url = "classReq/update/module";
      let token = this.$store.state.authUser.token;
      await axios
        .post(
          url,
          {
            classrequestid: this.updateModule.classrequestid,
            modules: this.updateModule.modules,
          },
          {
            headers: {
              "x-access-token": token,
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.moduletLoading = false;
          this.updateModuleDialog = false;

          this.$swal.fire({
            position: "center",
            icon: "success",
            title: "Module Update Sucesfully",
            showConfirmButton: false,
            timer: 1000,
            toast: true,
          });
          this.notification();

          setTimeout(() => {
            this.activeRequestClass();
          }, 500);
        })
        .catch((error) => {
          this.moduletLoading = false;
          this.updateModuleDialog = false;

          this.$swal.fire({
            position: "center",
            icon: "error",
            title: `${error.response.data.message}`,
            showConfirmButton: false,
            timer: 5000,
            toast: true,
          });
        });
    },
    async notification() {
      let url = "notifications/create";
      let token = this.$store.state.authUser.token;
      await axios
        .post(url, this.notificationData, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          console.log(response);
        })
        .catch(() => {});
    },
    percetage(index) {
      let count = 0;
      let lenght = this.activeClass[index].modules[0].curriculum.length;
      let curriculum = this.activeClass[index].modules[0].curriculum;

      for (let i = 0; i < lenght; i++) {
        if (curriculum[i].completion == true) {
          count++;
        }
      }
      return (count / lenght) * 100;
    },
    async completeClass(index) {
      this.completeClassSpin = true;
      let token = this.$store.state.authUser.token;
      let classid = this.activeClass[index]._id;

      this.notificationData = {
        userid: this.activeClass[index].userid,
        message: `Your tutor for ${this.activeClass[index].class.name} has completed the class, kindly confirm the class is completed, after which an e-certificate will be generated for you.`,
        url: "https://app.skillng.com/classes",
        imgUrl:
          "https://res.cloudinary.com/skillng/image/upload/v1626132590/skillng-app/t2fpd4ddbr98vz1rwqc2.png",
      };

      await axios
        .post(
          "classReq/completed",
          { classrequestid: classid },
          {
            headers: {
              "x-access-token": token,
            },
          }
        )
        .then((response) => {
          this.activeRequestClass();
          console.log(response);
          this.completeClassSpin = false;
          this.notification();
        })
        .catch((error) => {
          this.completeClassSpin = false;

          this.$swal.fire({
            position: "center",
            icon: "error",
            title: `${error.response.data.message}`,
            showConfirmButton: false,
            timer: 5000,
            toast: true,
          });
        });
    },
    async transaction() {
      let token = this.$store.state.authUser.token;
      let url = `wallet/user/${this.$store.state.authUser._id}`;
      await axios
        .get(url, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          let data = response.data.data;
          this.transactions = data;
          // console.log(response);
        })
        .catch(() => {});
    },
    async walletBalance() {
      let token = this.$store.state.authUser.token;
      await axios
        .get(`user/profile/${this.$store.state.authUser._id}`, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          this.tutorWallet = response.data.data.tutorWallet;
        })
        .catch(() => {});
    },
    async validateBank() {
      this.withdrawalDetails.bankCode = this.withdrawalDetails.bankValue[0];
      this.withdrawalDetails.bankName = this.withdrawalDetails.bankValue[1];

      this.validateBanktLoading = true;
      let url = `https://api.paystack.co/bank/resolve?account_number=${this.withdrawalDetails.accountNo}&bank_code=${this.withdrawalDetails.bankCode}`;
      let key = "Bearer sk_test_e3091c9cf1f5eed89ff72608ddbcf0bd32756e60";

      await axios
        .get(url, {
          headers: {
            Authorization: key,
          },
        })
        .then((response) => {
          console.log(response);
          this.withdrawalDetails.accountName = response.data.data.account_name;
          this.validateBanktLoading = false;
          this.validateBankSuccess = true;
        })
        .catch(() => {
          this.validateBanktLoading = false;
          this.withdrawalDetails.accountName =
            "Account name confirmation failed. Ensure the account number is correct and click validate.";
        });
    },
    async getBankList() {
      let url = "https://api.paystack.co/bank?active=true&country=Nigeria&currency=NGN";
      let key = "Bearer sk_test_e3091c9cf1f5eed89ff72608ddbcf0bd32756e60";

      await axios
        .get(url, {
          headers: {
            Authorization: key,
          },
        })
        .then((response) => {
          this.bankList = response.data.data;
        })
        .catch(() => {
          this.bankList[0] = "Error fetching bank list";
        });
    },

    async sendSMS() {
      this.requestWithdrawLoading = true;
      let otp = Math.floor(Math.random() * 9000);
      this.otpCode = otp;
      let message = `Your SkillNG wallet is about to be debited N${this.withdrawalDetails.amount}, please approve with code <#> ${otp}`;
      let mobile = this.$store.state.authUser.mobileNo;
      let data = {
        api_key: "TLZfOcg0BTvN6nGSqiISMjEwnHU2GsUDJSgjwCMKJ7WJ2DyFs71WSTgH6LxCtm",
        to: mobile,
        from: "N-Alert",
        sms: message,
        type: "plain",
        channel: "dnd",
      };

      let url = "https://api.ng.termii.com/api/sms/send";
      await axios
        .post(url, data)
        .then(() => {
          this.otp = true;
        })
        .catch(() => {
          this.$swal.fire({
            position: "top-end",
            icon: "error",
            title: "Error sending OTP, please try again later",
            showConfirmButton: false,
            timer: 3000,
            toast: true,
          });
          this.requestWithdrawLoading = false;
          this.OpenWithdrawlDialog = false;
        });
    },

    handleOnComplete(value) {
      // console.log("OTP completed: ", value);
      if (value == this.otpCode) {
        this.requestWithdraw();
        //this.debitWallet();
      } else this.optMessage = "Invalid OTP, please enter correct OTP";
    },

    async requestWithdraw() {
      this.withdrawalDetails.userid = this.$store.state.authUser._id;

      this.requestWithdrawLoading = true;
      let url = "withdrawal/create";
      let token = this.$store.state.authUser.token;
      await axios
        .post(url, this.withdrawalDetails, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          console.log(response);
          this.requestWithdrawLoading = false;
          this.OpenWithdrawlDialog = false;
          this.$swal.fire({
            position: "top-end",
            icon: "success",
            title:
              "Withdrawal request submitted successfully. Withdrawal are sent to bank within 3 working days.",
            showConfirmButton: false,
            timer: 10000,
            toast: true,
          });
        })
        .catch((error) => {
          this.OpenWithdrawlDialog = false;
          this.requestWithdrawLoading = false;
          this.$swal.fire({
            position: "top",
            icon: "error",
            title: `${error.response.data.message}`,
            showConfirmButton: false,
            timer: 5000,
            toast: true,
          });
        });
    },

    async debitWallet() {
      let url = "user/debit";
      let token = this.$store.state.authUser.token;
      let para = {
        userid: this.$store.state.authUser.id,
        amount: this.withdrawalDetails.amount,
      };

      await axios
        .post(url, para, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          console.log(response);
        })
        .catch(() => {});
    },
  },

  async beforeMount() {
    this.activeRequestClass();
    this.loadCourse();
    this.classRequest();
    this.transaction();
    this.walletBalance();
    this.getBankList();
  },
};
</script>

<style>
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
