<template>
  <div>
    <Nav v-if="!isLoggedIn" />
    <section class="bg-white h-full w-full flex justify-center items-center relative">
      <div
        class="lg:pb-10 pb-16 lg:w-3/5 w-full border-t border-slighter rounded-t-2xl relative min-h-screen h-full"
      >
        <div class="absolute top-5 left-3 z-20">
          <button @click="$router.go(-1)" class="">
            <i
              class="fal fa-long-arrow-left text-skilldeepyelow text-center text-xl pr-5"
            ></i>
          </button>
        </div>
        <div
          class="px-6 pb-5 pt-12 border-l border-r border-slighter rounded-t-2xl min-h-screen"
        >
          <div class="p-4">
            <p class="text-3xl pb-5">Privacy Policy</p>
            <div class="text-base">
              <p class="font-semibold py-3">Introduction</p>
              <p>
                SkillNG takes your privacy seriously. And SkillNG will protect the
                information you share with us as much as the law permits. To protect your
                privacy, we employ varying principles in line with global practices in
                customer's data protection policies. By using our services you agree to
                the use of the data collected in accordance with this Privacy Policy. This
                Privacy Policy sets forth the collection and use practices of SkillNG for
                personal data we process in the course of providing the Service. We will
                also detail how and when we might use or share your information and how
                you can correct any errors. This Policy covers all areas where user
                privacy is concerned and outlines the obligations & requirements of the
                users, the digital product, and product owners. This Privacy Policy will
                also explain our online information practices and the choices you can make
                about the way your information is collected and used.
              </p>
              <p class="font-semibold py-3">About Us</p>
              <p>
                SkillNG is a skill development and talent management company that helps
                young people acquire global in-demand digital skills in the categories of
                Design, Data, Coding and Marketing by leveraging on peer-to-peer learning
                networks and critical learning resources while connecting them to
                placement opportunities.
              </p>
              <p class="font-semibold py-3">Information We Collect</p>
              <p class="pb-2">
                SkillNG has compiled various ways in which we collect and store your
                information within our network infrastructure.
              </p>
              <p class="pb-2">
                - Contact Information: Online forms are used to collect basic personal
                information such as name, address, telephone number, email address and
                other personal and business details. These enable you to process payments,
                classes, request information, and obtain support. This information is
                collected and stored on our site.
              </p>
              <p class="pb-2">
                - Payments and Billing Information: When signing up for any of our classes
                or programs, we may require additional personal and payment information
                such as your name, credit card number, mailing address and other
                information needed to complete an online transaction. These transactions
                are concluded using our partners' API, networks or platforms, you will
                have to read their privacy policy in order to know how they collect and
                handle such information. Currently we use the below secure platform to
                handle our transactions.
              </p>
              <p class="pb-2">- https://paystack.com/terms</p>
              <p class="pb-2">
                - Automated Information and Analytics Tools: When you visit our website we
                receive and collect information, which may – in certain circumstances
                constitute personal information – and could include IP address, referral
                URL, exit URL, browser software, and operating system, date/time and/or
                clickstream data. We also use analytics services of third parties to
                collect information about the use of this site. This includes the number
                of visits, pages visited, and popularity of certain content. Analytics
                tools use tracking technologies (like cookies) to recognize a device and
                compile information about the user. They collect information such as what
                pages you visit and how much time you spend on these pages, the IP address
                assigned to you, what operating system and web browser you use, and what
                site you visited prior to visiting our website.
              </p>
              <p class="pb-2">
                - Other sources of information obtained can include: information you
                provide to us in surveys; information that is publicly available; and
                information you consent to us receiving from third parties.
              </p>
              <p class="font-semibold py-3">How We Use your Information</p>
              <p class="pb-2">
                - We use your information we collected through any of the channel above to
                provide and improve our Service; perform internal quality checks; prevent,
                detect, process and investigate malfunctions, incidents, fraudulent or
                other illegal activities, or mitigate the risk of occurrence of the
                aforementioned events; ensure network and information security; create
                statistics on access channels and the use of our Service; contact you, if
                requested by you or if necessary to fulfil the contract or permitted by
                law; enforcing obligations owed to us, and contractual terms, class
                quality assurance, other conditions and; accounting, risk management and
                record keeping.
              </p>
              <p class="pb-2">
                - Depending on the way you are communicating with us, we may use your
                name, email address, telephone number, and the content of your previous
                correspondence to respond to you and provide assistance.
              </p>
              <p class="pb-2">
                - Provided you have not opted out from receiving transactional emails from
                us, we will use your email address to send you emails about relevant
                developments on the Service concerning for example, your network, new
                features, publications, projects, updates from the Q&A section, and emails
                regarding your business development. In any case, you can opt out from
                receiving sponsored emails at any time by clicking the “Unsubscribe” link
                within an email.
              </p>
              <p class="pb-2">
                - We use your personal data to generate statistics/analytics which pertain
                to you or your customers' behaviors and achievements. We also generate
                statistics for internal purposes in order to constantly improve the
                Service and its features. We also conduct research and development to
                maintain quality and improve our Service and the User experience.
              </p>
              <p class="font-semibold py-3">Sharing of Information</p>
              <p class="pb-2">
                - SkillNG, as described below, may share certain personal information
                belonging to you in accordance with the situations below:
              </p>
              <p class="pb-2">
                Our Employees and Independent Contractors: We do disclose personal
                information with some of our employees and our independent contractors.
                This helps us provide services and process information on our behalf and
                also for administrative, billing, and tax purposes, All employees and
                independent contractors are required to comply strictly with this Privacy
                Policy and they all sign our non-disclosure agreements.
              </p>
              <p class="pb-2">
                - Legal Process: We may disclose information about you in response to
                subpoenas, court orders, or other legitimate governmental requests.
              </p>
              <p class="pb-2">
                - Business Transfers and Corporate Reorganization: If we are involved in a
                merger, acquisition, a sale of all or a substantial portion of our assets,
                or other similar sale transactions, your information will be transferred
                as part of that transaction. We will notify you by email and/or a
                prominent notice on our website of any such transfer and any choices you
                may have regarding your information.
              </p>
              <p class="pb-2">
                - Your Consent: With your explicit consent or at your direction we may
                share or disclose authorized information with third parties. We will not,
                however, sell, rent, share or otherwise disclose personally identifiable
                information for commercial purposes in any way that is contrary to this
                Privacy Policy. We will take all reasonable steps to notify you when and
                what information might go to third parties and you will be able to choose
                whether to share it or not.
              </p>
              <p class="font-semibold py-3">Account Termination and Data Erasure</p>
              <p>
                You may also send a special request to SkillNG that we erase any held
                personal information in circumstances where you believe it is no longer
                necessary for us to hold the personal information; when we are processing
                it on the basis of your consent and you wish to withdraw consent; when we
                are processing your data on the basis of our legitimate interest and you
                object to such processing; when you no longer wish us to use your data to
                send you marketing; or you believe we are unlawfully processing your data.
                However, SkillNG will require as much detail as possible regarding your
                reasons for the request before the request can be granted. Notwithstanding
                the above, your transaction and class records are always kept intact and
                may not be erased in compliance to relevant financial authorities and
                investigations.
              </p>
              <p class="font-semibold py-3">Age Restrictions</p>
              <p>
                Our services are not directed to people under 18 and we do not knowingly
                collect personal information from anyone under 18. If a parent or guardian
                becomes aware that their child has provided us with personal information
                without such parent or guardian’s consent, they should contact us. If we
                become aware that a child under 18 has provided us with personal
                information, it will be deleted from our files.
              </p>
              <p class="font-semibold py-3">Changes to this Privacy Policy</p>
              <p>
                We reserve the right to modify this Privacy Policy at any time. If we
                decide to change our Privacy Policy, we will post updates to our website
                and any other places deemed appropriate. We may notify users by email, or
                by means of a notice via our website, at least fifteen (15) days before
                the changes take effect. This will ensure that you are aware of what
                information we collect, how we use it, and under what circumstances, if
                any, we disclose it.
              </p>
              <p class="font-semibold py-3">Contact Us</p>
              <p>
                If you have any questions about this Privacy Policy or the practices
                described herein, you may email
                <a class="text-blue-500" href="mailto:hello@skillng.com">
                  hello@skillng.com</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Nav from "../../components/navs/HomeTopNav";

export default {
  components: {
    Nav,
  },
  data() {
    return {};
  },

  methods: {},
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
  },
};
</script>

<style></style>
