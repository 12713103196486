import firebase from "firebase/app";
import "firebase/database";
import "firebase/firestore";



const firebaseConfig = {
    apiKey: "AIzaSyCGGdBakYkZEtf80gv88IGsWE0UKy3eBlw",
    authDomain: "skillng-303221.firebaseapp.com",
    databaseURL: "https://skillng-303221-default-rtdb.firebaseio.com",
    projectId: "skillng-303221",
    storageBucket: "skillng-303221.appspot.com",
    messagingSenderId: "932795777840",
    appId: "1:932795777840:web:d1c72017e7c0bc5669fef5"
  };

  firebase.initializeApp(firebaseConfig);

  export const fireDB = firebase.database();
  export const fireBase = firebase;
  // const db = firebase.database();
  // export const chatsRef = db.ref("chats");
  // export const usersRef = db.ref("users");


export default firebase
