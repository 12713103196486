<template>
  <section class="">
    <section
      v-if="dropdown"
      @click="dropdown = false"
      class="z-40 h-screen w-screen fixed top-0 overflow-hidden bg-opacity-50 bg-gray-200"
    ></section>

    <!--  -->
    <div
      v-if="!dropdown"
      @click="dropdown = !dropdown"
      class="lg:hidden block h-screen w-3 left-0 top-0 bg-skilldeepyelow cursor-pointer"
    ></div>

    <div
      v-if="dropdown"
      class="w-2/3 px-1 fixed bg-white h-screen z-50 flex flex-col justify-between"
    >
      <div class="mt-14">
        <router-link v-for="tab in tabs" :key="tab.title" :to="{ name: tab.route }">
          <button
            class="flex items-center mb-1 py-3 px-4 hover:bg-skilldeepyelow rounded-full mr-auto w-full"
            :class="
              id === tab.id
                ? 'text-skillwhite rounded-full bg-skilldeepyelow font-medium'
                : 'text-skillblack font-regular'
            "
            @click="(id = tab.id), (dropdown = false)"
          >
            <i
              :class="[id === tab.id ? 'font-semibold ' : '', tab.icon]"
              class="text-lg mr-4"
            ></i>
            <p class="">{{ tab.title }}</p>
          </button>
        </router-link>
        <button
          @click="logout"
          class="flex items-center mb-1 py-3 px-4 hover:bg-skilldeepyelow rounded-full mr-auto w-full"
        >
          <i class="fad fa-sign-out text-lg mr-4"></i>
          <p class="">Sign Out</p>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      dropdown: false,
      tabs: [
        {
          icon: "far fa-users",
          title: "Users",
          id: "users",
          route: "AdminDashboard",
        },
        {
          icon: "far fa-graduation-cap",
          title: "School",
          id: "school",
          route: "AdminSchools",
        },
        {
          icon: "far fa-chalkboard-teacher",
          title: "Group Class",
          id: "Gclasses",
          route: "AdminGroupClass",
        },
        {
          icon: "far fa-chalkboard-teacher",
          title: "Private Class",
          id: "Pclasses",
          route: "AdminPrivateClass",
        },
        {
          icon: "fal fa-list-ul",
          title: "Tutor Request List",
          id: "tutorRequest",
          route: "AdminTutorRequestList",
        },
        {
          icon: "fal fa-list-ul",
          title: "Referral List",
          id: "referralList",
          route: "AdminReferralList",
        },
        {
          icon: "fal fa-gift",
          title: "Coupon",
          id: "coupon",
          route: "AdminCoupon",
        },
        {
          icon: "fal fa-comment-alt-lines",
          title: "SMS",
          id: "sms",
          route: "AdminSMS",
        },
      ],
      id: "dashboard",
    };
  },
  methods: {
    logout() {
      localStorage.removeItem("skill_user");
      localStorage.removeItem("skill_jwt");
      this.$store.commit("setAuthUser", {});
      this.$store.commit("setIsAdminLoggedIn", false);
      this.$router.push({ name: "AdminSignin" });
    },
  },
};
</script>

<style></style>
