<template>
  <div class="overflow-y-auto">
    <div
      class="px-6 pt-4 pb-3 border-b border-slighter flex items-center justify-between bg-white"
    >
      <div class="flex">
        <button @click="$router.go(-1)" class="">
          <i
            class="fal fa-long-arrow-left text-skilldeepyelow text-center text-xl pr-5"
          ></i>
        </button>
        <h1 class="text-base font-medium text-skillblack">My Balance</h1>
      </div>
    </div>
    <!--  -->
    <div class="p-4 mx-5">
      <!-- <p class="font-semibold py-2">Wallet</p> -->
      <div class="flex items-baseline">
        <i v-if="walletLoading" class="animate-spin text-3xl fad fa-circle-notch"></i>
        <p v-else class="font-normal text-3xl pb-1">
          ₦
          {{
            mywalletBalance.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          }}
        </p>
        <p class="text-gray-600 text-xs pl-5 mb-3">Available balance</p>
      </div>
      <div class="mt-5">
        <vs-button border block @click="rechargeAmount = !rechargeAmount">
          <p class="text-black">Recharge</p>
        </vs-button>
      </div>
      <!-- <vs-button border block>
        <p class="text-black">Withdraw</p>
      </vs-button> -->
    </div>

    <!-- Wallet History  -->
    <div class="p-4 pb-5 mb-24 rounded-t-3xl lg:ml-8 ml-0 pb-20">
      <p class="font-semibold py-2">Transaction History</p>

      <vs-table striped>
        <template #thead>
          <vs-tr>
            <vs-th class="w-2/12"> Date </vs-th>
            <vs-th class="w-3/12"> Amount </vs-th>
            <vs-th class="w-7/12"> Purpose </vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr :key="i" v-for="(tr, i) in transactions" :data="tr">
            <vs-td class="w-2/12">
              {{ tr.dateofTransaction.slice(0, 10) }}
            </vs-td>
            <vs-td class="w-3/12">
              {{ tr.amount.toLocaleString() }}
            </vs-td>
            <vs-td class="w-7/12">
              {{ tr.purpose }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>

    <!--  -->
    <vs-dialog scroll width="320px" v-model="rechargeAmount">
      <div class="p-3 overflow-hidden">
        <p class="font-semibold pb-3">Enter the amount to add to your balance.</p>
        <!--  -->
        <vs-input block type="number" v-model="amount" @keydown.enter="processPayment">
          <template #icon> ₦ </template>
        </vs-input>
        <p v-if="amount < 101 && amount != ''" class="text-red-500 text-sm p-2">
          Amount must be greater than 100
        </p>
        <div class="flex justify-center py-4">
          <vs-button
            :disabled="amount !== '' && amount > 100 ? false : true"
            color="#FFCC29"
            class=""
          >
            <paystack
              :amount="amount * 100"
              :email="this.$store.state.authUser.email"
              :paystackkey="'pk_live_a87e4916f0e775175aa3770a34a9bf101a8030ca'"
              :reference="reference"
              :callback="processPayment"
              :close="paystackDialogClose"
            >
              <p class="text-sm font-semibold px-3 py-1 text-skillblack">Make Payment</p>
            </paystack>
          </vs-button>
        </div>
      </div>
    </vs-dialog>
  </div>
</template>

<script>
import axios from "../../plugin/axios";
import paystack from "vue-paystack";

export default {
  components: {
    paystack,
  },
  data() {
    return {
      walletLoading: false,
      loading: false,
      rechargeAmount: false,
      mywalletBalance: 0,
      amount: "",
      transactions: [],
    };
  },
  mounted() {
    this.walletBalance();
    this.transaction();
  },
  methods: {
    paystackDialogClose() {},
    async walletBalance() {
      this.walletLoading = true;
      let token = this.$store.state.authUser.token;
      await axios
        .get(`user/profile/${this.$store.state.authUser._id}`, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          this.mywalletBalance = response.data.data.tutorWallet;
          let newProfile = response.data.data;
          this.$store.commit("setAuthProfile", newProfile);
          this.walletLoading = false;
        })
        .catch(() => {
          this.$swal.fire({
            position: "center",
            icon: "error",
            title: "Unable to load balance, please try again",
            showConfirmButton: false,
            timer: 3000,
            toast: true,
          });
        });
    },
    async processPayment() {
      const loading = this.$vs.loading();
      let token = this.$store.state.authUser.token;
      let url = "user/credit";
      let walletData = {
        userid: this.$store.state.authUser._id,
        amount: this.amount,
        refNo: this.reference,
        purpose: "Wallet topup",
        type: "credit",
        notificationData: {
          message: `Your SkillNG balance has been credited with ₦${this.amount}`,
          url: "https://app.skillng.com/settings/my-balance",
          imgUrl:
            "https://res.cloudinary.com/skillng/image/upload/v1626132590/skillng-app/t2fpd4ddbr98vz1rwqc2.png",
        },
      };
      await axios
        .post(url, walletData, {
          headers: {
            "x-access-token": token,
          },
        })
        .then(() => {
          this.rechargeAmount = false;
          loading.close();
          this.walletBalance();
          this.transaction();
          this.$swal.fire({
            position: "center",
            icon: "success",
            title: "Balance top-up successful.",
            showConfirmButton: false,
            timer: 3000,
            toast: true,
          });
        })
        .catch((error) => {
          this.rechargeAmount = false;
          loading.close();
          this.walletBalance();
          this.$swal.fire({
            position: "center",
            icon: "error",
            title: `${error}`,
            showConfirmButton: false,
            timer: 3000,
            toast: true,
          });
        });
    },
    async transaction() {
      let token = this.$store.state.authUser.token;
      let url = `wallet/user/${this.$store.state.authUser._id}`;
      await axios
        .get(url, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          console.log(response);
          let data = response.data.data;
          this.transactions = data;
          // console.log(response);
        })
        .catch(() => {});
    },
  },
  computed: {
    reference() {
      let text = "";
      let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    },
  },
};
</script>

<style scoped></style>
