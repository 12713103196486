<template>
  <div class="overflow-y-auto">
    <div
      class="px-6 pt-4 pb-3 border-b border-slighter flex items-center justify-between bg-white"
    >
      <div class="flex">
        <button @click="$router.go(-1)" class="">
          <i
            class="fal fa-long-arrow-left text-skilldeepyelow text-center text-xl pr-5"
          ></i>
        </button>
        <h1 class="text-base font-medium text-skillblack">Referrals</h1>
      </div>
    </div>
    <!--  -->
    <div class="px-4 pt-4 mx-5">
      <p class="text-gray-600 pb-2">
        Invite your friends to sign up for any of our classes and get 10% on every class
        they sign up for.
      </p>
    </div>
    <div class="sm:flex block justify-between mt-2 mx-5 mb-5">
      <div class="m-3">
        <div class="flex justify-center">
          <div
            class="rounded-full bg-yellow-100 h-12 w-12 flex justify-center items-center relative"
          >
            <i class="fas fa-envelope-open-text text-yellow-500"></i>
            <div
              class="absolute bottom-10 right-0 bg-yellow-500 rounded-full h-4 w-4 flex justify-center items-center"
            >
              <span class="text-white font-semibold text-xs">1</span>
            </div>
          </div>
        </div>
        <p class="font-semibold text-center pt-2">Send invitation</p>
        <p class="text-gray-600 text-center">
          Earn extra cash by sharing your SkillNG referral links with friends.
        </p>
      </div>
      <div class="m-3">
        <div class="flex justify-center">
          <div
            class="rounded-full bg-yellow-100 h-12 w-12 flex justify-center items-center relative"
          >
            <i class="fas fa-file-user text-yellow-500"></i>
            <div
              class="absolute bottom-10 right-0 bg-yellow-500 rounded-full h-4 w-4 flex justify-center items-center"
            >
              <span class="text-white font-semibold text-xs">2</span>
            </div>
          </div>
        </div>
        <p class="font-semibold text-center pt-2">Registration</p>
        <p class="text-gray-600 text-center">
          Let your friends register using your referral link or your mobile number as
          their referral code.
        </p>
      </div>
      <div class="m-3">
        <div class="flex justify-center">
          <div
            class="rounded-full bg-yellow-100 h-12 w-12 flex justify-center items-center relative"
          >
            <i class="fas fa-sack-dollar text-yellow-500"></i>
            <div
              class="absolute bottom-10 right-0 bg-yellow-500 rounded-full h-4 w-4 flex justify-center items-center"
            >
              <span class="text-white font-semibold text-xs">3</span>
            </div>
          </div>
        </div>
        <p class="font-semibold text-center pt-2">Make some money</p>
        <p class="text-gray-600 text-center">
          You get 10% on every amount spent on class registration. You can become a
          millionaire!
        </p>
      </div>
    </div>

    <!-- <div class="p-4 mx-5">
      <p class="text-lg pb-2">Invite your Friends</p>
      <p class="text-gray-600">
        Insert your friend's email address and send them an invitation to join SKillNG!
      </p>
      <div class="my-3 mr-5">
        <vs-input
          success
          block
          size="large"
          icon-after
          type="email"
          v-model="email"
          placeholder="Email"
        >
          <template #icon> <i @click="test()" class="fas fa-paper-plane"></i> </template>
        </vs-input>
      </div>
    </div> -->
    <div class="p-4 mx-5">
      <p class="text-lg pb-2">Share the referal link</p>
      <p class="text-gray-600">You can share your referral link by sharing this link.</p>
      <div class="my-3 mr-5 flex justify-between items-center bg-gray-100 rounded-lg p-3">
        <p class="break-all px-2">{{ referealCode }}</p>
        <p
          @click="copyLink"
          class="cursor-pointer px-2 text-blue-600 font-semibold text-sm"
        >
          Copy link
        </p>
      </div>
      <div class="flex flex-wrap justify-center items-center mt-3">
        <p class="text-3xl space-x-4">
          <ShareNetwork
            network="Facebook"
            :url="referealCode"
            title="Hi! I am inviting you to register for a class at SkillNG."
            description="My SKillNG Referal Link"
            hashtags="SkillNG"
            quote="My SKillNG Referal Link"
          >
            <i class="fab fa-facebook cursor-pointer"></i>
          </ShareNetwork>
          <ShareNetwork
            network="WhatsApp"
            :url="referealCode"
            title="Hi! I am inviting you to register for a class at SkillNG."
            description="My SKillNG Referal Link"
          >
            <i class="fab fa-whatsapp cursor-pointer"></i>
          </ShareNetwork>
          <ShareNetwork
            network="Twitter"
            :url="referealCode"
            title="Hi! I am inviting you to register for a class at SkillNG."
            twitter-user="skill_ng"
            hashtags="SkillNG"
          >
            <i class="fab fa-twitter cursor-pointer"></i>
          </ShareNetwork>
          <ShareNetwork
            network="LinkedIn"
            :url="referealCode"
            title="Hi! I am inviting you to register for a class at SkillNG."
            description="My SKillNG Referal Link"
            hashtags="SkillNG"
            quote="My SKillNG Referal Link"
          >
            <i class="fab fa-linkedin cursor-pointer"></i>
          </ShareNetwork>
        </p>
      </div>
    </div>
    <div class="p-4 mx-5 mb-5">
      <p class="text-lg pb-2">Referal Lists</p>
      <div class="">
        <vs-table striped>
          <template #thead>
            <vs-tr>
              <vs-th class="w-6/12"> Name </vs-th>
              <vs-th class="w-6/12"> Mobile </vs-th>
            </vs-tr>
          </template>
          <template #tbody>
            <vs-tr :key="i" v-for="(tr, i) in referralList" :data="tr">
              <vs-td class="w-6/12">
                {{ tr.fullName }}
              </vs-td>
              <vs-td class="w-6/12">
                {{ tr.mobileNo }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "../../plugin/axios";

export default {
  data() {
    return {
      email: "",
      referralList: [],
    };
  },
  mounted() {
    this.referralListUnit();
  },
  methods: {
    async referralListUnit() {
      this.referralList = await this.$store.state.authProfile.referralList;
    },
    copyLink() {
      navigator.clipboard.writeText(this.referealCode);
      this.$swal.fire({
        position: "top-right",
        icon: "success",
        title: "Referral link copied to clipboard",
        showConfirmButton: false,
        timer: 3000,
        toast: true,
      });
    },
  },
  computed: {
    referealCode() {
      return (
        "https://app.skillng.com/signup?referral=" + this.$store.state.authUser.mobileNo
      );
    },
  },
};
</script>

<style scoped></style>
