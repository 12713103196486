<template>
  <section class="bg-gray-100 w-full px-6 py-2 h-full overflow-y-auto">
    <div class="px-2 py-5 mt-8 lg:px-8 mx-0">
      <div class="">
        <h1 class="text-xl font-medium text-skillblack lg:px-6">Coupon</h1>
      </div>

      <!--  -->
      <div class="w-full lg:px-4 mt-4">
        <div class="flex mx-4 space-x-5">
          <vs-button @click="tab = 1" class="">
            <h3 class="font-semibold text-base text-gray-800">Create a Coupon</h3>
          </vs-button>
          <vs-button @click="tab = 2" class="">
            <h3 class="font-semibold text-base text-gray-800">Coupon List</h3>
          </vs-button>
        </div>
      </div>
      <!--   -->
      <div class="w-full lg:px-4 mt-3">
        <div class="pt-8 shadow-lg rounded bg-white border pb-4">
          <!-- Tab 1 -->
          <div v-if="tab == 1">
            <div class="w-full lg:px-4 mt-5">
              <vs-input
                label="Coupon Name"
                v-model="newCoupon.name"
                class="mb-8"
                block
                placeholder="Coupon Name"
              />
              <vs-input
                label="Coupon Amount"
                v-model="newCoupon.amount"
                class="mb-8"
                block
                type="number"
                placeholder="Coupon Amount"
              />
              <vs-input
                label="Number of Usage"
                v-model="newCoupon.maxUsage"
                class="mb-8"
                block
                type="number"
                placeholder="Number of Usage"
              />
              <div class="flex justify-between items-start">
                <vs-input
                  label="Coupon Code"
                  v-model="newCoupon.code"
                  class="mb-8"
                  block
                  placeholder="Coupon Code"
                />
                <vs-button class="px-2" @click="generateCode"> Generate </vs-button>
              </div>
              <vs-input
                type="date"
                v-model="newCoupon.expire"
                block
                label="Expire Date"
              />
            </div>
            <div class="flex justify-center items-center mt-8">
              <vs-button
                :disabled="
                  newCoupon.amount !== '' &&
                  newCoupon.code !== '' &&
                  newCoupon.expire !== '' &&
                  newCoupon.name !== '' &&
                  newCoupon.maxUsage > 0
                    ? false
                    : true
                "
                class=""
                @click="submit"
              >
                Submit
              </vs-button>
            </div>
          </div>

          <!-- Tab 2 -->
          <div v-if="tab == 2" class="block w-full overflow-x-auto">
            <table class="w-full">
              <thead>
                <tr class="bg-gray-100 border-gray-200 text-gray-600">
                  <th
                    class="py-3 px-4 border text-xs uppercase border-l-0 border-r-0 font-semibold text-left"
                  >
                    Name
                  </th>
                  <th
                    class="px-4 border text-xs uppercase border-l-0 border-r-0 font-semibold text-left"
                  >
                    Code
                  </th>
                  <th
                    class="px-4 border text-xs uppercase border-l-0 border-r-0 font-semibold text-left"
                  >
                    Usage
                  </th>
                  <th
                    class="px-4 border text-xs uppercase border-l-0 border-r-0 font-semibold text-left"
                  >
                    Amount
                  </th>
                  <th
                    class="px-4 border text-xs uppercase border-l-0 border-r-0 font-semibold text-left"
                  >
                    Expire
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(list, index) in couponList"
                  :key="list.index"
                  class="border-t cursor-pointer hover:bg-slightyellow"
                  @click="couponDetails(index)"
                >
                  <td class="py-2 pl-4">
                    <p>
                      {{ list.name }}
                    </p>
                  </td>
                  <td class="py-2 pl-4">
                    <p>
                      {{ list.code }}
                    </p>
                  </td>
                  <td class="py-2 pl-4">
                    <p>{{ list.usage }} / {{ list.maxUsage }}</p>
                  </td>
                  <td class="py-2 pl-4" v-if="list.amount">
                    <p>{{ list.amount.toLocaleString() }}</p>
                  </td>
                  <td class="py-2 pl-4">
                    {{ new Date(list.expire).toDateString() }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- Coupon User Usage Dialog  -->
      <vs-dialog width="750px" v-model="couponDetailDialog">
        <div class="con-content mt-3">
          <p class="py-3 font-semibold">Couper Users Detials</p>
        </div>
        <div cl>
          <table class="w-full">
            <thead>
              <tr class="bg-gray-100 border-gray-200 text-gray-600">
                <th
                  class="py-3 px-4 border text-xs uppercase border-l-0 border-r-0 font-semibold text-left"
                >
                  User Name
                </th>
                <th
                  class="px-4 border text-xs uppercase border-l-0 border-r-0 font-semibold text-left"
                >
                  Email
                </th>
                <th
                  class="px-4 border text-xs uppercase border-l-0 border-r-0 font-semibold text-left"
                >
                  Mobile No
                </th>
                <th
                  class="px-4 border text-xs uppercase border-l-0 border-r-0 font-semibold text-left"
                >
                  Class Name
                </th>
                <th
                  class="px-4 border text-xs uppercase border-l-0 border-r-0 font-semibold text-left"
                >
                  Used Date
                </th>
              </tr>
            </thead>
            <tbody v-if="couponListReady == true">
              <tr
                v-for="usagelist in couponList[couponIndex].usageUsers"
                :key="usagelist.index"
                class="border-t hover:bg-slightyellow"
              >
                <td class="py-2 pl-4">
                  <p>
                    {{ usagelist.userid.fullName }}
                  </p>
                </td>
                <td class="py-2 pl-4">
                  <p>
                    {{ usagelist.userid.email }}
                  </p>
                </td>
                <td class="py-2 pl-4">
                  <p>{{ usagelist.userid.mobileNo }}</p>
                </td>
                <td class="py-2 pl-4">
                  <p>{{ usagelist.classid.name }}</p>
                </td>
                <td class="py-2 pl-4">
                  {{ new Date(usagelist.usedDate).toDateString() }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </vs-dialog>
      <!--  -->
    </div>
  </section>
</template>

<script>
import axios from ".././plugin/axios";
export default {
  components: {},
  data() {
    return {
      tab: 1,
      newCoupon: { name: "", amount: "", maxUsage: "1", code: "", expire: "" },
      couponList: [],
      couponListReady: false,
      couponDetailDialog: false,
      couponIndex: 0,
    };
  },
  methods: {
    couponDetails(index) {
      this.couponDetailDialog = true;
      this.couponIndex = index;
    },

    generateCode() {
      let text = "";
      let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 8; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      return (this.newCoupon.code = text);
    },
    async submit() {
      this.loading = true;
      let token = this.$store.state.authUser.token;

      let url = "coupon/create/";
      await axios
        .post(url, this.newCoupon, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          this.clearData();
          this.couponListFetch();
          this.tab = 2;
          console.log(response);
          this.$swal.fire({
            position: "top-end",
            icon: "success",
            title: "Coupon created successfully.",
            showConfirmButton: false,
            timer: 3000,
            toast: true,
          });
        })
        .catch((error) => {
          this.$swal.fire({
            position: "top-end",
            icon: "error",
            title: `${error.response.data.message}`,
            showConfirmButton: false,
            timer: 6000,
            toast: true,
          });
        });
    },
    clearData() {
      this.newCoupon = { name: "", amount: "", maxUsage: "1", code: "", expire: "" };
    },
    async couponListFetch() {
      this.couponList = [{ name: "Loading data" }];
      let url = "coupon/all";
      let token = this.$store.state.authUser.token;

      await axios
        .get(url, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          // console.log(response);
          let data = response.data.data;
          this.couponList = data.Coupons;
          this.couponListReady = true;
        })
        .catch(() => {});
    },
  },
  async mounted() {
    this.couponList = [{ name: "Loading..." }];

    this.couponListFetch();
  },
};
</script>

<style></style>
