<template>
  <section class="flex relative">
    <div class="min-h-screen h-screen border-l mt-2.5 border-slighter fixed"></div>
    <div
      class="lg:pb-10 pb-16 lg:w-3/5 w-full border-t border-slighter rounded-t-2xl relative min-h-screen h-full"
    >
      <div
        class="px-6 py-5 flex items-center border-l border-r border-slighter rounded-t-2xl"
      >
        <button @click="$router.go(-1)" class="">
          <i
            class="fal fa-long-arrow-left text-skilldeepyelow text-center text-xl pr-5"
          ></i>
        </button>
        <h1 class="text-xl font-medium text-skillblack">Group Class</h1>
      </div>

      <!--  -->

      <div class="w-full">
        <div class="relative block overflow-visible">
          <div class="w-full h-full"></div>
          <div class="h-96 z-10">
            <!--  -->
            <img
              v-if="classData.classImage"
              class="inset-0 h-full w-full object-cover rounded-b-2rem bg-gray-200 border border-t-0 border-slighter pointer-events-none"
              :src="
                'https://res.cloudinary.com/skillng/image/fetch/' + classData.classImage
              "
            />
          </div>
          <!-- change the hidden to flex -->
          <div class="absolute top-2 right-3 hidden justify-between">
            <vs-button @click="bookmark" icon color="#FFCC29">
              <i class="far fa-bookmark"></i>
            </vs-button>
            <vs-button icon color="#000">
              <i class="fas fa-share-alt"></i>
            </vs-button>
          </div>
          <!--  -->
          <div class="w-full lg:px-12 md:px-10 px-6 absolute -mt-24">
            <div
              class="mb-5 p-5 w-full rounded-xl border border-gray-300 bg-white shadow-md"
            >
              <p class="text-xl mb-2 font-semibold">{{ classData.name }}</p>
              <div class="flex items-start justify-between">
                <p class="font-normal mb-4">{{ classData.description }}</p>
                <div
                  class="text-center rounded-lg bg-gray-200 shadow-md text-skillblack ml-2 p-2 break-words"
                >
                  <p v-if="classData.dueDate" class="font-bold">
                    {{ new Date(classData.dueDate).toDateString() }}
                  </p>
                </div>
              </div>

              <div v-if="classData.startClass.status === false && !isJoined">
                <div v-if="classData.price > 0" class="flex justify-center pb-3">
                  <vs-input
                    :disabled="couponDisable"
                    v-model="couponCode"
                    placeholder="Coupon Code"
                  />
                  <vs-button
                    :disabled="couponCode == '' || couponSubmited == true"
                    :loading="couponLoading"
                    @click="validateCoupon"
                  >
                    Validate
                  </vs-button>
                </div>
              </div>

              <div class="flex justify-center">
                <div v-if="classData.startClass.status === true && !isJoined">
                  <p class="text-lg font-semibold px-3 py-1 text-skillblack">
                    ~Class in Session~
                  </p>
                </div>
                <!--  -->
                <div v-if="classData.startClass.status === true && isJoined">
                  <p
                    class="text-lg font-semibold px-3 py-1 my-2 text-center text-skillblack"
                  >
                    ~Class in Session~
                  </p>
                  <div class="flex flex-wrap justify-center items-center">
                    <vs-button
                      color="#FFCC29"
                      class="mx-6"
                      @click="$router.push({ name: 'Messages' })"
                    >
                      <p class="text-sm font-semibold px-3 py-1 text-skillblack">
                        Training Group Chat
                      </p>
                    </vs-button>

                    <vs-button color="#FFCC29" class="" @click="ReviewDialogOpen = true">
                      <p class="text-sm font-semibold px-2 py-1 mx-6 text-skillblack">
                        Write A Review
                      </p>
                    </vs-button>
                  </div>
                </div>
                <!--  -->
                <div v-if="classData.startClass.status === false && isJoined">
                  <div class="flex flex-wrap justify-center items-center">
                    <vs-button
                      color="#FFCC29"
                      class="mx-6"
                      @click="$router.push({ name: 'Messages' })"
                    >
                      <p class="text-sm font-semibold px-3 py-1 text-skillblack">
                        Training Group Chat
                      </p>
                    </vs-button>

                    <vs-button color="#FFCC29" class="" @click="ReviewDialogOpen = true">
                      <p class="text-sm font-semibold px-2 py-1 mx-6 text-skillblack">
                        Write A Review
                      </p>
                    </vs-button>
                  </div>
                </div>
                <!--  -->
                <div
                  v-if="classData.startClass.status === false && !isJoined"
                  class="flex flex-wrap justify-center items-center"
                >
                  <p
                    v-if="couponOldAmount != null"
                    class="line-through text-sm font-normal px-3 py-1 text-red-400"
                  >
                    ₦{{ couponOldAmount.toLocaleString() }}
                  </p>
                  <p
                    v-if="classData.price > 0"
                    class="text-sm font-semibold px-3 py-1 text-skillblack"
                  >
                    ₦{{ classData.price.toLocaleString() }}
                  </p>

                  <vs-button v-if="classData.price > 0" color="#FFCC29" class="mx-6">
                    <p
                      v-if="walletbalance > 0"
                      @click="walletDailog = true"
                      class="text-sm font-semibold px-3 py-1 text-skillblack"
                    >
                      Make Payment
                    </p>
                    <paystack
                      v-else
                      :amount="classData.price * 100"
                      :email="this.$store.state.authUser.email"
                      :paystackkey="'pk_live_a87e4916f0e775175aa3770a34a9bf101a8030ca'"
                      :reference="reference"
                      :callback="processPayment"
                      :close="paystackDialogClose"
                    >
                      <p class="text-sm font-semibold px-3 py-1 text-skillblack">
                        Make Payment
                      </p>
                    </paystack>
                  </vs-button>

                  <p
                    v-if="classData.price <= 0"
                    class="text-sm font-semibold px-3 py-1 text-skillblack"
                  >
                    It's your lucky day,
                  </p>

                  <vs-button
                    v-if="classData.price <= 0"
                    color="#FFCC29"
                    class="mx-6"
                    @click="freeClass"
                  >
                    <p class="text-sm font-semibold px-3 py-1 text-skillblack">
                      Join Class For Free
                    </p>
                  </vs-button>
                </div>
                <!--  -->
              </div>
              <div
                v-if="
                  classData.startClass.status === false &&
                  !isJoined &&
                  classData.classAudit === true
                "
                class="flex justify-center items-center mt-2"
              >
                <vs-button
                  v-if="classData.price > 0"
                  color="#FFCC29"
                  class="mx-6"
                  @click="auditClassDailog = true"
                >
                  <p class="text-sm font-semibold px-3 py-1 text-skillblack">
                    Audit Class for Free
                  </p>
                </vs-button>
              </div>
              <!--  -->
              <!--  -->
              <div v-if="isJoined && !isPaid && classData.price > 1">
                <div class="mt-5">
                  <hr />
                </div>
                <p class="pt-5 px-3 py-1 font-medium text-red-600 text-center italic">
                  You're currently auditing this class. This means you'll have access to
                  attend 25% of the class for free. However, you'll need to pay for the
                  class to access the remaining class in the Cohort and get certified.
                </p>
                <div class="flex justify-center pb-3 mt-4">
                  <vs-input
                    :disabled="couponDisable"
                    v-model="couponCode"
                    placeholder="Coupon Code"
                  />
                  <vs-button
                    :disabled="couponCode == '' || couponSubmited == true"
                    :loading="couponLoading"
                    @click="validateCoupon"
                  >
                    Validate
                  </vs-button>
                </div>

                <div class="flex flex-wrap justify-center items-center">
                  <div>
                    <p
                      v-if="couponOldAmount != null"
                      class="line-through text-sm font-normal px-3 py-1 text-red-400"
                    >
                      ₦{{ couponOldAmount.toLocaleString() }}
                    </p>
                    <p
                      v-if="classData.price > 0"
                      class="text-sm font-semibold px-3 py-1 text-skillblack"
                    >
                      ₦{{ classData.price.toLocaleString() }}
                    </p>
                  </div>

                  <div class="flex flex-wrap justify-center items-center">
                    <vs-button v-if="classData.price > 0" color="#FFCC29" class="mx-6">
                      <p
                        v-if="walletbalance > 0"
                        @click="walletDailog = true"
                        class="text-sm font-semibold px-3 py-1 text-skillblack"
                      >
                        Make Payment
                      </p>
                      <paystack
                        v-else
                        :amount="classData.price * 100"
                        :email="this.$store.state.authUser.email"
                        :paystackkey="'pk_live_a87e4916f0e775175aa3770a34a9bf101a8030ca'"
                        :reference="reference"
                        :callback="processPaymentAudit"
                        :close="paystackDialogClose"
                      >
                        <p class="text-sm font-semibold px-3 py-1 text-skillblack">
                          Make Payment
                        </p>
                      </paystack>
                    </vs-button>
                    <p
                      v-if="classData.price <= 0"
                      class="text-sm font-semibold px-3 py-1 text-skillblack"
                    >
                      It's your lucky day,
                    </p>

                    <vs-button
                      v-if="classData.price <= 0"
                      color="#FFCC29"
                      class="mx-6"
                      @click="processPaymentAudit"
                    >
                      <p class="text-sm font-semibold px-3 py-1 text-skillblack">
                        Confirm your payment
                      </p>
                    </vs-button>
                  </div>
                </div>
              </div>
              <div class="my-5">
                <hr />
              </div>
              <!--  -->
              <!--  -->
              <div class="flex flex-wrap justify-center items-center mt-3">
                <p class="text-3xl space-x-4">
                  <ShareNetwork
                    network="Facebook"
                    :url="`https://app.skillng.com/shared/` + classData._id"
                    :title="classData.name"
                    hashtags="SkillNG"
                    :quote="classData.name"
                  >
                    <i class="fab fa-facebook cursor-pointer"></i>
                  </ShareNetwork>
                  <ShareNetwork
                    network="WhatsApp"
                    :url="`https://app.skillng.com/shared/` + classData._id"
                    :title="
                      'Hi! I am inviting you to register for the course below at SkillNG. \n \n *' +
                      classData.name +
                      '*'
                    "
                    :description="classData.description"
                  >
                    <i class="fab fa-whatsapp cursor-pointer"></i>
                  </ShareNetwork>
                  <ShareNetwork
                    network="Twitter"
                    :url="`https://app.skillng.com/shared/` + classData._id"
                    :title="classData.name"
                    twitter-user="skill_ng"
                    hashtags="SkillNG"
                  >
                    <i class="fab fa-twitter cursor-pointer"></i>
                  </ShareNetwork>
                  <ShareNetwork
                    network="LinkedIn"
                    :url="`https://app.skillng.com/shared/` + classData._id"
                    :title="classData.name"
                    hashtags="SkillNG"
                    :quote="classData.name"
                  >
                    <i class="fab fa-linkedin cursor-pointer"></i>
                  </ShareNetwork>
                  <ShareNetwork
                    network="Messenger"
                    :url="`https://app.skillng.com/shared/` + classData._id"
                    :title="classData.name"
                    :description="classData.description"
                  >
                    <i class="fab fa-facebook-messenger cursor-pointer"></i>
                  </ShareNetwork>
                  <ShareNetwork
                    network="Email"
                    :url="
                      'Hi! I am inviting you to register for the course below at SkillNG. \n \n' +
                      'https://app.skillng.com/shared/' +
                      classData._id
                    "
                    :title="classData.name"
                    :description="' \n' + classData.description"
                  >
                    <i class="fas fa-envelope cursor-pointer"></i>
                  </ShareNetwork>
                  <ShareNetwork
                    network="SMS"
                    :url="`https://app.skillng.com/shared/` + classData._id"
                    :title="
                      'Register for the course below at SkillNG \n' + classData.name
                    "
                  >
                    <i class="fas fa-sms cursor-pointer"></i>
                  </ShareNetwork>
                </p>
              </div>
              <!--  -->
            </div>
            <!--  -->
            <div
              v-if="classData.curriculum !== undefined"
              class="mb-5 p-5 w-full rounded-xl border border-gray-300 bg-white shadow-md"
            >
              <p class="text-xl mb-3 font-semibold">Curriculum</p>

              <div
                v-for="(currModule, index) in classData.curriculum[0].curriculum"
                :key="currModule.index"
                class="flex"
              >
                <p class="flex-none font-semibold pr-3">Module {{ index + 1 }}:</p>
                <p class="flex-auto">{{ currModule.name }}</p>
              </div>
            </div>
            <!--  -->
            <div
              v-if="classData.learningList[0] !== '' && classData.inclusion[0] !== ''"
              class="mb-5 p-5 w-full rounded-xl border border-gray-300 bg-white shadow-md"
            >
              <div v-if="classData.learningList[0] !== ''">
                <p class="text-xl mb-3 font-semibold">What you'll learn</p>
                <div
                  v-for="learnlist in classData.learningList"
                  :key="learnlist.index"
                  class="flex"
                >
                  <i class="fal fa-check pr-4"></i>
                  <p>{{ learnlist }}</p>
                </div>
              </div>
              <div v-if="classData.inclusion[0] !== ''">
                <p class="text-lg my-1 mt-3 ml-3 font-medium">This class includes:</p>
                <div class="ml-5">
                  <div
                    v-for="inclusion in classData.inclusion"
                    :key="inclusion.index"
                    class="flex text-sm"
                  >
                    <i class="fal fa-plus text-gray-600 pr-4"></i>
                    <p>{{ inclusion }}</p>
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div
              v-if="classData.duration"
              class="mb-5 p-5 w-full rounded-xl border border-gray-300 bg-white shadow-md"
            >
              <p class="text-xl mb-3 font-semibold">Duration</p>

              <p>
                {{ classData.duration }}
              </p>
            </div>
            <!--  -->
            <div
              v-if="classData.requirements[0] !== ''"
              class="mb-5 p-5 w-full rounded-xl border border-gray-300 bg-white shadow-md"
            >
              <p class="text-xl mb-3 font-semibold">Requirements</p>

              <div
                v-for="requirement in classData.requirements"
                :key="requirement.index"
                class="ml-6"
              >
                <ul class="list-disc">
                  <li>{{ requirement }}</li>
                </ul>
              </div>
            </div>
            <!--  -->
            <div
              v-if="classData.audienceDescription"
              class="mb-5 p-5 w-full rounded-xl border border-gray-300 bg-white shadow-md"
            >
              <p class="text-xl mb-3 font-semibold">Who is this course for?</p>

              <p>
                {{ classData.audienceDescription }}
              </p>
            </div>
            <!--  -->
            <div
              v-if="classData.venue"
              class="mb-5 p-5 w-full rounded-xl border border-gray-300 bg-white shadow-md"
            >
              <p class="text-xl mb-3 font-semibold">Class Venue</p>

              <p>
                {{ classData.venue }}
              </p>
            </div>
            <!--  -->
            <div
              class="mb-5 p-5 w-full rounded-xl border border-gray-300 bg-white shadow-md"
            >
              <p class="text-xl mb-2 font-semibold">Tutors</p>

              <div class="center con-avatars">
                <vs-avatar-group>
                  <vs-avatar
                    size="70"
                    v-for="tutor in classData.tutors"
                    :key="tutor.index"
                  >
                    <vs-tooltip>
                      <img src="../assets/ProfileAvater.jpg" class="img-av" />
                      <template #tooltip> {{ tutor }} </template>
                    </vs-tooltip>
                  </vs-avatar>
                </vs-avatar-group>
              </div>
            </div>

            <!--wallet Debit Dailog  -->
            <vs-dialog prevent-close scroll width="350px" v-model="walletDailog">
              <div class="flex justify-center items-center p-3 overflow-hidden">
                <div>
                  <div v-if="insuficientBalance" class="m-5 p-2 text-center">
                    <i
                      class="fad fa-exclamation-circle text-yellow-400 text-4xl mb-2"
                    ></i>
                    <p>Insufficient Balance, kindly top-up your wallet.</p>
                    <router-link
                      to="/settings/my-balance"
                      class="flex justify-center items-center"
                    >
                      <vs-button border color="#FFCC29" class="">
                        <p class="text-sm font-semibold px-3 py-1 text-skillblack">
                          Balance Top-up
                        </p>
                      </vs-button>
                    </router-link>
                  </div>

                  <div v-else class="flex justify-center items-center">
                    <vs-button @click="payFromBalance" color="#FFCC29" class="">
                      <p class="text-sm font-semibold px-3 py-1 text-skillblack">
                        Pay from Balance (Your Balance ₦{{
                          walletbalance ? walletbalance.toLocaleString() : 0
                        }})
                      </p>
                    </vs-button>
                  </div>

                  <!--  -->
                  <div class="flex justify-center items-center">
                    <vs-button color="#FFCC29" class="">
                      <paystack
                        :amount="classData.price * 100"
                        :email="this.$store.state.authUser.email"
                        :paystackkey="'pk_live_a87e4916f0e775175aa3770a34a9bf101a8030ca'"
                        :reference="reference"
                        :callback="processPayment"
                        :close="paystackDialogClose"
                      >
                        <p class="text-sm font-semibold px-3 py-1 text-skillblack">
                          Pay with PayStack (₦{{ classData.price.toLocaleString() }})
                        </p>
                      </paystack>
                    </vs-button>
                  </div>
                </div>
              </div>
            </vs-dialog>

            <!-- Audit Class  Dialog -->
            <vs-dialog width="550px" v-model="auditClassDailog">
              <template #header>
                <h4 class="pt-3">
                  Are you sure you want to Audit <b>{{ classData.name }}</b>
                </h4>
              </template>

              <div class="con-content">
                <p class="text-center">
                  This means you'll have access to attend 25% of the class for free.
                  However, you'll need to pay for the class to access the remaining class
                  in the Cohort and get certified.
                </p>
              </div>

              <template #footer>
                <div class="flex justify-center">
                  <vs-button @click="freeClass" color="#FFCC29" style="color: black">
                    <p class="font-semibold">Audit Class</p>
                  </vs-button>
                  <vs-button @click="auditClassDailog = false" dark transparent>
                    Cancel
                  </vs-button>
                </div>
              </template>
            </vs-dialog>

            <!-- Reviews  -->
            <div
              v-if="userReviews.length !== 0"
              class="mb-32 p-5 w-full rounded-xl border border-gray-300 bg-white shadow-md"
            >
              <p class="text-xl mb-2 font-semibold">Reviews</p>
              <div class="flex w-full mb-5">
                <div class="w-4/12 mx-auto flex flex-col">
                  <p class="font-extralight text-5xl mb-1 text-center">
                    {{ totalRating.toFixed(1) }}
                  </p>
                  <star-rating
                    :increment="0.5"
                    :star-size="20"
                    :read-only="true"
                    :show-rating="false"
                    v-model="totalRating"
                    class="mb-2 flex mx-auto"
                  ></star-rating>
                  <div class="flex items-center mx-auto">
                    <i class="fas fa-user text-gray-500 text-xs mr-2"></i>
                    <p class="text-gray-800 text-sm">{{ userReviews.length }} total</p>
                  </div>
                </div>
                <div class="w-8/12">
                  <div class="grid grid-cols-12 gap-1 items-center">
                    <div class="col-span-1 text-sm font-medium text-center">
                      <p>5</p>
                      <p>4</p>
                      <p>3</p>
                      <p>2</p>
                      <p>1</p>
                    </div>
                    <div class="col-span-11 space-y-2">
                      <div
                        class="h-3 relative max-w-xl rounded-full bg-gray-100 overflow-hidden"
                      >
                        <div
                          class="h-full bg-green-500 absolute rounded-full"
                          :style="{ width: startRating5 + '%' }"
                        ></div>
                      </div>

                      <div
                        class="h-3 relative max-w-xl rounded-full bg-gray-100 overflow-hidden"
                      >
                        <div
                          class="h-full absolute rounded-full"
                          style="background-color: #78c8a1"
                          :style="{ width: startRating4 + '%' }"
                        ></div>
                      </div>

                      <div
                        class="h-3 relative max-w-xl rounded-full bg-gray-100 overflow-hidden"
                      >
                        <div
                          class="h-full bg-skilldeepyelow absolute rounded-full"
                          :style="{ width: startRating3 + '%' }"
                        ></div>
                      </div>

                      <div
                        class="h-3 relative max-w-xl rounded-full bg-gray-100 overflow-hidden"
                      >
                        <div
                          class="h-full absolute rounded-full"
                          style="background-color: #ffb234"
                          :style="{ width: startRating2 + '%' }"
                        ></div>
                      </div>

                      <div
                        class="h-3 relative max-w-xl rounded-full bg-gray-100 overflow-hidden"
                      >
                        <div
                          class="h-full absolute rounded-full"
                          style="background-color: #ff8b5a"
                          :style="{ width: startRating1 + '%' }"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <!--  -->
                </div>
              </div>

              <!--  -->
              <div v-for="review in userReviews" :key="review.index">
                <div class="flex items-center mb-2">
                  <p class="font-semibold pr-4">{{ review.user.fullName }}</p>
                  <star-rating
                    :star-size="15"
                    :read-only="true"
                    :show-rating="false"
                    v-model="review.star"
                  ></star-rating>
                  <p class="text-gray-800 text-sm ml-4">
                    {{ new Date(review.reviewDate).toDateString() }}
                  </p>
                </div>
                <p class="text-gray-800">
                  {{ review.comment }}
                </p>
                <div class="w-full border-1 border-b border-gray-200 my-2"></div>
              </div>
              <!--  -->
            </div>
            <!--  -->
          </div>
        </div>
      </div>
      <!-- Review Dialog -->
      <vs-dialog width="650px" v-model="ReviewDialogOpen">
        <template #header>
          <h4 class="not-margin">Review by {{ $store.state.authUser.fullName }}</h4>
        </template>
        <p class="mb-1">Reviews are public and editable.</p>

        <div class="">
          <textarea
            v-model="addReview.comment"
            class="w-full border rounded-md mb-2 p-3"
            rows="7"
            placeholder="Tell others what you think about this class. Would you recommend it for them?"
          ></textarea>
          <div class="flex items-center">
            <star-rating :show-rating="false" v-model="addReview.star"></star-rating>
            <p class="ml-3 text-sm">Rating required</p>
          </div>
        </div>

        <template #footer>
          <div class="footer-dialog">
            <vs-button
              :loading="loading"
              :disabled="addReview.comment !== '' && addReview.star !== 0 ? false : true"
              block
              class="mb-2 mt-1 disabled"
              style="color: black"
              @click="addNewReview"
            >
              Submit
            </vs-button>
          </div>
        </template>
      </vs-dialog>
      <!--  -->
    </div>
    <!-- side bar-->
    <class-suggestions class="hidden lg:block" @gclassID="reloadPageData">
    </class-suggestions>
  </section>
</template>

<script>
import axios from "../plugin/axios";
import StarRating from "vue-star-rating";
import ClassSuggestions from "../components/ClassSuggestions";
import paystack from "vue-paystack";
import { fireDB, fireBase } from "../ultilities/firebase";

export default {
  components: {
    "class-suggestions": ClassSuggestions,
    StarRating,
    paystack,
  },
  data() {
    return {
      loading: false,
      ReviewDialogOpen: false,
      isJoined: false,
      isPaid: false,
      rating: 3,
      classData: {
        price: 0,
        learningList: [],
        inclusion: [],
        requirements: [],
        startClass: { status: false },
        audienceDescription: "",
        duration: "",
        venue: "",
        name: "",
        description: "",
        classImage: "",
      },
      userReviews: [],
      addReview: {
        star: 0,
        comment: "",
      },
      notificationData: {},
      couponDetails: {},
      couponCode: "",
      couponLoading: false,
      couponDisable: false,
      couponOldAmount: null,
      couponSubmited: false,
      walletDailog: false,
      insuficientBalance: false,
      payFromBalanceCheck: false,
      auditClassDailog: false,
    };
  },

  methods: {
    async payFromBalance() {
      if (this.walletbalance < this.classData.price) {
        this.insuficientBalance = true;
      } else {
        this.payFromBalanceCheck = true;

        //debit user wallet
        const loading = this.$vs.loading();
        let token = this.$store.state.authUser.token;
        let url = "user/debit";
        let walletData = {
          userid: this.$store.state.authUser._id,
          amount: this.classData.price,
          refNo: this.reference,
          purpose: `Payment for ${this.classData.name}`,
          type: "debit",
          notificationData: {
            message: `Your SkillNG balance have been debited with ₦${this.classData.price}`,
            url: "https://app.skillng.com/settings/my-balance",
            imgUrl:
              "https://res.cloudinary.com/skillng/image/upload/v1626132590/skillng-app/t2fpd4ddbr98vz1rwqc2.png",
          },
        };
        await axios
          .post(url, walletData, {
            headers: {
              "x-access-token": token,
            },
          })
          .then(() => {
            loading.close();
            this.processPayment();
          })
          .catch((error) => {
            loading.close();
            this.$swal.fire({
              position: "center",
              icon: "error",
              title: `${error.response.data.message}`,
              showConfirmButton: false,
              timer: 5000,
              toast: true,
            });
          });
        //
      }
    },
    async reloadPageData(value) {
      const loading = this.$vs.loading();

      // loadClassData
      if (!value) {
        value = this.$route.params.gclassID;
      }

      let url = `gclass/details/${value}`;

      let token = this.$store.state.authUser.token;

      await axios
        .get(url, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          // console.log(response);
          let data = response.data.data;
          this.$store.commit("setClassUnit", data);
          this.classData = this.$store.state.classUnit;
          loading.close();
        })
        .catch(() => {});

      // loadUserData
      await axios
        .get(`user/profile/${this.$store.state.authUser._id}`, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          this.$store.commit("setUpdateWallet", response.data.data.tutorWallet);
          this.$store.commit("setJoinedGroupClass", response.data.data.groupClasses);
        })
        .catch(() => {});

      let joinedGroupClass = this.$store.state.joinedGroupClass;
      for (let i = 0; i < joinedGroupClass.length; i++) {
        if (joinedGroupClass[i].classid === this.classData.id) {
          this.isJoined = true;
          if (joinedGroupClass[i].classPaid === true) {
            this.isPaid = true;
            break;
          }
        }
      }

      // loadUserReviews
      let url2 = `reviews/class/${value}`;
      await axios
        .get(url2, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          let data = response.data.data;
          this.userReviews = data;
          // console.log(response);
        })
        .catch(() => {});
    },
    async freeClass() {
      const loading = this.$vs.loading();
      this.auditClassDailog = false;
      let url = "user/joinGClass/";
      let token = this.$store.state.authUser.token;
      let requestData = {
        userid: this.$store.state.authUser._id,
        classid: this.classData.id,
        amount: this.classData.price,
        classPaid: false,
      };

      await axios
        .post(url, requestData, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          //
          this.notificationData = {
            userid: this.$store.state.authUser.id,
            message: `You have successfully registered to audit ${this.classData.name}. 
            The class will start after the deadline has passed. You will be added to the class group chat where you can get general updates and interact with your fellow learners.`,
            url: "https://wa.me/2347061282516",
            imgUrl:
              "https://res.cloudinary.com/skillng/image/upload/v1626132590/skillng-app/t2fpd4ddbr98vz1rwqc2.png",
          };
          //
          let data = response.data.data.groupClasses;
          //console.log(data);
          this.$store.commit("setJoinedGroupClass", data);
          this.isJoined = true;
          loading.close();
          this.$swal.fire({
            position: "center",
            icon: "success",
            title: "Class registered successfully.",
            showConfirmButton: false,
            timer: 3000,
            toast: true,
          });
          if (this.couponSubmited == true) {
            this.useCoupon();
          }
          this.notification();
        })
        .catch((error) => {
          loading.close();

          this.$swal.fire({
            position: "top",
            icon: "error",
            title: `${error.response.data.message}`,
            showConfirmButton: false,
            timer: 5000,
            toast: true,
          });
        });
      //

      this.firebaseChat();
    },

    async processPaymentAudit() {
      const loading = this.$vs.loading();
      let url = "user/gclass/updatepayment/";
      let token = this.$store.state.authUser.token;
      let requestData = {
        userid: this.$store.state.authUser._id,
        classid: this.classData.id,
        classPaid: true,
      };

      await axios
        .post(url, requestData, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          //
          this.notificationData = {
            userid: this.$store.state.authUser.id,
            message: `
            You have successfully completed payment for ${this.classData.name}. 
            We hope you enjoy the rest of the class. Do not hesitate to reach out to us if you need any help.`,
            url: "https://app.skillng.com/classes",
            imgUrl:
              "https://res.cloudinary.com/skillng/image/upload/v1626132590/skillng-app/t2fpd4ddbr98vz1rwqc2.png",
          };
          //
          console.log(response);

          this.walletDailog = false;
          loading.close();
          this.$swal.fire({
            position: "center",
            icon: "success",
            title: "Payment updated successfully.",
            showConfirmButton: false,
            timer: 3000,
            toast: true,
          });

          if (this.couponSubmited == true) {
            this.useCoupon();
          }
          this.notification();
          this.reloadPageData();
        })
        .catch((error) => {
          loading.close();

          this.$swal.fire({
            position: "top",
            icon: "error",
            title: `${error.response.data.message}`,
            showConfirmButton: false,
            timer: 5000,
            toast: true,
          });
        });
    },

    async processPayment() {
      if (this.isJoined && !this.isPaid) {
        this.processPaymentAudit();
        return;
      }
      const loading = this.$vs.loading();
      let url = "user/joinGClass/";
      let token = this.$store.state.authUser.token;
      let requestData = {
        userid: this.$store.state.authUser._id,
        classid: this.classData.id,
        amount: this.classData.price,
        classPaid: true,
      };

      await axios
        .post(url, requestData, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          //
          this.notificationData = {
            userid: this.$store.state.authUser.id,
            message: `Your payment for ${this.classData.name} is successful, you have also been added to the class group chat where you can get general updates and interact with your fellow learners.`,
            url: "https://app.skillng.com/classes",
            imgUrl:
              "https://res.cloudinary.com/skillng/image/upload/v1626132590/skillng-app/t2fpd4ddbr98vz1rwqc2.png",
          };
          //
          let data = response.data.data.groupClasses;
          //console.log(data);
          this.$store.commit("setJoinedGroupClass", data);
          this.isJoined = true;
          this.walletDailog = false;
          loading.close();
          this.$swal.fire({
            position: "center",
            icon: "success",
            title: "Class registered successfully.",
            showConfirmButton: false,
            timer: 5000,
            toast: true,
          });

          if (this.couponSubmited == true) {
            this.useCoupon();
          }
          this.notification();
          this.reloadPageData();
          if (!this.payFromBalanceCheck) {
            this.transaction();
          }
        })
        .catch((error) => {
          loading.close();

          this.$swal.fire({
            position: "top",
            icon: "error",
            title: `${error.response.data.message}`,
            showConfirmButton: false,
            timer: 5000,
            toast: true,
          });
        });
      //
      this.firebaseChat();
    },
    paystackDialogClose() {},

    firebaseChat() {
      //Add user to chat
      let userId = `${this.$store.state.authUser.id}`;
      let userName = `${this.$store.state.authUser.fullName}`;
      let chatId = this.classData._id;
      let chatName = this.classData.name;
      let chatImage = this.classData.classImage;
      let chatDescription = this.classData.description;
      let newChatUser = fireDB.ref("users/" + userId).push();
      newChatUser.set({
        chatId: chatId,
        chatName: chatName,
        chatImage: chatImage,
        chatDescription: chatDescription,
        createdAt: fireBase.database.ServerValue.TIMESTAMP,
        lastMessage: userName + " added to this chat",
        lastMessageTimeStamp: fireBase.database.ServerValue.TIMESTAMP,
        unreadMessage: true,
      });

      //chat message//
      let newChat = fireDB.ref("chats/" + chatId).push();
      newChat.set({
        userId: "",
        message: userName + " added to this chat",
        createdAt: fireBase.database.ServerValue.TIMESTAMP,
      });

      //chat userlist for student
      let userEmail = this.$store.state.authUser.email;
      let userMobileNo = this.$store.state.authUser.mobileNo;
      let userProfilePic = this.$store.state.authUser.profilePicUrl;
      let newChatUserList = fireDB.ref("chatUserList/" + chatId).push();
      let chatKey = newChatUser.getKey();
      newChatUserList.set({
        chatId: chatId,
        chatKey: chatKey,
        userId: userId,
        userName: userName,
        userEmail: userEmail,
        userMobileNo: userMobileNo,
        userProfilePic: userProfilePic,
      });
    },

    async notification() {
      let url = "notifications/create";
      let token = this.$store.state.authUser.token;
      await axios
        .post(url, this.notificationData, {
          headers: {
            "x-access-token": token,
          },
        })
        .then(() => {
          // console.log(response);
        })
        .catch(() => {});
    },
    async transaction() {
      let token = this.$store.state.authUser.token;
      let url = "txn/create";
      let txnData = {
        userid: this.$store.state.authUser._id,
        amount: this.classData.price,
        refNo: this.reference,
        purpose: `Payment for Group Class - ${this.classData.name}`,
      };
      await axios
        .post(url, txnData, {
          headers: {
            "x-access-token": token,
          },
        })
        .then(() => {
          //console.log();
        })
        .catch(() => {});
    },
    bookmark() {
      this.$swal.fire({
        position: "bottom",
        icon: "success",
        title: "Class bookmarked sucessfully",
        showConfirmButton: false,
        timer: 3000,
        toast: true,
      });
    },

    async addNewReview() {
      this.loading = true;
      let url = "reviews/create";
      let token = this.$store.state.authUser.token;
      let formData = {
        user: this.$store.state.authUser._id,
        class: this.classData.id,
        school: this.classData.school[0].id,
        star: this.addReview.star,
        comment: this.addReview.comment,
      };

      await axios
        .post(url, formData, {
          headers: {
            "x-access-token": token,
          },
        })
        .then(() => {
          // console.log(response);
          this.loading = false;
          this.ReviewDialogOpen = false;
          this.addReview.comment = "";
          this.addReview.star = 0;
          this.loadUserReviews();
          this.$swal.fire({
            position: "bottom",
            icon: "success",
            title: "Review Submission Successful!",
            showConfirmButton: false,
            timer: 3000,
            toast: true,
          });
        })
        .catch(() => {});
    },
    async loadUserReviews() {
      let url = `reviews/class/${this.$route.params.gclassID}`;
      let token = this.$store.state.authUser.token;

      await axios
        .get(url, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          let data = response.data.data;
          this.userReviews = data;
          // console.log(response);
        })
        .catch(() => {});
    },
    async useCoupon() {
      let url = `coupon/use`;
      let token = this.$store.state.authUser.token;
      let formdate = {
        id: this.couponDetails.id,
        newUser: { userid: this.$store.state.authUser._id, classid: this.classData.id },
      };

      await axios
        .post(url, formdate, {
          headers: {
            "x-access-token": token,
          },
        })
        .then(() => {
          // console.log(response);
          this.couponSubmited = false;
        })
        .catch(() => {});
    },
    async validateCoupon() {
      this.couponLoading = true;
      let url = "coupon/check";
      let token = this.$store.state.authUser.token;
      await axios
        .post(
          url,
          { code: this.couponCode },
          {
            headers: {
              "x-access-token": token,
            },
          }
        )
        .then((response) => {
          this.couponDetails = response.data.data;
          this.couponLoading = false;
          this.couponDisable = true;
          this.couponOldAmount = this.classData.price;
          let newAmount = this.classData.price - response.data.data.amount;
          this.classData.price = newAmount;
          this.couponSubmited = true;
          this.$swal.fire({
            position: "top",
            icon: "success",
            title: "Success: Your coupon discount has been applied!",
            showConfirmButton: false,
            timer: 3000,
            toast: true,
          });
        })
        .catch((error) => {
          this.couponLoading = false;

          this.$swal.fire({
            position: "top",
            icon: "error",
            title: `${error.response.data.message}`,
            showConfirmButton: false,
            timer: 5000,
            toast: true,
          });
        });
    },
  },
  computed: {
    walletbalance() {
      return this.$store.state.authProfile.tutorWallet;
    },
    totalRating() {
      let total = 0;
      for (let i = 0; i < this.userReviews.length; i++) {
        total += this.userReviews[i].star;
      }
      return total / this.userReviews.length;
    },
    startRating5() {
      let count = 0;
      for (let i = 0; i < this.userReviews.length; i++) {
        if (this.userReviews[i].star == 5) {
          count += 1;
        }
      }
      return (count / this.userReviews.length) * 100;
    },
    startRating4() {
      let count = 0;
      for (let i = 0; i < this.userReviews.length; i++) {
        if (this.userReviews[i].star == 4) {
          count += 1;
        }
      }
      return (count / this.userReviews.length) * 100;
    },
    startRating3() {
      let count = 0;
      for (let i = 0; i < this.userReviews.length; i++) {
        if (this.userReviews[i].star == 3) {
          count += 1;
        }
      }
      return (count / this.userReviews.length) * 100;
    },
    startRating2() {
      let count = 0;
      for (let i = 0; i < this.userReviews.length; i++) {
        if (this.userReviews[i].star == 2) {
          count += 1;
        }
      }
      return (count / this.userReviews.length) * 100;
    },
    startRating1() {
      let count = 0;
      for (let i = 0; i < this.userReviews.length; i++) {
        if (this.userReviews[i].star == 1) {
          count += 1;
        }
      }
      return (count / this.userReviews.length) * 100;
    },

    reference() {
      let text = "";
      let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    },
  },

  async beforeMount() {
    if (this.$route.params.gclassID === undefined) {
      this.$router.push({ name: "Schools" });
      return;
    }
    const loading = this.$vs.loading();
    this.loadUserReviews();

    let url = `gclass/details/${this.$route.params.gclassID}`;
    let token = this.$store.state.authUser.token;

    await axios
      .get(url, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        // console.log(response);
        let data = response.data.data;
        this.$store.commit("setClassUnit", data);
        this.classData = this.$store.state.classUnit;
        loading.close();
      })
      .catch(() => {});

    let joinedGroupClass = this.$store.state.joinedGroupClass;
    for (let i = 0; i < joinedGroupClass.length; i++) {
      if (joinedGroupClass[i].classid === this.classData.id) {
        this.isJoined = true;
        if (joinedGroupClass[i].classPaid === true) {
          this.isPaid = true;
          break;
        }
      }
    }
  },
};
</script>

<style scoped>
.break-column {
  flex-basis: 100%;
  width: 0;
}
</style>
