import Vue from 'vue'
import store from '../store/index'
import VueRouter from 'vue-router'
import VueGtag from "vue-gtag"
import F404 from '../components/404'
import VerifyCertificate from '../components/VerifyCertificate'
import Dashboard from '../views/Dashboard'
import Notifications from '../views/Notifications'
import Messages from '../views/Messages'
import Profile from '../views/Profile'
import Settings from '../views/Settings'
import Classes from '../views/Classes'
import GroupClass from '../views/GroupClass'
import SharedClass from '../views/SharedClass'
import Request from '../views/Request'
import Tutor from '../views/Tutor'

import SettingsProfile from '../views/SettingsComponents/ProfileEdit'
import SettingsAccount from '../views/SettingsComponents/Account'
import SettingsBecomeTutor from '../views/SettingsComponents/BecomeTutor'
import SettingsUpdatePassword from '../views/SettingsComponents/UpdatePassword'
import SettingsTransaction from '../views/SettingsComponents/Transaction'
import SettingsBalance from '../views/SettingsComponents/Balance.vue'
import SettingsReferral from '../views/SettingsComponents/Referral.vue'




import Signin from '../views/sessions/Signin'
import Signup from '../views/sessions/Signup'
import ConfirmEmail from '../views/sessions/ConfirmEmail'
import ResetPassword from '../views/sessions/ResetPassword'

import School from '../views/School'
import Schools from '../views/Schools'


import AdminSignin from '../viewsAdmin/sessions/Signin'
import AdminDashboard from '../viewsAdmin/Dashboard'
import AdminSchools from '../viewsAdmin/Schools'
import AdminPrivateClass from '../viewsAdmin/PrivateClass'
import AdminGroupClass from '../viewsAdmin/GroupClass'
import AdminTutorRequestList from '../viewsAdmin/TutorRequestList'
import AdminUsers from '../viewsAdmin/Users'
import AdminReferralList from '../viewsAdmin/ReferralList'
import AdminCoupon from '../viewsAdmin/Coupon.vue'
import AdminSMS from '../viewsAdmin/SMS'



import Home from '../views/Home/Home'
import About from '../views/Home/About'
import PrivacyPolicy from '../views/Home/PrivacyPolicy'






Vue.use(VueRouter);

const routes = [

  {
    path: '/admin-signin',
    name: 'AdminSignin',
    component: AdminSignin,
    meta: { guest: true },
  },
  {
    path: '/admin',
    name: 'AdminDashboard',
    component: AdminDashboard,
    meta: { requiresAuth: true, is_admin: true },
  },
  {
    path: '/admin/schools',
    name: 'AdminSchools',
    component: AdminSchools,
    meta: { requiresAuth: true, is_admin: true },
  },
  {
    path: '/admin/private-class',
    name: 'AdminPrivateClass',
    component: AdminPrivateClass,
    meta: { requiresAuth: true, is_admin: true },
  },
  {
    path: '/admin/group-class',
    name: 'AdminGroupClass',
    component: AdminGroupClass,
    meta: { requiresAuth: true, is_admin: true },
  },
  {
    path: '/admin/tutor-request-list',
    name: 'AdminTutorRequestList',
    component: AdminTutorRequestList,
    meta: { requiresAuth: true, is_admin: true },
  },
  {
    path: '/admin/referral-list',
    name: 'AdminReferralList',
    component: AdminReferralList,
    meta: { requiresAuth: true, is_admin: true },
  },
  {
    path: '/admin/users',
    name: 'AdminUsers',
    component: AdminUsers,
    meta: { requiresAuth: true, is_admin: true },
  },
  {
    path: '/admin/coupon',
    name: 'AdminCoupon',
    component: AdminCoupon,
    meta: { requiresAuth: true, is_admin: true },
  },
  {
    path: '/admin/sms',
    name: 'AdminSMS',
    component: AdminSMS,
    meta: { requiresAuth: true, is_admin: true },
  },


  // home screens non logon
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { guest: true },
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
  // login screens 
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresAuth: true },
  },

  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications,
    meta: { requiresAuth: true },
  },
  {
    path: '/messages',
    name: 'Messages',
    component: Messages,
    meta: { requiresAuth: true },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: { requiresAuth: true },
  },

  {
    path: '/classes',
    name: 'Classes',
    component: Classes,
    meta: { requiresAuth: true },
  },
  {
    path: '/request',
    name: 'Request',
    component: Request,
    meta: { requiresAuth: true },
  },
  {
    path: '/tutor',
    name: 'Tutor',
    component: Tutor,
    meta: { requiresAuth: true },
  },

  {
    path: '/signin',
    name: 'Signin',
    component: Signin,
    meta: { guest: true }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    meta: { guest: true }
  },
  {
    path: '/confirm_email_true',
    name: 'ConfirmEmail',
    component: ConfirmEmail,
    meta: { guest: true }
  },
  {
    path: '/password_reset/*',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: { guest: true }
  },

  {
    path: '/schools',
    name: 'Schools',
    component: Schools,
    meta: { requiresAuth: true },
  },

  {
    path: '/school',
    component: School,
    name: 'School',
    meta: { requiresAuth: true },
  },



  {
    path: '/schools/group-class',
    name: 'GroupClass',
    component: GroupClass,
    meta: { requiresAuth: true },
  },
  {
    path: '/shared/*',
    name: 'SharedClass',
    component: SharedClass,
    meta: { guest: true },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'editprofile',
        component: SettingsProfile,
        name: 'EditProfile',
        meta: { requiresAuth: true },
      },
      {
        path: 'account',
        component: SettingsAccount,
        meta: { requiresAuth: true },
      },
      {
        path: 'become-tutor',
        component: SettingsBecomeTutor,
        meta: { requiresAuth: true },
      },
      {
        path: 'update-password',
        component: SettingsUpdatePassword,
        meta: { requiresAuth: true },
      },
      {
        path: 'transaction',
        component: SettingsTransaction,
        meta: { requiresAuth: true },
      },
      {
        path: 'my-balance',
        component: SettingsBalance,
        meta: { requiresAuth: true },
      },
      {
        path: 'referral',
        component: SettingsReferral,
        meta: { requiresAuth: true },
      },

    ]
  },

  { path: '*', component: F404 },
  { path: '/VerifyCertificate', component: VerifyCertificate, alias: '/verifycertificate' },
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

Vue.use(VueGtag, {
  config: { id: "AW-855908999" },
}, router);



// router.beforeEach((to, from, next) => {
//     if(to.meta.middleware) {
//       const middleware = require(`../middleware/${to.meta.middleware}`);
//       if(middleware) {
//           middleware.default(to, from, next, store);
//       } else {
//           next()
//       }

//   } else {
//       next()
//   }
// })



router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)

//   let user = JSON.parse(localStorage.getItem('skill_user'))
//   if (to.matched.some(record => record.meta.guest)) {
//     next()
//   }
//   else if (to.matched.some(record => record.meta.requiresAuth && record.meta.is_admin)) {
//     if (user.is_admin === true) {
//       next()
//     }
//     else {
//       next({ path: '/admin-signin' })
//     }
//   } 
//   else if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (user) {
//       next()
//     } else {
//       next({ path: '/signin' })
//     }
//   }  
//   else {
//     next()
//   }
// })





/////////////////////////

  if(to.matched.some(record => record.meta.requiresAuth)) {
      if (localStorage.getItem('skill_jwt') === null) {
        if (store.state.isAdminLoggedIn === true) {
          next()
        }else {   
        next({path: '/signin'})
        }
      } else {
          let user = JSON.parse(localStorage.getItem('skill_user'))
          if(to.matched.some(record => record.meta.is_admin)) {
              if(user.is_admin === true){
                  next()
              }
              else{
                next({path: '/admin-signin'})
              }
          }else {
              next()
          }
      }
  } else if(to.matched.some(record => record.meta.guest)) {
      if(localStorage.getItem('skill_jwt') === null){
          next()
      }
      else if(to.name == 'SharedClass' && localStorage.getItem('skill_jwt') !== null){
        next()
      }
      else{
        next({ name: 'Dashboard'})
      }
  }else {
      next()
  }
})


export default router
