<template>
  <div class="w-2/5">
    <div
      class="px-6 pt-4 pb-5 border-b border-slighter mb-5 flex items-center justify-between"
    >
      <h1 class="text-base font-medium text-skillblack">Recommended for you</h1>
      <div
        @click="loadSuggestions"
        class="flex justify-center items-center rounded-full h-8 w-8 hover:bg-yellow-100"
      >
        <i class="fad fa-sync-alt text-gray-500 text-center"></i>
      </div>
    </div>
    <div class="flex flex-col mx-8 sticky top-20">
      <!-- skeleton loader -->
      <div v-if="skeletonLoader">
        <div
          v-for="suggest in 2"
          :key="suggest.index"
          class="w-full my-3 rounded-lg shadow-md animate-pulse skeleton-box bg-gray-100 h-52"
        ></div>
      </div>

      <!--  -->
      <div v-if="!skeletonLoader">
        <div
          class="relative w-full cursor-pointer py-3"
          v-for="clas in classes"
          :key="clas.index"
        >
          <div
            @click="
              $emit('gclassID', clas._id),
                $router.push({ name: 'GroupClass', params: { gclassID: clas._id } })
            "
            class="relative block rounded-lg overflow-hidden shadow-md"
          >
            <div
              class="w-full h-full bg-black hover:opacity-10 opacity-0 absolute z-10"
            ></div>
            <div class="h-36">
              <img
                v-if="clas.classImage"
                class="absolute inset-0 h-full w-full object-cover z-0"
                :src="'https://res.cloudinary.com/skillng/image/fetch/' + clas.classImage"
                alt="Image"
              />
            </div>

            <div class="h-24 bg-vtrans opacity-90"></div>

            <div class="absolute left-3 bottom-1 text-white w-full z-10">
              <p class="text-xl font-semibold w-2/3">{{ clas.name }}</p>
              <p v-if="clas.price" class="font-semibold mb-2">
                ₦{{ clas.price.toLocaleString() }}
              </p>
              <p v-else class="font-semibold mb-2">Free class</p>
            </div>
            <div class="absolute right-3 bottom-1 grid justify-items-center z-10">
              <div
                class="text-center rounded-lg border bg-skillyelow text-skillblack w-20 p-1"
              >
                <p class="font-bold">{{ new Date(clas.dueDate).toDateString() }}</p>
              </div>
              <p class="text-white font-semibold text-sm mb-2">Deadline</p>
            </div>
          </div>
        </div>
      </div>

      <!--  -->
    </div>
  </div>
</template>

<script>
import axios from "../plugin/axios";
export default {
  data() {
    return {
      skeletonLoader: true,
      classes: [],
    };
  },
  methods: {
    async loadSuggestions() {
      this.skeletonLoader = true;
      let url = "gclass/suggestions";
      let token = this.$store.state.authUser.token;

      await axios
        .get(url, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          //console.log(response);
          let data = response.data.data;
          this.classes = data;
          this.skeletonLoader = false;
        })
        .catch(() => {});
    },
  },
  mounted() {
    this.loadSuggestions();
  },
};
</script>

<style></style>
