<template>
  <section class="flex">
    <div
      class="lg:w-3/5 w-full border border-slighter rounded-t-2xl shadow-md lg:pb-10 pb-16 min-h-screen h-full"
    >
      <div class="px-6 py-5 mb-4 flex items-center justify-between">
        <h1 class="text-xl font-medium text-skillblack">Request for a Tutor</h1>
      </div>

      <div class="mb-4 w-full">
        <!-- stage 1 -->
        <!-- Spin Loader -->
        <div
          v-if="initialLoader"
          class="flex justify-center items-center mt-24 text-yellow-600 text-9xl"
        >
          <i class="animate-spin fad fa-circle-notch"></i>
        </div>

        <div v-if="!initialLoader">
          <div v-if="stage === 1" class="mb-4 w-full">
            <div class="px-5 p-2 mb-4 lg:w-3/4 w-11/12">
              <p class="mb-2">Select a skill</p>
              <vs-select
                @change="selectClass"
                block
                filter
                placeholder="Select"
                v-model="skill"
              >
                <vs-option-group v-for="school in schoolData" :key="school.index">
                  <div v-if="school.privateClasses.length !== 0" slot="title">
                    {{ school.name }}
                  </div>
                  <vs-option
                    v-for="group in school.privateClasses"
                    :key="group.index"
                    :label="group.name"
                    :value="group.id"
                  >
                    {{ group.name }}
                  </vs-option>
                </vs-option-group>
              </vs-select>
            </div>

            <!-- class details -->
            <div v-if="skill" class="w-full">
              <div class="relative block overflow-visible">
                <div class="w-full h-full"></div>
                <div hidden>
                  <div class="h-96 z-10">
                    <img
                      v-if="classUnit.classImage"
                      class="inset-0 h-full w-full object-cover rounded-b-2rem bg-gray-200"
                      :src="
                        'https://res.cloudinary.com/skillng/image/fetch/' +
                        classUnit.classImage
                      "
                      alt="Class Image"
                    />
                  </div>
                  <!--  -->
                  <div class="absolute top-2 right-3 flex justify-between">
                    <vs-button @click="bookmark" icon color="#FFCC29">
                      <i class="far fa-bookmark"></i>
                    </vs-button>
                    <vs-button icon color="#000">
                      <i class="fas fa-share-alt"></i>
                    </vs-button>
                  </div>
                </div>
                <!--  -->
                <div class="w-full lg:px-12 md:px-10 px-6">
                  <div
                    class="mt-5 mb-5 p-5 w-full rounded-xl border border-gray-300 bg-white shadow-md"
                  >
                    <p class="text-xl mb-2 font-semibold">
                      {{ classUnit.name }}
                    </p>
                    <p class="font-normal mb-4">
                      {{ classUnit.description }}
                    </p>

                    <div>
                      <p class="text-sm font-semibold px-3 py-1 text-skillblack cursor">
                        Price ₦: {{ classUnit.price.toLocaleString() }}
                      </p>
                    </div>
                  </div>
                  <!--  -->
                  <div
                    class="mb-5 p-5 w-full rounded-xl border border-gray-300 bg-white shadow-md"
                  >
                    <p class="text-xl mb-3 font-semibold">Curriculum</p>

                    <div
                      v-for="(curr, index) in classUnit.curriculum"
                      :key="curr.index"
                      class="flex"
                    >
                      <p class="flex-none font-semibold pr-3">Module {{ index + 1 }}:</p>
                      <p class="flex-auto">{{ curr }}</p>
                    </div>
                  </div>
                  <!--  -->
                  <div
                    v-if="
                      classUnit.learningList[0] !== '' && classUnit.inclusion[0] !== ''
                    "
                    class="mb-5 p-5 w-full rounded-xl border border-gray-300 bg-white shadow-md"
                  >
                    <div v-if="classUnit.learningList[0] !== ''">
                      <p class="text-xl mb-3 font-semibold">What you'll learn</p>
                      <div
                        v-for="learn in classUnit.learningList"
                        :key="learn.index"
                        class="flex"
                      >
                        <i class="fal fa-check pr-4"></i>
                        <p>{{ learn }}</p>
                      </div>
                    </div>

                    <div v-if="classUnit.inclusion[0] !== ''">
                      <p class="text-lg my-1 mt-3 ml-3 font-medium">
                        This class includes:
                      </p>
                      <div class="ml-5">
                        <div
                          v-for="inclusion in classUnit.inclusion"
                          :key="inclusion.index"
                          class="flex text-sm"
                        >
                          <i class="fal fa-plus text-gray-600 pr-4"></i>
                          <p>{{ inclusion }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--  -->
                  <div
                    v-if="classUnit.duration"
                    class="mb-5 p-5 w-full rounded-xl border border-gray-300 bg-white shadow-md"
                  >
                    <p class="text-xl mb-3 font-semibold">Duration</p>

                    <p>
                      {{ classUnit.duration }}
                    </p>
                  </div>
                  <!--  -->
                  <div
                    v-if="classUnit.requirements[0] !== ''"
                    class="mb-5 p-5 w-full rounded-xl border border-gray-300 bg-white shadow-md"
                  >
                    <p class="text-xl mb-3 font-semibold">Requirements</p>

                    <div class="ml-6">
                      <ul class="list-disc">
                        <li v-for="req in classUnit.requirements" :key="req.index">
                          {{ req }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!--  -->
                  <div
                    v-if="classUnit.audienceDescription"
                    class="mb-5 p-5 w-full rounded-xl border border-gray-300 bg-white shadow-md"
                  >
                    <p class="text-xl mb-3 font-semibold">Who is this course for?</p>

                    <p>
                      {{ classUnit.audienceDescription }}
                    </p>
                  </div>

                  <!--  -->
                </div>
              </div>
            </div>

            <!-- button -->
            <div class="ml-10 mt-5">
              <!-- <vs-button
                active
                flat
                :disabled="!skill"
                @click="stage = 2"
                style="color: black"
              >
                <p class="p-1 mx-4 font-medium text-base">Continue</p>
              </vs-button> -->
              <vs-button
                ref="button"
                flat
                :active="!loading"
                :loading="loading"
                @click="ConfirmRequest"
                style="color: black"
              >
                <p class="p-1 mx-4 font-medium text-base">Place Request</p>
              </vs-button>
            </div>
          </div>
        </div>

        <!-- Empty class -->
        <div v-if="emptyPrivateClass" class="md:px-24 px-10 my-12 p-2 text-center">
          <i class="fad fa-exclamation-circle text-yellow-400 text-4xl mb-2"></i>
          <p>
            We're not currently taking any registration for private classes, kindly check
            back later or visit the "Schools" menu to register for our available group
            tutor-led classes.
          </p>
        </div>

        <!-- Stage 2 -->
        <div v-if="stage === 2" class="pb-5 px-5 p-2 mb-4 lg:w-3/4 w-11/12">
          <p class="mb-2">Prefered location</p>

          <vs-select block placeholder="Select" v-model="venue">
            <vs-option label="Online" value="Online"> Online </vs-option>
            <vs-option label="Physical" value="Physical"> Physical </vs-option>
            <vs-option label="Either" value="Either"> Either </vs-option>
          </vs-select>
          <!--  -->
          <div class="flex mt-5">
            <div>
              <vs-button active flat @click="stage = 1" style="color: black">
                <p class="p-1 mx-4 font-medium text-base">Back</p>
              </vs-button>
            </div>

            <div v-if="venue === 'Physical' || venue === 'Either'" class="ml-4">
              <vs-button
                active
                flat
                :disabled="!venue"
                @click="stage = 3"
                style="color: black"
              >
                <p class="p-1 mx-4 font-medium text-base">Continue</p>
              </vs-button>
            </div>
            <div v-if="venue === 'Online'" class="ml-4">
              <vs-button
                ref="button"
                flat
                :active="!loading"
                :loading="loading"
                @click="ConfirmRequest"
                style="color: black"
              >
                <p class="p-1 mx-4 font-medium text-base">Place Request</p></vs-button
              >
            </div>
          </div>
        </div>
        <!-- Stage 3 -->
        <div v-if="stage === 3">
          <div class="pb-5 px-5 p-2 mb-4 lg:w-3/4 w-11/12">
            <p class="mb-2">Search Address</p>
            <gmaps-map @mounted="ready" />
            <input
              ref="autocomplete"
              id="autocomplete"
              placeholder="Search Address"
              class="w-full pl-4 p-2 bg-gray-100 rounded-lg"
            />
          </div>
          <!--  -->
          <div v-if="mapError">
            <p class="text-red-600 text-sm pl-4">{{ mapError }}</p>
          </div>
          <!--  -->
          <div class="ml-4 mt-5">
            <div class="flex mt-5">
              <vs-button active flat @click="stage = 2" style="color: black">
                <p class="p-1 mx-4 font-medium text-base">Back</p>
              </vs-button>
              <vs-button
                ref="button"
                flat
                :active="!loading"
                :loading="loading"
                :disabled="requestAddress.fullAddress ? false : true"
                @click="Request"
                style="color: black"
              >
                <p class="p-1 mx-4 font-medium text-base">Place Request</p>
              </vs-button>
            </div>
          </div>
        </div>

        <!-- stage 4 -->
        <div v-if="stage === 4">
          <div class="pb-5 px-5 p-2 mb-4">
            <div class="text-center">
              <i
                class="fad fa-check-circle text-skilldeepyelow text-center text-5xl pb-3"
              ></i>
              <p class="text-lg">
                Request sent sucesfully! <br />You will be notified when a tutor accept
                your request.
              </p>
            </div>
          </div>
        </div>
        <!--  -->
      </div>
    </div>

    <!-- side bar-->
    <ClassSuggestions class="hidden lg:block" />
  </section>
</template>

<script>
import axios from "axios";
import axios1 from "../plugin/axios";
import { gmapsMap } from "x5-gmaps";
import ClassSuggestions from "../components/ClassSuggestions";
export default {
  components: { ClassSuggestions, gmapsMap },
  data() {
    return {
      emptyPrivateClass: false,
      initialLoader: null,
      autocomplete: null,
      getPlace: null,
      stage: 1,
      mapError: "",
      skill: "",
      venue: "Online",
      placesCord: [],
      placesAll: [],
      places: [],
      cordinates: [],
      loading: false,
      schoolData: [{ privateClasses: [{ name: "Loading" }] }],
      classUnit: {
        name: "Loading.......",
        price: 0,
        learningList: [],
        inclusion: [],
        requirements: [],
        audienceDescription: "",
        duration: "",
      },

      requestAddress: {
        fullAddress: "",
        localGovt: "",
        city: "",
        state: "",
        country: "",
        latitude: "",
        longitude: "",
      },
    };
  },

  methods: {
    ready() {
      this.$GMaps().then((maps) => {
        this.autocomplete = new maps.places.Autocomplete(this.$refs.autocomplete);
        this.autocomplete.setComponentRestrictions({ country: "ng" });
        this.autocomplete.setFields([
          "geometry",
          "formatted_address",
          "address_components",
        ]);
        this.autocomplete.addListener("place_changed", this.onPlaceChanged);
      });
    },
    onPlaceChanged() {
      this.getPlace = this.autocomplete.getPlace();
      if (!this.getPlace.geometry) {
        this.mapError = "No Result Found";
        return;
      }
      if (this.getPlace.address_components !== undefined) {
        // console.log("placechanged", this.getPlace);
        this.mapError = "";
        this.getAddressData();
      }
    },
    getAddressData() {
      this.requestAddress.latitude = this.getPlace.geometry.location.lat();
      this.requestAddress.longitude = this.getPlace.geometry.location.lng();
      this.requestAddress.fullAddress = this.getPlace.formatted_address;

      let administrative_area_level_2 = this.getPlace.address_components.filter(function (
        address_component
      ) {
        return address_component.types.includes("administrative_area_level_2");
      });
      this.requestAddress.localGovt = administrative_area_level_2.length
        ? administrative_area_level_2[0].long_name
        : "";
      //
      let locality = this.getPlace.address_components.filter(function (
        address_component
      ) {
        return address_component.types.includes("locality");
      });
      this.requestAddress.city = locality.length ? locality[0].long_name : "";
      //
      let administrative_area_level_1 = this.getPlace.address_components.filter(function (
        address_component
      ) {
        return address_component.types.includes("administrative_area_level_1");
      });
      this.requestAddress.state = administrative_area_level_1.length
        ? administrative_area_level_1[0].long_name
        : "";
      //
      let country = this.getPlace.address_components.filter(function (address_component) {
        return address_component.types.includes("country");
      });
      this.requestAddress.country = country.length ? country[0].long_name : "";
    },

    async Request() {
      this.loading = true;
      const url = `https://cors-anywhere-origin.herokuapp.com/https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${this.requestAddress.latitude},${this.requestAddress.longitude}&radius=1000&key=AIzaSyCp6QRFXvfzKuAwFM9wACvQhi1Hh3xdmBE`;
      axios
        .get(url)
        .then((response) => {
          // console.log(response);

          for (let i = 0; i < response.data.results.length; i++) {
            let res = response.data.results[i].geometry.location;
            this.cordinates.push(res);
          }
          for (let i = 0; i < this.cordinates.length; i++) {
            let cordLat = this.cordinates[i].lat.toString().slice(0, 12);
            let cordLng = this.cordinates[i].lng.toString().slice(0, 12);
            this.placesCord.push({ lat: parseFloat(cordLat), lng: parseFloat(cordLng) });
          }
          this.reverseGeocodeToCity();
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
    async reverseGeocodeToCity() {
      for (let i = 0; i < this.placesCord.length; i++) {
        const url = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${parseFloat(
          this.placesCord[i].lat
        )}&longitude=${parseFloat(this.placesCord[i].lng)}&localityLanguage=en`;
        const response = await axios.get(url);
        var locality = await response.data.locality;
        this.placesAll.push(locality);
      }
      await this.reverseCityToUnique();
    },
    async reverseCityToUnique() {
      // var latLng = [];
      // for (let i = 0; i < this.placesCord.length; i++) {
      //   var lat = this.placesCord[i].lat;
      //   var lng = this.placesCord[i].lng;
      //   latLng.push({ lat: lat, lng: lng });
      // }
      let unique = [...new Set(this.placesAll)];
      this.places = unique;
      this.loading = false;
      await this.ConfirmRequest();
    },
    bookmark() {
      this.$swal.fire({
        position: "bottom",
        icon: "success",
        title: "Class bookmarked sucessfully",
        showConfirmButton: false,
        timer: 3000,
        toast: true,
      });
    },
    async notification() {
      let url = "notifications/create";
      let token = this.$store.state.authUser.token;
      let notificationData = {
        userid: this.$store.state.authUser._id,
        message: `Your request for private class (${this.classUnit.name}) was sent successfully! You will be notified when a tutor accepts your request.`,
        url: "https://app.skillng.com/classes",
        imgUrl:
          "https://res.cloudinary.com/skillng/image/upload/v1626132590/skillng-app/t2fpd4ddbr98vz1rwqc2.png",
      };
      await axios
        .post(url, notificationData, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          console.log(response);
        })
        .catch(() => {});
    },

    async ConfirmRequest() {
      if (this.requestAddress.localGovt == "" && this.venue !== "Online") {
        this.$swal.fire({
          position: "top",
          icon: "error",
          title:
            "The selected address is not specific enough to pair you with a tutor. Please try again with more specific address.",
          showConfirmButton: false,
          timer: 15000,
          toast: true,
        });
        this.loading = false;

        return;
      }
      this.loading = true;
      let url = "classReq/create";
      let token = this.$store.state.authUser.token;
      let id = this.$store.state.authUser.id;

      await axios1
        .post(
          url,
          {
            userid: id,
            classid: this.skill,
            preferedLocation: this.venue,
            address: this.requestAddress,
            modules: this.classUnit.curriculum,
          },
          {
            headers: {
              "x-access-token": token,
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.stage = 4;
          this.loading = false;
          this.notification();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.stage = 2;
          this.$swal.fire({
            position: "top",
            icon: "error",
            title: `${error.response.data.message}`,
            // title: "No tutor(s) found in your location. try other location.",
            showConfirmButton: false,
            timer: 15000,
            toast: true,
          });
        });
    },

    async selectClass() {
      this.initialLoader = true;
      let url = `pclass/details/${this.skill}`;
      let token = this.$store.state.authUser.token;

      await axios
        .get(url, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          this.classUnit = response.data.data;
          this.initialLoader = false;
        })
        .catch(() => {});
    },
  },

  async mounted() {
    // this.$refs.address.focus();

    let url = "school/all";
    let token = this.$store.state.authUser.token;

    await axios
      .get(url, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        let data = response.data.data.schools;
        this.schoolData = data;

        for (let i = 0; i < data.length; i++) {
          if (data[i].privateClasses.length > 0) {
            this.emptyPrivateClass = false;
            break;
          }
        }
      })
      .catch(() => {});
  },
};
</script>

<style></style>
