<template>
  <section
    class="flex w-full h-almost overflow-y-auto border border-slighter rounded-t-2xl shadow-md"
  >
    <div class="z-10 w-full min-h-screen h-full lg:pb-10 pb-16">
      <div class="px-6 py-5 flex items-center justify-between">
        <h1 class="text-xl font-medium text-skillblack">Schools</h1>
      </div>

      <!-- school skeleton  loader -->
      <div class="lg:px-8 px-5" v-if="skeletonLoader">
        <div>
          <div class="py-8">
            <div class="h-80 bg-gray-100 rounded-lg animate-pulse skeleton-box"></div>
          </div>
        </div>
      </div>
      <!--  -->
      <!-- school list -->
      <div class="lg:px-8 px-5 mb-10" v-if="!skeletonLoader">
        <span class="p-2 sm:hidden block italic">Swipe Right>>></span>
        <splide :options="options1">
          <splide-slide
            class="pb-8 sm:pt-5 cursor-pointer"
            v-for="school in filterSchool"
            :key="school.index"
          >
            <div
              class="h-80 shadow-xl"
              @click="$router.push({ name: 'School', params: { schoolID: school.id } })"
            >
              <img
                v-if="school.schoolImage"
                class="inset-0 h-full w-full rounded-t-lg object-cover bg-gray-200"
                :src="
                  'https://res.cloudinary.com/skillng/image/fetch/' + school.schoolImage
                "
                alt="Image"
              />
            </div>

            <div class="bg-gray-800 py-2 rounded-b-lg border-t border-gray-300">
              <div class="text-white font-light text-3xl text-center">
                <p class="py-4">{{ school.name }}</p>
              </div>
            </div>
          </splide-slide>
        </splide>
      </div>
      <!--  -->

      <!-- group class skeleton  loader -->
      <div class="lg:px-8 px-5 mb-10" v-if="skeletonLoader">
        <div>
          <div class="py-8">
            <div class="h-36 bg-gray-100 rounded-lg animate-pulse skeleton-box"></div>
          </div>
        </div>
      </div>
      <!--  -->

      <!-- Group Class List -->
      <div v-if="!skeletonLoader" class="lg:pb-10 pb-16">
        <div v-for="school in filterSchool" :key="school.index" class="lg:px-8 px-5 mb-8">
          <div v-show="school.groupClasses.length !== 0">
            <p class="font-semibold text-sm text-gray-600">
              Available group classes from <b>{{ school.name }} </b>
              <span class="sm:hidden block italic">Swipe Right>>></span>
            </p>
            <splide :options="options2">
              <splide-slide
                class="py-2 cursor-pointer"
                v-for="gclass in school.groupClasses"
                :key="gclass.index"
              >
                <div
                  @click="
                    $router.push({ name: 'GroupClass', params: { gclassID: gclass.id } })
                  "
                  class="relative block rounded-lg overflow-hidden shadow-md"
                >
                  <div
                    class="w-full h-full bg-black hover:opacity-10 opacity-0 absolute z-10"
                  ></div>
                  <div class="h-36">
                    <img
                      v-if="gclass.classImage"
                      class="absolute inset-0 h-full w-full object-cover z-0"
                      :src="
                        'https://res.cloudinary.com/skillng/image/fetch/' +
                        gclass.classImage
                      "
                      alt="Image"
                    />
                  </div>

                  <div class="h-32 bg-vtrans opacity-90"></div>

                  <div class="absolute left-3 bottom-1 text-white w-full z-10">
                    <p class="text-xl font-semibold w-2/3">{{ gclass.name }}</p>
                    <p v-if="gclass.price" class="font-semibold mb-2">
                      ₦{{ gclass.price.toLocaleString() }}
                    </p>
                    <p v-else class="font-semibold mb-2">Free class</p>
                  </div>
                  <div class="absolute right-3 bottom-1 grid justify-items-center z-10">
                    <div
                      class="text-center rounded-lg border bg-skillyelow text-skillblack w-20 p-1"
                    >
                      <p class="font-bold">
                        {{ new Date(gclass.dueDate).toDateString() }}
                      </p>
                    </div>
                    <div>
                      <p class="text-white font-semibold text-sm mb-2">Deadline</p>
                    </div>
                  </div>
                </div>

                <!-- for bookmark change hidden to flex   -->
                <div class="absolute top-2 right-2 hidden justify-between z-10">
                  <vs-button @click="bookmark" icon color="#fdffc2">
                    <i class="far fa-bookmark vs-icon-black"></i>
                  </vs-button>
                  <vs-button icon color="#000">
                    <i class="fas fa-share-alt"></i>
                  </vs-button>
                </div>
                <!--  -->
              </splide-slide>
            </splide>
          </div>
        </div>
      </div>

      <!--  -->
      <div class="mt-10"></div>
    </div>
  </section>
</template>

<script>
import axios from ".././plugin/axios";

export default {
  data() {
    return {
      skeletonLoader: true,
      schools: {},
      options1: {
        rewind: true,
        pagination: false,
        perPage: 2,
        perMove: 1,
        breakpoints: {
          1024: { perPage: 2 },
          640: { perPage: 1 },
        },
        gap: "1rem",
      },
      options2: {
        rewind: true,
        pagination: false,
        perPage: 3,
        perMove: 1,
        breakpoints: {
          1024: { perPage: 2 },
          640: { perPage: 1 },
        },
        gap: "1rem",
      },
    };
  },
  computed: {
    filterSchool() {
      return this.schools.filter(function (item) {
        return item.groupClasses.length !== 0;
      });
    },
  },
  methods: {
    bookmark() {
      this.$swal.fire({
        position: "bottom",
        icon: "success",
        title: "Class bookmarked sucessfully",
        showConfirmButton: false,
        timer: 3000,
        toast: true,
      });
    },
  },

  async beforeMount() {
    if (this.$store.state.schools.length === 0) {
      let url = "school/all/active";
      let token = this.$store.state.authUser.token;

      await axios
        .get(url, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          let data = response.data.data.schools;
          this.$store.commit("setSchools", data);
          this.schools = this.$store.state.schools;
          this.skeletonLoader = false;
        })
        .catch(() => {});
    }
    this.schools = this.$store.state.schools;
    this.skeletonLoader = false;
  },
};
</script>

<style></style>
