<template>
  <section class="w-full px-6 py-2 h-almost overflow-y-auto pb-24">
    <div
      class="lg:block hidden -ml-6 min-h-screen h-screen border-l border-slighter fixed"
    ></div>

    <div
      class="bg-skilldeepyelow text-skillblack shadow-lg rounded-3xl mb-5 mx-8 lg:mt-12 mt-8 lg:px-10 lg:py-10 px-6 py-8 lg:w-4/5"
    >
      <h1 class="lg:px-5 px-0 font-light text-5xl mb-5">Select Course</h1>
      <p class="mb-3 lg:px-5 px-0 text-lg">
        Learn and improve your digital skills across design, business, digital marketing,
        programming, data science and more!
      </p>
      <router-link to="/schools">
        <button class="flex items-center lg:px-5 px-0 text-lg">
          <p class="pr-3 font-regular">Get Started</p>
          <i class="fal fa-long-arrow-right animate-bounce-r"></i>
        </button>
      </router-link>
    </div>
    <!--  -->
    <div
      class="bg-gray-800 hover:bg-gray-900 text-white shadow-lg rounded-3xl mb-5 mx-8 mt-8 lg:px-10 lg:py-10 px-6 py-8 lg:w-4/5"
    >
      <h1 class="lg:px-5 px-0 font-extralight text-3xl mb-1 text-center">
        Get personal learning recommendations
      </h1>
      <p class="mb-3 lg:px-5 px-0 text-lg font-extralight text-center">
        Answer a few questions
      </p>
      <div class="flex justify-center">
        <button
          class="flex items-center px-5 text-lg bg-transparent hover:bg-yellow-500 text-skilldeepyelow hover:text-white py-2 border border-skilldeepyelow rounded"
        >
          <p class="pr-3 font-regular">Coming soon</p>
          <i class="fal fa-long-arrow-right animate-bounce-r"></i>
        </button>
      </div>
    </div>
    <!--  -->
  </section>
</template>

<script>
export default {};
</script>

<style></style>
