<template>
  <div class="px-6 pt-4 pb-5 sticky top-0 bg-white z-40">
    <section
      v-if="dropdown"
      @click="dropdown = false"
      class="z-40 h-screen w-screen fixed top-0 overflow-hidden"
    ></section>
    <div class="flex items-center justify-between">
      <div class="relative lg:w-96 sm:-ml-0 -ml-3">
        <input
          type="text"
          class="focus:outline-none bg-slightyellow rounded-full pl-10 w-full p-2 text-black text-base"
          placeholder="Search Course"
        />
        <i class="fas fa-search absolute left-4 top-2 pt-1 font-light"></i>
      </div>

      <div>
        <div class="flex items-center">
          <!--  -->
          <router-link to="/notifications" class="relative">
            <button class="hover:bg-mustardyellow rounded-full w-10 h-10 mr-4">
              <i class="far fa-bell font-thin text-xl"></i>
            </button>
            <div
              v-if="notificationCount"
              class="absolute animate-ping right-6 top-2 bg-yellow-500 rounded-full h-2.5 w-2.5"
            ></div>
            <div
              v-if="notificationCount"
              class="absolute shadow right-6 top-2 bg-yellow-500 rounded-full h-2 w-2"
            ></div>
          </router-link>

          <button @click="dropdown = !dropdown" class="mr-1 lg:mr-8 w-10 h-10">
            <img
              v-if="this.$store.state.authProfile.profilePicUrl"
              :src="
                'https://res.cloudinary.com/skillng/image/fetch/' +
                this.$store.state.authProfile.profilePicUrl
              "
              class="w-10 h-10 object-cover rounded-lg bg-gray-200 shadow-md"
            />
            <img
              v-else
              src="../../assets/ProfileAvater.jpg"
              class="w-10 h-10 object-cover rounded-lg bg-gray-200 shadow-md"
            />
          </button>
        </div>
      </div>
      <!--  -->
    </div>
    <!-- dropdown -->

    <div>
      <transition
        enter-active-class="transition ease-out duration-200"
        enter-from-class="opacity-0 translate-y-1"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transition ease-in duration-200"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 translate-y-1"
      >
        <div
          v-if="dropdown"
          class="z-50 absolute top-16 right-8 w-48 rounded-lg shadow-md border border-clightest bg-white mb-20 overflow-hidden"
        >
          <router-link v-for="tab in tabs" :key="tab.title" :to="tab.route">
            <button
              @click="dropdown = false"
              class="w-full text-left hover:bg-slightyellow border-b border-slighter p-3 text-sm focus:outline-none"
            >
              <div class="flex items-center">
                <i :class="tab.icon"></i>
                <p class="ml-2">{{ tab.title }}</p>
              </div>
            </button>
          </router-link>
          <button
            @click="logout"
            class="w-full text-left hover:bg-slightyellow border-b border-slighter p-3 text-sm focus:outline-none"
          >
            <div class="flex items-center">
              <i class="fad fa-sign-out"></i>
              <p class="ml-2">Sign Out</p>
            </div>
          </button>
        </div>
      </transition>
    </div>

    <!-- end dropdown -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      dropdown: false,
      tabs: [
        {
          icon: "far fa-user",
          title: "Profile",
          route: "/profile",
        },
        {
          icon: "fas fa-chalkboard-teacher",
          title: "My Classes",
          route: "/classes",
        },
      ],
    };
  },
  methods: {
    logout() {
      this.dropdown = false;
      localStorage.removeItem("skill_user");
      localStorage.removeItem("skill_jwt");
      this.$store.commit("setAuthUser", {});
      this.$store.commit("setAuthProfile", {});
      this.$store.commit("setIsLoggedIn", false);
      this.$router.push({ name: "Home" });
    },
  },

  computed: {
    notificationCount() {
      let status = false;
      let count = this.$store.state.unreadNotification;
      if (count >= 1) {
        status = true;
      }
      return status;
    },
  },
};
</script>

<style></style>
