import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoggedIn: false,
    isAdminLoggedIn: false,
    isTutor: false,
    authUser: {},
    authProfile: {},
    schools: [],
    schoolsHome: [],
    groupClass: [],
    privateClass: [],
    schoolUnit: {},
    classUnit: {},
    joinedGroupClass: [],
    usersList: [],
    unreadNotification: 0,
    unreadMessage: false,
    profileReady: false,
  },
  mutations: {
    setIsLoggedIn (state, payload) {
      state.isLoggedIn = payload;
    },
    setIsAdminLoggedIn (state, payload) {
      state.isAdminLoggedIn = payload;
    },
    setAuthUser(state, payload) {
      state.authUser = payload;
    },
    setAuthProfile(state, payload) {
      state.authProfile = payload;
    },
    setSchools(state, payload) {
      state.schools = payload;
    },
    setSchoolsHome(state, payload) {
      state.schoolsHome = payload;
    },
    setGroupClass(state, payload) {
      state.groupClass = payload;
    }, 
    setPrivateClass(state, payload) {
      state.privateClass = payload;
    },    
    setSchoolUnit(state, payload) {
      state.schoolUnit = payload;
    },
    setClassUnit(state, payload) {
      state.classUnit = payload;
    },
    setJoinedGroupClass(state, payload) {
      state.joinedGroupClass = payload;
    },
    setIsTutor(state, payload) {
      state.isTutor = payload;
    },
    setUsersList(state, payload) {
      state.usersList = payload;
    },
    setUnreadNotification(state, payload) {
      state.unreadNotification = payload;
    },
    setUnreadMessage(state, payload) {
      state.unreadMessage = payload;
    },
    setProfileReady(state, payload) {
      state.profileReady = payload;
    },
    setUpdateWallet(state, payload) {
      state.authProfile.tutorWallet = payload;
    },
  },
  actions: {
  },
  modules: {
  },
})

